import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { BTCPriceCard } from "../common/BTCPriceCard";
import { NotificationProvider } from "../common/NotificationProvider";
import { WinningRanks } from "../common/WinningRanks";
import { useCurrentRound } from "../hooks/contracts/useCurrentRound";
import { RoundStatus, useRoundStatus } from "../hooks/contracts/useRoundStatus";
import { useBillionboxRanking } from "../hooks/useBillionboxRanking";
import { AddNumber } from "../portion-box-room/AddNumber";
import { DashboardContainer } from "../portion-box-room/DashboardContainer";
import { GraphPriceInfo } from "../portion-box-room/GraphPriceInfo";
import { WaitingForDraw } from "../portion-box-room/WaitingForDraw";
import { HowToPlay } from "../portion-box/HowToPlay";
import { Questions } from "../portion-box/Questions";
import { RoomDataProvider } from "../portion-box/RoomDataProvider";
import { WinningCriteria } from "../portion-box/WinningCriteria";
import { useNumberStore } from "../store/number-store";

export function PortionBoxRoom() {
  const { room = "" } = useParams();
  const { clearNumbers } = useNumberStore.getState();

  const round = useCurrentRound(room, true);
  const roundStatus = useRoundStatus(round.round, true);
  const { ranking = [] } = useBillionboxRanking(room);

  useEffect(() => {
    clearNumbers();
  }, [clearNumbers]);

  return (
    <>
      <NotificationProvider>
        <RoomDataProvider onlyRoom={room}>
          <DashboardContainer />
          <div className="relative py-3 bg-gradient-black">
            <img
              alt=""
              className="absolute z-0 opacity-30"
              src={require("../assets/cube-trans4.png")}
            />
            <img
              alt=""
              className="absolute right-0 bottom-12 w-72 opacity-30"
              src={require("../assets/cube-trans.png")}
            />
            <div className="container relative z-10 mx-auto">
              <GraphPriceInfo toggleable digit={room} />
              <BTCPriceCard className="mt-12" />
            </div>
          </div>
          {roundStatus === RoundStatus.open && <AddNumber />}
          {roundStatus !== RoundStatus.open && <WaitingForDraw />}
        </RoomDataProvider>
      </NotificationProvider>
      <WinningRanks room={room} ranks={ranking} />
      <HowToPlay />
      <WinningCriteria />
      <Questions />
    </>
  );
}
