import { useMemo } from "react";
import { useRoundData } from "./useRoundData";
import { BigNumberish } from "ethers";
import { RoomDigit } from "./../config";

export const useSoldNumber = (room: RoomDigit, roundId?: BigNumberish) => {
  const { buyers } = useRoundData(room, roundId);

  return useMemo(() => {
    return buyers.reduce((prev, buyer) => {
      const prevSize = prev.get(buyer.number) ?? 0;
      prev.set(buyer.number, prevSize + buyer.size);

      return prev;
    }, new Map<number, number>());
  }, [buyers]);
};
