import { RoomDigit } from "../config";
import { Rank } from "../hooks/useBillionboxRanking";
import { TableWinningRanks } from "../portion-box/TableWinningRanks";

type WinningRanksProp = {
  ranks: Rank[];
  tabComponent?: JSX.Element;
  room: RoomDigit;
};

export function WinningRanks({ ranks, room, tabComponent }: WinningRanksProp) {
  return (
    <div id="2" className="bg-our-game">
      <div className="container py-16 mx-auto">
        <p className="text-4xl text-center font-audiowide lg:text-5xl">
          <span className="text-gradient gold">WINNING </span>
          <span>RANKS</span>
        </p>
        <p className="pt-3 text-lg font-light text-center">At the beginning of the competition</p>
        {tabComponent}
        <TableWinningRanks room={room} ranks={ranks} />
      </div>
    </div>
  );
}
