import { useContractEvent } from "wagmi";
import PortionBox from "../../contracts/PortionBox.json";
import { roomAddress, RoomDigit } from "./../../config";
import { usePortionBoxRead } from "./usePortionBoxRead";

export const useCurrentRound = (room: RoomDigit, enabled: boolean) => {
  const {
    data: currentRoundId,
    contractRead: { refetch: refetchRoundId },
  } = usePortionBoxRead({
    room,
    enabled,
    watch: true,
    functionName: "currentRoundId",
  });

  const {
    data: round,
    contractRead: { refetch: refetchRound },
  } = usePortionBoxRead({
    room,
    functionName: "rounds",
    args: currentRoundId,
    enabled: Boolean(currentRoundId),
  });

  useContractEvent({
    addressOrName: roomAddress[room],
    contractInterface: PortionBox,
    eventName: "StartRound",
    listener() {
      refetchRoundId();
    },
  });

  return { roundId: currentRoundId?.toNumber(), round, refetchRound };
};
