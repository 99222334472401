import { ClassicMode } from "../prediction-pro/ClassicMode";
import { ProMode } from "../prediction-pro/ProMode";

export enum ModeType {
  UpDown = "up-down",
  HighLow = "high-low",
  EvenOdd = "even-odd",
}

export function SelectMode() {
  return (
    <div className="relative bg-center bg-no-repeat bg-cover bg-mode ">
      <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black z-0"></div>
      <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black z-0"></div>
      <div className="container z-10 pt-4 lg:px-40">
        <div className="flex flex-col justify-center space-y-4 lg:space-y-0 lg:space-x-6 lg:flex-row">
          {/* <ClassicMode /> */}
          <ProMode />
        </div>
      </div>
    </div>
  );
}
