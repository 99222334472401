import { ModeType } from "../common/SelectMode";
import clsx from "clsx";
import { useLocation } from "react-router-dom";
import { useAssetContext } from "../context/PredictionAssetContext";

export function ProMode() {
  const game = useLocation().pathname.split("/")[2];
  const hash = useAssetContext();

  function changeGame(game: ModeType) {
    if (game === ModeType.HighLow) {
      window.location.href = `/prediction-pro/high-low${hash}`;
    } else if (game === ModeType.EvenOdd) {
      window.location.href = `/prediction-pro/even-odd${hash}`;
    }
  }

  return (
    <div className="z-10 px-5 pt-5 pb-8 border-2 border-dotted rounded-lg lg:basis-2/3 basis-1/2 border-smoke">
      <p className="text-2xl font-semibold text-center uppercase text-gradient gold">Pro MODE</p>
      <p className="mt-2 text-lg font-light text-center">Play with BillionBox multiplier rate</p>
      <div className="grid grid-cols-2 gap-2 lg:gap-5">
        <button
          onClick={() => {
            changeGame(ModeType.HighLow);
          }}
        >
          <div
            className={clsx(
              "relative flex flex-col items-center justify-center lg:px-5 px-3 pt-7 pb-10 mt-4",
              game === ModeType.HighLow
                ? "card-border-gradient-blue-bg-gradient-blue"
                : "card-border-light-gradient-silver-bg-black-to-blue"
            )}
          >
            <img className="w-16" src={require("../assets/prediction-pro/high-low.png")} alt="" />
            <p className="mt-5 text-xl font-bold text-center uppercase lg:text-3xl">HIGH / LOW</p>
            <p className="mt-1 font-light text-center lg:text-lg text-light-gray">
              Predict Decimal Price will High ≥ 50 or <br />
              Low ≤ 49 From Result price!
            </p>
          </div>
        </button>

        <button
          // onClick={() => {
          //   changeGame(ModeType.EvenOdd);
          // }}
          disabled
          className="relative"
        >
          <div className="absolute inset-0 flex items-center justify-center">
            <div className="z-10 text-2xl font-bold -rotate-12 text-gradient gold font-roboto-condensed">COMING SOON</div>
          </div>
          <div
            className={clsx(
              "relative opacity-20 flex flex-col items-center justify-center lg:px-5 px-3 pt-7 pb-10 mt-4 card-border-light-gradient-silver-bg-black-to-blue",
              game === ModeType.EvenOdd
                ? "card-border-gradient-blue-bg-gradient-blue"
                : "card-border-light-gradient-silver-bg-black-to-blue"
            )}
          >
            <img className="w-16" src={require("../assets/prediction-pro/even-odd.png")} alt="" />
            <p className="mt-5 text-xl font-bold text-center uppercase lg:text-3xl">EVEN / ODD</p>
            <p className="mt-1 font-light text-center opacity-60 lg:text-lg text-light-gray">
              Predict Decimal Price will EVEN or ODD From Result price!
            </p>
          </div>
        </button>
      </div>
    </div>
  );
}
