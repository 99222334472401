import { ClaimReward } from "./ClaimReward";
import { TotalWeeklyPrizes } from "./TotalWeeklyPrizes";

export function Banner() {
  return (
    <div className="lg:bg-prediction bg-prediction-mobile bg-no-repeat bg-cover relative lg:pt-[120px] pt-[170px]">
      <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black z-0 lg:block hidden"></div>
      <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black z-0"></div>
      <div className="container pt-10 pb-10 mx-auto">
        <div className="z-10 grid grid-cols-1 px-6 lg:grid-cols-3">
          <div className="z-10 lg:col-span-2">
            <img
              className="max-w-full w-[500px] -ml-10"
              src={require("../assets/prediction/title.png")}
              alt=""
            />
            <p className="-mt-2 font-light text-left lg:text-lg lg:-mt-4">
              Will BTC, ETH, or Matic price rise or fall? Make the right guess to win!
            </p>
            <div className="flex flex-col mt-6 xl:flex-row">
              <div className="flex justify-center lg:justify-start">
                <img className="h-10" src={require("../assets/secure-by-gold@2x.png")} alt=""></img>
                <img
                  className="h-10 ml-2 xl:mx-5"
                  src={require("../assets/chainlink@2x.png")}
                  alt=""
                ></img>
              </div>
              <div className="flex justify-center mt-6 lg:justify-start xl:mt-0">
                <a
                  href="https://info.billionbox.io/product/the-prediction-platform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mr-10"
                >
                  <img className="h-5 mr-2" src={require("../assets/icon/question.png")} alt="" />
                  <p className="text-sm uppercase text-gradient gold">How to play</p>
                </a>
                <a
                  href="https://info.billionbox.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <img className="h-5 mr-2" src={require("../assets/icon/doc.png")} alt="" />
                  <p className="text-sm uppercase text-gradient gold">doc</p>
                </a>
              </div>
            </div>
          </div>
          <div className="grid justify-center grid-flow-col gap-4 mt-8 lg:grid-rows-2 auto-cols-max lg:justify-end lg:mt-0">
            <TotalWeeklyPrizes />
            <ClaimReward />
          </div>
        </div>
      </div>
    </div>
  );
}
