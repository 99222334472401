import dayjs from "dayjs";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { RoomDigit } from "../../config";
import { Round } from "../../hooks/contracts/usePortionBoxRead";
import { RoundCountdownDisplay } from "./RoundCountdownDisplay";

const time: Record<string | number, string> = {
  1: "4 hours",
  2: "4 hours",
  3: "24 hours",
  4: "24 hours",
  5: "24 hours",
};

export const TopRoundCountdownDisplay = (props: { round?: Round; digit: RoomDigit }) => {
  const roundTime = time[props.digit];
  return (
    <RoundCountdownDisplay
      round={props.round}
      drawComponent={(countdown, endRoundDate) => (
        <div className="space-y-[2px]">
          <div className="mb-0 text-base">Next Draw in</div>
          <div className="text-xl font-bold text-gradient gold lg:text-2xl">
            {!props.round ? <Skeleton containerClassName="inline-block w-[120px]" /> : countdown}
          </div>
          <div className="space-x-2 text-[10px]">
            <span className="text-white/70">
              At {dayjs(endRoundDate).format("MMM DD, H:mm:ss")}
            </span>
            <span className="text-white/40">Every {roundTime} / round</span>
          </div>
        </div>
      )}
      executingComponent={(countdown) => (
        <>
          <p className="text-sm font-bold text-gradient gold lg:text-2xl">
            {!props.round ? <Skeleton containerClassName="inline-block w-[120px]" /> : countdown}
          </p>
          <p className="mb-0 text-md lg:mb-3">
            Waiting for a draw
            <br />
            the winner will be announce.
          </p>
        </>
      )}
      calculatingComponent={
        <>
          <p className="mb-0 text-base lg:text-xl md:text-lg lg:mb-3">Draw in</p>
          <p className="text-lg font-medium text-center text-gradient gold lg:text-xl">
            {!props.round ? (
              <Skeleton containerClassName="inline-block w-[120px]" />
            ) : (
              <>
                <motion.img
                  animate={{ y: -5 }}
                  transition={{
                    repeat: Infinity,
                    repeatType: "reverse",
                    duration: 1,
                    type: "tween",
                  }}
                  alt="logo"
                  className="inline-block w-5 mr-2 lg:w-7"
                  src="/bb-logo.svg"
                />{" "}
                Calculating..
              </>
            )}
          </p>
        </>
      }
    />
  );
};
