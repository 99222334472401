import { motion } from "framer-motion";
import { memo, useMemo, useState } from "react";

type TabGroupLive = {
  color?: "blue" | "gold" | "gradient";
  className?: string;
  buttonClassName?: string;
  initialActiveTabIndex?: number;
  activeTabIndex?: number;
  onTabChange?: (index: number) => void;
};

const tabs = ["Live", "Wining Rank", "My Play"];

const _TabGroupLive = ({
  color = "blue",
  className,
  buttonClassName,
  initialActiveTabIndex = 0,
  activeTabIndex: activeTabParent,
  onTabChange,
}: TabGroupLive) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex);
  const activeButtonClassName = useMemo(
    () => (color === "blue" ? "bg-gradient-blue" : "bg-gradient-gold-max"),
    [color]
  );

  const usedIndex = useMemo(
    () => activeTabParent ?? activeTabIndex,
    [activeTabParent, activeTabIndex]
  );

  return (
    <div className={`tabs bg-blue-gray flex flex-row rounded-full text-center ${className}`}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveTabIndex(index);
            onTabChange?.(index);
          }}
          className={`rounded-full flex flex-1 space-x-2 justify-center flex-row items-center py-2 lg:text-lg text-base font-semibold ${
            usedIndex === index && activeButtonClassName
          }`}
        >
          {index === 0 && (
            <motion.div
              className="w-[9px] h-[9px] rounded-full bg-gradient-blue"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{
                duration: 0.8,
                repeat: Infinity,
                repeatType: "reverse",
              }}
            ></motion.div>
          )}
          <span>{tab.toString()}</span>
        </button>
      ))}
    </div>
  );
};

export const TabGroupLive = memo(_TabGroupLive);
