import { Variants } from "framer-motion";

export const slideUpVariants: Variants = {
  hidden: {
    opacity: 0.2,
    y: "200px",
  },
  visible: {
    opacity: 1,
    y: "0%",
    transition: {
      y: {
        type: "spring",
        damping: 12,
        stiffness: 40,
      },
      opacity: {
        duration: 1,
      },
    },
  },
};
