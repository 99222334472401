import clsx from "clsx";
import { Token } from "./TokenSelector";

type InputAmountProps = {
  editable: boolean;
  selectedToken: Token;
  amount: string;
  onAmountChange: (amount: string) => void;
};

export const InputAmount = ({
  amount,
  editable,
  selectedToken,
  onAmountChange,
}: InputAmountProps) => {
  return (
    <div className="relative mt-1">
      <input
        className={clsx(
          "w-full py-3 pr-16 text-xl font-bold text-right lg:text-3xl lg:py-3 rounded-2xl",
          editable ? "text-black bg-white" : "text-white bg-[#00000040]"
        )}
        placeholder="0"
        onChange={({ target }) => {
          onAmountChange(target.value);
        }}
        value={amount}
        disabled={!editable}
      />
      <div className="absolute flex flex-col items-center justify-center w-8 top-2 lg:top-3 right-3">
        <img src={selectedToken.icon} alt="" className="w-6" />
        <p className="text-xs font-bold text-gray-500 lg:text-md">
          {selectedToken.name.toUpperCase()}
        </p>
      </div>
      <div className="absolute -mt-3 text-lg font-bold text-gray-500 top-1/2 left-4">Amount</div>
    </div>
  );
};
