import { useMemo } from "react";
import { PredictionGame } from "../context/PredictionGameContext";

export function usePredictionGameUp(game: PredictionGame, price?: string | number) {
  const result = useMemo(() => {
    const decimals = price?.toString().replaceAll(",", "").split(".")?.[1] ?? "0";
    const priceNumber = Number(decimals);

    if (game === "high-low") {
      return priceNumber >= 50;
    } else if (game === "even-odd") {
      return priceNumber % 2 === 1;
    } else {
      return true;
    }
  }, [game, price]);

  return result;
}
