import { useEffect } from "react";
import { Asset } from "../config";
import { useAssetContext } from "../context/PredictionAssetContext";
import useMenu from "../hooks/useMenu";
import { Dashboard } from "./Dashboard";
import { GraphPriceInfo } from "./GraphPriceInfo";
import { LetsPlay } from "./LetsPlay";
import { TabGroupEth } from "./TabGroupEth";

export type Tab = "BTC" | "ETH" | "MATIC";
export const tabIndexes = ["BTC", "ETH", "MATIC"] as const;
export const indexesTab: Record<Tab, number> = {
  BTC: 0,
  ETH: 1,
  MATIC: 2,
};

export function getEnumKeyByEnumValue<R extends string | number, T extends { [key: string]: R }>(
  myEnum: T,
  enumValue: any
): string {
  if (Number.isInteger(enumValue)) {
    return tabIndexes[Number(enumValue)];
  }
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys[0];
}

const assetTab: Record<Tab, Asset> = {
  BTC: "btc",
  ETH: "eth",
  MATIC: "matic",
};

let banners = [
  require("../assets/prediction/banner-btc.png"),
  require("../assets/prediction/banner-eth@2x.png"),
  require("../assets/prediction/banner-matic.png"),
];

export function SelectAsset() {
  const { activeTabIndex, keyTab, setActiveTabIndex } = useMenu();
  const hash = useAssetContext()
  
  useEffect(() => {
    const tab = hash.replace("#", "");
    const index = tabIndexes.indexOf(tab as Tab);
    setActiveTabIndex(tabIndexes[index]);
  }, [hash, setActiveTabIndex]);
  
  return (
    <>
      <div className="bg-black">
        <div className="container pt-16 mx-auto">
          <p className="text-2xl font-bold text-center uppercase text-gradient gold">
            Select Asset
          </p>

          <TabGroupEth
            tabs={["BTC", "ETH"]}
            className="relative z-10 mt-4"
            buttonClassName=""
            initialTabIndex={0}
            imageName={["btc", "eth"]}
            onTabChange={(index) => setActiveTabIndex(tabIndexes[index])}
          />
        </div>
        <div className="container justify-center">
          <img
            className="w-full relative -top-[30px] lg:-top-[60px] z-0"
            src={banners[indexesTab[activeTabIndex]]}
            alt=""
          />
          <Dashboard
            keyTab={keyTab}
            index={indexesTab[activeTabIndex]}
            className="hidden lg:block"
          />
        </div>
      </div>
      <LetsPlay keyTab={keyTab} index={indexesTab[activeTabIndex]} />
      <GraphPriceInfo asset={assetTab[activeTabIndex]} />
    </>
  );
}
