import { BigNumber } from "ethers";
import { useEffect, useMemo, useRef, useState } from "react";
import { Swiper, SwiperRef, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useGameContext } from "../context/PredictionGameContext";
import { useCurrentEpoch } from "../hooks/usePrediction";
import { GameStatusIcon } from "../prediction/GameStatusIcon";
import { CardFinished } from "./CardFinished";
import { CardLive } from "./CardLive";
import { CardPlay } from "./CardPlay";
import { Dashboard } from "./Dashboard";
import { Tab } from "./SelectAsset";

export type IndexTab = {
  index: number;
  keyTab: Tab;
};

const MAX_HISTORY = 6;
const MAX_GAME_STATUS = 8;

export function LetsPlay({ index, keyTab }: IndexTab) {
  const game = useGameContext();
  const { currentEpoch } = useCurrentEpoch({
    keyTab,
    activeTabIndex: index,
    pro: game,
  });
  const [latestEpoch, setLatestEpoch] = useState(1);
  const [isEpochInit, setIsEpochInit] = useState(false);
  const swiper = useRef<SwiperRef>(null);

  const CardFinisheds = useMemo(() => {
    return Array.from(new Array(MAX_HISTORY)).map((_, i) => {
      const epoch = latestEpoch - (MAX_HISTORY - 1 - i);
      return (
        <SwiperSlide key={i}>
          <CardFinished pro={game} activeTabIndex={index} currentEpoch={epoch} keyTab={keyTab} />
        </SwiperSlide>
      );
    });
  }, [latestEpoch, keyTab, index, game]);

  const GameStatusIcons = useMemo(() => {
    return Array.from(new Array(MAX_GAME_STATUS)).map((_, i) => {
      const epoch = latestEpoch - (MAX_GAME_STATUS - 1 - i);
      return (
        <GameStatusIcon
          key={i}
          epoch={epoch - 2}
          keyTab={keyTab}
          activeTabIndex={index}
          game={game}
        />
      );
    });
  }, [latestEpoch, keyTab, index, game]);

  useEffect(() => {
    if (currentEpoch !== latestEpoch) {
      setLatestEpoch(currentEpoch);

      if (!isEpochInit) {
        setIsEpochInit(true);
        return;
      }

      if (!swiper.current) return;
      const _swiper = swiper.current.swiper;
      const currentIndex = _swiper.activeIndex;
      _swiper.slideTo(currentIndex - 1, 0);

      setTimeout(() => {
        _swiper.slideTo(currentIndex, 800);
      }, 100);
    }
  }, [latestEpoch, currentEpoch, isEpochInit]);

  return (
    <div className="relative bg-gradient-black-to-blue-r">
      <div className="container pt-5 mx-auto lg:pt-16">
        <img
          className="relative z-20 justify-center hidden mx-auto w-[400px] lg:block my-5"
          src={require("../assets/prediction-pro/title-lets-play.png")}
          alt=""
        />
        <p className="relative z-20 block mt-4 text-3xl font-bold text-center uppercase font-audiowide text-gradient silver lg:hidden">
          Let'S PLAY
        </p>
        <p className="relative z-20 pt-4 mb-10 font-light text-center lg:-mt-8">
          Guess correctly to win. May the luck be with you!
        </p>
      </div>
      <Dashboard keyTab={keyTab} index={index} className="block px-4 pt-10 pb-14 lg:hidden" />
      <Swiper
        id="play-container"
        slidesPerView="auto"
        spaceBetween={0}
        centeredSlides
        initialSlide={6}
        ref={swiper}
      >
        {CardFinisheds}
        <SwiperSlide>
          <CardLive
            activeTabIndex={index}
            currentEpoch={BigNumber.from(latestEpoch ?? 0).toNumber()}
            keyTab={keyTab}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardPlay
            currentEpoch={BigNumber.from(latestEpoch ?? 0).toNumber()}
            keyTab={keyTab}
            index={index}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardPlay
            advance={1}
            currentEpoch={BigNumber.from(latestEpoch ?? 0).toNumber()}
            keyTab={keyTab}
            index={index}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardPlay
            advance={2}
            currentEpoch={BigNumber.from(latestEpoch ?? 0).toNumber()}
            keyTab={keyTab}
            index={index}
          />
        </SwiperSlide>
      </Swiper>
      <div className="relative z-20 flex justify-center mt-20 gap-x-3 pb-28">{GameStatusIcons}</div>

      <div className="hidden lg:block absolute inset-x-0 top-0 h-[200px] bg-gradient-to-b from-black z-10"></div>
      <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black z-10"></div>
      <img
        className="absolute bottom-0 right-0 z-0 w-64 lg:w-max"
        src={require("../assets/cube-trans5.png")}
        alt=""
      />
      <img
        className="absolute top-0 left-0 z-0 w-64 lg:w-max"
        src={require("../assets/cube-trans4.png")}
        alt=""
      />
    </div>
  );
}
