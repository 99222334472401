import { BigNumber, BigNumberish, utils } from "ethers";
import { useMemo } from "react";
import { useContractRead } from "wagmi";
import { config } from "../../config";
import PortionBox from "../../contracts/PortionBox.json";
import { roomAddress, RoomDigit } from "./../../config";

type ReadFns =
  | "rounds"
  | "currentRoundId"
  | "prizeReserved"
  | "maxPrizePerRound"
  | "currentPrize"
  | "buyersInRound"
  | "ticketsOf";

// prettier-ignore
type ReadArgs<Fn> = 
  Fn extends "rounds" ? BigNumberish :
  Fn extends "currentRoundId" ? undefined :
  Fn extends "prizeReserved" ? undefined :
  Fn extends "maxPrizePerRound" ? undefined :
  Fn extends "currentPrize" ? undefined :
  Fn extends "buyersInRound" ? [BigNumberish, BigNumberish] :
  Fn extends "ticketsOf" ? string :
  never;

// prettier-ignore
type PortionBoxReadResult<Fn> = 
  Fn extends "rounds" ? Round :
  Fn extends "currentRoundId" ? BigNumber :
  Fn extends "prizeReserved" ? BigNumber :
  Fn extends "maxPrizePerRound" ? BigNumber :
  Fn extends "currentPrize" ? BigNumber :
  Fn extends "buyersInRound" ? BuyerInfo[] :
  Fn extends "ticketsOf" ? Ticket[] :
  never;

export type BuyerInfo = {
  buyer: string;
  size: BigNumber;
};

export type Round = {
  roundId: BigNumber;
  totalSize: BigNumber;
  totalPrize: BigNumber;
  prizePerSize: BigNumber;
  startTime: BigNumber;
  closeTime: BigNumber;
  executeAfterTime: BigNumber;
  executedTime: BigNumber;
  answer: {
    number: BigNumber;
    timestamp: BigNumber;
    proveId: BigNumber;
  };
};

export type Ticket = {
  id: BigNumber;
  numbers: BigNumber[];
  sizes: BigNumber[];
  claimed: boolean;
  round: Round;
  win: boolean;
  winSize: number;
  winPrize: number;
  room: RoomDigit;
};

type FnObject<Fn extends ReadFns> = {
  room: RoomDigit;
  functionName: Fn;
  enabled?: boolean;
  args?: ReadArgs<Fn>;
  watch?: boolean;
};

export function usePortionBoxRead<ReadFn extends ReadFns>({
  room,
  functionName,
  args,
  watch = false,
  enabled = true,
}: FnObject<ReadFn>) {
  const contractRead = useContractRead({
    args,
    watch,
    enabled,
    functionName,
    addressOrName: roomAddress[room],
    contractInterface: PortionBox,
    staleTime: config.shortCacheTime,
    cacheTime: config.shortCacheTime,
  });

  const data: PortionBoxReadResult<ReadFn> | undefined = useMemo(() => {
    if (!contractRead.data) return;

    if (functionName === "ticketsOf") {
      let tickets = contractRead.data as PortionBoxReadResult<"ticketsOf">;

      return tickets.map((ticket) => {
        const newTicket = { ...ticket };
        const roundAnswer = ticket.round.answer.number.toNumber();
        let isTicketWin = false;
        let winSize = BigNumber.from(0);

        ticket.numbers.forEach((number, index) => {
          if (number.toNumber() === roundAnswer && ticket.round.executedTime.gt(0)) {
            isTicketWin = true;
            winSize = winSize.add(ticket.sizes[index]);
          }
        });

        newTicket.win = isTicketWin;
        newTicket.winSize = winSize.toNumber();
        newTicket.winPrize = Number(utils.formatUnits(winSize.mul(ticket.round.prizePerSize), 6));
        newTicket.room = room;

        return newTicket;
      }) as PortionBoxReadResult<ReadFn>;
    }

    return contractRead.data as PortionBoxReadResult<ReadFn> | undefined;
  }, [contractRead.data, functionName, room]);

  return {
    contractRead,
    data,
  };
}
