import { useMemo } from "react";
import { useAccount } from "wagmi";
import { Button } from "../common/Button";
import { useNotificationContext } from "../common/NotificationProvider";
import { predictionAddr } from "../config";
import { usePredictionV2Write } from "../hooks/contracts/usePredictionV2Write";
import { usePredictionV3Write } from "../hooks/contracts/usePredictionV3Write";
import { usePrediction } from "../hooks/usePrediction";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { PlayGameButton } from "./PlayGameButton";
import { Tab } from "./SelectAsset";

type CardFinishType = {
  activeTabIndex: number;
  currentEpoch: number;
  keyTab: Tab;
  pro?: boolean;
};

export function CardFinished({ activeTabIndex, currentEpoch, keyTab, pro }: CardFinishType) {
  const { pushNotification } = useNotificationContext();
  const { address } = useAccount();

  // end round is n-2
  const epoch = currentEpoch - 2 > 0 ? currentEpoch - 2 : 1;
  const { lockPrice, closePrice, claimed, prizePool, priceDiff, status, payoutUp, payoutDown } =
    usePrediction({ activeTabIndex, currentEpoch: epoch, keyTab, address, watch: false });
  const rewardCurrency = usePredictionRewardCurrency(keyTab.toString());

  const bullWin = useMemo(() => parseFloat(priceDiff) > 0, [priceDiff]);
  const win = useMemo(() => (status === "UP" && bullWin) || status === "DOWN", [status, bullWin]);

  const { writeAsync: claimV2 } = usePredictionV2Write("claim", [[epoch]]);
  const { writeAsync: claimV3 } = usePredictionV3Write(predictionAddr[keyTab], "claim", [[epoch]]);

  async function claim() {
    const claim = keyTab === "MATIC" ? claimV2 : claimV3;
    const tx = await claim();

    pushNotification({
      type: "sendTx",
      txhash: tx.hash,
      key: tx.hash,
    });

    await tx.wait();

    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: `You've claimed reward for round ${epoch}!`,
    });
  }

  return (
    <div className="relative">
      <div className="relative px-1 pb-1 mr-4 card-dark-blue w-card lg:mr-8">
        <div className="flex items-center justify-center opacity-60">
          <img className="w-3 mr-1" src={require("../assets/icon/flag.png")} alt="" />
          <span className="py-2 mr-1 font-semibold uppercase">finished</span>
          <span className="text-[#9a9a9a] font-semibold">#{epoch}</span>
        </div>

        <div className="pt-3 bg-gradient-black-to-blue-r rounded-2xl opacity-60">
          <div className="flex justify-between px-4 mt-3 text-sm font-bold">
            <p>Prize pool</p>
            <p>
              {prizePool} {rewardCurrency}
            </p>
          </div>
          <div className="flex justify-between px-4 pb-4 text-sm">
            <p>Last price</p>
            <p>${lockPrice}</p>
          </div>
          <div
            className={`${
              bullWin ? "card-gradient-green" : "card-gradient-red"
            } mx-4 py-2 mb-8 flex flex-col items-center justify-center`}
          >
            <div className="flex items-center">
              <img className="w-3 mr-1" src={require("../assets/close-price-icon.png")} alt="" />
              <p className="py-1 text-sm text-gradient gold">Closed price</p>
            </div>
            <p className={`text-xl font-bold ${bullWin ? "text-[#46b900]" : "text-[#ff1010]"}`}>
              ${closePrice}
            </p>
            <div
              className={`flex items-center space-x-1 font-bold text-sm ${
                bullWin ? "text-[#46b900]" : "text-[#ff1010]"
              }`}
            >
              <img
                alt=""
                className="w-2"
                src={
                  bullWin
                    ? require("../assets/up-icon-green.png")
                    : require("../assets/down-icon-red.png")
                }
              />
              <span>${priceDiff}</span>
            </div>
          </div>

          <div className="relative grid grid-cols-2 top-5">
            <div className="px-2 py-1 mx-6 text-sm font-semibold text-center card-border-blue-bg-black">
              Payout {payoutDown}x
            </div>
            <div className="px-2 py-1 mx-6 text-sm font-semibold text-center card-border-blue-bg-black">
              Payout {payoutUp}x
            </div>
          </div>
          <div className="h-[2px] bg-[#0e1a3a]"></div>
          <div className="grid grid-cols-2">
            <PlayGameButton className="rounded-bl-2xl" type="down" highlight={!bullWin} />
            <PlayGameButton className="rounded-br-2xl" type="up" highlight={bullWin} />
          </div>
        </div>
        <div className="w-full opacity-60">
          <div className="absolute flex justify-around w-full -bottom-3">
            {status === "UP" && !win ? (
              <>
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : status === "DOWN" && !win ? (
              <>
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : null}
          </div>
        </div>
        {win && (
          <div className="absolute inset-x-0 bottom-0 flex flex-col items-center p-4 bg-cover bg-winner rounded-b-2xl">
            <img className="-mt-10 w-11" src={require("../assets/bb-icon1.png")} alt="" />
            <p className="mt-2 text-xl font-bold">You’re Winnner</p>
            <div className="flex justify-center mt-2">
              <Button
                preset="white"
                buttonSize="md"
                className="px-8 uppercase"
                onClick={claim}
                disabled={claimed}
              >
                {claimed ? "CLAIMED" : "COLLECT"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
