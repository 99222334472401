import { GradientBorderDiv } from "../common/GradientBorderDiv";
import { skeletonWhileLoading } from "../common/Skeleton";
import { RoomDigit } from "../config";
import { useFormattedPrize } from "../hooks/useFormattedPrize";
import { RoomStateType } from "../portion-box/RoomDataProvider";

type Props = {
  digit: RoomDigit;
  room?: RoomStateType;
};

export function OverallPrize({ room }: Props) {
  const formattedPrizeReserved = useFormattedPrize(room?.prizeReserved, room?.round?.totalPrize);

  return (
    <GradientBorderDiv className="relative z-10 flex flex-col items-center px-3 py-5">
      <div className="flex flex-row items-center">
        <img className="w-3 mr-2 lg:w-4" src={require("../assets/icon/trophy.png")} alt="" />
        <p className="text-base lg:text-xl md:text-lg">Total Prize</p>
      </div>
      <p className="pt-3 text-3xl font-bold text-gradient gold lg:pt-3">
        {skeletonWhileLoading({
          value: formattedPrizeReserved.totalPrize,
          skeletonClassName: "w-[120px]",
        })}
      </p>

      <div className="w-full mx-2">
        <div className="grid grid-cols-2 p-3 items-center bg-[#04132c] rounded-xl mt-4">
          <p className="text-sm text-left">Prize in Round</p>
          <p className="font-bold text-right text-gradient gold">
            {skeletonWhileLoading({
              value: formattedPrizeReserved.prizeInRound,
              skeletonClassName: "w-[100px]",
            })}
          </p>
        </div>
        <div className="grid grid-cols-2 p-3 items-center bg-[#04132c] rounded-xl mt-3">
          <p className="text-sm text-left">Reserved Prizes</p>
          <p className="font-bold text-right text-gradient gold">
            {skeletonWhileLoading({
              value: formattedPrizeReserved.reserved,
              skeletonClassName: "w-[100px]",
            })}
          </p>
        </div>
      </div>
    </GradientBorderDiv>
  );
}
