import clsx from "clsx";
import { useEffect, useMemo } from "react";
import { usePrediction, useRounds } from "../hooks/usePrediction";
import { Tab } from "./SelectAsset";
import { PredictionGame } from "../context/PredictionGameContext";
import { usePredictionGameUp } from "../hooks/usePredictionGameUp";

type Props = {
  epoch: number;
  activeTabIndex: number;
  keyTab: Tab;
  game?: PredictionGame;
};

export const GameStatusIcon = ({ epoch, activeTabIndex, keyTab, game = "high-low" }: Props) => {
  const { priceDiff, closePrice } = useRounds({
    currentEpoch: epoch,
    activeTabIndex,
    keyTab,
    pro: game !== "up-down" ? game : undefined,
  });

  const isUp = useMemo(() => Number(priceDiff) > 0, [priceDiff]);
  const isProUp = usePredictionGameUp(game, closePrice);

  return (
    <div
      className={clsx(
        "w-5 rounded-full aspect-square",
        game !== "up-down" && isProUp && "bg-up",
        game !== "up-down" && !isProUp && "bg-down",
        game === "up-down" && isUp && "bg-up",
        game === "up-down" && !isUp && "bg-down"
      )}
    ></div>
  );
};
