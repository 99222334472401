import { Room } from "./Room";
import PlayTitle from "../assets/play-title.svg";
import { BTCPriceCard } from "../common/BTCPriceCard";

export function RoomList() {
  return (
    <div id="1" className="relative pt-12 bg-black bg-top bg-no-repeat bg-contain bg-play">
      <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black"></div>

      <div className="relative text-center">
        <img alt="" className="inline-block w-[500px]" src={PlayTitle} />
        <p className="-mt-8 text-white">
          Choosing the right ticket numbers to match with Bitcoin decimal places is easy to win the
          prize
        </p>
      </div>
      <div className="container">
        <BTCPriceCard className="mt-12 mb-7" />
      </div>

      <Room digit={5} />
    </div>
  );
}
