import create from "zustand";
import { JackpotAsset } from "../config";
import { useEffect, useState } from "react";

type AssetPriceStore = {
  price: Record<JackpotAsset, number | undefined>
  getPrice: (asset: JackpotAsset) => number | undefined
  setPrice: (asset: JackpotAsset, price: number) => void
}

export const useAssetPriceStore = create<AssetPriceStore>((set, get) => ({
  price: { 'bnb': undefined, 'btc': undefined, 'eth': undefined },
  getPrice: (asset: JackpotAsset) => {
    return get().price[asset]
  },
  setPrice: (asset: JackpotAsset, price: number) => {
    set((state) => ({ price: { ...state.price, [asset]: price } }))
  }
}));

export const useAssetPriceAsState = (asset: JackpotAsset, enabled: boolean = true) => {
  const [price, setPrice] = useState<number>()

  useEffect(() => {
    if (enabled) {
      return useAssetPriceStore.subscribe((state) => {
        setPrice(state.price[asset])
      })
    }
  }, [enabled, asset])


  return price
}