import { skeletonWhileLoading } from "../common";
import {
  useMultiplierBoxTotalVolume
} from "../hooks/contracts/multiplier-box";

type PrizeProps = { className?: string };

export const Prize = ({ className }: PrizeProps) => {
  const prize = useMultiplierBoxTotalVolume();
  return (
    <div
      className={`flex flex-col items-center justify-center flex-1 py-6 card-border-gradient-gold-bg-black border-sm ${className}`}
    >
      <span>Total Accumulated Volume</span>
      <span className="mt-1 text-3xl font-bold text-gradient gold">
        {skeletonWhileLoading({
          value:
            prize !== undefined
              ? `$${prize?.toLocaleString("en-US", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 2,
                })}`
              : undefined,
          skeletonClassName: "w-24",
        })}
      </span>
    </div>
  );
};
