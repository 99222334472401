import { RoomDigit } from "../config";
import { EmptyState } from "../portion-box/EmptyState";
import { UserPurchasedRow } from "./UserPurchasedRow";
import clsx from "clsx";

type Props = {
  digit: RoomDigit;
  buyers: Buyer[];
  className?: string;
  gold?: boolean;
};

export type Buyer = {
  user: string;
  time: string;
  number: number;
  size?: number;
};

export const RoundTicketList = ({ digit, buyers, className, gold }: Props) => {
  return (
    <div className={clsx(gold ? "h-[396px]" : "h-[270px]", className)}>
      {buyers.length > 0 ? (
        <div className="grid items-center grid-cols-12 p-4 px-12 gap-y-4">
          <div className="col-span-3 text-sm font-light text-white/50">User</div>
          <div className="col-span-3 text-sm font-light text-white/50">Time</div>
          <div className="col-span-6 text-sm font-light text-center text-white/50">Numbers</div>
          {buyers.map((buyer, index) => (
            <UserPurchasedRow key={index} digits={Number(digit)} {...buyer} />
          ))}
        </div>
      ) : (
        <EmptyState className="h-full p-12" />
      )}
    </div>
  );
};
