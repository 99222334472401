import { motion } from "framer-motion";
import { useId } from "react";
import { gradientVariants } from "../animation";

type AnimatedGradientProps = {
  className?: string;
};

export function AnimatedGradient({ className }: AnimatedGradientProps) {
  const linearGradientId = useId();
  return (
    <div className={className}>
      <svg
        viewBox="0 0 1 1"
        className="w-full h-full"
        preserveAspectRatio="none"
      >
        <motion.linearGradient
          id={linearGradientId}
          gradientUnits="objectBoundingBox"
          variants={gradientVariants}
        >
          <stop offset="0%" stopColor="#1b60fa" />
          <stop offset="15%" stopColor="#09307b" />
          <stop offset="30%" stopColor="#020d1e" />
          <stop offset="45%" stopColor="#d4b351" />
          <stop offset="100%" stopColor="#fbeb62" />
        </motion.linearGradient>
        <rect
          x="0"
          y="0"
          width="1"
          height="1"
          fill={`url(#${linearGradientId})`}
        />
      </svg>
    </div>
  );
}
