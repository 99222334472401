import { useEffect, useState } from "react";
import { RoundTicketList } from "../common/RoundTicketList";
import { skeletonWhileLoading } from "../common/Skeleton";
import { TabGroup } from "../common/TabGroup";
import { RoomDigit } from "../config";
import { useRoundData } from "../hooks/useRoundData";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";
import { AutoPlay } from "./AutoPlay";
import { FinishedRound } from "./FinishedRound";
import { YourTickets } from "./YourTickets";

const isShowFinishedRound = window.location.hash === "#finished-round";

export const RoundTicketInfo = ({ digit }: { digit: RoomDigit }) => {
  const roomState = useSpecificRoomState(digit);
  const { buyers, mutate } = useRoundData(digit, roomState?.roundId);
  const [activeTabIndex, setActiveTabIndex] = useState(isShowFinishedRound ? 2 : 0);

  const { registerFetchCallbackWithDelay } = useNumberStore.getState();

  useEffect(() => {
    registerFetchCallbackWithDelay("round-data", () => {
      mutate();
    });
  }, [registerFetchCallbackWithDelay, mutate]);

  useEffect(() => {
    if (isShowFinishedRound)
      document.getElementById("info-section")?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
  }, []);

  return (
    <div className="flex-1 mt-5 lg:ml-5 lg:mt-0" id="info-section">
      <div className="card-border-gradient-blue-to-yellow max-h-[450px] pt-4 overflow-y-auto no-scrollbar">
        <TabGroup
          initialActiveTabIndex={activeTabIndex}
          tabs={["This Round", "Your Tickets", "Finished Round"]}
          className="items-center justify-center mx-auto rounded-full bg-blue-gray w-max"
          buttonClassName="text-sm lg:font-semibold px-4"
          onTabChange={setActiveTabIndex}
        />
        {activeTabIndex === 1 && <YourTickets />}

        {activeTabIndex === 0 && (
          <>
            <p className="my-3 text-center">
              <span className="text-gold">
                {skeletonWhileLoading({
                  value: roomState?.round?.totalSize.toNumber().toLocaleString(),
                  skeletonClassName: "w-12",
                })}{" "}
                Numbers
              </span>{" "}
              in this Round!
            </p>
            <div className="h-[1px] bg-gold"></div>
            <div className="max-w-[500px] mx-auto">
              <RoundTicketList buyers={buyers} digit={digit} />
            </div>
          </>
        )}

        {activeTabIndex === 2 && (
          <div className="px-4 overflow-y-auto no-scroll">
            <FinishedRound size="s" />
          </div>
        )}
      </div>

      <AutoPlay />

      <div className="relative hidden p-5 text-center bg-blue-gray rounded-2xl lg:block">
        <img
          alt=""
          className="absolute top-0 mx-auto left-1/3 w-36"
          src={require("../assets/cube-trans7.png")}
        />
        <p className="font-bold">CONDITION</p>
        <p className="text-sm font-light">
          The maximum of each number is 10 tickets, hurry before it's all gone!
        </p>
      </div>
    </div>
  );
};
