import clsx from "clsx";
import Popup from "reactjs-popup";
import InfoIcon from "../assets/icon/info-icon.svg";
import { useAssetPriceStore } from "../store/asset-price";
import { CountUpNumber } from "../common";

export type SilverProp = {
  silver?: boolean;
};
export function CurrentPrice() {
  const price = useAssetPriceStore((state) => state.price);

  return (
    <div
      className={clsx(
        "relative flex flex-col items-center justify-center col-span-2 py-2 lg:col-span-4",
        "card-border-gradient-blue-to-purple-bg-black-to-blue"
      )}
    >
      <div className="absolute hidden top-2 right-2 lg:block">
        <Popup
          trigger={<img alt="" className="w-4" src={InfoIcon} />}
          on="hover"
          position="bottom center"
          mouseEnterDelay={0}
          contentStyle={{
            border: 0,
            backgroundColor: "#faf6a6",
            backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
          }}
          arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
        >
          <div className="flex items-start px-[6px] py-1">
            <div className="basis-1/12 mt-[1px]">
              <img className="w-3" src={require("../assets/icon/info2.png")} alt="" />
            </div>
            <div className="basis-11/12">
              <p className="text-xs font-black text-black">Currenly showing price from Binance</p>
              <p className="text-xs font-light text-black">
                The price you see come directly from the Binance API, which powers the prediction
                pro game.
              </p>
            </div>
          </div>
        </Popup>
      </div>
      <p className="text-sm text-gradient silver">Current Price</p>
      <div className="mt-1 text-2xl font-bold font-roboto-condensed lg:text-3xl">
        <CountUpNumber decimals={2} duration={0.3} end={price.btc ?? 0} prefix="$" startCounting />
      </div>
    </div>
  );
}
