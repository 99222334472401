import { useEffect, useMemo, useState } from "react";
import { Button } from "../../common/Button";
import { Fade } from "../../common/Fade";
import { useNotificationContext } from "../../common/NotificationProvider";
import { useNumberLimits } from "../../hooks/contracts/multiplier-box";
import { useExceedLimitNumbers } from "../../hooks/contracts/multiplier-box/useExceedLimitNumbers";
import { useMultiplierBoxNumberStore } from "../../store/multiplier-box-number-store";
import { useCurrentRoundState } from "./CurrentRoundProvider";
import { NumberList, allNumbers } from "./NumberList";
import { PlayModal } from "./modal/PlayModal";

const allNumbersLimits = allNumbers.map((number) => ({ number, amount: 0 }));

export const SelectNumber = () => {
  const { digits = 1, currentRound, gameAddress = "" } = useCurrentRoundState();
  const [newNumber, setNewNumber] = useState("");
  const [showPlayModal, setShowPlayModal] = useState(false);

  const {
    numbers,
    addNumber,
    addRandomNumber: addRandomNumberToStore,
    removeNumber,
    findNumberIndex,
    clearNumbers,
  } = useMultiplierBoxNumberStore();

  const { pushNotification } = useNotificationContext();

  const { numbers: numberLimits } = useNumberLimits(
    allNumbersLimits,
    currentRound?.roundId,
    gameAddress
  );

  const exceedLimitNumbers = useExceedLimitNumbers(numberLimits, gameAddress);
  const disabledNumbers = numbers.map((number) => number.number).concat(exceedLimitNumbers);
  const maxNumber = useMemo(() => Math.pow(10, digits) - 1, [digits]);

  useEffect(() => {
    clearNumbers();
  }, [digits]);

  function validateBeforeSetNewNumber(value: string) {
    const number = Number(value);
    if (!Number.isNaN(number) && number % 1 === 0 && number >= 0 && number <= maxNumber) {
      setNewNumber(value);
    }
  }

  function addNewNumber(add: string | number) {
    const number = Number(add);

    if (!Number.isNaN(number) && add !== "") {
      const numberIndex = findNumberIndex(number);
      if (numberIndex < 0) {
        addNumber(number);
        setNewNumber("");
        pushNotification({
          key: number.toString(),
          number: number.toString().padStart(digits, "0"),
          type: "add",
        });
      }
    }
  }

  function addRandomNumber() {
    const number = addRandomNumberToStore(maxNumber + 1);

    if (number || number === 0) {
      pushNotification({
        key: number.toString(),
        number: number.toString().padStart(digits, "0"),
        type: "random",
      });
    }
  }

  const isAddNumberDisabled =
    newNumber === "" || numbers.some((number) => number.number === Number(newNumber));

  return (
    <>
      <div className="relative px-4 pt-12 pb-4 -mt-6 border-gold">
        <div className="grid grid-cols-4 gap-3">
          <div className="col-span-4 text-xs md:col-span-1">
            Choose number or enter number you want
          </div>
          <div className="relative col-span-3 md:col-span-2">
            <input
              className="w-full h-full px-3 text-xs font-semibold tracking-wider text-black md:text-sm rounded-xl"
              placeholder="Enter number"
              type="number"
              value={newNumber}
              onChange={(e) => validateBeforeSetNewNumber(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !isAddNumberDisabled) {
                  addNewNumber(newNumber);
                }
              }}
            />
            <div className="absolute inset-y-0 flex items-center right-2">
              <Button
                className="px-4"
                preset="gold"
                buttonSize="xs"
                onClick={() => addNewNumber(newNumber)}
                disabled={isAddNumberDisabled}
              >
                ADD
              </Button>
            </div>
          </div>
          <Button
            className="flex flex-row items-center justify-center space-x-2 group hover:bg-gradient-gold-max hover:border-2 hover:border-[#c5a44e]"
            preset="blue"
            buttonSize="sm"
            onClick={addRandomNumber}
          >
            <img
              className="w-4 group-hover:hidden"
              src={require("../../assets/random.png")}
              alt="Random number"
            />
            <span className="text-xs text-gradient gold md:text-base group-hover:hidden">
              Random
            </span>
            <span className="hidden text-xs text-white md:text-base group-hover:inline-block">
              ADD
            </span>
          </Button>
        </div>
        <NumberList
          disabledNumbers={disabledNumbers}
          className="mt-6 max-h-[400px] overflow-scroll no-scrollbar pb-6"
          onClick={(number) => addNewNumber(number)}
          onRemove={(number) => removeNumber(number)}
        />
        <Fade direction="down" className="absolute inset-x-0 bottom-0 h-16" />
      </div>
      <div className="relative flex items-center p-5 -mt-5 border gap-x-6 bg-blue-gray border-lightGray rounded-2xl">
        <img className="w-8" src={require("../../assets/icon/bb-icon-s.png")} alt="Logo" />
        <div>
          <div className="text-xs text-white/40">Selected number</div>
          <div className="text-2xl font-bold">
            {numbers.length} number{numbers.length !== 1 && "s"}
          </div>
        </div>
        <Button
          className="px-16 py-2 ml-auto text-2xl uppercase glow-gold"
          preset="goldMax"
          disabled={numbers.length === 0}
          onClick={() => setShowPlayModal(true)}
        >
          Play
        </Button>
      </div>
      {showPlayModal && (
        <PlayModal active={showPlayModal} onDismiss={() => setShowPlayModal(false)} />
      )}
    </>
  );
};
