import { useConnectModal } from "@rainbow-me/rainbowkit";
import { animate, motion, MotionValue, useMotionTemplate, useMotionValue } from "framer-motion";
import { useAccount } from "wagmi";
import { slideUpVariants } from "../animation";
import { Button, LinkButton } from "./Button";
import { skeletonWhileLoading } from "./Skeleton";

type TicketProps = {
  ticketBalance?: string;
  room?: string;
  jackpot?: boolean;
};

export function Ticket({ ticketBalance, room, jackpot }: TicketProps) {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const finalBorderAngle = 206;
  const border = useMotionValue(finalBorderAngle);
  const classDisplay = room
    ? "flex-col lg:order-3 order-4 pt-4 pb-3 lg:py-4"
    : "lg:flex-row flex-col justify-around py-3";
  const classTitle = room ? "lg:text-xl md:text-lg text-base mb-1" : "mb-1";
  const classValue = room ? "lg:mb-2 text-center text-2xl" : "lg:mb-0 text-3xl";

  const getBorderTemplate = (border: MotionValue<number>) => {
    return useMotionTemplate`linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(${border}deg, rgba(27, 96, 250, 1) 0%, rgba(2, 13, 30, 1) 50%, rgba(251, 235, 98, 1) 100%) border-box`;
  };

  const getBorderJackpotTemplate = (border: MotionValue<number>) => {
    return useMotionTemplate`linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(${border}deg, #fccf61 0%,  #905613 28%,  #965d17 37%, #a87325 51%, #c4973b 68%, #ecc859 88%, #fad964 94%) border-box`;
  };

  const background = jackpot ? getBorderJackpotTemplate(border) : getBorderTemplate(border);

  const animateBorderIn = (border: MotionValue<number>) => {
    animate(border, 0, { duration: 0.35 });
  };

  const animateBorderOut = (border: MotionValue<number>) => {
    animate(border, finalBorderAngle, { duration: 0.25 });
  };

  return (
    <motion.div
      variants={slideUpVariants}
      className={` flex items-center relative overflow-hidden ${classDisplay} ${
        jackpot
          ? "card-border-gradient-gold-bg-black border-sm"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      }`}
      onHoverStart={() => animateBorderIn(border)}
      onHoverEnd={() => animateBorderOut(border)}
      style={{
        background: background,
      }}
    >
      <div className="text-center lg:text-left">
        <p className={classTitle}>Your number</p>
        <p className={`font-bold text-gradient gold mb-4 ${classValue}`}>
          {skeletonWhileLoading({
            value: ticketBalance,
            skeletonClassName: "w-[100px]",
          })}
        </p>
      </div>
      {isConnected && (
        <LinkButton
          to="/my-dashboard"
          preset={ jackpot ? "goldMax" : "gradient"}
          buttonSize={room ? "xs" : "sm"}
          className={`uppercase z-10 ${room ? "px-6 py-2" : "px-12"}`}
        >
          {room ? "View tickets" : "My Dashboard"}
        </LinkButton>
      )}
      {!isConnected && (
        <Button
          preset={jackpot ? "goldMax" : "gradient" }
          buttonSize={room ? "xs" : "sm"}
          className={`uppercase z-10 ${room ? "px-6 py-2" : "px-6"}`}
          onClick={() => openConnectModal?.()}
        >
          Connect Wallet
        </Button>
      )}

      <img
        alt=""
        className="absolute bottom-0 left-0 z-0 w-36 rounded-bl-2xl"
        src={require("../assets/tickets.png")}
      />
    </motion.div>
  );
}
