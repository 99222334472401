import { useRequest } from "../../useRequest";

type Response = {
  total: number;
};

export const useMultiplierBoxTotalVolume = () => {
  const { data } = useRequest<Response>({
    path: `/max/volume/total`,
  });

  return data?.total;
};
