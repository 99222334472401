import { BigNumber } from "ethers";
import { useMemo } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import { useCurrentEpoch } from "../hooks/usePrediction";
import { CardFinished } from "./CardFinished";
import { CardLive } from "./CardLive";
import { CardPlay } from "./CardPlay";
import { CardUpComing } from "./CardUpComing";
import { Dashboard } from "./Dashboard";
import { GameStatusIcon } from "./GameStatusIcon";
import { Tab } from "./SelectAsset";

export type IndexTab = {
  index: number;
  keyTab: Tab;
};

const MAX_HISTORY = 6;
const MAX_GAME_STATUS = 8;

export function LetsPlay({ index, keyTab }: IndexTab) {
  const { currentEpoch } = useCurrentEpoch({ keyTab, activeTabIndex: index });

  const CardFinisheds = useMemo(() => {
    return Array.from(new Array(MAX_HISTORY)).map((_, i) => {
      const epoch = currentEpoch - (MAX_HISTORY - 1 - i);
      return (
        <SwiperSlide key={i}>
          <CardFinished activeTabIndex={index} currentEpoch={epoch} keyTab={keyTab} />
        </SwiperSlide>
      );
    });
  }, [currentEpoch, keyTab, index]);

  const GameStatusIcons = useMemo(() => {
    return Array.from(new Array(MAX_GAME_STATUS)).map((_, i) => {
      const epoch = currentEpoch - (MAX_GAME_STATUS - 1 - i);
      return <GameStatusIcon key={i} epoch={epoch - 2} keyTab={keyTab} activeTabIndex={index} />;
    });
  }, [currentEpoch, keyTab, index]);

  return (
    <div className="relative bg-gradient-black-to-blue-r">
      <div className="container pt-2 mx-auto lg:pt-10">
        <img
          className="relative z-20 justify-center hidden mx-auto w-[400px] lg:block"
          src={require("../assets/prediction/title-lets-play.png")}
          alt=""
        />
        <p className="relative z-20 block mt-4 text-3xl font-bold text-center uppercase font-audiowide text-gradient gold lg:hidden">
          Let'S PLAY
        </p>
        <p className="relative z-20 mt-4 mb-10 font-light text-center lg:-mt-8">
          Guess correctly to win. May the luck be with you!
        </p>
      </div>
      <Dashboard keyTab={keyTab} index={index} className="block px-4 pt-10 pb-14 lg:hidden" />
      <Swiper
        id="play-container"
        slidesPerView="auto"
        spaceBetween={0}
        centeredSlides
        initialSlide={7}
      >
        {CardFinisheds}
        <SwiperSlide>
          <CardLive
            activeTabIndex={index}
            currentEpoch={BigNumber.from(currentEpoch ?? 0).toNumber()}
            keyTab={keyTab}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardPlay
            currentEpoch={BigNumber.from(currentEpoch ?? 0).toNumber()}
            keyTab={keyTab}
            index={index}
          />
        </SwiperSlide>
        <SwiperSlide>
          <CardUpComing
            currentEpoch={BigNumber.from(currentEpoch ?? 0).toNumber()}
            keyTab={keyTab}
            index={index}
          />
        </SwiperSlide>
      </Swiper>
      <div className="relative z-20 flex justify-center mt-20 gap-x-3 pb-28">{GameStatusIcons}</div>

      <div className="hidden lg:block absolute inset-x-0 top-0 h-[200px] bg-gradient-to-b from-black z-10"></div>
      <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black z-10"></div>
      <img
        className="absolute bottom-0 right-0 z-0 w-64 lg:w-max"
        src={require("../assets/cube-trans5.png")}
        alt=""
      />
      <img
        className="absolute top-0 left-0 z-0 w-64 lg:w-max"
        src={require("../assets/cube-trans4.png")}
        alt=""
      />
    </div>
  );
}
