import create from "zustand";

type AssetStore = {
  asset: 'BTC' | 'ETH' | 'MATIC',
  setAsset: (asset: 'BTC' | 'ETH' | 'MATIC') => void;
}

export const useAssetStore = create<AssetStore>((set) => ({
  asset: 'BTC',
  setAsset: (asset: 'BTC' | 'ETH' | 'MATIC') => {
    set(() => ({ asset }));
  },
}));