import { TotalWeeklyPrizes } from "../prediction/TotalWeeklyPrizes";
import { ClaimRewardPro } from "./ClaimRewardPro";

export function Banner() {
  return (
    <div className="bg-prediction-pro bg-no-repeat bg-cover bg-center relative lg:pt-[120px] pt-[170px]">
      <div className="absolute inset-x-0 top-0 h-[300px] bg-gradient-to-b from-black z-0"></div>
      <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black z-0"></div>
      <div className="container pt-10 pb-10 mx-auto">
        <div className="z-10 grid grid-cols-1 px-6 lg:grid-cols-3">
          <div className="z-10 lg:col-span-2">
            <img
              className="max-w-full w-[500px]"
              src={require("../assets/prediction-pro/title-logo.png")}
              alt=""
            />
            <div className="flex flex-col mt-4 gap-y-4">
              <div className="flex justify-center lg:justify-start">
                <img className="h-12" src={require("../assets/secure-by-gold@2x.png")} alt=""></img>
                <img
                  className="h-12 ml-2"
                  src={require("../assets/binance-blackwhite@2x.png")}
                  alt=""
                ></img>
                <img
                  className="h-12 ml-2 xl:mx-5"
                  src={require("../assets/chainlink@2x.png")}
                  alt=""
                ></img>
              </div>
              <div className="flex justify-center mt-1 lg:justify-start">
                <a
                  href="https://info.billionbox.io/product/the-prediction-platform"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center mr-10"
                >
                  <img className="h-5 mr-2" src={require("../assets/icon/question.png")} alt="" />
                  <p className="text-sm uppercase text-gradient gold">How to play</p>
                </a>
                <a
                  href="https://info.billionbox.io/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="flex items-center"
                >
                  <img className="h-5 mr-2" src={require("../assets/icon/doc.png")} alt="" />
                  <p className="text-sm uppercase text-gradient gold">doc</p>
                </a>
              </div>
            </div>
          </div>
          <div className="grid justify-center grid-flow-col gap-4 mt-8 lg:grid-rows-2 auto-cols-max lg:justify-end lg:mt-0">
            <TotalWeeklyPrizes silver />
            <ClaimRewardPro silver />
          </div>
        </div>
      </div>
    </div>
  );
}
