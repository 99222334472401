import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";

export function WinningCriteria() {
  return (
    <div className="relative bg-gradient-black-to-blue">
      <img
        alt=""
        className="absolute bottom-0 right-0 hidden w-96 -z-0 lg:block"
        src={require("../assets/cube-trans6.png")}
      />
      <div className="container py-20 mx-auto lg:py-16">
        <motion.div
          className="text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="W" gold />
          <AnimatedText size="md" text="I" gold />
          <AnimatedText size="md" text="N" gold />
          <AnimatedText size="md" text="N" gold />
          <AnimatedText size="md" text="I" gold />
          <AnimatedText size="md" text="N" gold />
          <AnimatedText size="md" text="G" gold />
          <br className="lg:hidden" />
          <AnimatedText size="md" text="C" className="lg:ml-4" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="T" />
          <AnimatedText size="md" text="E" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="A" />
        </motion.div>
        <p className="text-lg font-light text-center">
          New Bitcoin Game - Guess Ticket Numbers to Win the Prize!
        </p>

        <div className="grid grid-cols-1 gap-10 pt-10 mt-6 lg:grid-cols-2">
          <img alt="" className="z-10 mx-auto -mt-20" src={require("../assets/winning@2x.png")} />
          <div className="-mt-20 lg:-mt-0">
            <div className="flex items-center">
              <img alt="" className="w-10" src={require("../assets/decimal-box/icon.png")} />
              <p className="ml-3 text-2xl font-bold">The Decimal Box</p>
            </div>
            <p className="mt-5 text-lg font-light xl:text-xl">
              In the Decimal Box game, the rewards are determined based on the number of players and
              the total amount of USDT in the pool. The rewards are then divided among the winners
              based on the accuracy of their guesses. If no one wins, the prize pool is carried over
              to the next round.
            </p>
            <p className="mt-5 text-lg font-light xl:text-xl">
              Decimal Box is a fun and exciting way to test your skills and knowledge about BTC/USDT
              price fluctuations. With different levels of difficulty and potential rewards, there's
              something for everyone to enjoy.
            </p>
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 lg:h-[150px] h-[100px] bg-gradient-to-t from-black z-10"></div>
    </div>
  );
}
