import { memo, useMemo, useState } from "react";

interface Stringify {
  toString: () => string;
}

type TabGroupProps = {
  tabs: Stringify[];
  color?: "blue" | "gold" | "gradient";
  className?: string;
  buttonClassName?: string;
  initialActiveTabIndex?: number;
  activeTabIndex?: number;
  onTabChange?: (index: number) => void;
};

const _TabGroup = ({
  tabs,
  color = "blue",
  className,
  buttonClassName,
  initialActiveTabIndex = 0,
  activeTabIndex: activeTabParent,
  onTabChange,
}: TabGroupProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialActiveTabIndex);
  const activeButtonClassName = useMemo(
    () => (color === "blue" ? "bg-gradient-blue" : "bg-gradient-gold-max"),
    [color]
  );

  const usedIndex = useMemo(
    () => activeTabParent ?? activeTabIndex,
    [activeTabParent, activeTabIndex]
  );

  return (
    <div className={`tabs bg-blue-gray rounded-full text-center ${className}`}>
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveTabIndex(index);
            onTabChange?.(index);
          }}
          className={`rounded-full py-2 ${buttonClassName} ${
            usedIndex === index && activeButtonClassName
          }`}
        >
          {tab.toString()}
        </button>
      ))}
    </div>
  );
};

export const TabGroup = memo(_TabGroup);
