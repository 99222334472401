import { NavBarMode } from "../common/NavBarMode";
import { NotificationProvider } from "../common/NotificationProvider";
import { GameContext } from "../context/PredictionGameContext";
import { TabProvider } from "../contexts/MenuContext";
import { Questions } from "../portion-box/Questions";
import { SelectMode } from "../common/SelectMode";
import { Banner } from "../prediction/Banner";
import { GameRankAndHistory } from "../prediction/GameRankAndHistory";
import { SelectAsset } from "../prediction/SelectAsset";
import { WinningCriteria } from "../prediction/WinningCriteria";
import { AssetContext, PredictionAsset } from "../context/PredictionAssetContext";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

export function Prediction() {
  const hash = useLocation().hash as PredictionAsset;
  const [asset, setAsset] = useState(hash);

  useEffect(() => {
    hash === "#BTC" ? setAsset("#BTC") : setAsset("#ETH");
  }, [hash]);

  return (
    <GameContext.Provider value="up-down">
      <AssetContext.Provider value={asset}>
        <NotificationProvider>
          <TabProvider>
            <NavBarMode />
            <Banner />
            <SelectMode />
            <SelectAsset />
            <GameRankAndHistory />
          </TabProvider>
          <WinningCriteria />
          <Questions />
        </NotificationProvider>
      </AssetContext.Provider>
    </GameContext.Provider>
  );
}
