import clsx from "clsx";
import { useMemo, useRef } from "react";
import { useCurrentRoundState } from "./CurrentRoundProvider";

export const allNumbers = Array.from(new Array(100)).map((_, index) => index);

type Props = {
  className?: string;
  disabledNumbers?: number[];
  onClick?: (number: number) => void;
  onRemove?: (number: number) => void;
};

export const NumberList = ({ className, onClick, onRemove, disabledNumbers = [] }: Props) => {
  const { digits = 1 } = useCurrentRoundState();
  const isMouseDown = useRef(false);
  const addOrRemove = useRef<"add" | "remove">("add");
  const allNumbers = useMemo(() => {
    if (digits === 1) {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 0];
    } else {
      return Array.from(new Array(10 ** digits)).map((_, index) => index);
    }
  }, [digits]);

  function handleMouseOver(number: number) {
    if (isMouseDown.current) {
      if (disabledNumbers.includes(number)) return;
      addOrRemove.current === "add" ? onClick?.(number) : onRemove?.(number);
    }
  }

  return (
    <div
      onPointerDown={(event) => {
        const elementMouseIsOver = document.elementFromPoint(
          event.clientX,
          event.clientY
        ) as HTMLButtonElement;
        elementMouseIsOver?.click();

        isMouseDown.current = true;
      }}
      onPointerUp={() => (isMouseDown.current = false)}
      onPointerLeave={() => (isMouseDown.current = false)}
      onPointerMove={(event) => {
        if (isMouseDown.current) {
          const elementMouseIsOver = document.elementFromPoint(
            event.clientX,
            event.clientY
          ) as HTMLButtonElement;
          elementMouseIsOver?.click();
        }
      }}
      className={clsx(
        className,
        "grid",
        digits === 1 && "grid-cols-3 gap-4",
        digits === 2 && "grid-cols-6 gap-3"
      )}
      onScroll={(e) => isMouseDown.current && e.preventDefault()}
    >
      {allNumbers.map((number) => (
        <button
          onMouseDown={() => {
            addOrRemove.current = disabledNumbers.includes(number) ? "remove" : "add"
            addOrRemove.current === "add" ? onClick?.(number) : onRemove?.(number);
          }}
          onClick={() => handleMouseOver(number)}
          className={clsx(
            "group rounded-xl border border-lightGray text-center",
            number % 2 === 0 ? "bg-[#303541]" : "bg-[#1d1f2a]",
            digits === 1 && "aspect-[1.65]",
            digits === 1 && number === 0 && "col-start-2",
            digits === 2 && "aspect-square",
            disabledNumbers.includes(number)
              ? "opacity-10"
              : "hover:bg-gradient-gold-max hover:border-[#c5a44e] hover:border-2"
          )}
          key={number.toString()}
        >
          <div className="text-xl lg:text-3xl font-bold ml-[0.2em] tracking-[0.2em] text-white">
            {number.toString().padStart(digits, "0")}
          </div>
          <div
            className={clsx(
              "text-sm font-bold text-[#fccf61] mt-[-1em] opacity-0 transition-all",
              !disabledNumbers.includes(number) && "group-hover:-mt-1 group-hover:opacity-100"
            )}
          >
            ADD
          </div>
        </button>
      ))}
    </div>
  );
};
