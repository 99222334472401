import { motion, Variants } from "framer-motion";

type AnimateLogoProps = {
  className?: string;
};

export function AnimateLogo({ className }: AnimateLogoProps) {
  const draw: Variants = {
    hidden: { opacity: 0, y: "100%", scale: 0.5 },
    visible: {
      opacity: 1,
      y: "0%",
      scale: 1,
      transition: { type: "spring", duration: 1, bounce: 0 },
    },
  };

  return (
    <div className={className}>
      <motion.svg
        variants={draw}
        className="w-full h-auto"
        width="588"
        height="611"
        viewBox="0 0 588 611"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M372.978 98.856c10.999-.896 21.71 4.093 28.095 13.087l113.392 159.745c6.383 8.994 7.473 20.627 2.857 30.517l-81.99 175.65c-4.616 9.89-14.238 16.534-25.237 17.43L214.713 511.19c-11.002.895-21.712-4.094-28.095-13.088L73.225 338.358c-6.385-8.994-7.474-20.627-2.858-30.517l81.99-175.65c4.616-9.89 14.237-16.534 25.238-17.43l195.383-15.905z"
          fill="#000"
        />
        <path
          d="m466.179 272.708-83.53-121.4a30.401 30.401 0 0 0-28.021-13.053l-146.784 14.111c-11.051 1.063-20.792 8.002-25.555 18.204l-63.254 135.512c-4.761 10.201-3.821 22.118 2.468 31.257l83.53 121.4a30.468 30.468 0 0 0 6.88 7.158l84.168-180.316 10.197-7.201 164.4 6.588c1.047-2.243-2.563-9.108-4.499-12.26z"
          fill="url(#gvu12rn06a)"
        />
        <path
          d="m300.672 275.766 4.96 4.018 6.27 1.213-90.33 193.516-11.23-5.231 90.33-193.516z"
          fill="#000"
        />
        <path
          d="m306.294 278.381-112.88-124.286-12.349 4.491 119.337 132.416 174.132 7.707-.378-13.834-167.862-6.494z"
          fill="#000"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.27 87.435c11.664-.919 23.032 4.377 29.823 13.893l120.632 169.016c6.792 9.516 7.976 21.807 3.105 32.243l-7.256 15.545-11.225-5.23-15.71 33.655 11.226 5.23-63.548 136.141c-4.872 10.436-15.058 17.43-26.721 18.35l-207.144 16.325c-11.663.919-23.031-4.376-29.824-13.893L59.997 339.695c-6.792-9.517-7.976-21.807-3.104-32.244l7.256-15.544 11.23 5.231 15.709-33.655-11.23-5.232 63.548-136.141c4.871-10.435 15.056-17.43 26.719-18.349L377.27 87.435zm-4.274 11.417c11.001-.895 21.711 4.094 28.095 13.088l113.392 159.744c6.385 8.995 7.473 20.628 2.857 30.517l-81.99 175.651c-4.616 9.89-14.236 16.534-25.238 17.429L214.73 511.187c-10.999.896-21.71-4.093-28.095-13.088L73.244 338.355c-6.385-8.994-7.474-20.627-2.858-30.516l81.99-175.651c4.617-9.89 14.237-16.534 25.238-17.43l195.382-15.906z"
          fill="url(#uqt2r8mm6b)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.852 139.983a30.528 30.528 0 0 0-27.859-12.978l-160.909 14.658c-10.96.999-20.594 7.791-25.275 17.818L105.09 306.7c-4.68 10.028-3.696 21.768 2.584 30.796l92.189 132.562a30.53 30.53 0 0 0 27.86 12.978l160.908-14.66c10.959-.997 20.594-7.79 25.275-17.818l4.903-10.502-11.227-5.23-2.17 4.648a31.979 31.979 0 0 1-12.636 13.974 31.18 31.18 0 0 1-12.923 4.232l-146.784 14.112a30.405 30.405 0 0 1-28.021-13.054l-83.529-121.4c-6.288-9.14-7.229-21.055-2.467-31.258l63.254-135.51a31.972 31.972 0 0 1 11.243-13.098 31.212 31.212 0 0 1 14.315-5.109l146.782-14.112a30.405 30.405 0 0 1 28.023 13.055l83.53 121.399c6.288 9.14 7.229 21.056 2.467 31.258l-45.375 97.208 11.227 5.23 48.107-103.062c4.681-10.027 3.695-21.766-2.584-30.795l-92.189-132.561z"
          fill="url(#mwwvzzqybc)"
        />
        <path
          d="m262.492 416.204 23.564-50.483 58.648 2.251 15.244 29.828-38.808 20.655-58.648-2.251z"
          fill="url(#cofm0bm1fd)"
        />
        <path
          d="m291.289 354.503 23.565-50.483 58.647 2.253 15.244 29.828-38.808 20.654-58.648-2.252z"
          fill="url(#76gjk1qcze)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M377.27 87.431c11.664-.918 23.032 4.377 29.823 13.893L527.725 270.34c6.792 9.516 7.976 21.807 3.105 32.243l-7.256 15.545-11.225-5.229-15.71 33.654 11.226 5.23-63.548 136.141c-4.872 10.436-15.058 17.43-26.721 18.35l-207.144 16.325c-11.663.92-23.031-4.376-29.824-13.893L59.997 339.691c-6.792-9.517-7.976-21.807-3.104-32.244l7.256-15.544 11.23 5.231 15.709-33.655-11.23-5.231 63.548-136.141c4.871-10.436 15.056-17.431 26.719-18.35L377.27 87.431zm-4.274 11.417c11.001-.895 21.711 4.094 28.095 13.088L514.483 271.68c6.385 8.995 7.473 20.628 2.857 30.517l-81.99 175.651c-4.616 9.89-14.236 16.534-25.238 17.429L214.73 511.183c-10.999.896-21.71-4.093-28.095-13.088L73.244 338.351c-6.385-8.994-7.474-20.627-2.858-30.516l81.99-175.651c4.617-9.89 14.237-16.534 25.238-17.43l195.382-15.906z"
          fill="url(#1236czzgif)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M387.852 139.979a30.528 30.528 0 0 0-27.859-12.978L199.084 141.66c-10.96.998-20.594 7.79-25.275 17.818L105.09 306.697c-4.68 10.027-3.696 21.767 2.584 30.795l92.189 132.562a30.527 30.527 0 0 0 27.86 12.978l160.908-14.66c10.959-.997 20.594-7.79 25.275-17.818l4.903-10.502-11.227-5.23-2.17 4.648a31.99 31.99 0 0 1-12.636 13.974 31.18 31.18 0 0 1-12.923 4.232l-146.784 14.112a30.406 30.406 0 0 1-28.021-13.053l-83.529-121.4c-6.288-9.141-7.229-21.056-2.467-31.259l63.254-135.51a31.978 31.978 0 0 1 11.243-13.098 31.212 31.212 0 0 1 14.315-5.109l146.782-14.111a30.404 30.404 0 0 1 28.023 13.054l83.53 121.399c6.288 9.14 7.229 21.056 2.467 31.258l-45.375 97.208 11.227 5.23 48.107-103.062c4.681-10.027 3.695-21.766-2.584-30.795l-92.189-132.561z"
          fill="url(#v9021301ag)"
        />
        <path
          d="m262.492 416.2 23.564-50.483 58.648 2.251 15.244 29.828-38.808 20.655-58.648-2.251z"
          fill="url(#xf2qt7lenh)"
        />
        <path
          d="m291.289 354.499 23.565-50.483 58.647 2.253 15.244 29.828-38.808 20.655-58.648-2.253z"
          fill="url(#07z0oy9vpi)"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M407.08 101.326c-6.792-9.516-18.16-14.812-29.824-13.893l-207.144 16.325c-11.663.92-21.849 7.914-26.72 18.35L79.845 258.249l11.23 5.231-15.71 33.655-11.23-5.231-7.256 15.545c-4.871 10.436-3.688 22.727 3.104 32.244l120.631 169.014c6.793 9.517 18.161 14.813 29.824 13.893l207.144-16.324c11.663-.92 21.85-7.914 26.721-18.351l63.548-136.141-11.225-5.229 15.709-33.655 11.225 5.229 7.256-15.545c4.871-10.435 3.687-22.726-3.105-32.242L407.08 101.326zm-6.003 10.611c-6.384-8.994-17.094-13.983-28.095-13.087L177.6 114.756c-11 .895-20.621 7.539-25.237 17.429l-81.99 175.651c-4.617 9.89-3.528 21.523 2.857 30.517l113.392 159.744c6.385 8.994 17.095 13.983 28.095 13.087l195.382-15.905c11.001-.895 20.621-7.54 25.237-17.429l81.99-175.651c4.617-9.89 3.528-21.523-2.857-30.517L401.077 111.937zm-41.098 15.066a30.528 30.528 0 0 1 27.86 12.978l92.188 132.56c6.28 9.029 7.265 20.769 2.584 30.796l-48.106 103.061-11.227-5.23 45.374-97.207c4.762-10.203 3.822-22.118-2.467-31.258l-83.529-121.4a30.406 30.406 0 0 0-28.023-13.054L207.85 152.361a31.2 31.2 0 0 0-14.314 5.108 31.973 31.973 0 0 0-11.244 13.098l-63.253 135.511c-4.763 10.203-3.822 22.117 2.466 31.258l83.529 121.4a30.405 30.405 0 0 0 28.022 13.053l146.783-14.111a31.181 31.181 0 0 0 12.923-4.233 31.99 31.99 0 0 0 12.637-13.974l2.169-4.648 11.227 5.23-4.902 10.503c-4.681 10.028-14.317 16.82-25.276 17.818l-160.908 14.659a30.527 30.527 0 0 1-27.86-12.978L107.66 337.493c-6.28-9.027-7.264-20.767-2.583-30.795l68.718-147.219c4.681-10.027 14.316-16.82 25.276-17.818l160.908-14.658zm-97.501 289.198 23.564-50.482 58.648 2.25 15.244 29.828-38.808 20.655-58.648-2.251zm52.362-112.183-23.564 50.483 58.647 2.252 38.808-20.655-15.244-29.828-58.647-2.252z"
          fill="url(#hx277w7bej)"
          fillOpacity=".8"
        />
        <defs>
          <linearGradient
            id="gvu12rn06a"
            x1="100.964"
            y1="427.173"
            x2="393.396"
            y2="322.241"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#1921AE" />
            <stop offset="1" stopColor="#1A60F9" />
          </linearGradient>
          <linearGradient
            id="uqt2r8mm6b"
            x1="457.212"
            y1="567.177"
            x2="352.262"
            y2="17.337"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="mwwvzzqybc"
            x1="422.647"
            y1="517.026"
            x2="332.416"
            y2="72.674"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="cofm0bm1fd"
            x1="347.978"
            y1="443.58"
            x2="335.566"
            y2="347.836"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="76gjk1qcze"
            x1="376.775"
            y1="381.88"
            x2="364.364"
            y2="286.135"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="1236czzgif"
            x1="457.212"
            y1="567.173"
            x2="352.262"
            y2="17.333"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="v9021301ag"
            x1="422.647"
            y1="517.022"
            x2="332.416"
            y2="72.67"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="xf2qt7lenh"
            x1="347.978"
            y1="443.576"
            x2="335.566"
            y2="347.832"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <linearGradient
            id="07z0oy9vpi"
            x1="376.775"
            y1="381.876"
            x2="364.364"
            y2="286.131"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FAF6A6" />
            <stop offset="1" stopColor="#C5A44E" />
          </linearGradient>
          <motion.linearGradient
            id="hx277w7bej"
            x1="0"
            x2="588"
            y1="0"
            y2="611"
            gradientUnits="userSpaceOnUse"
          >
            <stop offset="0" stopColor="rgba(255, 255, 255, 0)">
              <animate
                attributeName="offset"
                from="-4"
                to="4"
                dur="5s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="0" stopColor="rgba(255, 255, 255, 1)">
              <animate
                attributeName="offset"
                from="-3.7"
                to="4.3"
                dur="5s"
                repeatCount="indefinite"
              />
            </stop>
            <stop offset="0" stopColor="rgba(255, 255, 255, 0)">
              <animate
                attributeName="offset"
                from="-3.4"
                to="4.6"
                dur="5s"
                repeatCount="indefinite"
              />
            </stop>
          </motion.linearGradient>
        </defs>
      </motion.svg>
    </div>
  );
}
