import { BigNumber, utils } from "ethers";
import { useEffect, useState } from "react";
import { useContractRead } from "wagmi";
import { EACAggregatorProxyAddr, config, predictionAddr, predictionProAddr } from "../config";
import EACAggregatorProxy from "../contracts/EACAggregatorProxy.json";
import { Tab, tabIndexes } from "../prediction/SelectAsset";
import { Rounds, usePredictionV2Read } from "./contracts/usePredictionV2Read";
import { usePredictionV3Read } from "./contracts/usePredictionV3Read";
import { useBinancePrice } from "./useBinancePrice";
import { PredictionGame } from "../context/PredictionGameContext";
import { useAssetPriceAsState } from "../store/asset-price";

type CardFinishType = {
  activeTabIndex: number;
  currentEpoch: number;
  keyTab: Tab;
  address?: string;
  watch?: boolean;
  pro?: PredictionGame;
  advance?: number;
};

type AggregatorType = Omit<CardFinishType, "currentEpoch" | "activeTabIndex" | "address"> & {
  enabled?: boolean;
};

type CurrentEpoch = Omit<CardFinishType, "currentEpoch" | "address">;

export type StatusType = "UP" | "DOWN" | "NO_POSITION";

export type PredictionRound = {
  epoch: number;
  priceDiff: string;
  lockPrice: { lockPrice: string; lockPriceDigit: string };
  closePrice: string;
  prizePool: string;
  lockTimestamp: string;
  closeTimestamp: string;
  payoutUp: string;
  payoutDown: string;
  closeOracleTimestamp: string;
}

export const keyTabToAssets = {
  BTC: "btc",
  ETH: "eth",
  MATIC: "btc",
  BNB: "btc",
} as const;

const calPriceDiff = (rounds: Rounds) => {
  const closePrice = rounds?.closePrice || 0;
  const lockPrice = rounds?.lockPrice || 0;
  return (+utils.formatUnits(
    BigNumber.from(closePrice).sub(BigNumber.from(lockPrice)),
    "8"
  )).toLocaleString(undefined, { minimumFractionDigits: 2 });
};

const calPriceChange = (latestPrice: string, lockPrice: string): string => {
  const _priceChage = BigNumber.from(latestPrice).sub(BigNumber.from(lockPrice)).toString();
  return (+utils.formatUnits(_priceChage, "8")).toLocaleString(undefined, {
    minimumFractionDigits: 2,
  });
};

export const useCurrentEpoch = ({
  keyTab,
  activeTabIndex,
  pro,
  watch = true,
}: CurrentEpoch): { currentEpoch: number } => {
  const [currentEpoch, setCurrentEpoch] = useState(1);

  const { data: currentEpochV2 } = usePredictionV2Read({
    watch,
    functionName: "currentEpoch",
    enabled: tabIndexes[activeTabIndex] === "MATIC" && !pro,
  });

  const { data: currentEpochV3 } = usePredictionV3Read({
    watch,
    address: pro ? predictionProAddr[pro][keyTab] : predictionAddr[keyTab],
    functionName: "currentEpoch",
    enabled: tabIndexes[activeTabIndex] !== "MATIC",
  });

  useEffect(() => {
    if (keyTab === "MATIC" && !!currentEpochV2) {
      setCurrentEpoch(Number(currentEpochV2));
    } else if (keyTab !== "MATIC" && !!currentEpochV3) {
      setCurrentEpoch(Number(currentEpochV3));
    }
  }, [activeTabIndex, currentEpochV2, currentEpochV3, keyTab]);

  return { currentEpoch };
};

export const useRounds = ({
  currentEpoch,
  keyTab,
  activeTabIndex,
  watch,
  pro,
}: Omit<CardFinishType, "address">) => {
  const [priceDiff, setPriceDiff] = useState("0");
  const [lockPrice, setLockPrice] = useState({
    lockPrice: "0",
    lockPriceDigit: "0.0000",
  });
  const [closePrice, setClosePrice] = useState("0");
  const [prizePool, setPrizePool] = useState("0");
  const [lockTimestamp, setLockTimestamp] = useState("0");
  const [closeTimestamp, setCloseTimestamp] = useState("0");
  const [closeOracleTimestamp, setCloseOracleTimestamp] = useState("0");
  const [payoutUp, setPayoutUp] = useState("0");
  const [payoutDown, setPayoutDown] = useState("0");
  const [bearAmount, setBearAmount] = useState("0");
  const [bullAmount, setBullAmount] = useState("0");

  const { data: roundsV2 } = usePredictionV2Read({
    watch,
    functionName: "rounds",
    args: currentEpoch,
    enabled: currentEpoch > 0 && keyTab === "MATIC" && !pro,
  });

  const { data: roundsV3 } = usePredictionV3Read({
    watch,
    address: pro ? predictionProAddr[pro][keyTab] : predictionAddr[keyTab],
    functionName: "rounds",
    args: currentEpoch,
    enabled: currentEpoch > 0 && keyTab !== "MATIC",
  });

  useEffect(() => {
    if (keyTab === "MATIC" && !roundsV2) return;
    if (keyTab !== "MATIC" && !roundsV3) return;

    const selectToken = tabIndexes[activeTabIndex];

    const rounds = selectToken === "MATIC" ? roundsV2! : roundsV3!;

    const _closePrice = (+utils.formatUnits(rounds?.closePrice ?? 0, "8")).toLocaleString(
      undefined,
      { minimumFractionDigits: 2, maximumFractionDigits: keyTab === "MATIC" ? 3 : 2 }
    );

    const _lockPrice = (+utils.formatUnits(rounds?.lockPrice ?? 0, "8")).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: keyTab === "MATIC" ? 3 : 2,
    });

    const _prizePool = (+utils.formatUnits(rounds?.totalAmount ?? 0, config.stablecoinDecimals)).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: keyTab === "MATIC" ? 3 : 2,
      }
    );

    setBearAmount(utils.formatUnits(rounds?.bearAmount, config.stablecoinDecimals))
    setBullAmount(utils.formatUnits(rounds?.bullAmount, config.stablecoinDecimals))

    let payoutUp = "1";
    let payoutDown = "1";

    if (pro) {
      payoutUp = config.predictionProPayout.toLocaleString();
      payoutDown = config.predictionProPayout.toLocaleString();
    } else {
      const bullAmount = rounds?.bullAmount ?? BigNumber.from(0);
      const bearAmount = rounds?.bearAmount ?? BigNumber.from(0);
      const roundAmount = bullAmount.add(bearAmount);

      if (bullAmount.gt(0) && bearAmount.eq(0)) {
        payoutDown = "2";
      } else if (bullAmount.gt(0) && bearAmount.eq(0)) {
        payoutUp = "2";
      } else if (bullAmount.gt(0) && bearAmount.gt(0)) {
        const payoutUpNumber = roundAmount.mul(1000).div(bullAmount).toNumber() / 1000;
        const payoutDownNumber = roundAmount.mul(1000).div(bearAmount).toNumber() / 1000;
        const maxUpFraction = payoutUpNumber >= 100 ? 0 : 2;
        const maxDownFraction = payoutDownNumber >= 100 ? 0 : 2;

        payoutUp = payoutUpNumber.toLocaleString(undefined, {
          minimumFractionDigits: maxUpFraction,
          maximumFractionDigits: maxUpFraction,
        });
        payoutDown = payoutDownNumber.toLocaleString(undefined, {
          minimumFractionDigits: maxDownFraction,
          maximumFractionDigits: maxDownFraction,
        });
      }
    }

    setClosePrice(_closePrice);
    setLockPrice({
      lockPrice: rounds?.lockPrice.toString() ?? "0",
      lockPriceDigit: _lockPrice,
    });
    setPrizePool(_prizePool);
    setPriceDiff(calPriceDiff(rounds));
    setLockTimestamp(BigNumber.from(rounds?.lockTimestamp ?? 0).toString());
    setPayoutUp(payoutUp);
    setPayoutDown(payoutDown);
    setCloseTimestamp(BigNumber.from(rounds?.closeTimestamp ?? 0).toString());
    setCloseOracleTimestamp(rounds?.closeOracleId.toString());
  }, [activeTabIndex, currentEpoch, roundsV2, roundsV3, keyTab, pro]);

  return {
    priceDiff,
    lockPrice,
    closePrice,
    prizePool,
    lockTimestamp,
    closeTimestamp,
    payoutUp,
    payoutDown,
    closeOracleTimestamp,
    bullAmount,
    bearAmount,
    epoch: currentEpoch,
  };
};

export const useLedger = ({
  currentEpoch,
  keyTab,
  activeTabIndex,
  address,
  watch,
  pro,
}: CardFinishType): { status: StatusType; claimed: boolean } => {
  const [status, setStatus] = useState<StatusType>("NO_POSITION");
  const [claimed, setClaimed] = useState(false);

  const { data: ledgerV2 } = usePredictionV2Read({
    watch,
    functionName: "ledger",
    args: [currentEpoch, address || ""],
    enabled: !!address && keyTab === "MATIC" && !pro,
  });

  const { data: ledgerV3 } = usePredictionV3Read({
    watch,
    address: pro ? predictionProAddr[pro][keyTab] : predictionAddr[keyTab],
    functionName: "ledger",
    args: [currentEpoch, address || ""],
    enabled: !!address && keyTab !== "MATIC",
  });

  useEffect(() => {
    if (keyTab === "MATIC" && !ledgerV2) return;
    if (keyTab !== "MATIC" && !ledgerV3) return;

    const selectToken = tabIndexes[activeTabIndex];
    const ledger = selectToken === "MATIC" ? ledgerV2 : ledgerV3;
    // position status
    let _status = "NO_POSITION";
    const amount = BigNumber.from(ledger?.amount ?? 0).gt(0);
    if (amount && BigNumber.from(ledger?.position ?? 0).lte(0)) _status = "UP";
    else if (amount && BigNumber.from(ledger?.position ?? 0).gte(1)) _status = "DOWN";
    setStatus(_status as StatusType);
    setClaimed(ledger?.claimed ?? false);
  }, [activeTabIndex, ledgerV2, ledgerV3, keyTab]);

  return { status, claimed };
};

export const useAggregator = ({
  keyTab,
  enabled,
}: AggregatorType): {
  latestPrice: string;
  tokenPrice: string;
} => {
  const [latestPrice, setLatestPrice] = useState("0");
  const [tokenPrice, setTokenPrice] = useState("0");

  const contractRead = useContractRead({
    addressOrName: EACAggregatorProxyAddr[keyTab],
    contractInterface: EACAggregatorProxy,
    functionName: "latestRoundData",
    watch: true,
    enabled,
  });
  const { data } = contractRead;

  useEffect(() => {
    if (!data) return;
    const _latestPrice = (+utils.formatUnits(data?.answer ?? 0, "8")).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: keyTab === "MATIC" ? 3 : 2,
    });
    setTokenPrice(data?.answer ?? 0);
    setLatestPrice(_latestPrice);
  }, [data, keyTab]);

  return {
    latestPrice,
    tokenPrice,
  };
};

export const usePrediction = ({
  currentEpoch,
  keyTab,
  activeTabIndex,
  pro,
  address,
  watch = true,
  advance = 0,
}: CardFinishType) => {
  const [priceChange, setPriceChange] = useState("0");

  const {
    lockPrice,
    closePrice,
    prizePool,
    priceDiff,
    payoutUp,
    payoutDown,
    closeTimestamp,
    closeOracleTimestamp,
    bearAmount,
    bullAmount
  } = useRounds({
    currentEpoch,
    keyTab,
    activeTabIndex,
    watch,
    pro,
  });

  const { status, claimed } = useLedger({
    currentEpoch: currentEpoch + advance,
    keyTab,
    activeTabIndex,
    address,
    watch,
    pro,
  });

  const { latestPrice, tokenPrice } = useAggregator({ keyTab, watch, enabled: !pro });
  const latestBinancePrice = useAssetPriceAsState(keyTabToAssets[keyTab], !!pro)

  useEffect(() => {
    if (!tokenPrice || !lockPrice) return;
    setPriceChange(calPriceChange(tokenPrice ?? "0", lockPrice.lockPrice ?? "0"));
  }, [lockPrice, tokenPrice]);

  return {
    lockPrice: lockPrice.lockPriceDigit,
    closePrice,
    prizePool,
    priceDiff,
    status,
    latestPrice: pro
      ? latestBinancePrice?.toLocaleString("en-US", { minimumFractionDigits: 2 })
      : latestPrice,
    priceChange,
    payoutUp,
    payoutDown,
    claimed,
    closeTimestamp,
    closeOracleTimestamp,
    bearAmount,
    bullAmount
  };
};
