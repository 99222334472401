import { ReactNode } from "react";

export const EmptyState = (props: {
  className?: string;
  children?: ReactNode;
}) => (
  <div
    className={`flex flex-col justify-center items-center ` + props.className}
  >
    <img className="w-11" alt="logo" src="/bb-logo.svg" />
    <p className="mt-4 text-center text-sm max-w-40 text-gray-400">
      {!props.children && (
        <>
          This round didn't have a number
          <br />
          Be the first. Let's start adding a number.
        </>
      )}
      {props.children}
    </p>
  </div>
);
