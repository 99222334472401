import { motion, Variants } from "framer-motion";
import { spinUpVariants } from "../animation/spin-up";
import iEye from "../assets/icon/eye.svg";
import iMobile from "../assets/icon/mobile.svg";
import iMoney from "../assets/icon/money.svg";
import iShield from "../assets/icon/shield.svg";
import logo from "../assets/logo1.svg";
import { AnimatedText } from "../common/AnimatedText";

export function WhatIs() {
  const logoContainerVariants: Variants = {
    hidden: {
      scale: 1.2,
      opacity: 0,
      "--blur": "10px",
    } as any,
    visible: {
      scale: 1,
      opacity: 1,
      "--blur": "0px",
      transition: { type: "spring", damping: 20, stiffness: 70 },
    } as any,
  };
  return (
    <div className="flex flex-col overflow-hidden lg:flex-row bg-gradient-black">
      <motion.div
        className="relative basis-7/12 lg:bg-what-is bg-what-is-mobile bg-cover lg:aspect-[825/688] aspect-[698/678] bg-no-repeat"
        initial="hidden"
        whileInView="visible"
        variants={logoContainerVariants}
        viewport={{ once: true, margin: "0px 0px -30% 0px" }}
        style={{ filter: "blur(var(--blur))" }}
      >
        <motion.img
          alt=""
          className="absolute lg:left-[18.6%] left-[21%] lg:top-[13.5%] top-[12.5%] lg:w-1/2 w-3/5"
          src={require("../assets/circle.png")}
        />
        <motion.img
          alt=""
          src={logo}
          variants={spinUpVariants(0.4)}
          className="absolute z-10 lg:top-[23%] top-[23%] lg:left-[28%] left-[32.5%] lg:w-[31%] w-[37%]"
        />
        <img
          alt=""
          className="absolute top-[11%] right-[28%] w-1/6 lg:w-24 floating"
          src={require("../assets/coin-logo.png")}
        />
        <img
          alt=""
          className="absolute bottom-[16%] right-[24%] w-1/6 lg:w-32 floating-2"
          src={require("../assets/coin-logo1.png")}
        />
        <img
          alt=""
          className="absolute bottom-[40%] left-[12%] w-1/6 lg:w-32 floating-3"
          src={require("../assets/coin-logo1.png")}
        />
      </motion.div>

      <div className="flex flex-col items-center justify-center py-5 basis-5/12">
        <motion.div
          className="container"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -30% 0px" }}
          transition={{ staggerChildren: 0.05 }}
        >
          <div className="mt-8 text-center lg:text-left lg:mt-0">
            <AnimatedText text="W" />
            <AnimatedText text="H" />
            <AnimatedText text="A" />
            <AnimatedText text="T" />
            <AnimatedText className="ml-3" gold text="I" />
            <AnimatedText gold text="S" />
          </div>
          <p className="text-lg font-light text-center lg:text-xl lg:mt-2 lg:text-left">
            <span className="font-bold">
              Billion Box is a decentralized prediction platform{" "} 
            </span>
              that offers users the chance to win up to{" "} 
            <span className="font-bold">
              $1 million dollar. 
            </span>{" "}
            With transparency at its core, Billion Box uses on-chain technology to ensure fair and unbiased results for all players
          </p>

          <div className="grid grid-cols-2 gap-10 mt-10 lg:gap-4">
            <div className="flex items-center">
              <img alt="" src={iShield} className="hvr-buzz" />
              <p className="ml-3 font-bold text-md lg:text-lg">
                Enchanced <br />
                Security & Fairness
              </p>
            </div>
            <div className="flex items-center">
              <img alt="" src={iMoney} className="hvr-buzz" />
              <p className="ml-3 font-bold text-md lg:text-lg">
                Play & Earn <br />
                Rewards
              </p>
            </div>
            <div className="flex items-center">
              <img alt="" src={iMobile} className="hvr-buzz" />
              <p className="ml-3 font-bold text-md lg:text-lg">
                Improve User <br />
                Experience
              </p>
            </div>
            <div className="flex items-center">
              <img alt="" src={iEye} className="hvr-buzz" />
              <p className="ml-3 font-bold text-md lg:text-lg">
                Transparent <br />
                Smart contract
              </p>
            </div>
          </div>
        </motion.div>
      </div>
    </div>
  );
}
