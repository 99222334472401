import dayjs from "dayjs";
import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Button } from "../common/Button";
import { Even } from "../common/Even";
import { HighLow } from "../common/HighLow";
import { useNotificationContext } from "../common/NotificationProvider";
import { Odd } from "../common/Odd";
import { ModeType } from "../common/SelectMode";
import { predictionProAddr } from "../config";
import { PredictionGame, useGameContext } from "../context/PredictionGameContext";
import { usePredictionV2Write } from "../hooks/contracts/usePredictionV2Write";
import { usePredictionV3Write } from "../hooks/contracts/usePredictionV3Write";
import { usePrediction } from "../hooks/usePrediction";
import { usePredictionGameUp } from "../hooks/usePredictionGameUp";
import { PlayGameButton } from "../prediction/PlayGameButton";
import { Tab } from "./SelectAsset";
import { PriceDecimal } from "./PriceDecimal";

type CardFinishType = {
  activeTabIndex: number;
  currentEpoch: number;
  keyTab: Tab;
  pro: PredictionGame;
};

export function CardFinished({ activeTabIndex, currentEpoch, keyTab, pro }: CardFinishType) {
  const { pushNotification } = useNotificationContext();
  const { address } = useAccount();
  const game = useGameContext();
  const [watch, setWatch] = useState(false);

  // end round is n-2
  const epoch = currentEpoch - 2 > 0 ? currentEpoch - 2 : 1;
  const {
    lockPrice,
    closePrice,
    claimed,
    closeTimestamp,
    closeOracleTimestamp,
    prizePool,
    priceDiff,
    status,
    payoutUp,
    payoutDown,
  } = usePrediction({ activeTabIndex, currentEpoch: epoch, keyTab, address, watch, pro });

  const bullWin = usePredictionGameUp(game, closePrice);
  const win = useMemo(() => (status === "UP" && bullWin) || (status === "DOWN" && !bullWin), [status, bullWin]);

  const { writeAsync: claimV2 } = usePredictionV2Write("claim", [[epoch]]);
  const { writeAsync: claimV3 } = usePredictionV3Write(predictionProAddr[pro][keyTab], "claim", [
    [epoch],
  ]);

  async function claim() {
    const claim = keyTab === "MATIC" ? claimV2 : claimV3;
    const tx = await claim();
    setWatch(true);

    pushNotification({
      type: "sendTx",
      txhash: tx.hash,
      key: tx.hash,
    });

    await tx.wait(3);

    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: `You've claimed reward for round ${epoch}!`,
    });

    setWatch(false);
  }

  return (
    <div className="relative">
      <div className="relative px-1 pb-1 mr-4 card-dark-blue w-card lg:mr-8">
        <div className="flex items-center justify-center opacity-60">
          <img className="w-3 mr-1" src={require("../assets/icon/flag.png")} alt="" />
          <span className="py-2 mr-1 font-semibold uppercase">finished</span>
          <span className="text-[#9a9a9a] font-semibold">#{epoch}</span>
        </div>

        <div className="pt-3 bg-gradient-black-to-blue-r rounded-2xl opacity-60">
          <div className="flex justify-between px-4 mt-3 text-sm">
            <p className="font-bold">Closed Time</p>
            <p>{dayjs(Number(closeOracleTimestamp) * 1000).format("DD MMM HH:mm")}</p>
          </div>
          <div
            className={`${
              bullWin ? "card-gradient-green" : "card-gradient-red"
            } mx-4 py-2 mb-8 flex flex-col items-center justify-center mt-4`}
          >
            <div className="flex items-center">
              <img className="w-3 mr-1" src={require("../assets/icon/close-price2.png")} alt="" />
              <p className="py-1 text-sm text-gradient silver">Closed price</p>
            </div>
            <PriceDecimal
              price={closePrice}
              color={bullWin ? "#46b900" : "#ff1010"}
              borderColor={bullWin ? "#46b900" : "#ff1010"}
            />
            {game === ModeType.UpDown && (
              <>
                <div
                  className={`flex items-center space-x-1 font-bold text-sm ${
                    bullWin ? "text-[#46b900]" : "text-[#ff1010]"
                  }`}
                >
                  <img
                    alt=""
                    className="w-2"
                    src={
                      bullWin
                        ? require("../assets/up-icon-green.png")
                        : require("../assets/down-icon-red.png")
                    }
                  />
                  <span>${priceDiff}</span>
                </div>
              </>
            )}

            {game === ModeType.EvenOdd && (
              <>
                {bullWin ? (
                  <div className="text-[#46b900] font-bold text-center flex items-center space-x-2 justify-center">
                    <Odd />
                    <span>ODD</span>
                  </div>
                ) : (
                  <div className="text-[#ff1010] font-bold text-center flex items-center space-x-2 justify-center">
                    <Even />
                    <span>Even</span>
                  </div>
                )}
              </>
            )}

            {game === ModeType.HighLow && (
              <>
                {bullWin ? (
                  <div className="text-[#46b900] font-bold text-center flex items-center space-x-2 justify-center">
                    <HighLow high />
                    <span>HIGH</span>
                  </div>
                ) : (
                  <div className="text-[#ff1010] font-bold text-center flex items-center space-x-2 justify-center">
                    <HighLow low />
                    <span>LOW</span>
                  </div>
                )}
              </>
            )}
          </div>

          <div className="relative grid grid-cols-2 top-4">
            <div className="px-2 py-1 mx-6 text-sm font-semibold text-center card-border-blue-bg-black">
              Payout {payoutDown}x
            </div>
            <div className="px-2 py-1 mx-6 text-sm font-semibold text-center card-border-blue-bg-black">
              Payout {payoutUp}x
            </div>
          </div>
          <div className="h-[2px] bg-[#0e1a3a]"></div>
          <div className="grid grid-cols-2">
            {game === ModeType.HighLow && (
              <>
                <PlayGameButton
                  highlight={!bullWin}
                  type="low"
                  className="pb-[20px] pt-[24px] rounded-bl-2xl"
                />
                <PlayGameButton
                  highlight={bullWin}
                  type="high"
                  className="pb-[20px] pt-[24px] rounded-br-2xl"
                />
              </>
            )}
            {game === ModeType.UpDown && (
              <>
                <PlayGameButton
                  highlight={!bullWin}
                  type="down"
                  className="pb-[20px] pt-[24px] rounded-bl-2xl"
                />
                <PlayGameButton
                  highlight={bullWin}
                  type="up"
                  className="pb-[20px] pt-[24px] rounded-br-2xl"
                />
              </>
            )}
            {game === ModeType.EvenOdd && (
              <>
                <PlayGameButton
                  highlight={!bullWin}
                  type="even"
                  className="pb-[20px] pt-[24px] rounded-bl-2xl"
                />
                <PlayGameButton
                  highlight={bullWin}
                  type="odd"
                  className="pb-[20px] pt-[24px] rounded-br-2xl"
                />
              </>
            )}
          </div>
        </div>
        <div className="w-full opacity-60">
          <div className="absolute flex justify-around w-full -bottom-3">
            {status === "UP" && !win ? (
              <>
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : status === "DOWN" && !win ? (
              <>
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : null}
          </div>
        </div>
        {win && (
          <div className="absolute inset-x-0 bottom-0 flex flex-col items-center p-4 bg-cover bg-winner-silver rounded-b-2xl">
            <img className="-mt-10 w-11" src={require("../assets/bb-icon1.png")} alt="" />
            <p className="mt-2 text-xl font-bold">You’re Winnner</p>
            <div className="flex justify-center mt-2">
              <Button
                preset="white"
                buttonSize="md"
                className="px-8 uppercase"
                onClick={claim}
                disabled={claimed}
              >
                {claimed ? "CLAIMED" : "COLLECT"}
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
