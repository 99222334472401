import { AnimatePresence, motion } from "framer-motion";
import { ReactNode, useEffect, useState } from "react";
import * as ReactDOM from "react-dom";

export const Modal = ({
  children,
  active,
  onDismiss,
}: {
  children: ReactNode;
  active: boolean;
  onDismiss?: () => void;
}) => {
  const modalContainer = document.getElementById("modal-container");
  const [domReady, setDomReady] = useState(false);

  useEffect(() => {
    setDomReady(true);
  }, []);

  return domReady
    ? ReactDOM.createPortal(
        <AnimatePresence>
          {active && (
            <div className="z-30 modal is-active">
              <motion.div
                onClick={onDismiss}
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="fixed inset-0 bg-black/70"
              ></motion.div>
              <motion.div
                className="lg:w-full w-[80%]"
                initial={{ opacity: 0, y: 300 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: 300 }}
                transition={{ type: "spring", damping: 25, stiffness: 200 }}
              >
                {children}
              </motion.div>
            </div>
          )}
        </AnimatePresence>,
        modalContainer!
      )
    : null;
};
