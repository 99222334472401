import { useMemo } from "react";

type Props = {
  price?: string;
  color: string;
  borderColor: string;
};

export const PriceDecimal = ({ price, color, borderColor }: Props) => {
  const currentPrice = useMemo(() => {
    if (!price)
      return {
        firstPrice: "",
        decimal: "",
      };
    const [firstPrice, decimal] = price?.split(".");
    return { firstPrice, decimal };
  }, [price]);

  return (
    <div className="flex flex-row items-center text-2xl font-bold font-roboto-condensed gap-x-1" style={{ color }}>
      <span>${currentPrice.firstPrice}.</span>
      <span className="px-[4px] bg-[#04132C] rounded-lg border" style={{ borderColor }}>
        {currentPrice.decimal}
      </span>
    </div>
  );
};
