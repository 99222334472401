type Props = {
  up?: boolean;
  down?: boolean;
};

export const Arrow = ({ up, down }: Props) => {
  return (
    <svg
      width="14"
      height="16"
      viewBox="0 0 14 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: down ? "rotate(180deg)" : "rotate(0deg)" }}
    >
      <path d="M12.816 5.835a1.067 1.067 0 0 1-.752 1.822c-.273 0-.543-.104-.75-.313L7.627 3.64v11.291a1.064 1.064 0 1 1-2.127 0V3.641L1.814 7.344a1.057 1.057 0 0 1-1.503 0 1.067 1.067 0 0 1 0-1.509l5.5-5.522c.2-.202.47-.313.752-.313.283 0 .553.111.751.313l5.5 5.522z" />
    </svg>
  );
};
