import { useMemo } from "react";
import { BigNumber } from "ethers";
import { formattedBigNumber } from "../utils/formatter";

export const useFormattedPrize = (prizeReserved?: BigNumber, totalPrize?: BigNumber) => {
  return useMemo(() => {
    const rawTotal =
      totalPrize && prizeReserved ? totalPrize.add(prizeReserved) : BigNumber.from(0);
    const reserved = formattedBigNumber("$", prizeReserved);
    const prizeInRound = formattedBigNumber("$", totalPrize);
    const total = formattedBigNumber("$", rawTotal);
    return { reserved, totalPrize: total, prizeInRound, rawTotal };
  }, [prizeReserved, totalPrize]);
};
