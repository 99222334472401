import { Round } from "../../hooks/contracts/usePortionBoxRead";
import { useCountdown } from "../../hooks/useCountdown";
import { bigNumberToTime } from "../../utils/formatter";

type Props = {
  round?: Round;
  drawComponent: (countdown: string, endRoundDate: Date) => JSX.Element;
  executingComponent: (countdown: string, endRoundDate: Date) => JSX.Element;
  calculatingComponent: JSX.Element;
};

export const RoundCountdownDisplay = ({
  round,
  drawComponent,
  executingComponent,
  calculatingComponent,
}: Props) => {
  const [, , closeCountdown] = useCountdown(bigNumberToTime(round?.closeTime));

  const [, , executeCountdown] = useCountdown(bigNumberToTime(round?.executeAfterTime));

  if (closeCountdown !== "0:00:00") {
    return drawComponent(closeCountdown, new Date(bigNumberToTime(round?.executeAfterTime) ?? 0));
  }

  if (executeCountdown !== "0:00:00") {
    return executingComponent(
      executeCountdown,
      new Date(bigNumberToTime(round?.executeAfterTime) ?? 0)
    );
  }

  return calculatingComponent;
};
