import { useNotificationContext } from "../../common";
import { Button } from "../../common/Button";
import { FadeSlideAnimatePresence } from "../../common/FadeSlideAnimatePresence";
import { config } from "../../config";
import { useRoundNumberStats } from "../../hooks/contracts/multiplier-box";
import { useMultiplierBoxNumberStore } from "../../store/multiplier-box-number-store";
import { useCurrentRoundState } from "../play/CurrentRoundProvider";

export const MostPlay = () => {
  const { numbers, addNumber } = useMultiplierBoxNumberStore();
  const { pushNotification } = useNotificationContext();
  const { currentRound, digits = 1, payoutRate, gameAddress = "" } = useCurrentRoundState();
  const roundNumbers = useRoundNumberStats(currentRound?.roundId, gameAddress, digits);
  const addedNumbers = numbers.reduce((prev, cur) => {
    prev[cur.number] = true;
    return prev;
  }, {} as Record<number, boolean>);

  function addNewNumber(add: string | number) {
    const number = Number(add);
    if (!Number.isNaN(number) && add !== "") {
      addNumber(number);
      pushNotification({
        key: number.toString(),
        number: number.toString().padStart(2, "0"),
        type: "add",
      });
    }
  }

  return (
    <FadeSlideAnimatePresence className="flex flex-col h-full space-y-4 overflow-y-scroll no-scrollbar">
      <div className="flex flex-row items-center justify-center space-x-2">
        <img
          className="w-6"
          src={require("../../assets/multiplier-box/trophy@2x.png")}
          alt="Round icon"
        />
        <span className="text-2xl font-bold text-gradient gold">
          x{payoutRate?.formattedRate?.toString()}
        </span>
        <span className="text-2xl font-bold text-white">
          ROUND {currentRound?.roundId.toString()}
        </span>
      </div>
      <div className="flex-1 space-y-3">
        {roundNumbers.map(({ number, amount }) => (
          <NumberInfo
            key={number}
            number={number}
            padStart={digits}
            maxPerNumber={config.multiplierBox.maxPerNumber[gameAddress] ?? 0}
            amount={amount}
            added={addedNumbers[number]}
            onAdd={addNewNumber}
          />
        ))}
      </div>
    </FadeSlideAnimatePresence>
  );
};

type NumberInfoProps = {
  number: number;
  amount: number;
  added?: boolean;
  padStart: number;
  maxPerNumber: number;
  onAdd?: (number: number) => void;
};

const NumberInfo = ({ number, amount, maxPerNumber, added, padStart, onAdd }: NumberInfoProps) => {
  return (
    <div className="flex flex-row items-center px-5 py-3 space-x-2 md:px-10 bg-dark-blue-gray rounded-xl">
      <div className="w-12 text-center md:text-2xl text-lg font-bold md:tracking-[0.75rem] tracking-[0.2rem] text-gradient gold">
        {number.toString().padStart(padStart, "0")}
      </div>
      <div className="flex-1 text-base font-bold text-center text-white md:text-xl">
        ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}{" "}
        <small className="text-sm md:text-base text-white/30">
          {" "}
          / {maxPerNumber.toLocaleString()}
        </small>
      </div>
      {amount >= maxPerNumber ? (
        <div className="text-base font-bold text-white/30">SOLDOUT</div>
      ) : (
        <Button
          className="px-6 py-1 text-black"
          buttonSize="sm"
          preset="gold"
          disabled={added}
          onClick={() => onAdd?.(number)}
        >
          ADD
        </Button>
      )}
    </div>
  );
};
