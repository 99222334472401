import clsx from "clsx";

type Props = {
  tabs: string[];
  activeTab: number;
  onTabChange: (tab: number) => void;
};

export const TopTabgroup = ({ tabs, activeTab, onTabChange }: Props) => {
  const mainTabClassName = "flex flex-1 items-center justify-center p-2 space-x-2 rounded-t-2xl";

  return (
    <div className="relative z-10 flex">
      {tabs.map((tab, index) => (
        <button
          key={index}
          className={clsx(mainTabClassName, {
            "bg-blue-gray": activeTab !== index,
            "bg-gradient-gold-tb": activeTab === index,
          })}
          onClick={() => onTabChange(index)}
        >
          <img
            className={clsx("w-7", activeTab !== index && "hidden")}
            src={require("../assets/bb-icon1.png")}
            alt="Logo"
          />
          <span className="font-semibold">{tab}</span>
        </button>
      ))}
    </div>
  );
};
