import { BigNumber, BigNumberish, utils } from "ethers";
import { NumberInfo } from "./../../../store/multiplier-box-number-store";

export const useParsedAmountsAndNumbers = (
  price: number,
  numbers: NumberInfo[],
  decimals?: number
) => {
  let playNumbers: BigNumberish[] = [];
  let amounts: BigNumberish[] = [];
  let total = BigNumber.from(0);

  for (const { number, amount = 0 } of numbers) {
    if (amount === 0) continue;
    const adjustedPriceAmount = Math.ceil((amount / price) * 100) / 100;
    const amountBigNumber = utils.parseUnits(adjustedPriceAmount.toString(), decimals);
    total = total.add(amountBigNumber);
    amounts.push(amountBigNumber);
    playNumbers.push(number);
  }

  return { numbers: playNumbers, amounts, total };
};
