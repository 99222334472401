import clsx from "clsx";
import { Arrow } from "../common/Arrow";
import { Odd } from "../common/Odd";
import { Even } from "../common/Even";
import { HighLow } from "../common/HighLow";

const bgColors = {
  up: "bg-lime-700",
  down: "bg-red-900",
  odd: "bg-lime-700",
  even: "bg-red-900",
  high: "bg-lime-700",
  low: "bg-red-900",
};

const textColors = {
  up: "text-[#46b900]",
  down: "text-[#ff1010]",
  odd: "text-[#46b900]",
  even: "text-[#ff1010]",
  high: "text-[#46b900]",
  low: "text-[#ff1010]",
};

type Props = {
  className?: string;
  highlight?: boolean;
  type: keyof typeof bgColors;
  onClick?: () => void;
};

const buttonComponents = {
  up: (
    <>
      <Arrow up /> <span>UP</span>
    </>
  ),
  down: (
    <>
      <Arrow down /> <span>DOWN</span>
    </>
  ),
  odd: (
    <>
      <Odd /> <span>ODD</span>
    </>
  ),
  even: (
    <>
      <Even /> <span>EVEN</span>
    </>
  ),
  high: (
    <>
      <HighLow high /> <span>HIGH</span>
    </>
  ),
  low: (
    <>
      <HighLow low /> <span>LOW</span>
    </>
  ),
};

export const PlayGameButton = ({ className, highlight, type, onClick }: Props) => {
  return (
    <div
      className={clsx(
        className,
        highlight ? bgColors[type] : "bg-[#151f2f]",
        "bg-opacity-70 pt-10 pb-[26px]"
      )}
      onClick={onClick}
    >
      <div
        className={clsx(
          highlight ? textColors[type] : "text-[#404040]",
          "text-xl font-bold text-center flex items-center space-x-2 justify-center"
        )}
      >
        {buttonComponents[type]}
      </div>
    </div>
  );
};
