type Props = {
  high?: boolean;
  low?: boolean;
};

export const HighLow = ({ high, low }: Props) => {
  return (
    <svg
      width="16"
      height="11"
      viewBox="0 0 16 11"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: high ? "rotate(180deg)" : "rotate(0deg)" }}
    >
      <path d="m14 .969-6 6-6-6-2 2 8 8 8-8-2-2z" />
    </svg>
  );
};
