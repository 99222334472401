import { useMemo } from "react";
import { address, config } from "../../../config";

const disabledNumbers = [1, 99]

export const useExceedLimitNumbers = (numberLimits: number[], game: string) =>
  useMemo(
    () =>
      numberLimits
        .map((numberLimit, number) => ({ numberLimit, number }))
        .filter(
          (numberLimit) => {
            if (game === address.BB_MAX_LOTTERY_ADDRESS && disabledNumbers.includes(numberLimit.number)) {
              return true
            }
            return config.multiplierBox.maxPerNumber[game] - numberLimit.numberLimit === 0
          }
        )
        .map((numberLimit) => numberLimit.number),
    [numberLimits, game]
  );
