import { BigNumber } from "ethers";
import { createContext, ReactNode, useContext, useEffect } from "react";
import { RoomDigit } from "../config";
import { useCurrentRound } from "../hooks/contracts/useCurrentRound";
import { Round, usePortionBoxRead } from "../hooks/contracts/usePortionBoxRead";
import { useNumberStore } from "../store/number-store";

export type RoomStateType = Partial<{
  round: Round;
  roundId: number;
  prizeReserved: BigNumber;
}>;

type RoomState = Partial<Record<RoomDigit, RoomStateType>>;

const RoomDataContext = createContext<RoomState>({});

type Props = {
  children: ReactNode;
  onlyRoom?: RoomDigit;
};

export const RoomDataProvider = ({ children, onlyRoom }: Props) => {
  const { registerFetchCallback } = useNumberStore.getState();

  // const roundForRoom1 = useCurrentRound(1, isRoomEnabled(1));
  const roundForRoom2 = useCurrentRound(2, isRoomEnabled(2));
  const roundForRoom3 = useCurrentRound(3, isRoomEnabled(3));
  const roundForRoom4 = useCurrentRound(4, isRoomEnabled(4));
  const roundForRoom5 = useCurrentRound(5, isRoomEnabled(5));

  // const { data: prizeReservedRoom1 } = usePortionBoxRead({
  //   room: 1,
  //   functionName: "prizeReserved",
  //   enabled: isRoomEnabled(1),
  // });

  const { data: prizeReservedRoom2 } = usePortionBoxRead({
    room: 2,
    functionName: "prizeReserved",
    enabled: isRoomEnabled(2),
  });

  const { data: prizeReservedRoom3 } = usePortionBoxRead({
    room: 3,
    functionName: "prizeReserved",
    enabled: isRoomEnabled(3),
  });

  const { data: prizeReservedRoom4 } = usePortionBoxRead({
    room: 4,
    functionName: "prizeReserved",
    enabled: isRoomEnabled(4),
  });

  const { data: prizeReservedRoom5 } = usePortionBoxRead({
    room: 5,
    functionName: "prizeReserved",
    enabled: isRoomEnabled(5),
  });

  useEffect(() => {
    [roundForRoom2, roundForRoom3, roundForRoom4].forEach((round, index) =>
      registerFetchCallback(`round-index-${index}`, round.refetchRound)
    );
  }, [roundForRoom2, roundForRoom3, roundForRoom4, registerFetchCallback]);

  function isRoomEnabled(room: RoomDigit) {
    return onlyRoom === undefined || onlyRoom == room;
  }

  return (
    <RoomDataContext.Provider
      value={{
        // 1: { ...roundForRoom1, prizeReserved: prizeReservedRoom1 },
        2: { ...roundForRoom2, prizeReserved: prizeReservedRoom2 },
        3: { ...roundForRoom3, prizeReserved: prizeReservedRoom3 },
        4: { ...roundForRoom4, prizeReserved: prizeReservedRoom4 },
        5: { ...roundForRoom5, prizeReserved: prizeReservedRoom5 },
      }}
    >
      {children}
    </RoomDataContext.Provider>
  );
};

export const useRoomState = () => useContext(RoomDataContext);

export const useSpecificRoomState = (roomDigit: RoomDigit) => {
  const roomState = useRoomState();
  return roomState[roomDigit];
};
