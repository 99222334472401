import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { useFormattedTicketBalance } from "../hooks/contracts/useTicketBalance";
import { useTickets } from "../hooks/contracts/useTickets";
import { useRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";
import { Dashboard } from "./Dashboard";
import { useBillionboxPrizeDistribute } from "../hooks";

export const DashboardContainer = () => {
  const { room = "" } = useParams();
  const { registerFetchCallback } = useNumberStore.getState();
  const rooms = useRoomState();
  const roomState = rooms[room];

  const { address = "" } = useAccount();
  const { refetchTickets, prize, winTickets, tickets } = useTickets(address, room);
  const ticketBalance = useFormattedTicketBalance(address, tickets.length);
  const prizeDistribution = useBillionboxPrizeDistribute(room);

  useEffect(() => {
    registerFetchCallback("ticket-balance", refetchTickets);
  }, [refetchTickets, registerFetchCallback]);

  return (
    <Dashboard
      digit={room}
      room={roomState}
      ticketBalance={ticketBalance}
      prizeDistribution={prizeDistribution}
      refetchTickets={refetchTickets}
      prize={prize}
      winTickets={winTickets}
    />
  );
};
