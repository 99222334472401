import { motion } from "framer-motion";

export function CardCalculating({ currentEpoch }: { currentEpoch: number }) {
  return (
    <div className="px-1 pb-1 mr-4 overflow-hidden select-none card-dark-blue w-card h-card lg:mr-8">
      <div className="flex items-center justify-center">
        <img className="w-4 mr-1" src={require("../assets/icon/clock-white.png")} alt="" />
        <span className="py-2 mr-1 font-semibold uppercase">Calculating</span>
        <span className="text-[#9a9a9a] font-semibold">#{currentEpoch}</span>
      </div>

      <div className="relative flex flex-col items-center justify-center bg-gradient-black-to-blue-r rounded-2xl h-card">
        <img
          className="absolute bottom-0 right-0 w-40"
          src={require("../assets/clock.png")}
          alt=""
        />
        <motion.img
          className="w-14"
          src={require("../assets/bb-icon1.png")}
          alt=""
          initial={{ y: -15 }}
          animate={{ y: 0 }}
          transition={{ repeat: Infinity, duration: 1.5, repeatType: "reverse" }}
        />
        <p className="text-[#a5a5a5] text-lg py-5">Calculating..</p>
      </div>
    </div>
  );
}
