import { Link } from "react-router-dom";

export const Header = () => {
  return (
    <div className="bg-cover bg-multiplier mt-[60px]">
      <div className="w-full h-24 bg-gradient-to-b from-black"></div>
      <div className="container">
        <img
          className="max-w-full w-[600px] -mt-10 mx-auto"
          src={require("../assets/multiplier-box/title-logo@2x.png")}
          alt=""
        />
        <p className="-mt-8 text-xl text-center font-audiowide max-w-[600px] mx-auto">
          Join in the single and double lotto games with BTC decimal prices, available every 60
          minutes
        </p>
        <div className="flex flex-col items-center justify-center mt-8 md:space-x-5 md:flex-row">
          <img className="w-[200px]" src={require("../assets/secure-by-gold.png")} alt="" />
          <div className="flex mt-5 md:mt-0">
            <Link to="/" className="flex items-center mr-10">
              <img className="h-5 mr-2" src={require("../assets/icon/question.png")} alt="" />
              <p className="text-sm uppercase text-gradient gold">How to play</p>
            </Link>
            <Link to="/" className="flex items-center">
              <img className="h-5 mr-2" src={require("../assets/icon/doc.png")} alt="" />
              <p className="text-sm uppercase text-gradient gold">doc</p>
            </Link>
          </div>
        </div>
      </div>

      <div className="w-full h-24 bg-gradient-to-t from-black"></div>
    </div>
  );
};
