import { chain } from "wagmi";
import { Tab } from "./prediction/SelectAsset";

export type Asset = "btc" | "eth" | "matic";
export type JackpotAsset = "btc" | "eth" | "bnb";

export const assetPairs = {
  btc: "BTCUSDT",
  eth: "ETHUSDT",
  bnb: "BNBUSDT",
  matic: "MATICUSDT",
};

export const address = {
  AGGREGATOR: {
    // btc: "0x007A22900a3B98143368Bd5906f8E17e9867581b",
    btc: "0xc907E116054Ad103354f2D350FD2514433D57F6f",
    eth: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
    matic: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
    // matic: "0xd0D5e3DB44DE05E9F294BB0a3bEEaF030DE24Ada",
  },
  PORTION_BOX1: "0xb048b611b7315A2CF4ceb6530dCC6F768277fEa9",
  PORTION_BOX2: "0xb048b611b7315A2CF4ceb6530dCC6F768277fEa9",
  PORTION_BOX3: "0x5A5dC39c940744baaEd1f8974f8eE7C8F897c8c5",
  PORTION_BOX4: "0x9c66F5147Eb2A66Fca20991b2EBf662BD566E3Db",
  PORTION_BOX5: "0x67FaF6A191d75b8d253031876f088Df6248DA4f1",
  TICKET: "0x33f93BcDC64227E30C9cEEb2aAE254c16034e853",
  // TEST TOKEN
  // USDT_ADDRESS: "0x4c1708720e0bb0cf76aa9d7386fa98bd4ea6fe71",
  USDT_ADDRESS: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  USDC_ADDRESS: "0x2791bca1f2de4661ed88a30c99a7a9449aa84174",
  DAI_ADDRESS: "0x8f3Cf7ad23Cd3CaDbD9735AFf958023239c6A063",
  BUY_TOKEN_ADDRESS: "0x92012e414293888de15ee54da2bc576ed13d4fe5",
  DBUSD_ADDRESS: "0x414F40dC0e3feA0E2197cF833a621D2B251B18ca",
  ALTERNATE_TOKEN_ADDRESS: "0x8b12564c088E3A7BC5DFa64b7209E12A775fad20",
  WETH_ADDRESS: "0x0d500B1d8E8eF31E21C99d1Db9A6444d3ADf1270",
  ROUTER_ADDRESS: "0xa5e0829caced8ffdd4de3c43696c57f7d7a678ff",
  BB_MAX_ADDRESS: "0xfC212f0a363957E890e71CdF6bcCA4f2baF790cF",
  BB_MAX_LOTTERY_ADDRESS: "0x8E0D184Cc7CDec93b18fb5439fC03d20147489a4",
  BB_MAX_ONE_DIGIT_ADDRESS: "0x47e71bC1Cd02c810bafd55F8bC3c6C36FBA90052",
};

export const predictionAddr: Record<Tab, string> = {
  BTC: "0x2c9671D0D3F81C4095706D27fc7C921d9FD7E292",
  ETH: "0xe152216654e02fbbe756b4556304042b583fea8b",
  MATIC: "0x43A71c7c28D9322520E1F06a9D2062C3484E775c",
};

export const predictionProAddr = {
  "high-low": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
  "up-down": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
  "even-odd": {
    BTC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    ETH: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
    MATIC: "0x4082C3cF1F866BFd75E544181ad9D1377BbF0E7A",
  },
};

export const tokenAddr: Record<Tab, string> = {
  BTC: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  ETH: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
  MATIC: "0xc2132d05d31c914a87c6611c10748aeb04b58e8f",
};

export const EACAggregatorProxyAddr: Record<Tab, string> = {
  BTC: "0x007A22900a3B98143368Bd5906f8E17e9867581b",
  // BTC: "0xc907E116054Ad103354f2D350FD2514433D57F6f",
  ETH: "0xF9680D99D6C9589e2a93a78A04A279e509205945",
  MATIC: "0xAB594600376Ec9fD91F8e885dADF0CE036862dE0",
};

export type RoomDigit = 1 | 2 | 3 | 4 | 5 | number | string;
export const digits: RoomDigit[] = [5];

export const roomAddress: Record<string | number, string> = {
  1: address.PORTION_BOX1,
  2: address.PORTION_BOX2,
  3: address.PORTION_BOX3,
  4: address.PORTION_BOX4,
  5: address.PORTION_BOX5,
};

export const maxPrize: Record<RoomDigit, number> = {
  1: 10000000,
  2: 10000000,
  3: 10000000,
  4: 10000000,
  5: 10000000,
};

export const chainlinkAssetLink = {
  btc: "https://data.chain.link/polygon/mainnet/crypto-usd/btc-usd",
  eth: "https://data.chain.link/polygon/mainnet/crypto-usd/eth-usd",
  matic: "https://data.chain.link/polygon/mainnet/crypto-usd/matic-usd",
} as const;

export const BB_MAX_GAMES: Record<number, string> = {
  1: address.BB_MAX_ONE_DIGIT_ADDRESS,
  2: address.BB_MAX_LOTTERY_ADDRESS,
};

export const predictionProGameContractName = {
  "even-odd": "EVEN_ODD",
  "high-low": "HIGH_LOW",
  "up-down": "UP_DOWN",
};

export const config = {
  chain: chain.polygon,
  predictionProPayout: 1.90,
  predictionProLockSeconds: 60,
  predictionMaxAmount: 500,
  predictionRoundTime: {
    BTC: 60 * 15,
    ETH: 60 * 60,
    MATIC: 60 * 60,
    pro: {
      BTC: 60 * 60,
      ETH: 60 * 15,
      MATIC: 60 * 15,
    },
  },
  ticketPrice(room: RoomDigit) {
    return 5;
  },
  maxSizePerNumber: 99999,
  maxNumberPerTx(room: RoomDigit) {
    if (room == 1) return 10;
    return 100;
  },
  refetchInterval: 15_000,
  binancePriceRefetchInterval: 100,
  cacheTime: 10_000,
  shortCacheTime: 5_000,
  // explorerUrl: "https://mumbai.polygonscan.com",
  explorerUrl: "https://polygonscan.com",
  stablecoinDecimals: 6,
  multiplierBox: {
    feePercent: 0,
    maxDecimalPlaces: 0,
    maxPerNumber: {
      [BB_MAX_GAMES[1]]: 100,
      [BB_MAX_GAMES[2]]: 100,
    } as Record<string, number>,
    maxPerRound: {
      [BB_MAX_GAMES[1]]: 5000,
      [BB_MAX_GAMES[2]]: 10000,
    } as Record<string, number>,
  },
  binancePriceUrl: "https://api.binance.com/api/v3/ticker/price",
  binanceKlinesUrl: "https://data.binance.com/api/v3/klines",
};
