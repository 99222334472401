import clsx from "clsx";

type Props = {
  progress: number;
  size?: "sm" | "md" | "lg";
  className?: string;
  progressClassName?: string;
  reverse?: boolean;
  rounded?: boolean;
};

const sizes = {
  sm: "h-[6px]",
  md: "h-[10px]",
  lg: "h-[14px]",
};

export const ProgressBar = ({
  className,
  progressClassName,
  progress,
  size = "md",
  reverse = false,
  rounded = true,
}: Props) => {
  return (
    <div
      className={clsx(
        "w-full flex bg-darkGray",
        sizes[size],
        className,
        rounded && "rounded-xl",
        reverse && "justify-end"
      )}
    >
      <div
        style={{ width: `${progress}%` }}
        className={clsx(
          rounded && "rounded-xl",
          !rounded && progress < 100 && (reverse ? "rounded-l-xl" : "rounded-r-xl"),
          progressClassName
        )}
      ></div>
    </div>
  );
};
