import { Ticket } from "./contracts/usePortionBoxRead";
import { BigNumberish } from "ethers";
import { useMemo } from "react";

export const useCurrentRoundTickets = (tickets: Ticket[], roundId?: BigNumberish) => {
  return useMemo(() => {
    if (!roundId) return [];

    return tickets.filter((ticket) => ticket.round.roundId.eq(roundId!));
  }, [tickets, roundId]);
};
