import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useCallback, useEffect, useState } from "react";
import { useAccount } from "wagmi";

type ConnectWalletCompletion = () => void;

export const useWalletGuard = (completion: ConnectWalletCompletion) => {
  const account = useAccount();
  const { openConnectModal } = useConnectModal();
  const [isExecuted, setIsExecuted] = useState(false);
  const [isCallbackCalled, setIsCallbackCalled] = useState(false);

  useEffect(() => {
    if (isCallbackCalled && !isExecuted && account.isConnected) {
      setIsExecuted(true);
    }
  }, [account.isConnected, isExecuted, isCallbackCalled, completion]);

  return useCallback(() => {
    if (account.isConnected) {
      completion();
    } else {
      setIsCallbackCalled(true);
      openConnectModal?.();
    }
  }, [account, openConnectModal, completion]);
};
