import { BigNumberish } from "ethers";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";

export const useRoundInfos = (roundIds: BigNumberish[], game: string) => {
  const { data } = useMultiplierBoxRead({
    functionName: "getRounds",
    args: [roundIds, roundIds.map(() => game)],
    enabled: roundIds.length > 0 && !!game,
  });

  return data;
};
