import { AnimatePresence } from "framer-motion";
import { useState } from "react";
import { TabGroup } from "../../common/TabGroup";
import { FinishedRound } from "./FinishedRound";
import { Graph } from "./Graph";
import { MostPlay } from "./MostPlay";
import { NumberStat } from "./NumberStat";

export const GameInfo = () => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className="h-[610px] flex flex-col border-gold p-4 space-y-6">
      <TabGroup
        color="gold"
        tabs={["BTC Price", "Most Play", "Finished Round"]}
        className="sm:w-full bg-blue-gray rounded-3xl"
        buttonClassName="py-3 w-1/3 md:text-sm text-xs md:font-semibold h-full"
        onTabChange={setActiveTabIndex}
      />
      <AnimatePresence>{activeTabIndex === 0 && <Graph />}</AnimatePresence>
      <AnimatePresence>{activeTabIndex === 1 && <MostPlay />}</AnimatePresence>
      <AnimatePresence>{activeTabIndex === 2 && <FinishedRound />}</AnimatePresence>
    </div>
  );
};
