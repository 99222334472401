import clsx from "clsx";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { ProgressBar } from "../common/ProgressBar";
import { ModeType } from "../common/SelectMode";
import { config } from "../config";
import { useGameContext } from "../context/PredictionGameContext";
import { useAssetColor } from "../hooks/useAssetColor";
import { usePrediction, useRounds } from "../hooks/usePrediction";
import { useWalletGuard } from "../hooks/useWalletGuard";
import { CardCalculating } from "../prediction/CardCalculating";
import { SetPositionModal } from "../prediction/SetPositionModal";
import { IndexTab } from "./LetsPlay";
import { PriceDecimal } from "./PriceDecimal";
import { useMaxAmount } from "./hook/useMaxAmount";
import { useCountdown } from "../hooks";
import { bigNumberToTime } from "../utils/formatter";

type CardPlayType = {
  currentEpoch: number;
  pro?: boolean;
  advance?: number;
  onModalVisibilityChange?: (isVisible: boolean) => void;
} & IndexTab;

export type PlayType = "UP" | "DOWN";

export const CardPlay = forwardRef<HTMLDivElement, CardPlayType>(
  ({ index, keyTab, currentEpoch, advance = 0, onModalVisibilityChange }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [playType, setPlayType] = useState<PlayType>("UP");
    const [isSuccess, setIsSuccess] = useState(false);
    const color = useAssetColor(keyTab);
    const [progress, setProgress] = useState(6);
    // const [game, selectMode] = useState<ModeType>(ModeType.HighLow);
    const game = useGameContext();

    const { address } = useAccount();
    // end round is n
    const epoch = useMemo(() => (currentEpoch > 0 ? currentEpoch : 1), [currentEpoch]);

    const { latestPrice, prizePool, status, payoutUp, payoutDown, bearAmount, bullAmount } =
      usePrediction({
        activeTabIndex: index,
        currentEpoch: epoch,
        keyTab,
        address,
        pro: game,
        advance,
      });

    const maxAmount = useMaxAmount(bearAmount, bullAmount);

    const { closeTimestamp } = useRounds({
      currentEpoch,
      keyTab,
      activeTabIndex: index,
      pro: game,
    });

    const advancedLockTimestamp = useMemo(() => {
      return (
        (Number(closeTimestamp) + Math.floor(advance * config.predictionRoundTime.pro[keyTab])) *
        1000
      );
    }, [closeTimestamp, advance, keyTab]);

    const [, , countDown] = useCountdown(advancedLockTimestamp, true);

    useEffect(() => {
      setIsSuccess(false);
    }, [currentEpoch]);

    let colorCard = "";
    let invisibleEnteredDown = "invisible";
    let invisibleEnteredUp = "invisible";
    if ((playType === "UP" && isSuccess === true) || status === "UP") {
      invisibleEnteredDown = "visible";
      invisibleEnteredUp = "invisible";
    }

    if ((playType === "DOWN" && isSuccess === true) || status === "DOWN") {
      invisibleEnteredUp = "visible";
      invisibleEnteredDown = "invisible";
    }

    if (index === 0) {
      colorCard = "card-orange";
    } else if (index === 1) {
      colorCard = "card-sky";
    } else {
      colorCard = "card-violet";
    }

    const openSetUpPositionModal = useWalletGuard(() => {
      setPlayType("UP");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    const openSetDownPositionModal = useWalletGuard(() => {
      setPlayType("DOWN");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    function closeModal() {
      setModalIsOpen(false);
      onModalVisibilityChange?.(false);
    }

    const Entered = useCallback(() => {
      return (
        <>
          <div className="pb-5 bg-red-900 pt-9 bg-opacity-40 rounded-bl-2xl">
            <p className="text-2xl font-bold text-[#ff1010] text-center">
              {game === ModeType.EvenOdd ? "EVEN" : "LOW"}
            </p>
          </div>
          <div className="pb-5 pt-9 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <p className="text-2xl font-bold text-[#46b900] text-center">
              {game === ModeType.EvenOdd ? "ODD" : "HIGH"}
            </p>
          </div>
        </>
      );
    }, []);

    const Play = useCallback(() => {
      return (
        <>
          <div className="pt-8 pb-4 bg-red-900 bg-opacity-40 rounded-bl-2xl">
            <button
              onClick={openSetDownPositionModal}
              className="flex items-center justify-center mx-auto"
            >
              {game === ModeType.EvenOdd && (
                <div className="px-6 bg-[#ff1010] flex space-x-1 items-center py-2 rounded-full">
                  <img className="" src={require("../assets/icon/even2.png")} alt="" />
                  <p className="text-sm font-bold">EVEN</p>
                </div>
              )}
              {game === ModeType.UpDown && (
                <img className="px-4 play-btn" src={require("../assets/going-down1.png")} alt="" />
              )}
              {game === ModeType.HighLow && (
                <div className="px-6 bg-[#ff1010] flex space-x-1 items-center py-2 rounded-full">
                  <img className="w-3" src={require("../assets/btn-down.png")} alt="" />
                  <p className="text-sm font-bold">LOW</p>
                </div>
              )}
            </button>
          </div>
          <div className="pt-8 pb-4 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <button
              onClick={openSetUpPositionModal}
              className="flex items-center justify-center mx-auto"
            >
              {game === ModeType.EvenOdd && (
                <div className="px-6 bg-[#46b900] flex space-x-1 items-center py-2 rounded-full">
                  <img className="" src={require("../assets/icon/odd2.png")} alt="" />
                  <p className="text-sm font-bold">ODD</p>
                </div>
              )}
              {game === ModeType.UpDown && (
                <img className="px-4 play-btn" src={require("../assets/going-up1.png")} alt="" />
              )}
              {game === ModeType.HighLow && (
                <div className="px-6 bg-[#46b900] flex space-x-1 items-center py-2 rounded-full">
                  <img className="w-3" src={require("../assets/btn-up.png")} alt="" />
                  <p className="text-sm font-bold">HIGH</p>
                </div>
              )}
            </button>
          </div>
        </>
      );
    }, [openSetUpPositionModal, openSetDownPositionModal, game]);

    if (countDown === "00:00") {
      return (
        <div ref={ref} id="card-play">
          <CardCalculating currentEpoch={epoch + advance} />
        </div>
      );
    }

    return (
      <div ref={ref} id="card-play">
        <div className={`${colorCard} px-1 pb-1 w-card lg:mr-8 mr-4 relative mb-12`}>
          <div className="flex items-center justify-center py-2 space-x-1 text-sm">
            <img className="w-3" src={require("../assets/icon/arrow-head-right.png")} alt="" />
            <span className="font-semibold">PLAY</span>
            <span className="text-[#0e1a39] font-semibold">#{epoch + advance}</span>
          </div>

          <ProgressBar size="sm" rounded={false} progress={progress} progressClassName={color.bg} />

          <div className="pt-3 bg-gradient-black-to-blue-r rounded-b-2xl">
            <div className="flex flex-col items-center justify-center py-4 mx-4 mt-4 card-border-gradient-blue-bg-black-to-blue">
              <div className="flex items-center">
                <img
                  className="w-3 mr-1"
                  src={require("../assets/icon/shield-silver.png")}
                  alt=""
                />
                <p className="text-sm text-gradient silver">Current price</p>
              </div>
              <PriceDecimal price={latestPrice} borderColor="#353734" color="#ffffff" />
            </div>
            <div className="flex items-center justify-center mt-3">
              <img className="w-3 mr-1" src={require("../assets/icon/clock@2x.png")} alt="" />
              <p className="mr-3 text-sm">Close in</p>
              <p className="text-xl font-bold text-gradient silver">{countDown}</p>
            </div>
            <div className="mt-3 mb-5 text-sm text-center">
              <span>Will {keyTab} go </span>
              <span className="font-bold">{game === "even-odd" ? "Even" : "Low"} </span>
              <span>or </span>
              <span className="font-bold">{game === "even-odd" ? "Odd" : "High"} ?</span>
            </div>
            <div className="relative grid grid-cols-2 top-5">
              <div
                className={clsx(
                  "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                  "border-light-gray"
                )}
              >
                Payout {payoutDown}x
              </div>
              <div
                className={clsx(
                  "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                  "border-light-gray"
                )}
              >
                Payout {payoutUp}x
              </div>
            </div>
            <div className={clsx("h-[2px]", "bg-light-gray")}></div>

            <div className="grid grid-cols-2">
              {isSuccess || status !== "NO_POSITION" ? <Entered /> : <Play />}
            </div>
          </div>

          <div className="absolute z-10 flex justify-around -bottom-3">
            <img
              className={`w-20 mx-6 ${invisibleEnteredUp}`}
              src={require("../assets/entered.png")}
              alt=""
            />
            <img
              className={`w-20 mx-6 ${invisibleEnteredDown}`}
              src={require("../assets/entered.png")}
              alt=""
            />
          </div>
        </div>
        {modalIsOpen && (
          <SetPositionModal
            currentEpoch={currentEpoch + advance}
            keyTab={keyTab}
            isOpen={modalIsOpen}
            onCloseModal={closeModal}
            playType={playType}
            onSuccessChanged={setIsSuccess}
            maxAmount={playType === "UP" ? maxAmount.bull : maxAmount.bear}
            game={game}
            pro
          />
        )}
      </div>
    );
  }
);
