import { createContext, ReactNode, useContext, useState } from "react";
import { Tab } from "../prediction/SelectAsset";

export type TabContextType = {
  activeTabIndex: Tab;
  keyTab: Tab;
  setActiveTabIndex: (activeTabIndex: Tab) => void;
  setKeyTab: (activeTabIndex: Tab) => void;
};

const TabContext = createContext<TabContextType | null>(null);
export const useTabContext = () => useContext(TabContext);

const TabProvider = ({ children }: { children: ReactNode }) => {
  const [activeTabIndex, _setActiveTabIndex] = useState<Tab>("BTC");
  const [keyTab, _setKeyTab] = useState<Tab>("BTC");

  const setActiveTabIndex = (activeTabIndex: Tab) => {
    _setActiveTabIndex(activeTabIndex);
    _setKeyTab(activeTabIndex);
  };

  const setKeyTab = (activeTabIndex: Tab) => {
    _setKeyTab(activeTabIndex);
  };

  return (
    <TabContext.Provider
      value={{
        activeTabIndex,
        keyTab,
        setActiveTabIndex,
        setKeyTab,
      }}
    >
      {children}
    </TabContext.Provider>
  );
};

export { TabContext, TabProvider };
