import { utils } from "ethers";
import { formatUnits } from "ethers/lib/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useParams, useSearchParams } from "react-router-dom";
import { NumberCircleCard } from "../common/NumberCircleCard";
import { skeletonWhileLoading } from "../common/Skeleton";
import { usePortionBoxRead } from "../hooks/contracts/usePortionBoxRead";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { compactAddress } from "../utils/formatter";

export function FinishedRound({ size }: { size: "s" | "l" }) {
  const { room = "" } = useParams();
  const [searchParams] = useSearchParams();
  const [roundId, setRoundId] = useState<number | string | undefined>(
    searchParams.get("round") || 0
  );

  const roomState = useSpecificRoomState(room);

  const { data: round } = usePortionBoxRead({
    room,
    functionName: "rounds",
    args: roundId,
    enabled: Boolean(roundId),
  });

  const { data: buyers = [] } = usePortionBoxRead({
    room,
    functionName: "buyersInRound",
    args: roundId && round ? [roundId, round.answer.number] : undefined,
    enabled: Boolean(roundId && round),
  });

  const lastFinishedRound = (roomState?.roundId ?? 0) - 1;

  const totalPrize = useMemo(() => {
    if (!round) return;
    if (buyers.length === 0) return "0";
    const totalBuyerSize = buyers.reduce((prev, buyer) => prev + buyer.size.toNumber(), 0);

    return Number(utils.formatUnits(round.prizePerSize.mul(totalBuyerSize), 6)).toLocaleString(
      undefined,
      { maximumFractionDigits: 5 }
    );
  }, [round, buyers]);

  useEffect(() => {
    if (!roundId) {
      setRoundId(lastFinishedRound);
    }
  }, [lastFinishedRound, roundId]);

  const answerComponents = useMemo(() => {
    if (!round) return;

    const numbers = round.answer.number.toString().padStart(Number(room), "0");
    return numbers
      .split("")
      .map((number, index) => (
        <NumberCircleCard
          key={index}
          number={number}
          className="w-10 h-10 text-2xl md:w-12 md:h-12"
        />
      ));
  }, [round, room]);

  const changeRound = useCallback(
    (change: number) => {
      const showRoundId = Number(roundId);
      const newRoundId = change + showRoundId;

      if (newRoundId > 0 && newRoundId <= lastFinishedRound) {
        setRoundId(newRoundId);
      }
    },
    [lastFinishedRound, roundId]
  );

  return (
    <div id="finished-round">
      <div className="py-3 my-5 rounded-lg bg-gradient-blue lg:my-10 md:py-4 ">
        <div className="flex flex-row items-start justify-between px-4 lg:px-6 md:items-center">
          <div className="flex items-baseline md:items-center">
            <p className="pr-3 text-sm sm:text-xl md:text-xl">Round</p>
            <div className="flex flex-col md:flex-row">
              <input
                type="number"
                className="rounded-xl bg-white font-bold lg:text-4xl md:text-3xl sm:text-3xl text-2xl text-black py-1 w-[110px] px-2 text-center"
                value={roundId}
                onChange={(e) => {
                  const { value } = e.target;
                  const numberValue = Number(value);
                  if (numberValue > 0 && numberValue <= lastFinishedRound) {
                    setRoundId(e.target.value);
                  }
                }}
              />
              <div className="flex flex-row md:flex-col pl-0 md:pl-3 text-[9px] sm:text-sm md:text-base font-light pt-2 md:pt-0 justify-center">
                <p>
                  Draw:
                  {round ? (
                    new Date(round!.executeAfterTime.mul(1000).toNumber()).toLocaleString()
                  ) : (
                    <Skeleton containerClassName="ml-2 inline-block w-24" />
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className="flex mt-1 md:mt-0">
            <button onClick={() => changeRound(-1)}>
              <img className="w-8 sm:w-10" src={require("../assets/btn-l.png")} alt="" />
            </button>
            <button onClick={() => changeRound(1)}>
              <img
                className="w-8 ml-3 sm:w-10 md:ml-4"
                src={require("../assets/btn-r.png")}
                alt=""
              />
            </button>
          </div>
        </div>
      </div>

      <div className={`grid grid-cols-1 lg:gap-12 gap-5 ${size === "l" && "lg:grid-cols-2 pb-8"}`}>
        <div className="flex flex-col">
          <p className="text-xl font-semibold text-center md:text-2xl text-gradient gold">
            Winning Number
          </p>
          <div className="flex justify-center my-4 md:my-6">
            {round ? (
              answerComponents
            ) : (
              <Skeleton containerClassName="block w-24" className="h-12" />
            )}
          </div>
          <div
            className={`grid grid-row-1 gap-5 lg:my-1 my-3 font-bold ${
              size === "s" && "grid-cols-2"
            }`}
          >
            <div className="grid items-center grid-cols-2 p-5 card-border-gold-bg-blue">
              <p
                className={`text-left ${
                  size === "s" ? "text-sm md:text-md" : "tex-md md:text-lg "
                }`}
              >
                Total Prize
              </p>
              <p
                className={`text-right text-gradient gold ${
                  size === "s" ? "text-xl md:text-2xl" : "text-3xl md:text-4xl"
                }`}
              >
                $
                {skeletonWhileLoading({
                  value: totalPrize,
                  skeletonClassName: "w-12 ml-2",
                })}
              </p>
            </div>
            <div className="grid items-center grid-cols-2 p-5 card-border-gold-bg-blue">
              <p
                className={`text-left ${
                  size === "s" ? "text-sm md:text-md" : "tex-md md:text-lg "
                }`}
              >
                Total numbers
              </p>
              <p
                className={`text-right text-gradient gold ${
                  size === "s" ? "text-xl md:text-2xl" : "text-3xl md:text-4xl"
                }`}
              >
                {skeletonWhileLoading({
                  value: round?.totalSize.toNumber().toLocaleString(),
                  skeletonClassName: "w-12 ml-2",
                })}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col overflow-y-auto no-scrollbar">
          {buyers.length === 0 && (
            <div className="flex items-center justify-center h-full mb-12 -mt-2 text-lg text-gray-400">
              Didn’t have a winner in this round.
            </div>
          )}
          {buyers.map((buyer) => (
            <div
              key={buyer.buyer}
              className="flex justify-between lg:py-5 py-4 px-5 items-center bg-[#04132c] rounded-2xl mb-3 max-h-[350px]"
            >
              <p className="text-sm font-bold text-left lg:text-2xl md:text-xl">
                {compactAddress(buyer.buyer)}
              </p>
              <p className="text-sm font-bold text-right lg:text-2xl md:text-xl text-gradient gold">
                ${formatUnits(buyer.size.mul(round!.prizePerSize), 6)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
