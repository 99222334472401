import { TabGroupMode } from "../common/TabGroupMode";
import { AnimatePresence, motion } from "framer-motion";
import { useState, useCallback, useMemo } from "react";
import clsx from "clsx";

type HistoryDetailType = {
  direction: direction;
  yourPosition: string;
  earned: string;
  amountCollect: string;
  closePrice: string;
  lockPrice: string;
  prizePool: string;
  percentChange: string;
  unit: string;
  payoutUp: string;
  payoutDown: string;
};

export type direction = "up" | "down" | "even" | "odd" | "high" | "low";

export function TablePredictionBox() {
  const [showHistoryIndex, setShowHistoryIndex] = useState<number>();

  const toggleHistoryDetail = useCallback(
    (index: number) => {
      setShowHistoryIndex(showHistoryIndex === index ? undefined : index);
    },
    [showHistoryIndex]
  );

  const predictions = [
    {
      roundId: 72346,
      roundTime: "Jul 12, 2022, 7:00 PM",
      earnings: 30.2312,
      status: "LOSE",
      direction: "down" as direction,
      yourPosition: "20.0000",
      earned: "10.2312",
      amountCollect: "30.2312",
      closePrice: "12,797.3091",
      lockPrice: "12,923.1234",
      prizePool: "90.8723",
      percentChange: "-4.4",
      unit: "USDT",
      payoutUp: "2.1x Payout / 30.2312",
      payoutDown: "2.1x Payout / 30.2312",
    },
    {
      roundId: 72346,
      roundTime: "Jul 12, 2022, 7:00 PM",
      earnings: 30.2312,
      status: "WON",
      direction: "up" as direction,
      yourPosition: "20.0000",
      earned: "10.2312",
      amountCollect: "30.2312",
      closePrice: "12,797.3091",
      lockPrice: "12,923.1234",
      prizePool: "90.8723",
      percentChange: "4.4",
      unit: "USDT",
      payoutUp: "2.1x Payout / 30.2312",
      payoutDown: "2.1x Payout / 30.2312",
    },
  ];

  const lose = "LOSE";

  return (
    <div className="col-span-5 mt-12 overflow-hidden border lg:col-span-3 rounded-3xl border-primary lg:mt-10">
      <div className="py-4 text-center bg-gradient-blue">
        <p className="text-xl text-center uppercase font-audiowide">Prediction Box</p>
      </div>
      <div className="px-2 pt-5 bg-black lg:px-20 md:px-9">
        <TabGroupMode
          tabs={["UP / DOWN", "HIGH / LOW", "EVEN / ODD"]}
          className="mx-auto lg:w-max"
          buttonClassName="px-6 py-3 lg:py-3 w-1/3 lg:w-[140px] md:text-base sm:text-xs text-[10px] font-semibold"
          initialTabIndex={0}
          imageName={["up-down", "high-low", "even-odd"]}
        />
        <div className="grid grid-cols-12 px-6 py-4 text-xs font-light gap-x-4 text-light-gray lg:text-base">
          <div className="col-span-5">Round</div>
          <div className="col-span-4">Earnings</div>
          <div className="col-span-3">Result</div>
        </div>
        <div className="h-[400px] overflow-y-scroll no-scrollbar pb-2">
          {predictions.map((prediction, index) => {
            return (
              <div key={index} className="mb-2 bg-[#010814] rounded-2xl">
                <div className="items-center rounded-2xl grid grid-cols-12 px-4 py-4 bg-[#04132c] gap-x-4 font-semibold lg:text-2xl md:text-xl sm:text-sm text-xs">
                  <div className="col-span-5">
                    <p className="text-xs font-medium lg:text-sm">#{prediction.roundId}</p>
                    <p className="text-xs font-light lg:text-sm">{prediction.roundTime}</p>
                  </div>
                  <div className="col-span-4 font-bold text-gradient gold">
                    ${prediction.earnings}
                  </div>
                  <div
                    className={clsx(
                      "col-span-2 font-bold",
                      prediction.status === lose ? "text-brownish-grey" : "text-gradient gold"
                    )}
                  >
                    {prediction.status}
                  </div>

                  <div className="col-span-1">
                    <button onClick={() => toggleHistoryDetail(index)}>
                      <img
                        alt=""
                        className="w-3 md:w-5"
                        src={
                          showHistoryIndex !== index
                            ? require("../assets/btn-down.png")
                            : require("../assets/btn-up.png")
                        }
                      />
                    </button>
                  </div>
                </div>
                <div className="overflow-hidden ">
                  <AnimatePresence>
                    {showHistoryIndex === index && (
                      <UserHistoryDetail
                        direction={prediction.direction}
                        yourPosition={prediction.yourPosition}
                        earned={prediction.earned}
                        closePrice={prediction.closePrice}
                        lockPrice={prediction.lockPrice}
                        prizePool={prediction.prizePool}
                        percentChange={prediction.percentChange}
                        amountCollect={prediction.amountCollect}
                        unit={prediction.unit}
                        payoutUp={prediction.payoutUp}
                        payoutDown={prediction.payoutDown}
                      />
                    )}
                  </AnimatePresence>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export const UserHistoryDetail = (props: HistoryDetailType) => {
  const {
    direction,
    yourPosition,
    earned,
    amountCollect,
    lockPrice,
    prizePool,
    closePrice,
    percentChange,
    unit,
    payoutUp,
    payoutDown,
  } = props;
  return (
    <motion.div
      className="grid grid-cols-1 gap-4 px-4 py-3 overflow-hidden lg:grid-cols-2"
      initial={{ y: "-40%", opacity: 0 }}
      exit={{ y: "-40%", opacity: 0 }}
      animate={{ y: "0%", opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <div className="overflow-hidden bg-black rounded-xl h-max">
        <div className="px-4 py-2 text-sm font-bold bg-blue-gray">Your History</div>
        <div className="grid items-center grid-cols-2 px-4 py-2 gap-y-2">
          <div className="text-sm font-light">Your direction</div>
          <div className="text-right">
            <PredictDirection
              direction={direction}
              className="px-3 py-1 text-xs font-medium rounded-xl"
            />
          </div>
          <div className="text-sm font-light">Your position</div>
          <div className="text-sm font-medium text-right">
            {yourPosition} {unit}
          </div>
          <div className="text-sm font-light">Your earning</div>
          <div className="text-sm font-medium text-right">
            +{earned} {unit}
          </div>
          <div className="text-sm font-light">Amount to collect</div>
          <div className="text-sm font-medium text-right text-gradient gold">
            {amountCollect} {unit}
          </div>
        </div>
      </div>

      <div className="overflow-hidden bg-black rounded-xl">
        <div className="flex justify-between px-4 py-2 text-sm font-bold bg-blue-gray">
          <p> Round History</p>
          <div className="flex items-center space-x-1">
            <p className="font-light text-gradient gold">view</p>
            <img className="w-2 h-2" src={require("../assets/icon/left-gold2.png")} alt="" />
          </div>
        </div>
        <div className="grid grid-cols-2 px-4 py-2 gap-y-2">
          <div className="text-sm font-light">Closed price</div>
          <div
            className={clsx(
              "text-sm font-medium text-right",
              parseFloat(percentChange) > 0 ? "text-up" : "text-down"
            )}
          >
            <div>${closePrice}</div>
            <div>
              {parseFloat(percentChange).toLocaleString(undefined, { minimumFractionDigits: 2 })}%
            </div>
          </div>
          <div className="text-sm font-light">Locked Price</div>
          <div className="text-sm font-medium text-right">${lockPrice}</div>
          <div className="text-sm font-light">Prize Pool</div>
          <div className="text-sm font-medium text-right">
            {prizePool} {unit}
          </div>
          <div className="text-xs font-light">Up</div>
          <div className="text-xs text-right text-light-gray">
            {payoutUp} {unit}
          </div>
          <div className="text-xs font-light">Down</div>
          <div className="text-xs text-right text-light-gray">
            {payoutDown} {unit}
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const PredictDirection = (props: { className?: string; direction: direction }) => {
  const usedProperty = useMemo(() => {
    const directionProperty = {
      up: {
        colorClass: "bg-up",
        icon: require("../assets/icon/arrow-up.png"),
        text: "Going up",
      },
      down: {
        colorClass: "bg-down",
        icon: require("../assets/icon/arrow-down.png"),
        text: "Going down",
      },
      even: {
        colorClass: "bg-down",
        icon: require("../assets/icon/even2.png"),
        text: "EVEN",
      },
      odd: {
        colorClass: "bg-up",
        icon: require("../assets/icon/odd2.png"),
        text: "ODD",
      },
      high: {
        colorClass: "bg-up",
        icon: require("../assets/btn-up.png"),
        text: "HIGH",
      },
      low: {
        colorClass: "bg-down",
        icon: require("../assets/btn-down.png"),
        text: "LOW",
      },
    };
    return directionProperty[props.direction];
  }, [props.direction]);

  return (
    <div className={clsx(usedProperty.colorClass, props.className, "inline-block")}>
      <img src={usedProperty.icon} alt="" className="inline-block w-3" />
      <span className="ml-2">{usedProperty.text}</span>
    </div>
  );
};
