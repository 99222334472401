import { motion } from "framer-motion";
import ClockIcon from "../../assets/icon/clock-icon.svg";
import { Round } from "../../hooks/contracts/usePortionBoxRead";
import { RoundCountdownDisplay } from "./RoundCountdownDisplay";

export const RoomRoundCountdownDisplay = (props: { round?: Round }) => {
  return (
    <RoundCountdownDisplay
      round={props.round}
      drawComponent={(countdown) => (
        <>
          <div className="flex flex-row items-center justify-center">
            <img alt="" src={ClockIcon} />
            <span className="ml-3 text-sm">Draw in</span>
          </div>
          <p className="mt-2 text-xl font-bold text-center lg:text-2xl">{countdown}</p>
        </>
      )}
      executingComponent={(countdown) => (
        <>
          <div className="flex flex-row items-center justify-center">
            <img alt="" src={ClockIcon} />
            <span className="ml-3 text-sm">Wating for a draw</span>
          </div>
          <p className="mt-2 text-xl font-bold text-center lg:text-2xl">{countdown}</p>
        </>
      )}
      calculatingComponent={
        <>
          <div className="flex flex-row items-center justify-center">
            <img alt="" src={ClockIcon} />
            <span className="ml-3 text-sm">Draw in</span>
          </div>
          <p className="mt-2 text-xl font-bold text-center lg:text-2xl text-gradient gold">
            <motion.img
              animate={{ y: -5 }}
              transition={{ repeat: Infinity, repeatType: "reverse", duration: 1, type: "tween" }}
              alt="logo"
              className="hidden w-8 mr-2 md:inline-block"
              src="/bb-logo.svg"
            />{" "}
            Calculating...
          </p>
        </>
      }
    />
  );
};
