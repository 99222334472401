import clsx from "clsx";
import { useParams } from "react-router-dom";
import { Button } from "../common/Button";
import { config } from "../config";
import { Modal } from "./Modal";

type WariningProps = {
  isOpen: boolean;
  closeModal: any;
  gold?: boolean;
};

export function WarningLimitModal({ isOpen, closeModal, gold }: WariningProps) {
  const { room = "" } = useParams();

  return (
    <Modal active={isOpen} onDismiss={closeModal}>
      <div
        className={clsx(
          "modal-content p-5 max-w-[25rem] rounded-3xl",
          gold
            ? "card-border-gradient-gold-bg-dark-blue-gray"
            : "card-border-gradient-blue-to-yellow-bg-dark-blue-gray"
        )}
      >
        <div className="relative">
          <button onClick={closeModal}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className="absolute top-0 w-6 h-6 right-2"
            />
          </button>
        </div>
        <p className="text-4xl font-bold text-center uppercase text-gradient gold">Don't Hurry!</p>
        <p className="my-5 text-lg font-medium text-center">
          We have a limit to buying {config.maxNumberPerTx(room).toLocaleString()} numbers per
          transaction, You need to buy this order first, then you can buy more numbers your want in
          next order.
        </p>
        <div className="text-center">
          <Button
            className="px-20 uppercase"
            preset={gold ? "goldMax" : "gold"}
            buttonSize="md"
            onClick={closeModal}
          >
            OK
          </Button>
        </div>
      </div>
    </Modal>
  );
}
