import create from "zustand";
import { BB_MAX_GAMES } from "../config";

type MaxPlayinfoTabStore = {
  activeTab: number;
  setActiveTab: (number: number) => void;
  activeGame: () => string;
};

export const useMaxPlayinfoTabStore = create<MaxPlayinfoTabStore>((set, get) => ({
  activeTab: 0,
  setActiveTab: (number) => set(() => ({ activeTab: number })),
  activeGame: () => {
    return BB_MAX_GAMES[get().activeTab];
  },
}));
