import clsx from "clsx";
import { motion } from "framer-motion";
import { useEffect, useMemo, useRef, useState } from "react";
import { NotificationProvider } from "../common";
import { useMultiplierBoxStartRoundEvent, useRoundInfos } from "../hooks/contracts/multiplier-box";
import { useCompareCurrentTime } from "../hooks/useCompareCurrentTime";
import { ModalWinningNumber } from "./ModalWinningNumber";
import { useCurrentRoundState } from "./play/CurrentRoundProvider";
import { LiveRoundStatus } from "./play/LiveRoundStatus";
import { SelectNumber } from "./play/SelectNumber";

type CurrentRoundProps = {
  className?: string;
};

export const CurrentRound = (props: CurrentRoundProps) => {
  const { currentRound, gameAddress = "" } = useCurrentRoundState();
  const isClose = useCompareCurrentTime(currentRound?.closeTime.toNumber());
  const isCalculating = useCompareCurrentTime(currentRound?.executeAfterTime.toNumber());
  // const isClose = false
  // const isCalculating = false

  const [showWinningModal, setShowWinningModal] = useState(false);
  const roundId = useMultiplierBoxStartRoundEvent();
  const endedRoundIds = useMemo(() => (roundId ? [roundId!.sub(1)] : []), [roundId]);
  const roundInfos = useRoundInfos(endedRoundIds, gameAddress);
  const shownRoundIds = useRef<Record<string, boolean>>({});
  const endedRoundInfo = useMemo(
    () => (!roundInfos || roundInfos.length === 0 ? undefined : roundInfos[0]),
    [roundInfos]
  );

  useEffect(() => {
    const roundId = endedRoundInfo?.roundId.toString();
    if (endedRoundInfo && endedRoundInfo.answerPrice.gt(0) && !shownRoundIds.current[roundId!]) {
      shownRoundIds.current[roundId!] = true;
      setShowWinningModal(true);
    }
  }, [endedRoundInfo]);

  return (
    <div className={clsx("relative", props.className)}>
      {endedRoundInfo && (
        <ModalWinningNumber
          isOpen={showWinningModal}
          roundId={endedRoundInfo.roundId.toNumber()}
          price={endedRoundInfo.answerPrice.toNumber()}
          answer={endedRoundInfo.answer.toNumber()}
          onCloseModal={() => setShowWinningModal(false)}
        />
      )}
      <LiveRoundStatus calculating={isCalculating} />
      <NotificationProvider gold>
        <SelectNumber />
      </NotificationProvider>
      {isClose && !isCalculating && (
        <div className="flex space-y-4 flex-col items-center justify-center absolute inset-0 bg-[#0a172b]/90 rounded-xl">
          <motion.img
            className="w-20"
            src={require("../assets/bb-icon1.png")}
            alt=""
            initial={{ y: -15 }}
            animate={{ y: 0 }}
            transition={{ repeat: Infinity, duration: 1.5, repeatType: "reverse" }}
          />
          <div className="text-lg text-gray-400">Waiting for draw...</div>
        </div>
      )}
      {isCalculating && (
        <div className="flex space-y-4 flex-col items-center justify-center absolute inset-0 bg-[#0a172b]/90 rounded-xl">
          <motion.img
            className="w-20"
            src={require("../assets/bb-icon1.png")}
            alt=""
            initial={{ y: -15 }}
            animate={{ y: 0 }}
            transition={{ repeat: Infinity, duration: 1.5, repeatType: "reverse" }}
          />
          <div className="text-lg text-gray-400">Calculating...</div>
        </div>
      )}
    </div>
  );
};
