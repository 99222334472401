import clsx from "clsx";
import { motion } from "framer-motion";
import { useMemo } from "react";
import { fadeInVariants } from "../animation";
import InfoIcon from "../assets/info-black-icon.svg";
import TimeIcon from "../assets/time-icon.svg";
import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";
import { CountUpNumber } from "./CountUpNumber";
import { NumberCircleCard } from "./NumberCircleCard";

type BTCPriceCardProps = {
  className?: string;
  jackpot?: boolean;
};

const digits = 5;

export function BTCPriceCard({ className, jackpot }: BTCPriceCardProps) {
  const { latest, refreshIn } = useChainlinkPrice("btc");

  const answerDecimal = useMemo(() => {
    const answerLength = latest?.answerDecimalLength || 0;

    return [...Array(digits)].map((v, index) => {
      return answerLength >= index + 1 ? latest?.answerDecimal[index] : null;
    });
  }, [latest]);

  return (
    <motion.div
      initial="hidden"
      animate="visible"
      className={clsx(
        "py-4 px-6 flex lg:flex-row flex-col lg:items-center items-start lg:w-max mx-auto",
        className,
        jackpot ? "card-border-gradient-gold-bg-black border-sm" : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <motion.div className="flex flex-col">
        <p className="font-light text-gradient gold">Current BTC price</p>
        <motion.div variants={fadeInVariants} className="py-2 text-4xl font-bold">
          <CountUpNumber
            startCounting
            duration={1}
            end={Number(latest?.answerDigit)}
            decimals={0}
            prefix="$"
          />
          .
        </motion.div>

        <div className="items-center hidden lg:flex">
          <img className="w-3 mr-2" src={require("../assets/icon/clock.png")} alt="" />
          <p className="mr-1 text-sm font-light">Update in</p>
          <p className="text-sm font-bold text-gradient gold">
            00:{refreshIn.toString().padStart(2, "0")}
          </p>
        </div>
      </motion.div>

      <motion.div variants={fadeInVariants} className="flex flex-row my-3">
        {jackpot ? (
          <>
            {answerDecimal.map((number, index) => (
              <NumberCircleCard
                gold
                key={index}
                className="w-10 text-3xl lg:w-20 md:w-14 aspect-square lg:text-5xl"
                tooltip={
                  number == null ? (
                    <div className="flex items-start space-x-2">
                      <img alt="delay info" className="w-4 mt-[1px]" src={InfoIcon} />
                      <div className="text-black">
                        <div className="text-sm font-bold">Slight delay</div>
                        <div className="text-[11px] leading-tight">
                          Oracle is currently get answer with less than expected digits. Please wait
                          a moment for new answer.
                        </div>
                      </div>
                    </div>
                  ) : undefined
                }
              >
                {number ? (
                  <CountUpNumber
                    startCounting
                    duration={0.8}
                    end={Number(number)}
                    decimals={0}
                    prefix=""
                  />
                ) : (
                  <img alt="delay icon" src={TimeIcon} />
                )}
              </NumberCircleCard>
            ))}
          </>
        ) : (
          <>
            {answerDecimal.map((number, index) => (
              <NumberCircleCard
                key={index}
                className="w-10 text-3xl lg:w-20 md:w-14 aspect-square lg:text-5xl"
                tooltip={
                  number == null ? (
                    <div className="flex items-start space-x-2">
                      <img alt="delay info" className="w-4 mt-[1px]" src={InfoIcon} />
                      <div className="text-black">
                        <div className="text-sm font-bold">Slight delay</div>
                        <div className="text-[11px] leading-tight">
                          Oracle is currently get answer with less than expected digits. Please wait
                          a moment for new answer.
                        </div>
                      </div>
                    </div>
                  ) : undefined
                }
              >
                {number ? (
                  <CountUpNumber
                    startCounting
                    duration={0.8}
                    end={Number(number)}
                    decimals={0}
                    prefix=""
                  />
                ) : (
                  <img alt="delay icon" src={TimeIcon} />
                )}
              </NumberCircleCard>
            ))}
          </>
        )}
      </motion.div>

      <div className="flex items-center lg:hidden">
        <img className="w-5 h-5 mr-2" src={require("../assets/icon/clock.png")} alt="" />
        <p className="mr-1 font-light text-md">Update in</p>
        <p className="font-bold text-md text-gradient gold">
          00:{refreshIn.toString().padStart(2, "0")}
        </p>
      </div>
    </motion.div>
  );
}
