import Popup from "reactjs-popup";
import InfoIcon from "../assets/icon/info-icon.svg";
import { Asset, config } from "../config";
import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";
import { IndexTab } from "./LetsPlay";
import clsx from "clsx";
import { keyTabToAssets, useBinancePrice } from "../hooks";
import { useEffect, useState } from "react";

export type SilverProp = {
  silver?: boolean;
};
export function CurrentPrice({
  index,
  pro,
  keyTab,
  silver,
}: IndexTab & SilverProp & { pro?: boolean }) {
  const asset = keyTab?.toString().toLowerCase();
  const { latest, refreshIn } = useChainlinkPrice(asset as Asset, {
    formattedDecimalPlaces: 2,
    enabled: !pro,
  });

  const binancePrice = useBinancePrice(keyTabToAssets[keyTab]);
  const [binancePriceRefreshIn, setBinancePriceRefreshIn] = useState(
    config.binancePriceRefetchInterval / 1000
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setBinancePriceRefreshIn((prev) =>
        prev === 0 ? config.binancePriceRefetchInterval / 1000 : prev - 1
      );
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className={clsx(
        "relative flex flex-col items-center justify-center col-span-2 py-2 lg:col-span-4",
        silver
          ? "card-border-gradient-blue-to-purple-bg-black-to-blue"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <div className="absolute hidden top-2 right-2 lg:block">
        <Popup
          trigger={<img alt="" className="w-4" src={InfoIcon} />}
          on="hover"
          position="bottom center"
          mouseEnterDelay={0}
          contentStyle={{
            border: 0,
            backgroundColor: "#faf6a6",
            backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
          }}
          arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
        >
          <div className="flex items-start px-[6px] py-1">
            <div className="basis-1/12 mt-[1px]">
              <img className="w-3" src={require("../assets/icon/info2.png")} alt="" />
            </div>
            <div className="basis-11/12">
              <p className="text-xs font-black text-black">Currenly showing price from Binance</p>
              <p className="text-xs font-light text-black">
                The price you see come directly from the Binance API, which powers the prediction
                pro game.
              </p>
            </div>
          </div>
        </Popup>
      </div>
      <p className={clsx("text-sm", silver ? "text-gradient silver" : "text-gradient gold")}>
        Current Price
      </p>
      <p
        className={clsx(
          "mt-1 font-bold font-roboto-condensed",
          pro ? "text-2xl lg:text-3xl" : "text-xl lg:text-2xl"
        )}
      >
        $
        {pro
          ? binancePrice?.toLocaleString("en-US", { minimumFractionDigits: 2 })
          : latest?.formattedAnswer}
      </p>
      {!pro && (
        <div className="flex items-center mt-2">
          <img
            className="w-3 mr-1 lg:w-4 lg:mr-2"
            src={require(silver ? "../assets/icon/clock@2x.png" : "../assets/icon/clock.png")}
            alt=""
          />
          <p className="mr-1 text-sm font-light">Update in</p>
          <p
            className={clsx(
              "text-sm font-bold lg:text-md",
              silver ? "text-gradient silver" : "text-gradient gold"
            )}
          >
            00:{(pro ? binancePriceRefreshIn : refreshIn).toString().padStart(2, "0")}
          </p>
        </div>
      )}
    </div>
  );
}
