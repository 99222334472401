import { useConnectModal } from "@rainbow-me/rainbowkit";
import { BigNumber, utils } from "ethers";
import { useMemo } from "react";
import { useAccount } from "wagmi";
import { Button } from "../common/Button";
import { useNotificationContext } from "../common/NotificationProvider";
import { skeletonWhileLoading } from "../common/Skeleton";
import { predictionAddr } from "../config";
import { usePredictionV2Write } from "../hooks/contracts/usePredictionV2Write";
import { usePredictionV3Write } from "../hooks/contracts/usePredictionV3Write";
import useMenu from "../hooks/useMenu";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { useRequest } from "../hooks/useRequest";
import clsx from "clsx";

export const CHAIN = {
  MUMBAI: "MUMBAI",
  MATIC: "MATIC",
};

export type RewardType = {
  reward: string;
  rounds: number[];
};

type Props = {
  silver?: boolean;
};

export function ClaimReward({ silver }: Props) {
  const { pushNotification } = useNotificationContext();
  const { address = "", isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { activeTabIndex, keyTab } = useMenu();
  const selectToken = activeTabIndex;
  const rewardCurrency = usePredictionRewardCurrency(selectToken);

  const { data } = useRequest<{ reward: string; rounds: number[] }>({
    baseURL: process.env.REACT_APP_BASE_PREDICTION_API,
    path:
      "/api/reward/prediction?" +
      new URLSearchParams({
        chain: CHAIN.MATIC,
        contractName: selectToken,
        address: address,
      }),
    config: {
      refreshInterval: 30_000,
    },
    enabled: address !== "",
  });

  const formattedReward = useMemo(() => {
    if (address === "") return "0";
    if (data) {
      return (+utils.formatUnits(data.reward ?? 0, 6)).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    }
  }, [data, address]);

  const { writeAsync: claimV2 } = usePredictionV2Write("claim", [data ? data.rounds : []]);
  const { writeAsync: claimV3 } = usePredictionV3Write(predictionAddr[keyTab], "claim", [
    data ? data.rounds : [],
  ]);

  async function claim() {
    const claim = selectToken === "MATIC" ? claimV2 : claimV3;
    const tx = await claim();

    pushNotification({
      type: "sendTx",
      txhash: tx.hash,
      key: tx.hash,
    });

    await tx.wait();

    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: "You've claimed all the rewards!",
    });
  }

  return (
    <div className="relative flex flex-col items-center justify-center px-2 py-4 w-44 rounded-2xl bg-gradient-blue lg:px-10 md:w-72 sm:w-60 lg:mb-6">
      <img
        alt=""
        className="absolute bottom-0 left-0 z-0 w-28 rounded-bl-2xl"
        src={require("../assets/money-bag.png")}
      />
      <p className="pb-2 text-sm font-base">Your Reward</p>
      <p className={clsx("mb-2 text-lg font-bold text-center lg:text-xl lg:mb-3", silver ? "text-gradient silver" : "text-gradient gold")}>
        {skeletonWhileLoading({ value: formattedReward, skeletonClassName: "w-24" })}{" "}
        {rewardCurrency}
      </p>
      {isConnected ? (
        <Button
          className="z-10 px-3 py-1 text-xs uppercase lg:px-5"
          preset="white"
          buttonSize="sm"
          disabled={!data || BigNumber.from(data.reward).eq(0)}
          onClick={() => {
            claim();
          }}
        >
          Claim reward
        </Button>
      ) : (
        <Button
          className="z-10 px-3 py-1 text-xs uppercase lg:px-5"
          preset="white"
          buttonSize="sm"
          onClick={() => openConnectModal?.()}
        >
          Connect Wallet
        </Button>
      )}
    </div>
  );
}
