import { ConnectButton } from "@rainbow-me/rainbowkit";
import { useEffect } from "react";
import { useDisconnect, useNetwork, useSwitchNetwork } from "wagmi";
import ChevronDown from "../assets/chevron-down.svg";
import WalletIcon from "../assets/wallet-icon.svg";
import { config } from "../config";
import { Button } from "./Button";
import { GradientBorderDiv } from "./GradientBorderDiv";

export function ConnectWalletButton() {
  const { disconnect } = useDisconnect();
  const { chain } = useNetwork();
  const { switchNetworkAsync } = useSwitchNetwork();

  useEffect(() => {
    if (chain?.unsupported && switchNetworkAsync) {
      switchNetworkAsync?.(config.chain.id);
    }
  }, [chain, switchNetworkAsync]);

  return (
    <ConnectButton.Custom>
      {({ account, chain, openConnectModal, authenticationStatus, mounted, openChainModal }) => {
        const ready = mounted && authenticationStatus !== "loading";
        const connected =
          ready &&
          account &&
          chain &&
          (!authenticationStatus || authenticationStatus === "authenticated");

        if (!connected) {
          return (
            <Button
              className="px-5 uppercase"
              preset="gradient"
              buttonSize="sm"
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          );
        }

        if (chain.unsupported) {
          return (
            <Button
              className="px-5 uppercase"
              preset="gradient"
              buttonSize="sm"
              onClick={openChainModal}
            >
              Change Network
            </Button>
          );
        }

        return (
          <div className="relative group">
            <button
              type="button"
              className="flex items-center px-4 py-[5px] rounded-3xl border border-[#ffca48]"
            >
              <img className="inline-block mr-2" src={WalletIcon} alt="Wallet" />
              {account.displayName}
              <img className="inline-block ml-2" src={ChevronDown} alt="Icon" />
            </button>
            <div className="absolute w-full pt-2 ">
              <GradientBorderDiv className="flex-col hidden p-3 group-hover:flex">
                <a href="/my-dashboard" className="py-2 tracking-wide text-center text-white">
                  My Dashboard
                </a>
                <button
                  className="py-2 tracking-wide text-center text-white"
                  onClick={() => disconnect()}
                >
                  Disconnect
                </button>
              </GradientBorderDiv>
            </div>
          </div>
        );
      }}
    </ConnectButton.Custom>
  );
}
