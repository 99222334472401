import { motion } from "framer-motion";
import { slideUpVariants } from "../animation";
import { AnimatedText } from "../common/AnimatedText";

export function Partnership() {
  return (
    <motion.div
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "0px 0px -30% 0px" }}
      transition={{ staggerChildren: 0.1 }}
      className="px-4 pt-6 bg-black lg:px-20 lg:pt-32 pb-14"
    >
      <div className="text-center header">
        <AnimatedText gold className="ml-3" text="Integration" />
        <p className="font-light lg:text-2xl lg:mt-8">
          Billion Box Games Brings More Fun to More Players with Latest Integrations
        </p>
      </div>
      <div className="container p-10 mx-auto">
        <div className="grid justify-center grid-cols-2 gap-4 md:grid-cols-4 place-items-center">
          <motion.p variants={slideUpVariants} className="">
            <img src={require("../assets/partner/polygon.png")} alt="partnership" />
          </motion.p>
          <motion.p variants={slideUpVariants} className="">
            <img src={require("../assets/partner/quest3.png")} alt="partnership" />
          </motion.p>
          <motion.p variants={slideUpVariants} className="">
            <img src={require("../assets/partner/certik.png")} alt="partnership" />
          </motion.p>
          <motion.p variants={slideUpVariants} className="">
            <img src={require("../assets/partner/snapshot.png")} alt="partnership" />
          </motion.p>
        </div>
        <div className="grid grid-cols-2 gap-4 mt-8 md:grid-cols-8 place-items-center">
          <div className="hidden col-auto md:block"></div>
          <motion.p variants={slideUpVariants} className="col-span-1 md:col-span-2">
            <img src={require("../assets/partner/guild.png")} alt="partnership" />
          </motion.p>
          <motion.p variants={slideUpVariants} className="md:col-span-2">
            <img src={require("../assets/partner/crew.png")} alt="partnership" />
          </motion.p>
          <motion.p variants={slideUpVariants} className="md:col-span-2">
            <img src={require("../assets/partner/comingsoon.png")} alt="partnership" />
          </motion.p>
          <div className="col-auto"></div>
        </div>
      </div>
    </motion.div>
  );
}
