import clsx from "clsx";
import { BigNumber } from "ethers";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Even } from "../common/Even";
import { HighLow } from "../common/HighLow";
import { Odd } from "../common/Odd";
import { ProgressBar } from "../common/ProgressBar";
import { ModeType } from "../common/SelectMode";
import { config } from "../config";
import { useGameContext } from "../context/PredictionGameContext";
import { useCountdown, useWalletGuard } from "../hooks";
import { usePrediction, useRounds } from "../hooks/usePrediction";
import { usePredictionGameUp } from "../hooks/usePredictionGameUp";
import { CardCalculating } from "../prediction/CardCalculating";
import { PlayGameButton } from "../prediction/PlayGameButton";
import { SetPositionModal } from "../prediction/SetPositionModal";
import { bigNumberToTime } from "../utils/formatter";
import { PlayType } from "./CardPlay";
import { Tab } from "./SelectAsset";
import { PriceDecimal } from "./PriceDecimal";
import { useMaxAmount } from "./hook/useMaxAmount";

type CardLiveType = {
  activeTabIndex: number;
  currentEpoch: number;
  keyTab: Tab;
  onModalVisibilityChange?: (isVisible: boolean) => void;
};

export const CardLive = forwardRef<HTMLDivElement, CardLiveType>(
  ({ currentEpoch, activeTabIndex, keyTab, onModalVisibilityChange }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const { address } = useAccount();
    const [progress, setProgress] = useState(0);
    const [isSuccess, setIsSuccess] = useState(false);
    const game = useGameContext();

    // end round is n-1
    const epoch = currentEpoch - 1 > 0 ? currentEpoch - 1 : 1;
    const {
      lockPrice,
      prizePool,
      latestPrice,
      priceChange,
      status,
      payoutUp,
      payoutDown,
      bearAmount,
      bullAmount,
    } = usePrediction({ activeTabIndex, currentEpoch: epoch, keyTab, address, pro: game });

    const { closeTimestamp } = useRounds({
      currentEpoch: epoch,
      keyTab,
      activeTabIndex,
      pro: game,
    });

    const maxAmount = useMaxAmount(bearAmount, bullAmount)

    const [, , countDown, countDownNumber] = useCountdown(
      bigNumberToTime(BigNumber.from(closeTimestamp)),
      false
    );
    const priceChangeUp = usePredictionGameUp(game, latestPrice);
    const [playType, setPlayType] = useState<PlayType>("UP");

    useEffect(() => {
      const interval = setInterval(() => {
        const now = Math.floor(new Date().getTime() / 1000);
        const diff = Number(closeTimestamp) - now;
        const progress = diff > 0 ? 1 - diff / config.predictionRoundTime.pro[keyTab] : 1;
        setProgress(progress * 100);
      }, 1000);

      return () => clearInterval(interval);
    }, [closeTimestamp, keyTab]);

    const openSetUpPositionModal = useWalletGuard(() => {
      setPlayType("UP");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    const openSetDownPositionModal = useWalletGuard(() => {
      setPlayType("DOWN");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    function closeModal() {
      setModalIsOpen(false);
      onModalVisibilityChange?.(false);
    }

    if (countDown === "00:00") {
      return (
        <div ref={ref}>
          <CardCalculating currentEpoch={epoch} />
        </div>
      );
    }

    function Entered() {
      return (
        <>
          <div className="pb-5 bg-red-900 pt-9 bg-opacity-40 rounded-bl-2xl">
            <p className="text-2xl font-bold text-[#ff1010] text-center">
              {game === ModeType.EvenOdd ? "EVEN" : "LOW"}
            </p>
          </div>
          <div className="pb-5 pt-9 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <p className="text-2xl font-bold text-[#46b900] text-center">
              {game === ModeType.EvenOdd ? "ODD" : "HIGH"}
            </p>
          </div>
        </>
      );
    }

    function Play() {
      return (
        <>
          <div className="pt-8 pb-4 bg-red-900 bg-opacity-40 rounded-bl-2xl">
            <button
              onClick={openSetDownPositionModal}
              className="flex items-center justify-center mx-auto"
            >
              {game === ModeType.EvenOdd && (
                <div className="px-6 bg-[#ff1010] flex space-x-1 items-center py-2 rounded-full">
                  <img className="" src={require("../assets/icon/even2.png")} alt="" />
                  <p className="text-sm font-bold">EVEN</p>
                </div>
              )}
              {game === ModeType.UpDown && (
                <img className="px-4 play-btn" src={require("../assets/going-down1.png")} alt="" />
              )}
              {game === ModeType.HighLow && (
                <div className="px-6 bg-[#ff1010] flex space-x-1 items-center py-2 rounded-full">
                  <img className="w-3" src={require("../assets/btn-down.png")} alt="" />
                  <p className="text-sm font-bold">LOW</p>
                </div>
              )}
            </button>
          </div>
          <div className="pt-8 pb-4 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <button
              onClick={openSetUpPositionModal}
              className="flex items-center justify-center mx-auto"
            >
              {game === ModeType.EvenOdd && (
                <div className="px-6 bg-[#46b900] flex space-x-1 items-center py-2 rounded-full">
                  <img className="" src={require("../assets/icon/odd2.png")} alt="" />
                  <p className="text-sm font-bold">ODD</p>
                </div>
              )}
              {game === ModeType.UpDown && (
                <img className="px-4 play-btn" src={require("../assets/going-up1.png")} alt="" />
              )}
              {game === ModeType.HighLow && (
                <div className="px-6 bg-[#46b900] flex space-x-1 items-center py-2 rounded-full">
                  <img className="w-3" src={require("../assets/btn-up.png")} alt="" />
                  <p className="text-sm font-bold">HIGH</p>
                </div>
              )}
            </button>
          </div>
        </>
      );
    }

    return (
      <div ref={ref} id="card-live" className="relative px-1 pb-1 mr-4 card-silver w-card lg:mr-8">
        <div className="flex items-center justify-center py-2 space-x-1 text-sm">
          <img className="w-4" src={require("../assets/bb-icon1.png")} alt="" />
          <span className="font-semibold">LIVE</span>
          <span className="font-semibold text-[#0e1a39]">#{epoch}</span>
        </div>

        <ProgressBar
          size="sm"
          rounded={false}
          progress={progress}
          progressClassName="bg-light-gray"
        />

        <div className="pt-3 bg-gradient-black-to-blue-r rounded-b-2xl">
          <div className="flex justify-between px-4 pb-4 mt-2 text-sm"></div>
          <div
            className={`${
              priceChangeUp ? "card-gradient-green" : "card-gradient-red"
            } mx-4 py-5 flex flex-col items-center justify-center`}
          >
            <div className="flex items-center">
              <img className="w-3 mr-1" src={require("../assets/icon/shield-silver.png")} alt="" />
              <p className="py-1 text-sm text-gradient silver">Current price</p>
            </div>
            <PriceDecimal
              price={latestPrice}
              borderColor={priceChangeUp ? "#46b900" : "#ff1010"}
              color={priceChangeUp ? "#46b900" : "#ff1010"}
            />

            {game === ModeType.UpDown && (
              <>
                <div
                  className={`flex items-center space-x-1 font-bold text-sm ${
                    priceChangeUp ? "text-[#46b900]" : "text-[#ff1010]"
                  }`}
                >
                  <img
                    alt=""
                    className="w-2"
                    src={
                      priceChangeUp
                        ? require("../assets/up-icon-green.png")
                        : require("../assets/down-icon-red.png")
                    }
                  />
                  <span>${priceChange}</span>
                </div>
              </>
            )}

            {game === ModeType.EvenOdd && (
              <>
                {priceChangeUp ? (
                  <div className="text-[#46b900] font-bold text-center flex items-center space-x-2 justify-center">
                    <Odd />
                    <span>ODD</span>
                  </div>
                ) : (
                  <div className="text-[#ff1010] font-bold text-center flex items-center space-x-2 justify-center">
                    <Even />
                    <span>Even</span>
                  </div>
                )}
              </>
            )}

            {game === ModeType.HighLow && (
              <>
                {priceChangeUp ? (
                  <div className="text-[#46b900] font-bold text-center flex items-center space-x-2 justify-center">
                    <HighLow high />
                    <span>HIGH</span>
                  </div>
                ) : (
                  <div className="text-[#ff1010] font-bold text-center flex items-center space-x-2 justify-center">
                    <HighLow low />
                    <span>LOW</span>
                  </div>
                )}
              </>
            )}
          </div>
          <div className="flex items-center justify-center mt-4 space-x-1">
            <img className="w-3" src={require("../assets/icon/clock@2x.png")} alt="" />
            <p>Draw in</p>
            <p className="text-lg font-bold text-gradient silver">{countDown}</p>
          </div>

          <div className="relative grid grid-cols-2 top-5">
            <div
              className={clsx(
                "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                "border-light-gray"
              )}
            >
              Payout {payoutDown}x
            </div>
            <div
              className={clsx(
                "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                "border-light-gray"
              )}
            >
              Payout {payoutUp}x
            </div>
          </div>
          <div className={clsx("h-[2px]", "bg-light-gray")}></div>
          {countDown < "00:15" ? (
            <div className="grid grid-cols-2">
              {game === ModeType.HighLow && (
                <>
                  <PlayGameButton
                    highlight={!priceChangeUp}
                    type="low"
                    className="rounded-bl-2xl"
                    onClick={openSetDownPositionModal}
                  />
                  <PlayGameButton
                    highlight={priceChangeUp}
                    type="high"
                    className="rounded-br-2xl"
                    onClick={openSetUpPositionModal}
                  />
                </>
              )}
              {game === ModeType.UpDown && (
                <>
                  <PlayGameButton
                    highlight={!priceChangeUp}
                    type="down"
                    className="rounded-bl-2xl"
                  />
                  <PlayGameButton highlight={priceChangeUp} type="up" className="rounded-br-2xl" />
                </>
              )}
              {game === ModeType.EvenOdd && (
                <>
                  <PlayGameButton
                    highlight={!priceChangeUp}
                    type="even"
                    className="rounded-bl-2xl"
                    onClick={openSetDownPositionModal}
                  />
                  <PlayGameButton
                    highlight={priceChangeUp}
                    type="odd"
                    className="rounded-br-2xl"
                    onClick={openSetUpPositionModal}
                  />
                </>
              )}
            </div>
          ) : (
            <div className="grid grid-cols-2">
              {isSuccess ||
              status !== "NO_POSITION" ||
              countDownNumber < config.predictionProLockSeconds * 1000 ? (
                <Entered />
              ) : (
                <Play />
              )}
            </div>
          )}
        </div>
        <div className="absolute inset-x-0">
          <div className="relative flex justify-around -top-3">
            {status === "UP" ? (
              <>
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : status === "DOWN" ? (
              <>
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : null}
          </div>
        </div>
        {modalIsOpen && (
          <SetPositionModal
            currentEpoch={epoch}
            keyTab={keyTab}
            isOpen={modalIsOpen}
            onCloseModal={closeModal}
            playType={playType}
            maxAmount={playType === "UP" ? maxAmount.bull : maxAmount.bear}
            onSuccessChanged={setIsSuccess}
            game={game}
            pro
          />
        )}
      </div>
    );
  }
);
