import { Button } from "../common/Button";

export function AutoPlay() {
  return (
    <div className="justify-between hidden p-4 my-5 card-border-gradient-blue-to-yellow lg:flex">
      <div className="flex items-center">
        <img src={require("../assets/portion-box-room/auto.png")} alt="" className="h-8" />
        <div className="ml-3 mr-10">
          <p className="font-bold">AUTO PLAY</p>
          <p className="text-xs font-light">No time? try autoplay feature</p>
        </div>
      </div>
      <Button preset="disabled" buttonSize="sm" className="px-6">
        Coming Soon!
      </Button>
    </div>
  );
}
