import { HTMLMotionProps, motion } from "framer-motion";
import { FC } from "react";

export const FadeSlideAnimatePresence: FC<HTMLMotionProps<"div">> = ({ children, ...props }) => {
  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} {...props}>
      {children}
    </motion.div>
  );
};
