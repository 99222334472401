import { useMemo } from "react";
import Popup from "reactjs-popup";
import CubeTrans from "../assets/cube-trans5.png";
import InfoIcon from "../assets/icon/info-icon.svg";
import PrizeIcon from "../assets/icon/prize-icon.svg";
import RoomBorderLeft from "../assets/room-border-left.svg";
import { Button, LinkButton } from "../common/Button";
import { RoomRoundCountdownDisplay } from "../common/round-countdown/RoomRoundCountdown";
import { RoundTicketList } from "../common/RoundTicketList";
import { Skeleton, skeletonWhileLoading } from "../common/Skeleton";
import { RoomDigit } from "../config";
import { useFormattedPrize } from "../hooks/useFormattedPrize";
import { useRoundData } from "../hooks/useRoundData";
import { RoomStateType, useRoomState } from "./RoomDataProvider";

type RoomProps = {
  digit: RoomDigit;
};

const MAX_BUYER_SHOW = 5;

export function Room({ digit }: RoomProps) {
  const rooms = useRoomState();
  const { round, roundId, prizeReserved } = rooms[digit] as RoomStateType;

  const formattedPrizeReserved = useFormattedPrize(prizeReserved, round?.totalPrize);

  const { buyers } = useRoundData(digit, roundId);
  const recentBuyers = useMemo(() => buyers.slice(0, MAX_BUYER_SHOW), [buyers]);

  return (
    <section className="py-8">
      <div className="container flex flex-col items-center justify-center mx-auto">
        <div className="relative w-full overflow-hidden rounded-3xl lg:p-5 card-border-gradient-blue-to-yellow-bg-black-to-blue lg:w-auto">
          <img
            alt=""
            className="absolute z-0 w-full -top-2 -left-2 lg:w-1/2"
            src={RoomBorderLeft}
          />
          <img alt="" className="absolute bottom-0 right-0 z-0 w-52" src={CubeTrans} />

          <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-12 gap-4 lg:w-[900px] z-10 relative">
            <div className="relative px-4 pt-3">
              <h3 className="relative text-5xl text-white uppercase font-audiowide">
                {digit} Digits
              </h3>
              <p className="mt-2 text-sm font-light">
                {roundId && round ? (
                  `#${roundId} | Draw: ${new Date(
                    round!.executeAfterTime.mul(1000).toNumber()
                  ).toLocaleString()}`
                ) : (
                  <Skeleton containerClassName="w-[200px]" />
                )}
              </p>

              <div className="grid grid-cols-2 gap-4 lg:grid-cols-1">
                <div className="max-w-[370px] card-border-gradient-blue-to-yellow p-5 mt-3 relative">
                  <div className="absolute hidden top-2 right-2 lg:block">
                    <Popup
                      trigger={<img alt="" className="w-4" src={InfoIcon} />}
                      on="hover"
                      position="bottom center"
                      mouseEnterDelay={0}
                      contentStyle={{
                        border: 0,
                        backgroundColor: "#faf6a6",
                        backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
                      }}
                      arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
                    >
                      <div className="px-[5px] py-1">
                        <div className="flex justify-between">
                          <span className="text-xs text-black">Total Reserved Prizes</span>
                          <span className="text-xs font-black text-black">
                            {skeletonWhileLoading({
                              value: formattedPrizeReserved.totalPrize,
                              skeletonClassName: "w-[50px]",
                            })}
                          </span>
                        </div>
                        <div className="flex justify-between mt-1">
                          <span className="text-xs text-black">Prize in Round</span>
                          <span className="text-xs font-black text-black">
                            {skeletonWhileLoading({
                              value: formattedPrizeReserved.prizeInRound,
                              skeletonClassName: "w-[50px]",
                            })}
                          </span>
                        </div>
                        <div className="flex justify-between mt-1">
                          <span className="text-xs text-black">Reserved Prizes</span>
                          <span className="text-xs font-black text-black">
                            {skeletonWhileLoading({
                              value: formattedPrizeReserved.reserved,
                              skeletonClassName: "w-[50px]",
                            })}
                          </span>
                        </div>
                      </div>
                    </Popup>
                  </div>
                  <div className="flex flex-row items-center justify-center">
                    <img alt="Prize" src={PrizeIcon} />
                    <span className="ml-3 text-sm font-bold lg:text-base">Overall Prize</span>
                  </div>
                  <div className="pt-1 text-2xl font-bold text-center lg:pt-4 lg:pb-2 lg:text-3xl text-gradient gold">
                    {skeletonWhileLoading({
                      value: formattedPrizeReserved.totalPrize,
                      skeletonClassName: "w-[200px]",
                    })}
                  </div>
                </div>

                <div className="max-w-[370px] card-border-gradient-blue-to-yellow lg:p-6 p-5 mt-3 relative">
                  <RoomRoundCountdownDisplay round={round} />
                </div>
              </div>
            </div>
            <div>
              <div className="mt-2 card-border-gradient-blue-to-yellow">
                <div className="flex justify-between px-4 py-2">
                  <div className="flex items-center text-xl lg:text-sm">
                    <span className="inline-block w-6 rounded-full lg:w-4 bg-gradient-blue aspect-square"></span>
                    <span className="ml-2 font-bold text-gold">Live</span>
                  </div>
                  <div>
                    <div className="flex flex-col space-x-1 text-sm lg:flex-row">
                      <strong className="text-right text-gold">
                        {skeletonWhileLoading({
                          value: round?.totalSize.toNumber().toLocaleString(),
                          skeletonClassName: "w-12",
                        })}{" "}
                        numbers
                      </strong>{" "}
                      <span> have been in this round!</span>
                    </div>
                  </div>
                </div>
                <div className="h-[1px] bg-gold"></div>
                <RoundTicketList digit={digit} buyers={recentBuyers} />
              </div>
              <div className="flex flex-row justify-between px-5 pb-5 mt-6 space-x-3 ">
                <LinkButton
                  to={`/decimal-box/${digit}#finished-round`}
                  buttonSize="md"
                  preset="primary-ghost"
                  className="flex-1"
                >
                  Finished Rounds
                </LinkButton>
                <LinkButton
                  buttonSize="md"
                  preset="gold"
                  className="flex-1"
                  to={`/decimal-box/${digit}`}
                >
                  Play
                </LinkButton>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
