import { Button } from "../common";

type PrizeItemProps = {
  title: string;
  prize: number;
  onClaim: () => void;
};

export function PrizeItem({ title, prize, onClaim }: PrizeItemProps) {
  return (
    <>
      <div className="h-[1px] bg-smoke mt-3"></div>
      <div className="px-8">
        <div className="flex justify-between">
          <div className="flex flex-col">
            <p className="mt-5 text-sm font-bold">{title}</p>
            <p className="mt-1 text-2xl font-bold text-gradient gold">
              $
              {prize.toLocaleString(undefined, {
                maximumFractionDigits: 5,
                minimumFractionDigits: 2,
              })}
            </p>
          </div>
          {prize > 0 ? (
            <Button
              preset="gradient"
              buttonSize="md"
              className="w-24 px-2 my-6 uppercase"
              onClick={onClaim}
            >
              Claim
            </Button>
          ) : (
            <Button preset="opacity" buttonSize="md" className="w-24 px-2 my-6 uppercase">
              Claimed
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
