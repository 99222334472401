import { useCallback } from "react";
import { config } from "../../../../config";
import { validateAmountNumber } from "../../../../utils/vadlidator";

export const useUpdateAmount = (
  userBalance: number,
  maxAmount: number,
  amountSetter: (value: string) => void
) => {
  return useCallback(
    (value: string) => {
      if (value === ".") {
        amountSetter("0");
      } else if (
        validateAmountNumber(value, { maxDecimalPlaces: config.multiplierBox.maxDecimalPlaces })
      ) {
        const amountNumber = Number(value);

        if (amountNumber >= maxAmount) {
          amountSetter(maxAmount.toFixed(config.multiplierBox.maxDecimalPlaces));
        } else if (amountNumber > userBalance) {
          amountSetter(userBalance.toFixed(config.multiplierBox.maxDecimalPlaces));
        } else {
          amountSetter(value);
        }
      }
    },
    [userBalance, maxAmount]
  );
};
