type ValidateAmountNumberOption = {
  maxDecimalPlaces: number;
};

export const validateAmountNumber = (
  value: string,
  { maxDecimalPlaces }: ValidateAmountNumberOption
) => {
  const onlyNumber = /^[0-9]*$/.test(value);
  return onlyNumber;
};
