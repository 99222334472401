import clsx from "clsx";

type Props = {
  direction: "up" | "down";
  className?: string;
};

export const Fade = ({ direction, className }: Props) => {
  return (
    <div
      className={clsx("from-black", direction === "up" ? "bg-gradient-to-b" : "bg-gradient-to-t", className)}
    ></div>
  );
};
