import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import { Button } from "../common/Button";

export function Questions() {
  return (
    <div className="bg-black">
      <div className="container pt-8 mx-auto text-center lg:pt-16">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="s" gold />
          <AnimatedText size="md" text="t" gold />
          <AnimatedText size="md" text="i" gold />
          <AnimatedText size="md" text="l" gold />
          <AnimatedText size="md" text="l" gold />
          <AnimatedText size="md" className="ml-4" text="g" gold />
          <AnimatedText size="md" text="o" gold />
          <AnimatedText size="md" text="t" gold />
          <br className="lg:hidden" />
          <AnimatedText size="md" className="ml-1 lg:ml-4" text="q" gold />
          <AnimatedText size="md" text="u" gold />
          <AnimatedText size="md" text="e" gold />
          <AnimatedText size="md" text="s" gold />
          <AnimatedText size="md" text="t" gold />
          <AnimatedText size="md" text="i" gold />
          <AnimatedText size="md" text="o" gold />
          <AnimatedText size="md" text="n" gold />
          <AnimatedText size="md" text="s" gold />
          <AnimatedText size="md" text="?" gold />
        </motion.div>
        <p className="text-lg font-light">
          Interested in learning more about Billion Box? Our in-depth guide can help
        </p>
        <Button
          className="mt-6 uppercase px-9 lg:px-16 lg:mt-8"
          preset="primary-ghost"
          buttonSize="lg"
          onClick={() => window.open("https://info.billionbox.io/product/the-prediction-platform", "_blank")}
        >
          Learn more
        </Button>
      </div>
    </div>
  );
}
