import { useNumberStore } from "./../store/number-store";

export const useNumberSize = (
  buyers: {
    number: number;
    size: number;
  }[]
) => {
  const { numbers } = useNumberStore();

  return buyers.concat(numbers).reduce((prev, cur) => {
    if (!prev[cur.number]) {
      prev[cur.number] = cur.size;
    } else {
      prev[cur.number] += cur.size;
    }
    return prev;
  }, {} as Record<number, number>);
};
