import "reactjs-popup/dist/index.css";
import { AllWinningRanks } from "../portion-box/AllWinningRanks";
import { Banner } from "../portion-box/Banner";
import { DashboardContainer } from "../portion-box/DashboardContainer";
import { HowToPlay } from "../portion-box/HowToPlay";
import { Questions } from "../portion-box/Questions";
import { RoomDataProvider } from "../portion-box/RoomDataProvider";
import { RoomList } from "../portion-box/RoomList";
import { WinningCriteria } from "../portion-box/WinningCriteria";

export function PortionBox() {
  return (
    <>
      <RoomDataProvider>
        <Banner />
        <DashboardContainer />
        <RoomList />
      </RoomDataProvider>
      <AllWinningRanks />
      <HowToPlay />
      <WinningCriteria />
      <Questions />
    </>
  );
}
