import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import { Button } from "../common/Button";

export const Questions = () => {
  return(
    <div className="bg-black">
      <div className="container pt-20 mx-auto text-center lg:pt-0">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="s" goldMax />
          <AnimatedText size="md" text="t" goldMax />
          <AnimatedText size="md" text="i" goldMax />
          <AnimatedText size="md" text="l" goldMax />
          <AnimatedText size="md" text="l" goldMax />
          <AnimatedText size="md" className="ml-4" text="g" goldMax />
          <AnimatedText size="md" text="o" goldMax />
          <AnimatedText size="md" text="t" goldMax />
          <br className="lg:hidden" />
          <AnimatedText size="md" className="ml-1 lg:ml-4" text="q" goldMax />
          <AnimatedText size="md" text="u" goldMax />
          <AnimatedText size="md" text="e" goldMax />
          <AnimatedText size="md" text="s" goldMax />
          <AnimatedText size="md" text="t" goldMax />
          <AnimatedText size="md" text="i" goldMax />
          <AnimatedText size="md" text="o" goldMax />
          <AnimatedText size="md" text="n" goldMax />
          <AnimatedText size="md" text="s" goldMax />
          <AnimatedText size="md" text="?" goldMax />
        </motion.div>
        <p className="text-lg font-light">
          Interested in learning more about Billion Box? Our in-depth guide can help
        </p>
        <Button
          className="pb-2 mt-6 uppercase px-9 lg:px-16 lg:mt-8"
          preset="gold-max-solid"
          buttonSize="lg"
        >
          Learn more
        </Button>
      </div>
    </div>
  )
}