import { Button } from "../common/Button";
import clsx from "clsx";

type Props = {
  silver?: boolean;
};

export function HistortAndChart({ silver }: Props) {
  return (
    <div
      className={clsx(
        "flex flex-col items-center col-span-1 px-4 justify-evenly lg:col-span-3 lg:px-5",
        silver
          ? "card-border-gradient-blue-to-purple-bg-black-to-blue"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <div className="flex justify-center">
        <div className="flex items-center mr-6 md:mr-11">
          <img
            className="w-4 mr-1 md:w-6 lg:mr-2"
            src={require(silver ? "../assets/icon/clock@2x.png" : "../assets/icon/clock.png")}
            alt=""
          />
          <p className="mr-1 text-sm font-normal lg:text-base">History</p>
        </div>
        <a href="#history">
          <Button
            className="uppercase lg:px-9 px-4 text-sm py-1.5"
            preset={silver ? "silver-ghost" : "primary-ghost-blue"}
            buttonSize="sm"
          >
            View
          </Button>
        </a>
      </div>
      <div className="flex justify-between">
        <div className="flex items-center mr-8 md:mr-14">
          <img
            className="w-4 mr-1 md:w-6 lg:mr-2"
            src={require(silver ? "../assets/icon/chart@2x.png" : "../assets/icon/chart.png")}
            alt=""
          />
          <p className="mr-1 text-sm font-normal lg:text-base">Chart</p>
        </div>
        <a href="#chart">
          <Button
            className="uppercase lg:px-9 px-4 text-sm py-1.5"
            preset={silver ? "silver-ghost" : "primary-ghost-blue"}
            buttonSize="sm"
          >
            View
          </Button>
        </a>
      </div>
    </div>
  );
}
