import { BigNumber } from "ethers";
import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";
import { BB_MAX_GAMES } from "../../config";
import {
  MultiplierBoxRound,
  useCurrentRound,
  usePayoutRate,
} from "../../hooks/contracts/multiplier-box";

export type CurrentRoundState = Partial<{
  digits: number;
  setDigits: (digits: number) => void;
  gameAddress: string;
  currentRound: MultiplierBoxRound;
  payoutRate: {
    rate?: BigNumber;
    formattedRate?: number;
  };
}>;

const CurrentRoundContext = createContext<CurrentRoundState>({});

export const CurrentRoundProvider = (props: PropsWithChildren) => {
  const [digits, setDigits] = useState(1);
  const currentRound = useCurrentRound();
  const gameAddress = useMemo(() => BB_MAX_GAMES[digits], [digits]);
  const payoutRate = usePayoutRate(currentRound?.roundId, gameAddress);

  return (
    <CurrentRoundContext.Provider
      value={{ currentRound, payoutRate, gameAddress, digits, setDigits }}
    >
      {props.children}
    </CurrentRoundContext.Provider>
  );
};

export const useCurrentRoundState = () => useContext(CurrentRoundContext);
