import { config } from "./../config";
import create from "zustand";

type BuyNumber = {
  number: number;
  size: number;
};

type Numberstore = {
  currentNumber?: number;
  numbers: BuyNumber[];
  fetchCallback: Record<string, (() => void) | undefined>;
  setCurrentNumber: (currentNumber: number) => void;
  addNumber: (number: number, size: number, maxNumberPerTx: number) => void;
  addNumbers: (numbers: number[], maxNumberPerTx: number) => void;
  changeNumberSize: (numberIndex: number, changeSize: number) => void;
  setNumberSize: (numberIndex: number, size: number) => void;
  deleteNumber: (numberIndex: number) => void;
  clearNumbers: () => void;
  registerFetchCallback: (key: string, callback?: () => void) => void;
  registerFetchCallbackWithDelay: (key: string, callback?: () => void) => void;
  runFetchCallback: () => void;
};

export const useNumberStore = create<Numberstore>((set, get) => ({
  currentNumber: undefined,
  numbers: [],
  fetchCallback: {},
  setCurrentNumber: (currentNumber: number) => {
    set(() => ({ currentNumber }));
  },
  addNumber: (number: number, size: number, maxNumberPerTx: number) => {
    if (get().numbers.length >= maxNumberPerTx) {
      return;
    }

    set((state) => {
      const currentNumberIndex = state.numbers.findIndex((n) => n.number === number);
      if (currentNumberIndex >= 0) {
        state.numbers[currentNumberIndex].size += size;
      } else {
        state.numbers.push({ number, size });
      }
      return { ...state };
    });
  },
  addNumbers: (numbers: number[], maxNumberPerTx: number) => {
    set((state) => {
      numbers.forEach((number) => {
        state.addNumber(number, 1, maxNumberPerTx);
      });
      return { ...state };
    });
  },
  changeNumberSize: (numberIndex: number, changeSize: number) => {
    set((state) => {
      const number = state.numbers[numberIndex];
      number.size += changeSize;
      return { ...state, [numberIndex]: number };
    });
  },
  setNumberSize: (numberIndex: number, size: number) => {
    set((state) => {
      const number = state.numbers[numberIndex];
      number.size = size;
      return { ...state, [numberIndex]: number };
    });
  },
  deleteNumber: (numberIndex: number) => {
    set((state) => {
      const numbers = [...state.numbers];
      numbers.splice(numberIndex, 1);
      state.numbers = numbers;
      return { ...state };
    });
  },
  clearNumbers: () => {
    set((state) => {
      state.numbers = [];
      return { ...state };
    });
  },
  registerFetchCallback: (key: string, callback?: () => void) => {
    set((state) => {
      state.fetchCallback[key] = callback;

      return { ...state };
    });
  },
  registerFetchCallbackWithDelay: (key: string, callback?: () => void) => {
    set((state) => {
      state.fetchCallback[key] = () => {
        setTimeout(() => callback?.(), 5000);
        setTimeout(() => callback?.(), 10000);
        setTimeout(() => callback?.(), 30000);
      };

      return { ...state };
    });
  },
  runFetchCallback: () => {
    const { fetchCallback } = get();
    for (const key in fetchCallback) {
      fetchCallback[key]?.();
    }
  },
}));
