import { useCallback, useEffect, useMemo, useState } from "react";
import { config } from "../../../config";
import { useMultiplierBoxNumberStore } from "../../../store/multiplier-box-number-store";
import { validateAmountNumber } from "../../../utils/vadlidator";
import { useCurrentRoundState } from "../CurrentRoundProvider";

type NumberItemProps = {
  number: number;
  amount: number;
  numberLimit: number;
  estimatePrize: number;
  amountEditable: boolean;
  userBalance: number;
  onRemove?: () => void;
};

export const NumberItem = ({
  number,
  amount,
  numberLimit,
  estimatePrize,
  amountEditable,
  userBalance,
  onRemove,
}: NumberItemProps) => {
  const { digits = 1, gameAddress = "" } = useCurrentRoundState();
  const { setNumberAmount, totalAmount } = useMultiplierBoxNumberStore.getState();
  const [customAmount, setCustomAmount] = useState("");
  const maxPerNumber = useMemo(
    () => config.multiplierBox.maxPerNumber[gameAddress] ?? 0,
    [gameAddress]
  );

  const updateAmount = useCallback(
    (value: string) => {
      if (value === ".") {
        setCustomAmount("0");
      } else if (
        validateAmountNumber(value, { maxDecimalPlaces: config.multiplierBox.maxDecimalPlaces })
      ) {
        const amountNumber = Number(value);
        const amountLeft = Math.min(
          maxPerNumber - numberLimit,
          userBalance - totalAmount({ exclude: number })
        );

        if (amountNumber >= amountLeft) {
          setCustomAmount(amountLeft.toFixed(0));
        } else if (amountNumber >= userBalance) {
          setCustomAmount(userBalance.toFixed(config.multiplierBox.maxDecimalPlaces));
        } else {
          setCustomAmount(value);
        }
      }
    },
    [userBalance, number, numberLimit]
  );

  useEffect(() => {
    if (!amountEditable) return;

    setNumberAmount(number, Number(customAmount));
  }, [number, customAmount, amountEditable]);

  return (
    <div className="flex p-3 mt-2 space-x-4 border border-white/10 rounded-xl">
      <div className="mt-2 w-[40px] text-center text-3xl font-bold tracking-widest text-gradient gold">
        {number.toString().padStart(digits, "0")}
      </div>
      <div className="grid flex-1 grid-cols-2 gap-x-3">
        {amountEditable ? (
          <>
            <div className="relative col-span-2 pb-1">
              <input
                className="w-full py-1 pr-2 text-xl font-bold text-right text-black rounded-xl"
                placeholder="0"
                value={amountEditable ? customAmount : amount}
                onChange={(e) => updateAmount(e.target.value)}
              />
              <div className="absolute mt-[-11px] text-sm font-bold text-gray-500 top-1/2 left-2">
                Amount
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="text-xs text-white/50">Amount</div>
            <div className="text-sm font-bold">
              ${amount.toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })}
            </div>
          </>
        )}
        <div className="text-xs text-white/50">Number Limit</div>
        <div className="text-xs">
          ${numberLimit.toLocaleString()}/{maxPerNumber.toLocaleString()}
        </div>
        <div className="text-xs text-white/50">Estimate Prize</div>
        <div className="text-xs">${estimatePrize.toLocaleString()}</div>
      </div>
      <button type="button" className="self-start" onClick={() => onRemove?.()}>
        <img className="w-5" src={require("../../../assets/portion-box-room/delete.png")} />
      </button>
    </div>
  );
};
