import { useRounds } from "../hooks/usePrediction";
import { useCountdown } from "../hooks/useTime";
import { CardCalculating } from "./CardCalculating";
import { IndexTab } from "./LetsPlay";
import clsx from "clsx";
import { SilverProp } from "./CurrentPrice";

type upcomingType = {
  currentEpoch: number;
} & IndexTab;

export function CardUpComing({ index, keyTab, silver, currentEpoch }: upcomingType & SilverProp) {
  const { closeTimestamp } = useRounds({
    currentEpoch,
    keyTab,
    activeTabIndex: index,
    watch: false,
  });
  const { countDown } = useCountdown(closeTimestamp);

  if (countDown === "00:00") {
    return <CardCalculating currentEpoch={currentEpoch + 1} />;
  }

  return (
    <div className="px-1 pb-1 overflow-hidden card-dark-blue w-card h-card">
      <div className="flex items-center justify-center">
        <img className="w-4 mr-1" src={require("../assets/icon/clock-white.png")} alt="" />
        <span className="py-2 mr-1 font-semibold">UPCOMING</span>
        <span className="text-[#9a9a9a] font-semibold">#{currentEpoch + 1}</span>
      </div>

      <div className="bg-gradient-black-to-blue-r rounded-2xl flex flex-col items-center justify-center h-[380px] relative">
        <img
          className="absolute bottom-0 right-0 w-40"
          src={require("../assets/clock.png")}
          alt=""
        />
        <div className="flex items-center mb-2">
          <img className="w-4 mr-1" src={require(silver ? "../assets/icon/clock@2x.png" : "../assets/icon/clock.png")} alt="" />
          <p className="mr-3 text-lg">Start in</p>
        </div>
        <p className={clsx("mb-12 text-2xl font-bold", silver ? "text-gradient silver": "text-gradient gold")}>{countDown}</p>
      </div>
    </div>
  );
}
