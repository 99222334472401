import SkeletonLoading, { SkeletonProps } from "react-loading-skeleton";

export const Skeleton = ({
  className,
  containerClassName,
  ...rest
}: SkeletonProps) => {
  const overrideContainerClassName = "inline-block " + containerClassName;
  const overrideClassName = "inline-block " + className;

  return (
    <SkeletonLoading
      baseColor="rgba(180, 180, 180)"
      highlightColor="rgba(255, 255, 255, 0.7)"
      containerClassName={overrideContainerClassName}
      className={overrideClassName}
      {...rest}
    />
  );
};

export const skeletonWhileLoading = ({
  value,
  skeletonClassName,
}: {
  value?: any;
  skeletonClassName: string;
}) => {
  return value === undefined || value === null ? (
    <Skeleton containerClassName={skeletonClassName} />
  ) : (
    value
  );
};
