import { BigNumber } from "ethers";
import { erc20ABI, useContractWrite, usePrepareContractWrite } from "wagmi";

type ContractFns = "approve";

// prettier-ignore
type ContractArgs<Fn> = 
  Fn extends "approve" ? [string, BigNumber] :
  never;

export function useTokenWrite<WriteFn extends ContractFns>(
  address: string,
  enabled: boolean,
  writeFunction: WriteFn,
  params?: ContractArgs<WriteFn>
) {
  const { config } = usePrepareContractWrite({
    addressOrName: address,
    contractInterface: erc20ABI,
    functionName: writeFunction,
    args: params,
    enabled,
  });

  return useContractWrite(config);
}
