import { Button, LinkButton } from "../common/Button";
import { ClaimReward } from "../common/ClaimReward";
import { DrawIn } from "../common/DrawIn";
import { skeletonWhileLoading } from "../common/Skeleton";
import { RoomDigit } from "../config";
import { Ticket as TicketModel } from "../hooks/contracts/usePortionBoxRead";
import { RoomStateType } from "../portion-box/RoomDataProvider";
import { OverallPrize } from "./OverallPrize";

type Props = {
  digit: RoomDigit;
  room?: RoomStateType;
  ticketBalance?: string;
  prizeDistribution?: number;
  refetchTickets?: () => void;
  prize?: string;
  winTickets: TicketModel[];
};

export function Dashboard({
  digit,
  room,
  ticketBalance,
  prizeDistribution,
  refetchTickets,
  prize,
  winTickets,
}: Props) {
  const title = require(`../assets/portion-box-room/${digit}digits.png`);

  return (
    <div className="bg-banner bg-cover bg-center relative mt-[60px]">
      <a href="/decimal-box">
        <Button
          preset="gradient"
          buttonSize="md"
          className="px-6 py-2 mt-4 ml-4 uppercase lg:px-8 lg:mt-8 lg:ml-16"
        >
          <div className="flex items-center">
            <img className="w-5 mr-2" src={require("../assets/icon/back.png")} alt="" />
            <p className="text-sm font-bold">BACK</p>
          </div>
        </Button>
      </a>

      <div className="container pb-10 mx-auto text-center">
        <img alt="" src={title} className="items-center w-2/3 mx-auto mt-2 lg:w-96 lg:-mt-8" />
        <div className="grid grid-cols-2 gap-3 mt-2 lg:grid-cols-3 lg:gap-4">
          <img
            alt=""
            className="absolute bottom-0 left-[5%] w-1/6 lg:w-32 floating z-10 lg:block hidden"
            src={require("../assets/coin-logo1.png")}
          />
          <img
            alt=""
            className="absolute top-[30%] right-5 w-1/6 lg:w-32 floating-2 z-10 lg:block hidden"
            src={require("../assets/coin-logo.png")}
          />

          <OverallPrize digit={digit} room={room} />

          <DrawIn digit={digit} room={room} />

          <div
            className={`flex-col gap-y-2 lg:order-3 order-4 card-border-gradient-blue-to-yellow-bg-black-to-blue flex items-center justify-center relative overflow-hidden`}
          >
            <div className="z-10 text-lg">My Dashboard</div>
            <LinkButton preset="gradient" className="z-10 px-12 py-1" to="/my-dashboard">
              View
            </LinkButton>
            <img
              alt=""
              className="absolute bottom-0 left-0 z-0 w-28 rounded-bl-2xl"
              src={require("../assets/tickets.png")}
            />
          </div>

          <div className="z-10 flex flex-col items-center justify-center order-3 py-4 card-border-gradient-blue-to-yellow-bg-black-to-blue lg:order-4">
            <p className="mb-1 text-base lg:text-xl md:text-lg lg:mb-3">Total Prize Distribution</p>
            <p className="text-3xl font-bold text-gradient gold">
              {skeletonWhileLoading({
                value:
                  prizeDistribution !== undefined
                    ? `$${prizeDistribution?.toLocaleString("en-US", {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}`
                    : undefined,
                skeletonClassName: "w-24",
              })}
            </p>
          </div>

          <ClaimReward
            room="a"
            winTickets={winTickets}
            onClaimed={() => refetchTickets?.()}
            prize={prize}
          />
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black z-0"></div>
    </div>
  );
}
