import { memo, useMemo, useState } from "react";
import BinanceLogo from "../../assets/multiplier-box/binance-logo.svg";
import TradingViewGraph from "../../common/TradingViewGraph";
import { FadeSlideAnimatePresence } from "../../common/FadeSlideAnimatePresence";
import { TabGroup } from "../../common/TabGroup";

const timeframes = [1, 5, 10, 30, 60];
const timeframeTitles = ["1 min", "5 mins", "10 mins", "30 mins", "60 mins"];

const MemoBTCTradeViewGraph = memo(TradingViewGraph);

export const Graph = () => {
  const [activeTimeframeIndex, setActiveTimeframeIndex] = useState(0);
  const activeTimeframe = useMemo(() => timeframes[activeTimeframeIndex], [activeTimeframeIndex]);

  return (
    <FadeSlideAnimatePresence className="flex flex-col h-full space-y-4">
      <div className="flex flex-row items-center justify-between">
        <div className="flex flex-row items-center space-x-2">
          <img src={require("../../assets/icon/btc.png")} alt="" />
          <span className="text-xl uppercase">BTC Price</span>
        </div>
        <a
          href="https://www.binance.com/en/trade/BTC_USDT"
          target="_blank"
          rel="noreferrer"
          className="flex flex-row items-center px-4 py-2 space-x-2 bg-black border border-gray-600 rounded-3xl"
        >
          <span className="text-sm">View on</span>
          <img className="h-[12px]" src={BinanceLogo} alt="" />
        </a>
      </div>
      <div className="flex-1">
        <MemoBTCTradeViewGraph asset="btc" timeframe={activeTimeframe} />
      </div>
      <TabGroup
        color="gold"
        tabs={timeframeTitles}
        className="sm:w-full bg-blue-gray rounded-3xl"
        buttonClassName="py-2 w-1/5 text-[12px] font-semibold h-full"
        onTabChange={setActiveTimeframeIndex}
      />
    </FadeSlideAnimatePresence>
  );
};
