import { utils } from "ethers";
import clsx from "clsx";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import MinusDisbaledIcon from "../assets/icon/minus-disable.png";
import MinusIcon from "../assets/icon/minus.png";
import PlusDisabledIcon from "../assets/icon/plus-disable.png";
import PlusIcon from "../assets/icon/plus.png";
import { Button } from "../common/Button";
import { WarningLimitModal } from "../common/WarningLimitModal";
import { config, maxPrize } from "../config";
import { useFormattedPrize } from "../hooks/useFormattedPrize";
import { useNumberSize } from "../hooks/useNumberSize";
import { useRoundData } from "../hooks/useRoundData";
import { EmptyState } from "../portion-box/EmptyState";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";
import { BuyNumberModal } from "./BuyNumberModal";

type Props = {
  gold?: boolean
}

export function SelectedNumber({ gold }: Props) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isHurryOpen, setIsHurryOpen] = useState(false);

  const { room = "" } = useParams();
  const roomState = useSpecificRoomState(room);
  const { changeNumberSize, setNumberSize, deleteNumber } = useNumberStore();
  const numbers = useNumberStore((state) => state.numbers);
  const { rawTotal } = useFormattedPrize(roomState?.prizeReserved, roomState?.round?.totalPrize);

  const totalPrize = useMemo(() => {
    const total = Number(utils.formatUnits(rawTotal, 6));
    return Math.min(total, maxPrize[room]);
  }, [rawTotal, room]);

  const { buyers } = useRoundData(room, roomState?.roundId);
  const numberSize = useNumberSize(buyers);

  const isNumberSizeOverLimit = useCallback(
    (number: number, size: number) => {
      const currentSize = numberSize[number] ?? 0;
      return currentSize + size >= config.maxSizePerNumber;
    },
    [numberSize]
  );

  const numberLength = numbers.length;

  useEffect(() => {
    const isNumberOver = numberLength >= config.maxNumberPerTx(room);
    if (isNumberOver) {
      setIsHurryOpen(true);
    }
  }, [numberLength, room]);

  function openBuyModal() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    setIsHurryOpen(false);
  }

  function deleteRow(index: number) {
    deleteNumber(index);
  }

  function minusQty(index: number) {
    changeNumberSize(index, -1);
  }

  function plusQty(index: number) {
    changeNumberSize(index, 1);
  }

  return (
    <div className="lg:w-[700px] w-full lg:mx-auto">
      <div className={clsx("mt-4 ", gold ? "card-border-gradient-gold-bg-black" : "card-border-gradient-blue-to-yellow")}>
        <div className={clsx("p-4  rounded-t-lg", gold ? "bg-gradient-gold-max" : "bg-gradient-blue")}>
          <p className="text-2xl text-center text-white uppercase font-audiowide">
            Selected Number
          </p>
        </div>

        <div className="px-2 py-4 lg:container">
          {numberLength === 0 && <EmptyState className="py-24">Start add number.</EmptyState>}
          {numberLength > 0 && (
            <div className="space-x-1 text-xl">
              <span className="text-white/60">Total</span>
              <span className="font-bold text-white">
                {numberLength} number{numberLength > 1 ? "s" : ""}
              </span>
            </div>
          )}
          <div className="lg:px-5 px-2 overflow-scroll overflow-x-hidden scrollbar-gold max-h-[400px]">
            {numbers.map((number, index) => {
              return (
                <div
                  className="grid grid-cols-3 gap-4 pt-4"
                  key={number.number.toString() + "|" + index}
                >
                  <div className="card-border-brown-bg-blue py-[8px] text-center relative">
                    <button
                      className="absolute w-6 -top-3 -right-3"
                      onClick={() => deleteRow(index)}
                    >
                      <img src={require("../assets/portion-box-room/delete.png")} alt="" />
                    </button>

                    <div className="flex items-center justify-center text-3xl font-bold tracking-widest text-gradient gold">
                      {number.number.toString().padStart(Number(room), "0")}
                    </div>
                  </div>
                  <div className="bg-white rounded-[10px] p-2 flex justify-between items-center">
                    <button
                      className="py-3 pr-3"
                      onClick={() => minusQty(index)}
                      disabled={number.size <= 1}
                    >
                      <img
                        className="h-1 lg:h-2"
                        src={number.size <= 1 ? MinusDisbaledIcon : MinusIcon}
                        alt=""
                      />
                    </button>

                    <input
                      className="w-[70px] text-2xl lg:text-3xl font-bold text-center text-black focus:border-none focus:outline-none"
                      type="number"
                      value={number.size === 0  ? "" : number.size}
                      onChange={(e) => {
                        const { value } = e.target;
                        const valueNumber = Number(e.target.value);
                        if (valueNumber <= 0 && value !== "") {
                          return;
                        }
                        setNumberSize(index, valueNumber);
                      }}
                      onBlur={() => {
                        if (number.size === 0) {
                          setNumberSize(index, 1);
                        }
                      }}
                    />

                    <button
                      className="py-3 pl-3"
                      onClick={() => plusQty(index)}
                      disabled={isNumberSizeOverLimit(number.number, 0)}
                    >
                      <img
                        className="h-3 lg:h-5"
                        src={isNumberSizeOverLimit(number.number, 0) ? PlusDisabledIcon : PlusIcon}
                        alt=""
                      />
                    </button>
                  </div>
                  <div className="bg-blue-gray p-2 flex lg:flex-row flex-col justify-between items-center rounded-[10px]">
                    <p className="text-xs text-center lg:text-left">Total Prize</p>
                    <p className="font-bold text-center">
                      $
                      {totalPrize.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        {numbers.length > 0 && (
          <div className="flex flex-col justify-between p-6 lg:flex-row">
            <div className="flex items-center">
              <img className="w-8 mr-5" src={require("../assets/icon/question@2x.png")} alt="" />
              <div>
                <p className="font-bold">Prize Condition</p>
                <p className="text-sm font-light">1 Number ~${config.ticketPrice(room)}</p>
              </div>
            </div>

            <Button
              className="px-20 mt-5 uppercase lg:px-44 lg:mt-0"
              preset={ gold ? "goldMax" : "gold"}
              buttonSize="md"
              onClick={openBuyModal}
            >
              buy
            </Button>
          </div>
        )}
        {isModalOpen && <BuyNumberModal isOpen={isModalOpen} closeModal={closeModal} />}
        <WarningLimitModal isOpen={isHurryOpen} closeModal={closeModal} />
      </div>
    </div>
  );
}
