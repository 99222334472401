import { address, config } from "../../../config";
import { useRequest } from "../../useRequest";

export type Buyer = {
  id: string;
  contract: string;
  roundId: number;
  user: string;
  game: string;
  number: number[];
  amounts: number[];
  totalPrice: number;
  transactionHash: string;
  transactionAt: string;
  createdAt: string;
};

type Response = {
  buyers: Buyer[];
};

export const useLatestBuyers = (game: string) => {
  const { data } = useRequest<Response>({
    path: `/max/${address.BB_MAX_ADDRESS}/game/${game}/buyers?limit=100`,
    config: {
      refreshInterval: config.refetchInterval,
    },
  });

  return data?.buyers ?? [];
};
