import { BigNumber } from "ethers";
import { NumberInfo } from "./../../../store/multiplier-box-number-store";
import { useMultiplierBoxWrite } from "./useMultiplierBoxWrite";
import { useParsedAmountsAndNumbers } from "./useParsedAmountsAndNumbers";

export const useExecutePlay = (
  numbers: NumberInfo[],
  roundId?: BigNumber,
  token?: string,
  decimals?: number,
  game?: string,
  price?: number
) => {
  const parsedAmountsAndNumbers = useParsedAmountsAndNumbers(price ?? 1, numbers, decimals);

  const play = useMultiplierBoxWrite("play", [
    parsedAmountsAndNumbers.amounts,
    parsedAmountsAndNumbers.numbers,
    game,
    roundId,
    token ?? "",
  ]);

  const playNativeToken = useMultiplierBoxWrite("playNativeToken", [
    parsedAmountsAndNumbers.total,
    parsedAmountsAndNumbers.amounts,
    parsedAmountsAndNumbers.numbers,
    game,
    roundId,
  ]);

  return { play, playNativeToken };
};
