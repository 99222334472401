import create from "zustand";
import { EndRoundEvent } from "../portion-box/Graph";

type RoundStore = {
  activeEndRound?: EndRoundEvent;
  setEndRound: (newEndRound: EndRoundEvent | undefined) => void;
};

export const useRoundStore = create<RoundStore>((set) => ({
  timeframe: undefined,
  setEndRound: (newEndRound) => set({ activeEndRound: newEndRound }),
}));
