import { motion, Variants } from "framer-motion";
import { flip3D } from "../animation/flip-3d";
import { AnimatedText } from "../common/AnimatedText";

export function WinningCriteria() {
  const cubeVariants: Variants = {
    hidden: { x: "-100%", y: "-30%", scale: 2, opacity: 0.5 },
    visible: {
      x: "0%",
      y: "0%",
      opacity: 1,
      scale: 1,
      transition: {
        type: "spring",
        stiffness: 20,
        damping: 20,
        velocity: 1,
      },
    },
  };

  return (
    <motion.div
      className="relative overflow-hidden bg-gradient-black"
      initial="hidden"
      whileInView="visible"
      viewport={{ once: true, margin: "0px 0px -20% 0px" }}
      transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
    >
      <motion.img
        alt=""
        className="absolute top-0 left-0 hidden w-96 lg:block"
        src={require("../assets/cube-trans4.png")}
        variants={cubeVariants}
      />
      <div className="container py-16 mx-auto">
        <div className="text-center">
          <AnimatedText text="W" gold />
          <AnimatedText text="I" gold />
          <AnimatedText text="N" gold />
          <AnimatedText text="N" gold />
          <AnimatedText text="I" gold />
          <AnimatedText text="N" gold />
          <AnimatedText text="G" gold />
          <br className="lg:hidden" />
          <AnimatedText text="C" className="lg:ml-4" />
          <AnimatedText text="R" />
          <AnimatedText text="I" />
          <AnimatedText text="T" />
          <AnimatedText text="E" />
          <AnimatedText text="R" />
          <AnimatedText text="I" />
          <AnimatedText text="A" />
        </div>

        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 mt-28 lg:mt-10">
          <div className="order-2 -mt-20 lg:-mt-0 lg:order-1">
            <div className="flex items-center">
              <img alt="" className="w-10" src={require("../assets/icon/dicimal.png")} />
              <p className="ml-3 text-2xl font-bold">The Decimal Box</p>
            </div>
            <p className="mt-5 text-lg font-light xl:text-xl">
              In the Decimal Box game, the rewards are determined based on the number of players and
              the total amount of USDT in the pool. The rewards are then divided among the winners
              based on the accuracy of their guesses. If no one wins, the prize pool is carried over
              to the next round.
            </p>
            <p className="mt-5 text-lg font-light xl:text-xl">
              Decimal Box is a fun and exciting way to test your skills and knowledge about BTC/USDT
              price fluctuations. With different levels of difficulty and potential rewards, there's
              something for everyone to enjoy.
            </p>
          </div>
          <div className="order-1 hvr-float lg:order-2">
            <motion.img
              alt=""
              className="-mt-20"
              src={require("../assets/winning@2x.png")}
              initial="hidden"
              whileInView="visible"
              variants={flip3D("right")}
              viewport={{ once: true, margin: "0px 0px -50% 0px" }}
            />
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 mt-28 lg:mt-10">
          <div className="hvr-float">
            <motion.img
              alt=""
              className="-mt-20"
              src={require("../assets/graph4.png")}
              initial="hidden"
              whileInView="visible"
              variants={flip3D("right")}
              viewport={{ once: true, margin: "0px 0px -50% 0px" }}
            />
          </div>
          <div className="-mt-20 lg:-mt-0">
            <div className="flex items-center">
              <img alt="" className="w-10" src={require("../assets/icon/prediction.png")} />
              <p className="ml-3 text-2xl font-bold">The Prediction Box</p>
            </div>
            <p className="mt-5 text-lg font-light xl:text-xl">
              Will BTC, ETH, or Matic price rise or fall? Make the right guess to win!
            </p>
            <p className="mt-5 text-lg font-light xl:text-xl">
              Test your foresight by playing Billionbox's prediction game to earn rewards. Use your
              foresight to test your market knowledge or gut feeling to predict whether the Matic,
              Bitcoin, Ethereum prices will go up or down in the near future. Be right and you’ll
              earn a portion of the prize pool!
            </p>
          </div>
        </div>

        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2 mt-28 lg:mt-10">
          <div className="order-2 -mt-20 lg:-mt-0 lg:order-1">
            <div className="flex items-center">
              <img alt="" className="w-10" src={require("../assets/icon/bb-max.png")} />
              <p className="ml-3 text-2xl font-bold">The BB MAX</p>
            </div>
            <p className="mt-5 text-lg font-light xl:text-xl">
              Choose two BTC decimals and unlock the chance to win big with up to 95x your original
              bet in the Double Lotto. Prefer a lower risk? Try the Single Lotto with a 8x payout.
            </p>
            <p className="mt-5 text-lg font-light xl:text-xl">
              BB Max combines excitement and rewards, allowing you to put your prediction skills to
              the test. Join Billion Box now and dive into the world of BB Max!
            </p>
          </div>
          <div className="order-1 hvr-float lg:order-2">
            <motion.img
              alt=""
              className="-mt-20"
              src={require("../assets/graph5.png")}
              initial="hidden"
              whileInView="visible"
              variants={flip3D("right")}
              viewport={{ once: true, margin: "0px 0px -50% 0px" }}
            />
          </div>
        </div>
      </div>
      <img
        alt=""
        className="absolute right-0 hidden bottom-1/4 w-96 lg:block"
        src={require("../assets/cube-trans6.png")}
      />
      <img
        alt=""
        className="absolute bottom-0 left-0 hidden w-[420px] lg:block"
        src={require("../assets/cube-trans8.png")}
      />
    </motion.div>
  );
}
