import clsx from "clsx";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import ReactApexChart from "react-apexcharts";
import ChainlinkWhite from "../assets/chainlink-white.svg";
import { Loading } from "../common/Loading";
import { Asset, RoomDigit, chainlinkAssetLink, roomAddress } from "../config";
import { usePortionBoxStartRoundEvent } from "../hooks/contracts/usePortionBoxStartRoundEvent";
import { useRequest } from "../hooks/useRequest";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { useRoundStore } from "../store/round-store";

type Props = {
  asset: Asset;
  digit: RoomDigit;
  jackpot?: boolean;
};

export type Timeframe = {
  timeframe: string;
  prices: number[];
  open: number;
  end: number;
  high: number;
  low: number;
  answer?: number;
  endRoundEvent?: EndRoundEvent[];
};

export type EndRoundEvent = {
  roundId: string;
  answer: number;
  executedTime: string;
  contract: string;
};

type PriceResponse = {
  timeframes: Timeframe[];
};

type LogoElement = {
  dataIndex: number;
  element: SVGImageElement;
};

const timeframeIndexForRoom: Record<any, number> = {
  1: 1,
  2: 3,
  3: 4,
  4: 3,
  5: 3,
  default: 3,
};

export const Graph = memo(({ asset, digit, jackpot }: Props) => {
  const { setEndRound } = useRoundStore();
  const contractAddress = roomAddress[digit].toLowerCase();

  const { width } = useWindowDimensions();
  const [logoElements, setLogoElements] = useState<LogoElement[]>([]);
  const [activeTab, setActiveTab] = useState(
    timeframeIndexForRoom[digit] ?? timeframeIndexForRoom.default
  );
  const activeTimeframeMinute = useMemo(() => {
    const timeframeMinutes = [1, 2, 5, 10, 15, 60];
    return timeframeMinutes[activeTab];
  }, [activeTab]);

  const [timeframes, setTimeframes] = useState<Timeframe[]>([]);
  const { data, mutate } = useRequest<PriceResponse>({
    path: `/price/${asset}?timeframe=${activeTimeframeMinute}`,
    config: {
      refreshInterval: 15_000,
    },
  });

  const timeframeChartSeries = useMemo(() => getChartSeries(timeframes), [timeframes]);

  const [currentDataIndex, setCurrentDataIndex] = useState(-1);
  const chartKey = useMemo(() => {
    if (timeframes.length > 0)
      return digit.toString() + timeframes[timeframes.length - 1].timeframe;
  }, [timeframes, digit]);

  usePortionBoxStartRoundEvent(() => {
    mutate();
  });

  const mouseMoveUpdate = useCallback((e: any, chart: any, options: any) => {
    if (options?.dataPointIndex) {
      setCurrentDataIndex(options.dataPointIndex);
    }
  }, []);

  const updateChartElements = useCallback(() => {
    const gElement = updateChart();
    const newLogoElements = getLogoElements(contractAddress, timeframes);

    newLogoElements.forEach((logo) => gElement?.appendChild(logo.element));

    setLogoElements(newLogoElements);
  }, [timeframes, contractAddress]);

  useEffect(() => {
    setTimeframes(data?.timeframes ?? []);
  }, [data]);

  useEffect(() => {
    let activeEndRound: EndRoundEvent | undefined;
    const timeframeLength = timeframes.length;

    for (const { element, dataIndex } of logoElements) {
      if (dataIndex === currentDataIndex && timeframeLength >= dataIndex + 1) {
        const activeTimeframe = timeframes[dataIndex];
        activeEndRound = activeTimeframe.endRoundEvent?.find(
          (round) => round.contract === contractAddress
        );

        element.style.filter = "drop-shadow(0px 0px 6px #faf6a6)";
      } else {
        element.style.filter = "";
      }
    }

    setEndRound(activeEndRound);
  }, [currentDataIndex, logoElements, timeframes, contractAddress, setEndRound]);

  useEffect(() => {
    setActiveTab(timeframeIndexForRoom[digit] ?? timeframeIndexForRoom.default);
  }, [digit]);

  return (
    <>
      <div className="p-4 mt-6 bg-[#04132c] rounded-3xl relative">
        {!data && <Loading />}
        <ReactApexChart
          key={chartKey}
          options={{
            grid: {
              borderColor: "rgba(255, 255, 255, 0.1)",
            },
            chart: {
              type: "candlestick",
              height: 300,
              toolbar: {
                show: false,
                autoSelected: "pan",
              },
              foreColor: "#fff",
              events: {
                updated: updateChartElements,
                mouseMove: mouseMoveUpdate,
              },
            },
            xaxis: {
              type: "datetime",
              // min: minZoomTime,
              crosshairs: {
                position: "back",
                stroke: {
                  dashArray: 4,
                  color: "#e2d27f",
                },
              },
              labels: {
                format: "HH:mm",
                datetimeUTC: false,
              },
            },
            yaxis: {
              forceNiceScale: true,
              labels: {
                offsetX: -10,
              },
              tooltip: {
                enabled: false,
              },
            },
            tooltip: {
              followCursor: true,
              custom: ({ dataPointIndex }) => {
                const timeframeData = timeframeChartSeries[dataPointIndex];
                const price = timeframeData.answer ?? timeframeData.prices[0];
                const [digits, decimals] = price.toString().split(".");
                const decimalNumbers = decimals?.slice(0, 4) ?? "00";
                return `
              <div class="px-1">
                <div>$${digits}.${decimalNumbers}</div>
              </div>
            `;
              },
            },
          }}
          series={[{ data: timeframeChartSeries }]}
          type="candlestick"
          height={300}
        />
      </div>
      <div className="flex flex-col items-center justify-end mt-6 lg:flex-row">
        <a
          href={chainlinkAssetLink.btc}
          className={clsx(
            "px-4 py-1 my-6 lg:my-0",
            jackpot ? "card-border-gradient-gray-to-white" : "card-border-gradient-blue-to-yellow"
          )}
          target="_blank"
          rel="noreferrer"
        >
          View on <img alt="chainlink website" className="inline-block" src={ChainlinkWhite} />
        </a>
      </div>
    </>
  );
});

function getChartSeries(timeframes: Timeframe[]) {
  return timeframes.map((timeframe) => ({
    x: timeframe.timeframe,
    y: [
      timeframe.open.toFixed(2),
      timeframe.high.toFixed(2),
      timeframe.low.toFixed(2),
      timeframe.end.toFixed(2),
    ],
    ...timeframe,
  }));
}

function getMinZoomTime(timeframes: Timeframe[], width: number | null) {
  if (timeframes.length < 4) {
    return;
  }

  const timeframeLength = timeframes.length;
  const minTimeframe =
    width && width < 1100
      ? timeframes[Math.floor(timeframeLength / 1.7)].timeframe
      : timeframes[Math.floor(timeframeLength * 0.3)].timeframe;

  return new Date(minTimeframe).getTime();
}

function updateChart() {
  const chartSvg = document.querySelector(".apexcharts-svg") as SVGElement;
  const addedWidth = Number(chartSvg.getAttribute("width")) + 15;
  chartSvg.setAttribute("width", addedWidth.toString());

  const g = document.querySelector("g.apexcharts-series");
  const lineDotted = document.createElementNS("http://www.w3.org/2000/svg", "line");

  lineDotted.setAttribute("x1", "-20");
  lineDotted.setAttribute("x2", "93%");
  lineDotted.setAttribute("y1", "0");
  lineDotted.setAttribute("y2", "0");
  lineDotted.style.stroke = "rgb(255, 255, 255)";
  lineDotted.style.strokeDasharray = "2,2";

  g?.appendChild(lineDotted);

  return g;
}

function getLogoElements(contract: string, timeframes: Timeframe[]) {
  const paths = document.querySelectorAll<SVGImageElement>(".apexcharts-series path");

  let elements: LogoElement[] = [];

  timeframes.forEach((timeframe, index) => {
    const selectedPath = paths[index];
    const pathCxAttribute = selectedPath.getAttribute("cx");
    if (!pathCxAttribute) return;

    const pathCx = Number(pathCxAttribute);
    const endRoundEvent = timeframe.endRoundEvent?.find((t) => t.contract === contract);

    if (!endRoundEvent || pathCx < 0) return;

    const image = document.createElementNS("http://www.w3.org/2000/svg", "image");
    image.setAttribute("x", (pathCx - 15).toString());
    image.setAttribute("y", "-16");
    image.setAttribute("width", "28");
    image.setAttribute("height", "28");
    image.setAttribute("href", "/bb-logo.svg");

    elements.push({ dataIndex: index, element: image });
  });

  return elements;
}
