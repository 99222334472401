import { BigNumberish, ContractFactory } from "ethers";
import { useState } from "react";
import { useContractWrite } from "wagmi";
import { address } from "../../../config";
import MultiplierBox from "../../../contracts/MultiplierBox";

type WriteFns = "play" | "playNativeToken" | "claim";

// prettier-ignore
type WriteArgs<Fn> = 
  Fn extends "play" ? [BigNumberish[], BigNumberish[], string?, BigNumberish?, string?] :
  Fn extends "playNativeToken" ? [BigNumberish, BigNumberish[], BigNumberish[], string?, BigNumberish?] :
  Fn extends "claim" ? [BigNumberish[]] :
  never;

type WriteError =
  | "InvalidFeeBPS"
  | "NotWhitelistGame"
  | "PlayTokenInvalid"
  | "InvalidNumberAndSizeLength"
  | "AlreadyStarted"
  | "PlayBeforeGenesisRound"
  | "ExecuteRoundTooEarly"
  | "RoundClosed"
  | "RoundNotOpen"
  | "NumberLimitExceeded"
  | "ExceedLimitNumberPerTx"
  | "PriceFeedInvalid"
  | "InsufficientAmount";

export function useMultiplierBoxWrite<WriteFn extends WriteFns>(
  functionName: WriteFn,
  args?: WriteArgs<WriteFn>
) {
  const [writeError, setWriteError] = useState<WriteError>();
  const overrides: any = {
    value: functionName === "playNativeToken" ? args?.[0] : "0",
  };

  return {
    writeError,
    ...useContractWrite({
      addressOrName: address.BB_MAX_ADDRESS,
      contractInterface: MultiplierBox,
      functionName,
      mode: "recklesslyUnprepared",
      args: functionName === "playNativeToken" ? args?.slice(1) : args,
      overrides,
      onError(error: any) {
        setWriteError(
          ContractFactory.getInterface(MultiplierBox).parseError(error.error.data.data)
            .name as WriteError
        );
      },
    }),
  };
}
