
export const UserBalance = (props: { balance?: string; tokenName?: string }) => {
  return (
    <div className="flex justify-between mt-6 text-right">
      <span className="text-sm text-white/50">No Decimals</span>

      <span className="text-sm text-right text-white/30">
        Balance {props.balance} {props.tokenName?.toUpperCase()}{" "}
      </span>
    </div>
  );
};
