import { useMemo } from "react";
import { FadeSlideAnimatePresence } from "../../common/FadeSlideAnimatePresence";
import { MultiplierBoxFinishedRound, useRoundInfos } from "../../hooks/contracts/multiplier-box";
import { bigNumberToFormattedTime } from "../../utils/formatter";
import { useCurrentRoundState } from "../play/CurrentRoundProvider";
import { Loading } from "../../common";
import { motion } from "framer-motion";

const MAX_FINISHED_ROUND = 40;

export const FinishedRound = () => {
  const { currentRound, gameAddress, digits = 1 } = useCurrentRoundState();
  const fecthedRoundIds = useMemo(() => {
    const startRoundId = currentRound?.roundId.sub(1).toNumber();

    if (!startRoundId) return [];

    const roundIds: number[] = [];
    for (let i = 0; i < MAX_FINISHED_ROUND; i++) {
      if (startRoundId - i === 0) break;
      roundIds.push(startRoundId - i);
    }

    return roundIds;
  }, [currentRound?.roundId]);

  const roundInfos = useRoundInfos(fecthedRoundIds, gameAddress ?? "");

  return (
    <FadeSlideAnimatePresence className="relative flex flex-col h-full space-y-4 overflow-y-scroll no-scrollbar">
      <div className="text-2xl font-bold text-center text-gradient gold">Finished Round</div>
      {!roundInfos?.length ? (
        <div className="absolute inset-0 flex flex-col items-center justify-center space-y-2">
          <motion.img
            className="w-16"
            src={require("../../assets/bb-icon1.png")}
            alt=""
            initial={{ y: -15 }}
            animate={{ y: 0 }}
            transition={{ repeat: Infinity, duration: 1.5, repeatType: "reverse" }}
          />
          <div className="text-gray-400">Loading...</div>
        </div>
      ) : (
        <div className="grid flex-1 grid-cols-5 auto-rows-min gap-y-2">
          <div className="col-span-2 text-sm text-center sm:text-lg text-white/40">Round</div>
          <div className="col-span-3 text-sm text-center sm:text-lg text-white/40">Drawn</div>
          {roundInfos?.map((round) => (
            <RoundInfo digits={digits} key={round.roundId.toString()} {...round} />
          ))}
        </div>
      )}
    </FadeSlideAnimatePresence>
  );
};

const RoundInfo = (props: MultiplierBoxFinishedRound & { digits: number }) => {
  const answerDigits = useMemo(() => {
    return props.answerPrice.sub(props.answer).div(100).toNumber().toLocaleString();
  }, [props.answerPrice]);

  const formattedPayout = useMemo(() => props.payout.toNumber() / 100, [props.payout]);
  return (
    <div className="grid grid-cols-5 col-span-5 p-3 bg-dark-blue-gray rounded-xl">
      <div className="flex flex-col justify-center col-span-2">
        <div className="text-xs sm:font-semibold sm:text-sm">
          ROUND {props.roundId.toString()}{" "}
          <span className="text-gradient gold">X{formattedPayout.toString()}</span>
        </div>
        <div className="text-[10px] sm:text-sm text-white/40">
          {bigNumberToFormattedTime(props.answerTime)}
        </div>
      </div>
      <div className="flex items-center justify-center col-span-3 gap-x-3">
        <img
          className="sm:h-[25px] lg:mr-0 md:mr-2 mr-1"
          src={require("../../assets/icon/btc.png")}
          alt="BTC"
        />
        <div className="text-lg font-bold sm:text-3xl">${answerDigits}.</div>
        <div className="flex items-center h-full px-2 mx-1 border rounded-lg md:mx-2 lg:mx-0 border-lightGray">
          <span className="text-lg font-bold tracking-widest sm:text-3xl text-gradient gold">
            {props.answer.toString().padStart(2, "0").slice(0, props.digits)}
          </span>
        </div>
      </div>
    </div>
  );
};
