import { AvatarComponent } from "@rainbow-me/rainbowkit";
import { useEffect, useRef } from "react";
import blockies from "./blockies";

export const EthereumAvatar: AvatarComponent = ({ address, size }) => {
  const ref = useRef<HTMLDivElement>(null);
  const icon = blockies.create({
    size,
    scale: 8,
    seed: address,
  });

  useEffect(() => {
    ref.current?.appendChild(icon);
  }, [ref, icon]);

  return <div ref={ref}></div>;
};
