import { useState } from "react";
import { useLocation } from "react-router-dom";
import { TabGroup } from "../common/TabGroup";
import { FinishedRound } from "./FinishedRound";
import { YourTickets } from "./YourTickets";

export function HistoryRound() {
  const [activeTabIndex, setActiveTab] = useState(0);
  const location = useLocation()

  return (
    <div className="px-4 pt-6 mt-6 overflow-hidden card-border-gradient-blue-to-yellow lg:px-10 lg:mt-12 md:mt-12 lg:mx-16">
      <TabGroup
        tabs={["Your Tickets", "Finished Round"]}
        className="flex items-center justify-center w-full mx-auto rounded-full bg-blue-gray lg:w-max md:w-max"
        buttonClassName="py-3 lg:w-48 md:w-36 w-full lg:text-lg text-md font-normal"
        onTabChange={setActiveTab}
      />
      {activeTabIndex === 0 && <YourTickets />}
      {activeTabIndex === 1 && <FinishedRound size="l" />}
    </div>
  );
}
