import clsx from "clsx";
import { useMemo } from "react";

export const Switch = (props: {
  title: [check: string, uncheck: string];
  checked: boolean;
  onCheckChanged: (checked: boolean) => void;
  className?: string;
}) => {
  const leftPercent = useMemo(() => (props.checked ? "0%" : "50%"), [props.checked]);
  return (
    <div
      className={clsx(
        "font-roboto-condensed right-4 absolute flex py-2 justify-around overflow-hidden border rounded-3xl bg-[#04132c]  border-white/5",
        props.className
      )}
    >
      <div
        style={{ left: leftPercent }}
        className="absolute inset-y-0 z-0 w-1/2 transition-all rounded-3xl bg-gradient-blue"
      ></div>
      <button
        onClick={() => props.onCheckChanged(true)}
        className="relative w-full text-sm font-semibold md:text-base"
      >
        {props.title[0]}
      </button>
      <button
        onClick={() => props.onCheckChanged(false)}
        className="relative w-full text-sm font-semibold md:text-base"
      >
        {props.title[1]}
      </button>
    </div>
  );
};
