import { HistoryRound } from "./HistoryRound";
import { TicketsInRound } from "./TicketsInRound";

export function WaitingForDraw() {
  return (
    <div className="bg-black">
      <div className="container py-6 mx-auto">
        <div className="flex items-center justify-center">
          <img
            className="pr-2 lg:w-14 md:w-9 w-7 lg:pr-4"
            src={require("../assets/icon/time.png")}
            alt=""
          />
          <p className="text-xl text-center uppercase font-audiowide lg:text-5xl md:text-3xl text-gradient gold">
            Waiting for Draw
          </p>
        </div>
        <p className="pt-3 pb-3 font-light text-center md:text-xl text-md md:pb-0">
          waiting untill the round draw and winner will be annocunce
        </p>
        <TicketsInRound />
        <HistoryRound />
      </div>
    </div>
  );
}
