import { FetchBalanceResult } from "@wagmi/core";
import { BigNumber, utils } from "ethers";
import { useMemo } from "react";

export const useFormattedBalance = (balance?: FetchBalanceResult, decimalPlaces: number = 2) => {
  return useMemo(() => {
    const balanceString = utils.formatUnits(balance?.value ?? BigNumber.from(0), balance?.decimals);
    return Number(balanceString).toLocaleString(undefined, {
      maximumFractionDigits: decimalPlaces,
      minimumFractionDigits: decimalPlaces,
    });
  }, [balance]);
};
