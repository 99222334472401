import { BigNumber } from "ethers";
import { useEffect, useState } from "react";
import { useContractEvent } from "wagmi";
import { address } from "../../../config";
import MultiplierBox from "../../../contracts/MultiplierBox";

export const useMultiplierBoxStartRoundEvent = () => {
  const [roundId, setRoundId] = useState<BigNumber>();

  useContractEvent({
    addressOrName: address.BB_MAX_ADDRESS,
    contractInterface: MultiplierBox,
    eventName: "StartRound",
    listener(node) {
      const [roundId] = node;
      setRoundId(roundId);
    },
  });

  return roundId;
};
