import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { useNotificationContext } from "../common/NotificationProvider";
import { NumPad } from "../common/NumPad";
import { RoomDigit, config } from "../config";
import { useNumberReachMaxPerTx } from "../hooks/useNumberReachMaxPerTx";
import { useNumberStore } from "../store/number-store";
import { PopularNumber } from "./PopularNumber";
import { Random } from "./Random";
import { RoundTicketInfo } from "./RoundTicketInfo";
import { SelectedNumber } from "./SelectedNumber";
import { SwipeDigit } from "./SwipeDigit";

type StringNumber = number | string | undefined;

export function AddNumber() {
  const numberStore = useNumberStore.getState();
  const { room = "" } = useParams();
  const { openConnectModal } = useConnectModal();
  const { isConnected } = useAccount();
  const { pushNotification } = useNotificationContext();
  const maxNumberPerTx = config.maxNumberPerTx(room);
  const reachMaxPerTx = useNumberReachMaxPerTx(maxNumberPerTx);

  const digit = Number(room) as RoomDigit;
  const digitArray = useMemo(() => [...Array(digit)], [digit]);

  const [numbers, setNumbers] = useState<StringNumber[]>(digitArray);
  const [isSwipe, setIsSwipe] = useState(false);

  const enableSwipeButton =
    !numbers.some((number) => number === undefined || number === "") && !reachMaxPerTx;

  const handleInputs = (e: any, index: number) => {
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    const { value } = e.target;
    var numberRegex = /^\d+$/;

    if (!numberRegex.test(value) && value !== "") {
      return;
    }

    let newNumbers = [...numbers];
    newNumbers[index] = value;
    setNumbers(newNumbers);

    if (value !== "") {
      const nextInput = e.target.parentElement?.parentElement?.children[index + 1]
        ?.children[0] as HTMLInputElement;

      if (nextInput) {
        nextInput?.focus();
      } else {
        e.target.blur();
      }
    }
  };

  const activeNumberIndex = useMemo(() => {
    return numbers.findIndex((number) => number === undefined || number === "");
  }, [numbers]);

  const deleteNumberIndex = useMemo(() => {
    for (let index = numbers.length - 1; index >= 0; index--) {
      if (numbers[index] !== undefined && numbers[index] !== "") {
        return index;
      }
    }
    return 0;
  }, [numbers]);

  function numberInput(number: number) {
    if (!isConnected) {
      openConnectModal?.();
      return;
    }

    if (activeNumberIndex === -1) return;

    let newNumbers = [...numbers];
    newNumbers[activeNumberIndex] = number.toString();
    setNumbers(newNumbers);
  }

  function numberDelete() {
    let newNumbers = [...numbers];
    delete newNumbers[deleteNumberIndex];
    setNumbers(newNumbers);
    setIsSwipe(false);
  }

  function addNumberToStore() {
    const number = Number(numbers.join(""));
    numberStore.addNumber(number, 1, config.maxNumberPerTx(room));
    setNumbers(digitArray);

    pushNotification({
      type: "add",
      number: numbers.join(""),
      key: Math.random().toString(),
    });
  }

  useEffect(() => {
    const currentNumber = Number(numbers.join(""));
    numberStore.setCurrentNumber(currentNumber);
  }, [numbers, numberStore]);

  return (
    <div className="bg-black">
      <div className="container mx-auto lg:pt-2 lg:pb-10">
        <p className="pt-6 text-5xl text-center font-audiowide text-gradient gold">ADD NUMBER</p>
        <p className="pt-2 font-light text-center">
          Typing number you want or select our many options to play
        </p>

        <div className="grid grid-cols-2 gap-2 mt-6 lg:grid-cols-3 lg:gap-5">
          <PopularNumber />
          <Random />
          <SwipeDigit
            enableSwipeButton={enableSwipeButton}
            toggleIsSwipe={() => setIsSwipe(!isSwipe)}
          />
        </div>

        <div className="flex flex-col mt-5 lg:flex-row lg:items-start">
          <NumPad
            digitArray={digitArray}
            numbers={numbers}
            handleInputs={handleInputs}
            numberInput={numberInput}
            numberDelete={numberDelete}
            isSwipe={isSwipe}
            onAddNumberClick={addNumberToStore}
            onSwiped={() => setIsSwipe(false)}
          />

          <RoundTicketInfo digit={room} />
        </div>
      </div>

      <div className="lg:container lg:mx-auto">
        <SelectedNumber />
      </div>
    </div>
  );
}
