import { config } from "./../config";
import { useCallback } from "react";

export const useBillionboxNumberRemaining = (numberSize: Record<number, number>) => {
  return useCallback(
    (number: number, size: number) => {
      const currentSize = numberSize[number] ?? 0;
      return config.maxSizePerNumber - currentSize - size;
    },
    [numberSize]
  );
};
