import { useMemo } from "react";
import { Asset } from "../../config";
import { useChainlinkPrice } from "./useChainlinkPrice";

export const useChainlinkPriceCalculate = (asset: Asset, amount: number, enabled: boolean = true) => {
  const { latest } = useChainlinkPrice(asset, {
    refetchInterval: 5000,
    enabled: enabled,
  });

  const calculatedAmount = useMemo(() => {
    return amount * (latest?.parsedAnswer ?? 0);
  }, [latest]);
  return calculatedAmount;
};
