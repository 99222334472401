import { Button } from "../common/Button";
import { Modal } from "../common/Modal";
import { useMaxPlayinfoTabStore } from "../store/max-playinfo-tab-store";

type Prop = {
  isOpen: boolean;
  roundId: number;
  price: number;
  answer: number;
  onCloseModal: () => void;
};

const MY_PLAY_TAB = 2;

export const ModalWinningNumber = ({ isOpen, roundId, price, answer, onCloseModal }: Prop) => {
  const { setActiveTab } = useMaxPlayinfoTabStore.getState();

  const handleClickScroll = () => {
    document.getElementById("play")?.scrollIntoView({
      behavior: "smooth",
      block: "start",
      inline: "nearest",
    });
    onCloseModal();
    setActiveTab(MY_PLAY_TAB);
  };

  return (
    <Modal active={isOpen} onDismiss={onCloseModal}>
      <div className="modal-content bg-blue-border-gold lg:p-5 p-8 max-w-[25rem] rounded-3xl flex flex-col items-center justify-center relative overflow-visible">
        <img
          className="absolute w-16 -top-8"
          src={require("../assets/multiplier-box/trophy-circle.png")}
          alt=""
        />
        <button onClick={onCloseModal}>
          <img
            src={require("../assets/icon/close.png")}
            alt=""
            className="absolute w-6 h-6 top-3 right-3"
          />
        </button>
        <p className="mt-6 text-2xl font-medium text-center uppercase">ROUND {roundId}</p>
        <p className="mt-1 text-3xl font-bold text-center uppercase text-gradient gold">
          WINING NUMBER IS
        </p>

        <div className="relative flex flex-col items-center justify-center p-20 -my-1">
          <img
            className="absolute inset-0"
            src={require("../assets/multiplier-box/glow-aura.webp")}
            alt=""
          />
          <div className="absolute flex items-center top-7">
            <img className="w-8" src={require("../assets/icon/btc.png")} alt="" />
            <p className="ml-2 text-3xl font-semibold text-center">
              $
              {(price / 100).toLocaleString("en-US", {
                maximumFractionDigits: 2,
                minimumFractionDigits: 2,
              })}
            </p>
          </div>

          <div className="relative w-24 flex justify-center items-center rounded-full aspect-square bg-gradient-gold-max border-[#c5a44e] border-2">
            <span className="text-4xl font-bold tracking-[0.3em] ml-[0.3em]">
              {answer.toString().padStart(2, "0")}
            </span>
          </div>
        </div>

        <Button
          className="relative px-5 mb-3 -mt-10 text-center uppercase"
          preset="goldMax"
          buttonSize="md"
          onClick={handleClickScroll}
        >
          Check your play
        </Button>
      </div>
    </Modal>
  );
};
