import { Tab } from "./../prediction/SelectAsset";
import { Asset } from "./../config";

const colors = {
  btc: {
    text: "text-btc",
    border: "border-btc",
    bg: "bg-btc",
  },
  eth: {
    text: "text-eth",
    border: "border-eth",
    bg: "bg-eth",
  },
  matic: {
    text: "text-matic",
    border: "border-matic",
    bg: "bg-matic",
  },
};

export const useAssetColor = (asset: Asset | Tab) => {
  return colors[asset.toLowerCase() as Asset];
};
