import { BigNumberish } from "ethers";
import { useMemo } from "react";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";

export const usePayoutRate = (roundId?: BigNumberish, game?: string) => {
  const { data } = useMultiplierBoxRead({
    functionName: "getPayoutRate",
    args: [roundId, game ?? ""],
    enabled: !!roundId && !!game,
  });

  const formattedPayoutRate = useMemo(() => (data?.toNumber() ?? 100) / 100, [data]);
  return { rate: data, formattedRate: formattedPayoutRate };
};
