import { useMemo } from "react";
import useSWR from "swr";
import { JackpotAsset, config } from "../config";
import { fetcher } from "./useRequest";

const symbols = {
  btc: "BTCUSDT",
  eth: "ETHUSDT",
  bnb: "BNBUSDT",
};

export const useBinancePrice = (asset: JackpotAsset = "btc") => {
  const symbol = useMemo(() => symbols[asset], [asset]);
  const { data } = useSWR<{ price: string }, Error>(
    `${config.binancePriceUrl}?symbol=${symbol}`,
    fetcher,
    {
      refreshInterval: config.binancePriceRefetchInterval,
    }
  );

  const priceNumber = useMemo(() => {
    if (data?.price) {
      return Number(data.price);
    }
  }, [data]);

  return priceNumber;
};
