import { BigNumberish } from "ethers";
import { useCallback, useMemo } from "react";
import { digits } from "../../config";
import { Ticket } from "./usePortionBoxRead";
import { usePortionBoxWrite } from "./usePortionBoxWrite";

export const useClaimReward = (winTickets: Ticket[]) => {
  const winTicketsByRoom = useMemo(() => {
    return winTickets.reduce((prev, cur) => {
      if (cur.claimed) return prev;

      const room = cur.room.toString();
      const winIds = prev.get(room) ?? [];
      prev.set(room, [...winIds, cur.id]);

      return prev;
    }, new Map<string, BigNumberish[]>());
  }, [winTickets]);

  // const { writeAsync: claim1 } = usePortionBoxWrite(1, "claim", [winTicketsByRoom.get("1") ?? []]);
  // const { writeAsync: claim2 } = usePortionBoxWrite(2, "claim", [winTicketsByRoom.get("2") ?? []]);
  // const { writeAsync: claim3 } = usePortionBoxWrite(3, "claim", [winTicketsByRoom.get("3") ?? []]);
  // const { writeAsync: claim4 } = usePortionBoxWrite(4, "claim", [winTicketsByRoom.get("4") ?? []]);
  const { writeAsync: claim5 } = usePortionBoxWrite(5, "claim", [winTicketsByRoom.get("5") ?? []]);

  const claim = useCallback(async () => {
    // const claimFns = [claim1, claim2, claim3, claim4, claim5];
    const claimFns = [claim5];

    for (const [index, room] of digits.entries()) {
      if (winTicketsByRoom.has(room.toString())) {
        const result = await claimFns[index]?.();
        await result.wait();
      }
    }
  }, [claim5, winTicketsByRoom]);
  // }, [claim1, claim2, claim3, claim4, claim5, winTicketsByRoom]);

  return claim;
};
