import clsx from "clsx";
import { utils } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { useAccount, useBalance } from "wagmi";
import successWhite from "../assets/success-white.svg";
import SuccessIcon from "../assets/success.svg";
import { Button } from "../common/Button";
import { Loading } from "../common/Loading";
import { Modal } from "../common/Modal";
import { useNotificationContext } from "../common/NotificationProvider";
import { address, config, roomAddress } from "../config";
import { usePortionBoxWrite } from "../hooks/contracts/usePortionBoxWrite";
import { TokenPathKey, useTicketPrice } from "../hooks/contracts/useTicketPrice";
import { useTokenAllowance } from "../hooks/contracts/useTokenAllowance";
import { useTokenWrite } from "../hooks/contracts/useTokenWrite";
import { useFormattedBigNumber } from "../hooks/useFormattedBigNumber";
import { useNumberStore } from "../store/number-store";

type BuyNumberProps = {
  isOpen: boolean;
  closeModal: any;
  gold?: boolean
};

type Token = {
  icon: any;
  name: TokenPathKey;
  address?: string;
  gasToken?: boolean;
  decimals: number;
};

let tokens: Token[] = [
  {
    icon: require("../assets/icon/usdt.png"),
    name: "usdt",
    address: address.USDT_ADDRESS,
    decimals: 6,
  },
  {
    icon: require("../assets/icon/usdc.png"),
    name: "usdc",
    address: address.USDC_ADDRESS,
    decimals: 6,
  },
  {
    icon: require("../assets/icon/dai.png"),
    name: "dai",
    address: address.DAI_ADDRESS,
    decimals: 18,
  },
  {
    icon: require("../assets/icon/matic.png"),
    name: "matic",
    gasToken: true,
    decimals: 18,
  },
  {
    icon: require("../assets/icon/dbusd.png"),
    name: "dbusd",
    address: address.DBUSD_ADDRESS,
    decimals: 6,
  },
];

export function BuyNumberModal({ isOpen, closeModal, gold }: BuyNumberProps) {
  const account = useAccount();
  const { numbers, runFetchCallback, clearNumbers } = useNumberStore();
  const { room = "" } = useParams();
  const { pushNotification } = useNotificationContext();
  const roomDigit = Number(room);

  const [selectedToken, setSelectedToken] = useState(tokens[0]);
  const [isLoading, setIsLoading] = useState(false);

  const { data: balanceResult } = useBalance({
    addressOrName: account.address,
    token: selectedToken.address,
    watch: true,
  });

  const formattedSelectedTokenBalance = useFormattedBigNumber(balanceResult?.value, {
    max: 4,
    unit: selectedToken.decimals,
  });

  const totalSize = useMemo(() => {
    return numbers.flatMap((number) => number.size).reduce((prev, cur) => prev + cur, 0);
  }, [numbers]);

  const { price } = useTicketPrice({
    token: selectedToken.name,
    amount: totalSize,
    ticketPrice: config.ticketPrice(roomDigit),
  });

  const formattedPrice = useFormattedBigNumber(price, { max: 4, unit: selectedToken.decimals });

  const contractAddress = roomAddress[room];
  const { allowance, refetch: refetchAllowance } = useTokenAllowance(
    selectedToken.address ?? "",
    account.address,
    contractAddress
  );

  const { writeAsync: executeApprove } = useTokenWrite(
    selectedToken.address ?? "",
    !selectedToken.gasToken,
    "approve",
    [contractAddress, utils.parseEther("99999999999999")]
  );

  const { writeAsync: executeBuy } = usePortionBoxWrite(room, "buy", [
    numbers.map((n) => n.number),
    numbers.map((n) => n.size),
  ]);

  const { writeAsync: executeBuyWithToken } = usePortionBoxWrite(room, "buyWithToken", [
    selectedToken.address ?? "",
    price,
    numbers.map((n) => n.number),
    numbers.map((n) => n.size),
  ]);

  const { writeAsync: executeBuyWithMatic } = usePortionBoxWrite(room, "buyWithETH", [
    price,
    numbers.map((n) => n.number),
    numbers.map((n) => n.size),
  ]);

  const approve = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await executeApprove?.();
      if (result) {
        pushNotification({
          key: result.hash,
          type: "sendTx",
          txhash: result.hash,
        });
      }
      await result?.wait(3);
      refetchAllowance();
    } finally {
      setIsLoading(false);
    }
  }, [executeApprove, refetchAllowance]);

  const buy = useCallback(async () => {
    setIsLoading(true);
    let buyResult: any;
    try {
      switch (selectedToken.name) {
        case "usdt":
          buyResult = await executeBuy();
          break;
        case "usdc":
        case "dai":
        case "dbusd":
          buyResult = await executeBuyWithToken();
          break;
        case "matic":
          buyResult = await executeBuyWithMatic();
      }

      setIsLoading(false);

      pushNotification({
        key: buyResult.hash,
        type: "sendTx",
        txhash: buyResult.hash,
      });

      clearNumbers();
      closeModal();

      await buyResult?.wait(3);

      runFetchCallback();

      pushNotification({
        key: buyResult.hash,
        type: "success",
        text: "Your selected numbers have been bought successfully!",
      });
    } catch (error) {
      pushNotification({
        key: Math.random().toString(),
        type: "failed",
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    executeBuy,
    selectedToken,
    closeModal,
    clearNumbers,
    runFetchCallback,
    executeBuyWithMatic,
    executeBuyWithToken,
    pushNotification,
  ]);

  return (
    <Modal active={isOpen} onDismiss={closeModal}>
      <div className="modal-card">
        <header className={clsx("justify-between border-b-0 modal-card-head ", gold ? "bg-gradient-gold-max" : "bg-gradient-blue")}>
          <p className="text-xl text-white uppercase md:text-3xl modal-card-title font-audiowide">
            BUY number
          </p>
          <button onClick={closeModal}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className="w-5 h-5 lg:w-7 lg:h-7"
            />
          </button>
        </header>
        <section className="p-0 divide-y modal-card-body bg-dark-blue-gray divide-dashed">
          <div className="relative">
            {isLoading && <Loading />}

            <div className="grid grid-cols-4 gap-1 p-3 md:gap-4 md:px-9 md:pt-6 md:pb-2">
              {numbers.map((number, index) => {
                return (
                  <div key={index} className="py-3 text-center card-border-brown-bg-blue">
                    <p>
                      <span className="text-base font-bold tracking-widest md:text-xl text-gradient gold">
                        {number.number.toString().padStart(roomDigit, "0")}
                      </span>
                      <span className="ml-3 text-sm font-medium md:text-lg">X{number.size}</span>
                    </p>
                  </div>
                );
              })}
            </div>
            <div className="p-3 md:p-9">
              <p className="mb-5 text-xl font-medium text-gradient gold">Payment</p>

              <div className="grid grid-cols-5 gap-3 lg:gap-6 md:gap-3">
                {tokens.map((token) => {
                  return (
                    <button
                      key={token.name}
                      className="relative flex flex-col items-center py-4 overflow-hidden card-border-brown-bg-blue"
                      onClick={() => setSelectedToken(token)}
                    >
                      {selectedToken.name === token.name && (
                        <div className="absolute inset-0 flex items-center justify-center">
                          <div className={clsx("absolute inset-0 z-0 opacity-95", gold ? "bg-gradient-gold-max" : "bg-gold")}></div>
                          <img src={ gold? successWhite : SuccessIcon} className="z-10" alt="Selected icon" />
                        </div>
                      )}
                      <img className="mb-2 w-7 lg:w-9" src={token.icon} alt="" />
                      <p className="text-base font-semibold">{token.name.toUpperCase()}</p>
                    </button>
                  );
                })}
              </div>

              <div className="flex justify-between mt-8">
                <p className="text-sm font-bold lg:text-base">Total ({numbers.length} numbers)</p>
                <p className="text-sm font-normal lg:text-base text-brownish-grey">
                  Balance {formattedSelectedTokenBalance} {selectedToken.name.toUpperCase()}
                </p>
              </div>
              <div className="flex items-center justify-between px-4 py-2 mt-1 bg-black/40 rounded-2xl lg:mt-4">
                <p className="text-lg font-bold text-brownish-grey">You Pay</p>
                <div className="flex items-center">
                  <p className="mr-5 text-2xl font-bold lg:text-3xl">{formattedPrice}</p>
                  <div className="flex flex-col items-center w-[50px]">
                    <img className="w-6 mb-1" src={selectedToken.icon} alt="" />
                    <p className="text-xs font-semibold lg:text-lg">
                      {selectedToken.name.toUpperCase()}
                    </p>
                  </div>
                </div>
              </div>

              <div className="flex flex-col justify-between mt-8 md:flex-row">
                <div className="flex items-center">
                  <img
                    className="w-8 mr-3"
                    src={require("../assets/icon/question@2x.png")}
                    alt=""
                  />
                  <div>
                    <p className="font-bold">Prize Condition</p>
                    <p className="text-xs font-light">1 Number ~${config.ticketPrice(roomDigit)}</p>
                  </div>
                </div>

                {!selectedToken.gasToken && (!allowance || allowance === 0) ? (
                  <Button
                    className="px-20 mt-5 uppercase lg:px-32 lg:mt-0"
                    preset={ gold ? "goldMax" : "gold"}
                    buttonSize="md"
                    onClick={approve}
                  >
                    APPROVE
                  </Button>
                ) : (
                  <Button
                    className="px-20 mt-5 uppercase lg:px-32 lg:mt-0"
                    preset={ gold ? "goldMax" : "gold"}
                    buttonSize="md"
                    onClick={buy}
                  >
                    CONFIRM
                  </Button>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
}
