import clsx from "clsx";
import { ComponentPropsWithoutRef } from "react";
import { LinkProps } from "react-router-dom";

const presets = {
  primary: "bg-gold text-black",
  "primary-ghost": "btn-border-gradient-gold",
  "primary-ghost-blue": "btn-border-gradient-gold-bg-blue",
  "primary-blue-yellow": "btn-border-gradient-blue-to-yellow-bg-black-to-blue",
  white: "bg-white text-black",
  gradient: "bg-gradient-blue",
  gold: "bg-gold text-black",
  goldMax: "bg-gradient-gold-max text-white",
  blue: "bg-dark-blue-gray border border-lightGray",
  orange: "bg-oddGradient text-white",
  disabled: "bg-[#a7a1a1] text-black",
  "silver-ghost": "btn-border-gradient-silver-bg-blue",
  opacity: "text-brownish-grey",
  "gold-max-solid": "btn-black-border-gold text-white",
};
const sizes = {
  xs: "text-xs py-[5px]",
  sm: "text-sm py-2",
  md: "text-md py-3",
  lg: "md:text-lg text-sm md:py-4 py-2",
  xl: "text-xl lg:text-2xl py-4",
};

type BaseButtonProps = {
  preset: keyof typeof presets;
  buttonSize?: keyof typeof sizes;
};

type ButtonProps = ComponentPropsWithoutRef<"button"> & BaseButtonProps;
type LinkButtonProps = LinkProps & ComponentPropsWithoutRef<"a"> & BaseButtonProps;

export function Button({
  children,
  className,
  preset,
  buttonSize,
  disabled,
  ...rest
}: ButtonProps) {
  const usedPreset = disabled ? presets.disabled : presets[preset];
  return (
    <button
      type="button"
      disabled={disabled}
      className={clsx(
        className,
        usedPreset,
        buttonSize ? sizes[buttonSize] : "",
        "rounded-full font-semibold"
      )}
      {...rest}
    >
      {children}
    </button>
  );
}

export function LinkButton({ children, className, preset, buttonSize, ...rest }: LinkButtonProps) {
  return (
    <a
      className={`text-center rounded-full font-semibold ${className} ${presets[preset]} ${
        buttonSize ? sizes[buttonSize] : ""
      }`}
      href={rest.to as string}
      {...rest}
    >
      {children}
    </a>
  );
}
