import { useCallback, useEffect, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNotificationContext } from "../common";
import { config } from "../config";
import { useBillionboxNumberRemaining } from "../hooks/useBillionboxNumberRemaining";
import { useNumberSize } from "../hooks/useNumberSize";
import { usePopularNumbers } from "../hooks/usePopularNumbers";
import { useRoundData } from "../hooks/useRoundData";
import { EmptyState } from "../portion-box/EmptyState";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";

export function PopularNumber() {
  const { room = "" } = useParams();
  const roomState = useSpecificRoomState(room);
  const { registerFetchCallbackWithDelay } = useNumberStore.getState();
  const { popularNumbers, refetch } = usePopularNumbers(room, roomState?.roundId);

  const popularNumberLength = useMemo(() => popularNumbers?.length ?? 0, [popularNumbers]);

  const popularNumberSlides = useMemo(() => {
    const slides: React.ReactNode[] = [];

    for (let index = 0; index < popularNumberLength / 2; index++) {
      const [first, second] = popularNumbers?.slice(index * 2, index * 2 + 2) ?? [];
      slides.push(
        <SwiperSlide key={index}>
          <div className="ml-3">
            <div className="flex mb-3">
              <PopularNumberItem number={first.number} size={first.size} />
            </div>
            <div className="flex">
              {second && <PopularNumberItem number={second.number} size={second.size} />}
            </div>
          </div>
        </SwiperSlide>
      );
    }

    return slides;
  }, [popularNumbers, popularNumberLength]);

  useEffect(() => {
    registerFetchCallbackWithDelay("popular-number", () => {
      refetch();
    });
  }, [registerFetchCallbackWithDelay, refetch]);

  return (
    <div className="col-span-2 pt-4 pb-2 overflow-hidden text-center lg:col-span-1 card-border-gradient-blue-to-yellow-bg-black-to-blue">
      <p className="mb-6 font-bold uppercase lg:mb-3">Popular Number</p>
      <Swiper slidesPerView="auto" spaceBetween={0}>
        {popularNumberSlides}
      </Swiper>
      <div className="grid overflow-x-auto grid-row-2 no-scrollbar">
        {popularNumberLength > 0 && <></>}
        {popularNumberLength === 0 && <EmptyState className="mt-2" />}
      </div>
    </div>
  );
}

const PopularNumberItem = ({ number, size }: { number: number; size: number }) => {
  const { addNumber } = useNumberStore.getState();
  const { room = "" } = useParams();
  const roomState = useSpecificRoomState(room);
  const { buyers } = useRoundData(room, roomState?.roundId);
  const numberSize = useNumberSize(buyers);
  const getNumberRemaining = useBillionboxNumberRemaining(numberSize);
  const isNumberOut = getNumberRemaining(Number(number), 1) >= 0;
  const { pushNotification } = useNotificationContext();

  const addPopularNumber = useCallback(() => {
    if (isNumberOut) {
      addNumber(number, 1, config.maxNumberPerTx(room));
      pushNotification({
        key: number.toString(),
        number: number.toString().padStart(Number(room), "0"),
        type: "add",
      });
    }
  }, [isNumberOut, number, addNumber, pushNotification, room]);

  return (
    <div className="card-popular-number py-[6px] w-28 relative">
      <div className="card-opacity">
        <p className="text-xl font-bold tracking-widest text-gradient gold">
          {number.toString().padStart(Number(room), "0")}
        </p>
        <p className="text-xs font-light">{size} Ticket</p>
      </div>
      <div
        className={`absolute inset-0 top-0 opacity-0 card-add flex justify-center items-center z-10 ${
          isNumberOut ? "cursor-pointer" : "cursor-not-allowed"
        }`}
        onClick={addPopularNumber}
      >
        <div className="text-xl font-bold text-black">{isNumberOut ? "ADD" : "LIMIT"}</div>
      </div>
    </div>
  );
};
