import { BigNumber } from "ethers";
import { useContractRead } from "wagmi";
import Prediction from "../../contracts/PredictionV2.json";
import { config, predictionAddr } from "../../config";
import { useMemo } from "react";

type ReadFns =
  | "claimable"
  | "getUserRounds"
  | "getUserRoundsLength"
  | "ledger"
  | "refundable"
  | "rounds"
  | "userRounds"
  | "currentEpoch";

// prettier-ignore
type ReadArgs<Fn> = 
  Fn extends "claimable" ? [string | BigNumber | number, string] :
  Fn extends "getUserRounds" ? [string, string | BigNumber | number, string | BigNumber | number] :
  Fn extends "getUserRoundsLength" ? string :
  Fn extends "ledger" ? [string | BigNumber | number, string] :
  Fn extends "refundable" ? [string | BigNumber | number, string] :
  Fn extends "rounds" ? string | BigNumber | number :
  Fn extends "userRounds" ? [string, string | BigNumber | number] :
  never;

// prettier-ignore
type PredictionV2ReadResult<Fn> = 
  Fn extends "claimable" ? boolean :
  Fn extends "getUserRounds" ? UserRound :
  Fn extends "getUserRoundsLength" ? BigNumber :
  Fn extends "ledger" ? Ledger :
  Fn extends "refundable" ? boolean :
  Fn extends "rounds" ? Rounds :
  Fn extends "userRounds" ? BigNumber :
  never;

export type UserRound = {
  value: BigNumber[];
  betInfo: {
    position: BigNumber;
    amount: BigNumber;
    claimed: boolean;
  }[];
  cursorLength: BigNumber;
};

export type Ledger = {
  position: BigNumber;
  amount: BigNumber;
  claimed: boolean;
};

export type Rounds = {
  epoch: BigNumber;
  startTimestamp: BigNumber;
  lockTimestamp: BigNumber;
  closeTimestamp: BigNumber;
  lockPrice: BigNumber;
  closePrice: BigNumber;
  lockOracleId: BigNumber;
  closeOracleId: BigNumber;
  totalAmount: BigNumber;
  bullAmount: BigNumber;
  bearAmount: BigNumber;
  rewardBaseCalAmount: BigNumber;
  rewardAmount: BigNumber;
  oracleCalled: boolean;
};

type FnObject<Fn extends ReadFns> = {
  functionName: Fn;
  enabled?: boolean;
  args?: ReadArgs<Fn>;
  watch?: boolean;
};

export function usePredictionV2Read<ReadFn extends ReadFns>({
  functionName,
  args,
  enabled = true,
  watch = false,
}: FnObject<ReadFn>) {
  const contractRead = useContractRead({
    args,
    watch,
    enabled,
    functionName,
    addressOrName: predictionAddr.MATIC,
    contractInterface: Prediction,
    staleTime: config.shortCacheTime,
    cacheTime: config.shortCacheTime,
  });

  const data: PredictionV2ReadResult<ReadFn> | undefined = useMemo(() => {
    if (!contractRead.data) return;

    if (functionName === "getUserRounds") {
      let userRounds = (contractRead.data as unknown) as PredictionV2ReadResult<"getUserRounds">;
      const newUserRounds = { ...userRounds };
      newUserRounds.value = contractRead.data[0];
      newUserRounds.betInfo = contractRead.data[1];
      newUserRounds.cursorLength = contractRead.data[2];

      return newUserRounds as PredictionV2ReadResult<ReadFn>;
    }

    return (contractRead.data as unknown) as PredictionV2ReadResult<ReadFn> | undefined;
  }, [contractRead.data, functionName]);

  return {
    contractRead,
    data,
  };
}
