import Popup from "reactjs-popup";
import InfoIcon from "../../../assets/icon/info-icon.svg";
import { useMultiplierBoxNumberStore } from "../../../store/multiplier-box-number-store";
import { Token } from "./TokenSelector";
import { useTokenTotalAmount } from "./hooks/useTokenTotalAmount";

type PlaySummaryProps = {
  estimatePrize: number;
  inputAmount: number;
  token: Token;
};

export const PlaySummary = ({ estimatePrize, inputAmount, token }: PlaySummaryProps) => {
  const { numbers, totalAmount } = useMultiplierBoxNumberStore(({ numbers, totalAmount }) => ({
    numbers,
    totalAmount,
  }));

  const tokenTotalAmount = useTokenTotalAmount(totalAmount(), token.gasToken);

  return (
    <div className="grid grid-cols-2 gap-2 px-4 py-3 mt-4 border rounded-lg border-white/10">
      <div className="text-sm text-white/30">Selected Number</div>
      <div className="text-sm font-bold text-right text-white">
        {numbers.length} number{numbers.length !== 1 && "s"}
      </div>
      <div className="text-sm text-white/30">
        Change
        <Popup
          trigger={<img alt="" className="inline-block w-3 ml-1" src={InfoIcon} />}
          on="hover"
          position="bottom center"
          mouseEnterDelay={0}
          contentStyle={{
            border: 0,
            backgroundColor: "#faf6a6",
            backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
          }}
          arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
        >
          <div className="px-[6px] py-1">
            <p className="text-xs text-black">
              Number of amount that will be change back to your wallet after of round down each
              number amount
            </p>
          </div>
        </Popup>
      </div>
      <div className="text-sm font-bold text-right">
        {(inputAmount - tokenTotalAmount).toLocaleString("en-US", { maximumFractionDigits: 6 })}
        <img src={token.icon} alt="" className="inline-block w-3 ml-1" />
      </div>
      <div className="text-sm text-white/30">Estimate Prize</div>
      <div className="text-sm font-bold text-right text-gradient gold">
        {estimatePrize.toLocaleString("en-US", { maximumFractionDigits: 6 })}
        <img
          src={require("../../../assets/icon/usdt.png")}
          alt=""
          className="inline-block w-3 ml-1"
        />
      </div>
      <div className="text-sm text-white/30">Total</div>
      <div className="text-sm font-bold text-right text-gradient gold">
        {tokenTotalAmount.toLocaleString("en-US", { maximumFractionDigits: 6 })}
        <img src={token.icon} alt="" className="inline-block w-3 ml-1" />
      </div>
    </div>
  );
};
