import { AnimatePresence } from "framer-motion";
import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from "react";
import { TransactionNotification, TransactionNotificationProps } from "./TransactionNotification";

type Notification = TransactionNotificationProps & { key: string };

export type NotificationState = {
  notifications: Notification[];
  pushNotification: (notification: Notification) => void;
};

const NotificationContext = createContext<NotificationState>({
  notifications: [],
  pushNotification: (notification: Notification) => {},
});

export const NotificationProvider = ({
  gold = false,
  children,
}: {
  gold?: boolean;
  children: ReactNode;
}) => {
  const [notifications, setNotifications] = useState<Notification[]>([]);

  const addNotification = useCallback(
    (notification: Notification) => {
      const newNotification = [notification];
      setNotifications(newNotification);
    },
    [notifications]
  );

  useEffect(() => {
    if (notifications.length === 0) return;
    const timeout = setTimeout(() => setNotifications([]), 3000);
    return () => clearTimeout(timeout);
  }, [notifications]);

  return (
    <NotificationContext.Provider
      value={{
        notifications: notifications,
        pushNotification: addNotification,
      }}
    >
      {children}
      <AnimatePresence>
        {notifications.map((notification) => (
          <TransactionNotification gold={gold} {...notification} />
        ))}
      </AnimatePresence>
    </NotificationContext.Provider>
  );
};

export const useNotificationContext = () => useContext(NotificationContext);
