import clsx from "clsx";

export function TableBBMax() {
  const plays = [
    {number: 59, play: 1000.98, round: 4328, time: "Jul 12, 2022, 7:00 PM", prize: 60000, status: "Waiting"},
    {number: 99, play: 1000, round: 4328, time: "Jul 12, 2022, 7:00 PM", prize: 60000, status: "WON"},
    {number: 67, play: 1900, round: 4328, time: "Jul 12, 2022, 7:00 PM", prize: 60000, status: "LOSE"},
    {number: 89, play: 1000, round: 4328, time: "Jul 12, 2022, 7:00 PM", prize: 60000, status: "LOSE"},
    {number: 22, play: 1000, round: 4328, time: "Jul 12, 2022, 7:00 PM", prize: 60000, status: "LOSE"},
  ];

  const lose = "LOSE";
  const waiting = "Waiting";

  return (
    <div className="col-span-5 mt-12 overflow-hidden border lg:col-span-3 rounded-3xl border-primary lg:mt-10">
      <div className="py-4 text-center bg-gradient-blue">
        <p className="text-xl text-center uppercase font-audiowide">BB Max</p>
      </div>
      <div className="px-2 pt-1 bg-black md:px-9">
        <div className="grid grid-cols-12 text-[#a7a1a1] lg:text-base md:text-sm text-xs font-light text-left mt-6 lg:mb-2 mb-0 ">
          <p className="col-span-2 pl-3 pr-2 lg:pl-8 md:pl-5 lg:pr-8 md:pr-5">
            Number
          </p>
          <p className="col-span-4 px-1 lg:col-span-2">Round</p>
          <p className="col-span-3 px-1 lg:col-span-2 lg:px-8 md:px-5">Play</p>
          <p className="hidden col-span-3 px-1 pl-8 lg:block">Estimate Prize</p>
          <p className="col-span-2 pl-4">Result</p>
          <p className="col-span-1"></p>
        </div>

        <div className="h-[400px] overflow-y-scroll no-scrollbar pb-2">
          {plays.map((play) => {
            return (
              <div className="grid items-center grid-cols-12 my-2 text-xs text-left sm:text-sm bg-blue-gray rounded-xl lg:text-2xl md:text-xl lg:my-3">
                <p className="col-span-2 tracking-[0.5em] py-3 pl-4 pr-2 space-x-4 font-bold lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 lg:text-2xl text-xl text-gradient gold">
                  {play.number}
                </p>
                <div className="col-span-4 px-1 py-3 lg:col-span-2">
                  <p className="text-xs font-normal lg:text-sm">Round {play.round}</p>
                  <p className="text-[10px] font-light lg:text-sm">{play.time}</p>
                </div>
                <p className="col-span-3 px-1 py-3 font-bold lg:col-span-2 lg:px-8 md:px-5">
                  ${play.play.toLocaleString()}
                </p>
                <p className="hidden col-span-3 px-1 py-3 pl-8 font-bold lg:block">
                  ~${play.prize.toLocaleString()}
                </p>
                <p
                  className={clsx(
                    "col-span-2 p-1 pl-4 py-3 font-bold",
                    play.status === lose ? "text-brownish-grey" : "text-gradient gold"
                  )}
                >
                  {play.status}
                </p>
                <div className="flex items-center col-span-1 space-x-1">
                  {play.status !== waiting && (
                    <>
                      <p className="hidden text-sm font-light lg:block md:text-base text-gradient gold">view</p>
                      <img
                        className="w-3 md:w-4 lg:w-3"
                        src={require("../assets/icon/left-gold2.png")}
                        alt=""
                      />
                    </>
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
