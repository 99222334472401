import { predictionProAddr } from "../../config";
import { PredictionGame } from "../../context/PredictionGameContext";
import { usePredictionV3Read } from "../../hooks/contracts/usePredictionV3Read";

export function useMultipleRounds(roundIds: number[], game: PredictionGame) {
  return usePredictionV3Read({
    functionName: "getRounds",
    address: predictionProAddr[game].BTC,
    args: [roundIds],
    watch: false
  })
}