import clsx from "clsx";

type InputPercentSliderProps = {
  value: number;
  onChange: (newValue: number) => void;
  className?: string;
  size?: "sm" | "md";
};

const positionMultiplier = {
  sm: 0.88,
  md: 0.92,
};

export const InputPercentSlider = ({
  value,
  onChange,
  className,
  size = "sm",
}: InputPercentSliderProps) => {
  return (
    <div className="relative">
      <input
        className={clsx(
          className,
          "h-5 bg-[#080d1c] w-full",
          size === "sm" ? "slider-sm" : "slider"
        )}
        type="range"
        min="0"
        max="100"
        onChange={({ target }) => {
          const percent = Number(target.value);
          onChange(percent);
        }}
        style={{ backgroundSize: `${value}%` }}
        value={value}
      />
      <div
        className="absolute mt-2 ml-2 text-xs"
        style={{ left: `${value * positionMultiplier[size]}%` }}
      >
        {Math.floor(value)}%
      </div>
    </div>
  );
};
