import clsx from "clsx";
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { useAssetColor } from "../hooks/useAssetColor";
import { usePrediction, useRounds } from "../hooks/usePrediction";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { useCountdown } from "../hooks/useTime";
import { useWalletGuard } from "../hooks/useWalletGuard";
import { CardCalculating } from "./CardCalculating";
import { IndexTab } from "./LetsPlay";
import { SetPositionModal } from "./SetPositionModal";

type CardPlayType = {
  pro?: boolean
  currentEpoch: number;
  onModalVisibilityChange?: (isVisible: boolean) => void;
} & IndexTab;

export type PlayType = "UP" | "DOWN";

export const CardPlay = forwardRef<HTMLDivElement, CardPlayType>(
  ({ index, keyTab, currentEpoch, onModalVisibilityChange }, ref) => {
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [playType, setPlayType] = useState<PlayType>("UP");
    const [isSuccess, setIsSuccess] = useState(false);
    const color = useAssetColor(keyTab);

    const { address } = useAccount();
    // end round is n
    const epoch = useMemo(() => (currentEpoch > 0 ? currentEpoch : 1), [currentEpoch]);

    const { latestPrice, prizePool, status, payoutUp, payoutDown } = usePrediction({
      activeTabIndex: index,
      currentEpoch: epoch,
      keyTab,
      address,
    });
    const { lockTimestamp } = useRounds({ currentEpoch, keyTab, activeTabIndex: index });
    const { countDown } = useCountdown(lockTimestamp);

    useEffect(() => {
      setIsSuccess(false);
    }, [currentEpoch]);

    let colorCard = "";
    let invisibleEnteredDown = "invisible";
    let invisibleEnteredUp = "invisible";
    if ((playType === "UP" && isSuccess === true) || status === "UP") {
      invisibleEnteredDown = "visible";
      invisibleEnteredUp = "invisible";
    }

    if ((playType === "DOWN" && isSuccess === true) || status === "DOWN") {
      invisibleEnteredUp = "visible";
      invisibleEnteredDown = "invisible";
    }

    if (index === 0) {
      colorCard = "card-orange";
    } else if (index === 1) {
      colorCard = "card-sky";
    } else {
      colorCard = "card-violet";
    }

    const openSetUpPositionModal = useWalletGuard(() => {
      setPlayType("UP");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    const openSetDownPositionModal = useWalletGuard(() => {
      setPlayType("DOWN");
      setModalIsOpen(true);
      onModalVisibilityChange?.(true);
    });

    function closeModal() {
      setModalIsOpen(false);
      onModalVisibilityChange?.(false);
    }

    const Entered = useCallback(() => {
      return (
        <>
          <div className="pb-5 bg-red-900 pt-9 bg-opacity-40 rounded-bl-2xl">
            <p className="text-2xl font-bold text-[#ff1010] text-center">DOWN</p>
          </div>
          <div className="pb-5 pt-9 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <p className="text-2xl font-bold text-[#46b900] text-center">UP</p>
          </div>
        </>
      );
    }, []);

    const Play = useCallback(() => {
      return (
        <>
          <div className="pt-8 pb-4 bg-red-900 bg-opacity-40 rounded-bl-2xl">
            <button onClick={openSetDownPositionModal}>
              <img className="px-4 play-btn" src={require("../assets/going-down1.png")} alt="" />
            </button>
          </div>
          <div className="pt-8 pb-4 bg-lime-700 bg-opacity-40 rounded-br-2xl">
            <button onClick={openSetUpPositionModal}>
              <img className="px-4 play-btn" src={require("../assets/going-up1.png")} alt="" />
            </button>
          </div>
        </>
      );
    }, [openSetUpPositionModal, openSetDownPositionModal]);

    const rewardCurrency = usePredictionRewardCurrency(keyTab.toString());

    if (countDown === "00:00") {
      return (
        <div ref={ref} id="card-play">
          <CardCalculating currentEpoch={epoch} />
        </div>
      );
    }

    return (
      <div ref={ref} id="card-play">
        <div className={`${colorCard} px-1 pb-1 w-card h-card lg:mr-8 mr-4 relative`}>
          <div className="flex items-center justify-center py-[5px] space-x-1 text-sm">
            <img className="w-3" src={require("../assets/icon/arrow-head-right.png")} alt="" />
            <span className="font-semibold">PLAY</span>
            <span className="text-[#0e1a39] font-semibold">#{epoch}</span>
          </div>
          <div className="pt-3 bg-gradient-black-to-blue-r rounded-2xl">
            <div className="flex justify-between px-4 pb-4 text-sm font-bold">
              <p>Prize pool</p>
              <p>
                {prizePool} {rewardCurrency}
              </p>
            </div>
            <div className="flex flex-col items-center justify-center py-3 mx-4 card-border-gradient-blue-bg-black-to-blue">
              <div className="flex items-center">
                <img className="w-3 mr-1" src={require("../assets/icon/shield.png")} alt="" />
                <p className="text-sm text-gradient gold">Current price</p>
              </div>
              <p className="text-xl font-bold">${latestPrice}</p>
            </div>
            <div className="flex items-center justify-center mt-3">
              <img className="w-3 mr-1" src={require("../assets/icon/clock.png")} alt="" />
              <p className="mr-3 text-sm">Start in</p>
              <p className="text-xl font-bold text-gradient gold">{countDown}</p>
            </div>
            <div className="mt-3 mb-1 text-sm text-center">
              <span>Will {keyTab} go </span>
              <span className="font-bold">Up </span>
              <span>or </span>
              <span className="font-bold">Down </span>
              <p className="font-bold">in next 10 min ?</p>
            </div>
            <div className="relative grid grid-cols-2 top-5">
              <div
                className={clsx(
                  "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                  color.border
                )}
              >
                Payout {payoutDown}x
              </div>
              <div
                className={clsx(
                  "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                  color.border
                )}
              >
                Payout {payoutUp}x
              </div>
            </div>
            <div className={clsx("h-[2px]", color.bg)}></div>

            <div className="grid grid-cols-2">
              {isSuccess || status !== "NO_POSITION" ? <Entered /> : <Play />}
            </div>
          </div>

          <div className="relative flex justify-around -top-3">
            <img
              className={`w-20 mx-6 ${invisibleEnteredUp}`}
              src={require("../assets/entered.png")}
              alt=""
            />
            <img
              className={`w-20 mx-6 ${invisibleEnteredDown}`}
              src={require("../assets/entered.png")}
              alt=""
            />
          </div>
        </div>
        {modalIsOpen && (
          <SetPositionModal
            currentEpoch={currentEpoch}
            keyTab={keyTab}
            isOpen={modalIsOpen}
            onCloseModal={closeModal}
            playType={playType}
            onSuccessChanged={setIsSuccess}
          />
        )}
      </div>
    );
  }
);
