import { roomAddress, RoomDigit } from "../config";
import { useRequest } from "./useRequest";

export type Response = {
  total: number;
};

export const useBillionboxPrizeDistribute = (digit: RoomDigit) => {
  const pool = roomAddress[digit];
  const url = `/room/${pool}/prize-distribution`;

  const request = useRequest<Response>({ path: url, enabled: pool !== undefined });

  return request.data?.total;
};
