import clsx from "clsx";
import { useMemo } from "react";
import ClockIcon from "../../assets/icon/clock-icon.svg";
import { useCountdown } from "../../hooks";
import { bigNumberToTime } from "../../utils/formatter";
import { useCurrentRoundState } from "./CurrentRoundProvider";
import { address, config } from "../../config";
import { HiOutlineExternalLink } from "react-icons/hi";

type CountdownState = "normal" | "almost-end" | "calculating" | "waiting-for-draw";

const COUNTDOWN_TITLE = {
  normal: "Close in",
  "almost-end": "Hurry up!",
  calculating: "",
  "waiting-for-draw": "Draw in",
};

export const LiveRoundStatus = ({ calculating }: { calculating: boolean }) => {
  const { payoutRate, currentRound } = useCurrentRoundState();
  const [liveMinute, liveSecond, liveCountdown] = useCountdown(
    bigNumberToTime(currentRound?.closeTime)
  );
  const [, , executeCountdown, executeCountdownNumber] = useCountdown(
    bigNumberToTime(currentRound?.executeAfterTime)
  );

  const countdownState: CountdownState = useMemo(() => {
    if (liveMinute > 1) return "normal";
    if (liveMinute === 0 && liveSecond > 0) return "almost-end";
    if (executeCountdownNumber > 0) return "waiting-for-draw";
    return "calculating";
  }, [liveMinute, liveSecond, executeCountdownNumber]);

  const countdownTitle = useMemo(() => COUNTDOWN_TITLE[countdownState], [countdownState]);

  return (
    <div className="relative z-10 grid grid-cols-6 gap-4 px-4 py-3 rounded-b-2xl bg-gradient-gold-max">
      <div className="flex flex-col justify-center flex-1 col-span-4 p-4 bg-dark-blue-gray rounded-xl border-gold">
        <div className="flex items-center space-x-2">
          <img
            className="w-4 md:w-6"
            src={require("../../assets/multiplier-box/trophy@2x.png")}
            alt="Round icon"
          />
          <span className="text-lg font-bold md:text-2xl text-gradient gold">
            x{payoutRate?.formattedRate?.toString()}
          </span>
          <span className="text-lg font-bold text-white md:text-2xl">
            ROUND {currentRound?.roundId.toNumber().toLocaleString()}
          </span>
        </div>
        <a
          className="flex items-center mt-1 text-xs text-white/60 gap-x-1"
          target="_blank"
          rel="noreferrer"
          href={`${config.explorerUrl}/address/${address.BB_MAX_ADDRESS}`}
        >
          <span>View Contract</span>
          <HiOutlineExternalLink />
        </a>
      </div>
      <div
        className={clsx(
          "flex flex-col items-center py-4 space-x-2 bg-dark-blue-gray rounded-xl col-span-2",
          countdownState === "almost-end" && !calculating ? "border-danger border-2" : "border-gold"
        )}
      >
        <div className="flex items-center space-x-[6px]">
          <img className="w-4" src={ClockIcon} alt="Round icon" />
          <span className="text-sm text-white lg:text-base">{countdownTitle}</span>
        </div>
        {calculating ? (
          <span className="mt-1 h-[26px]">Calculating...</span>
        ) : (
          <span
            className={clsx(
              "text-lg lg:text-xl font-bold",
              countdownState === "almost-end" ? "text-danger" : "text-gradient gold"
            )}
          >
            {(countdownState === "normal" || countdownState === "almost-end") && liveCountdown}
            {countdownState === "waiting-for-draw" && executeCountdown}
          </span>
        )}
      </div>
    </div>
  );
};
