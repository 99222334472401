import { RoomDigit } from "./../config";
import { Round, Ticket } from "./contracts/usePortionBoxRead";
import { useMemo } from "react";

export type ToElementProps = {
  number: number;
  size: number;
  round: Round;
  room: RoomDigit;
  index: number;
  ticket: Ticket;
};

export function useTransformTicketsElement(
  tickets: Ticket[],
  toElement: (props: ToElementProps) => JSX.Element | undefined
) {
  return useMemo(() => {
    let items: JSX.Element[] = [];

    tickets.forEach((ticket) => {
      ticket.numbers.forEach((number, index) => {
        const element = toElement({
          number: number.toNumber(),
          size: ticket.sizes[index].toNumber(),
          room: ticket.room,
          round: ticket.round,
          index: items.length,
          ticket: ticket,
        });

        if (element) items.push(element);
      });
    });

    return items;
  }, [tickets, toElement]);
}
