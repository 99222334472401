import { Banner } from "./Banner";
import { Dashboard } from "./Dashboard";
import { OurGame } from "./OurGame";
import { Partnership } from "./Partnership";
import { Security } from "./Security";
import { Slogan } from "./Slogan";
import { WhatIs } from "./WhatIs";
import { WinningCriteria } from "./WinningCriteria";

export function Home() {
  return (
    <>
      <Banner />
      <Dashboard />
      <WhatIs />
      <OurGame />
      <Slogan />
      <WinningCriteria />
      <Security />
      <Partnership />
    </>
  );
}
