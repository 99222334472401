import clsx from "clsx";
import dayjs from "dayjs";
import { animate, motion, MotionValue, useMotionTemplate, useMotionValue } from "framer-motion";
import { useMemo, useState } from "react";
import { slideUpVariants } from "../animation";
import { NumberCircleCard } from "../common/NumberCircleCard";
import { TopRoundCountdownDisplay } from "../common/round-countdown/TopRoundCountdownDisplay";
import { RoomDigit } from "../config";
import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";
import { Graph } from "../portion-box/Graph";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useRoundStore } from "../store/round-store";

export const GraphPriceInfo = ({
  digit,
  toggleable,
  status,
  jackpot
}: {
  digit: RoomDigit;
  toggleable?: boolean;
  status?: boolean;
  jackpot?: boolean
}) => {
  const roomState = useSpecificRoomState(digit);
  const { activeEndRound } = useRoundStore();
  const { latest } = useChainlinkPrice("btc");
  const [showGraph, setShowGraph] = useState(toggleable === undefined ? true : false);

  const finalBorderAngle = 206;
  const border1 = useMotionValue(finalBorderAngle);
  const border2 = useMotionValue(finalBorderAngle);
  const border3 = useMotionValue(finalBorderAngle);

  const animateBorderIn = (border: MotionValue<number>) => {
    animate(border, 0, { duration: 0.35 });
  };

  const animateBorderOut = (border: MotionValue<number>) => {
    animate(border, finalBorderAngle, { duration: 0.25 });
  };

  const getBorderTemplate = (borderValue: MotionValue<number>) => {
    return useMotionTemplate`linear-gradient(#060606, #060606) padding-box, linear-gradient(${borderValue}deg, #151515, #5b5d59) border-box`;
  };

  const answerComponent = useMemo(() => {
    const answer = activeEndRound?.answer.toString() || "";
    const roundId = activeEndRound?.roundId ?? "";
    const executedTime = activeEndRound?.executedTime ?? Date.now().toString();

    const answerPadded = answer.toString().padStart(Number(digit), "0").split("");

    return (
      <>
        <div className="flex items-center justify-center">
        {jackpot ? (
            <>
              {answerPadded.map((number, index) => (
                <NumberCircleCard key={index} number={number} className="w-10 h-10 text-3xl" gold />
              ))}
            </>
          ) : (
            <>
              {answerPadded.map((number, index) => (
                <NumberCircleCard key={index} number={number} className="w-10 h-10 text-3xl" />
              ))}
            </>
          )}
        </div>
        <p className="mt-4 text-sm">
          <span className="font-bold text-gradient gold">Round: #{roundId}</span>{" "}
          <span>{dayjs(executedTime).format("MMM D, YYYY, HH:mm")}</span>
        </p>
      </>
    );
  }, [activeEndRound, digit]);

  return (
    <div>
      {toggleable && (
        <div className="relative z-10 w-[85%] px-4 py-5 mx-auto overflow-hidden bg-cover rounded-2xl btn-border-gradient-gold">
          <div className="absolute inset-0 z-0 bg-cover lg:bg-graph-toggle bg-[#04132c]"></div>
          <div className="relative flex flex-row items-center space-x-3 lg:justify-center">
            <img
              alt=""
              className="object-contain h-4"
              src={require("../assets/chart-icon@2x.webp")}
            />
            <span className="text-xl font-bold">CHART</span>
            {jackpot ? <ToggleGraphSwitch checked={showGraph} onCheckChanged={setShowGraph} jackpot /> : <ToggleGraphSwitch checked={showGraph} onCheckChanged={setShowGraph} />}
          </div>
        </div>
      )}
      <div
        style={{ display: showGraph ? "" : "none" }}
        className={clsx(
          "p-2 lg:p-10",
          toggleable ? "-mt-5" : "",
          jackpot ? "bg-black-border-gold" : "card-border-gradient-blue-to-yellow"
        )}
      >
        <div
          className={clsx(
            "grid grid-cols-2 gap-2 lg:grid-cols-3 lg:gap-7",
            toggleable ? "lg:pt-0 pt-6" : ""
          )}
        >
          <motion.div
            variants={slideUpVariants}
            className="flex flex-col justify-center px-2 py-4 text-center gap-y-1 card-border-gradient-gray-to-white"
            onHoverStart={() => animateBorderIn(border1)}
            onHoverEnd={() => animateBorderOut(border1)}
            style={{
              background: getBorderTemplate(border1),
            }}
          >
            <div className="flex items-center justify-center">
              <img alt="" className="w-5 mr-1 lg:mr-2" src={require("../assets/icon/btc.png")} />
              <div className="text-sm">BTC PRICE</div>
            </div>
            <div className="text-xl lg:text-3xl">${latest?.formattedAnswer}</div>
          </motion.div>

          {status ? (
            <motion.div
              variants={slideUpVariants}
              className="px-2 py-3 text-center card-border-gradient-gray-to-white"
              onHoverStart={() => animateBorderIn(border2)}
              onHoverEnd={() => animateBorderOut(border2)}
              style={{
                background: getBorderTemplate(border2),
              }}
            >
              <TopRoundCountdownDisplay digit={digit} round={roomState?.round} />
            </motion.div>
          ) : (
            <div></div>
          )}

          <motion.div
            variants={slideUpVariants}
            className="col-span-2 px-2 py-3 text-center lg:col-span-1 card-border-gradient-gray-to-white"
            onHoverStart={() => animateBorderIn(border3)}
            onHoverEnd={() => animateBorderOut(border3)}
            style={{
              opacity: activeEndRound ? 1 : 0,
              background: getBorderTemplate(border3),
            }}
          >
            {answerComponent}
          </motion.div>
        </div>
        { jackpot ? <Graph asset="btc" digit={digit} jackpot/> : <Graph asset="btc" digit={digit} />}
      </div>
    </div>
  );
};

export const ToggleGraphSwitch = (props: {
  checked: boolean;
  onCheckChanged: (checked: boolean) => void;
  jackpot?: boolean;
}) => {
  const leftPercent = useMemo(() => (props.checked ? "0%" : "50%"), [props.checked]);
  return (
    <div className="right-4 absolute flex py-2 w-[140px] justify-around overflow-hidden border rounded-3xl bg-[#04132c]  border-white/5">
      <div
        style={{ left: leftPercent }}
        className={clsx(
          "absolute inset-y-0 z-0 w-1/2 transition-all rounded-3xl",
          props.jackpot ? "bg-gradient-gold-max" : "bg-gradient-blue"
        )}
      ></div>
      <button onClick={() => props.onCheckChanged(true)} className="relative w-full">
        Show
      </button>
      <button onClick={() => props.onCheckChanged(false)} className="relative w-full">
        Hide
      </button>
    </div>
  );
};
