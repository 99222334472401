import { BigNumber, BigNumberish, utils } from "ethers";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";
import { config } from "../../../config";
import { useMemo } from "react";

export const useRoundAmounts = (roundId?: BigNumberish, game?: string) => {
  const { data } = useMultiplierBoxRead({
    functionName: "roundAmounts",
    args: [roundId ?? BigNumber.from(0), game ?? ""],
    enabled: !!roundId && !!game,
  });

  const parsedAmount = useMemo(
    () => data && Number(utils.formatUnits(data, config.stablecoinDecimals)),
    [data]
  );

  return { amount: data, parsedAmount };
};
