import { BigNumberish } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { useMemo } from "react";
import { RoomDigit } from "./../../config";
import { Ticket, usePortionBoxRead } from "./usePortionBoxRead";

export const useTickets = (address: string, room?: RoomDigit, roundId?: BigNumberish) => {
  let refetchTickets: (() => void)[] = [];

  const useReadTickets = (address: string, currentRoom: RoomDigit) => {
    // eslint-disable-next-line
    const enabled = isAddress(address) && (!room || room == currentRoom);
    const read = usePortionBoxRead({
      enabled,
      room: currentRoom,
      functionName: "ticketsOf",
      args: address,
    });

    if (enabled) {
      refetchTickets.push(read.contractRead.refetch);
    }

    return read.data ?? [];
  };

  const tickets1 = useReadTickets(address, 1);
  const tickets2 = useReadTickets(address, 2);
  const tickets3 = useReadTickets(address, 3);
  const tickets4 = useReadTickets(address, 4);
  const tickets5 = useReadTickets(address, 5);

  const allTickets = useMemo(() => {
    if (room === undefined) {
      return [...tickets1, ...tickets2, ...tickets3, ...tickets4, ...tickets5];
    } else {
      const roomTickets: Record<RoomDigit, Ticket[]> = {
        1: tickets1,
        2: tickets2,
        3: tickets3,
        4: tickets4,
        5: tickets5,
      };
      return roomTickets[room] ?? [];
    }
  }, [tickets1, tickets2, tickets3, tickets4, tickets5, room]);

  const tickets = useMemo(() => {
    if (roundId) {
      return allTickets.filter((ticket) => ticket.round.roundId.eq(roundId));
    } else {
      return allTickets;
    }
  }, [allTickets, roundId]);

  const prizeNumber = useMemo(() => {
    return tickets.reduce((prev, cur) => {
      const winPrize = cur.claimed ? 0 : cur.winPrize;
      return prev + winPrize;
    }, 0);
  }, [tickets]);

  const prize = useMemo(() => {
    return `$${prizeNumber.toLocaleString(undefined, { maximumFractionDigits: 5 })}`;
  }, [prizeNumber]);

  const winTickets = useMemo(() => tickets.filter((t) => t.win), [tickets]);

  return {
    tickets,
    prize,
    prizeNumber,
    winTickets,
    refetchTickets: () => {
      refetchTickets.forEach((fn) => fn());
    },
  };
};
