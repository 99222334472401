import { useConnectModal } from "@rainbow-me/rainbowkit";
import { motion } from "framer-motion";
import { useAccount } from "wagmi";
import { slideUpVariants } from "../animation";
import certik from "../assets/secure-by.png";
import chainlink from "../assets/chainlink.png";
import { AnimatedGradient } from "../common/AnimatedGradient";
import { Button, LinkButton } from "../common/Button";
import { compactAddress } from "../utils/formatter";
import { AnimateLogo } from "./AnimateLogo";

export function Banner() {
  const { openConnectModal } = useConnectModal();
  const { isConnected, address } = useAccount();

  return (
    <div className="relative bg-banner mt-[60px]">
      <motion.div
        className="relative transition-all max-w-[1500px] px-6 lg:px-12 pt-6 lg:pt-12 pb-48 mr-[8%] lg:mr-[250px] z-10"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
      >
        <AnimatedGradient className="absolute inset-0 clip-skew" />
        <motion.div
          className="relative z-10 uppercase font-audiowide text-[30px] md:text-[48px] leading-tight"
          variants={slideUpVariants}
        >
          DECENTRALIZED <br />
          Prediction <br />
          Platform &
          <br />
          Gaming
        </motion.div>
        <motion.div variants={slideUpVariants} className="relative z-10 inline-block mt-8">
          {!isConnected ? (
            <Button
              preset="white"
              buttonSize="md"
              className="px-6 uppercase"
              onClick={openConnectModal}
            >
              Connect Wallet
            </Button>
          ) : (
            <Button
              preset="white"
              buttonSize="md"
              className="px-6 uppercase"
              onClick={() => (window.location.href = "/my-dashboard")}
            >
              My Dashboard
            </Button>
          )}
          <br className="md:hidden" />
          <Button preset="blue" buttonSize="md" className="px-6 mt-4 uppercase sm:ml-2 md:mt-0">
            Staking (Soon)
          </Button>
        </motion.div>
      </motion.div>
      <motion.div
        className="absolute inset-0 overflow-hidden"
        initial="hidden"
        animate="visible"
        transition={{ staggerChildren: 0.1, delayChildren: 0.2 }}
      >
        <div className="absolute w-1/2 md:w-1/3 max-w-[550px] top-0 md:-top-16 right-0 lg:right-12 z-0 floating">
          <AnimateLogo />
        </div>
        <div className="absolute w-32 lg:w-[11%] left-4 lg:left-[30%] bottom-4 lg:bottom-20 z-20 floating-2">
          <AnimateLogo className="rotate-12" />
        </div>
        <div className="absolute w-44 lg:w-[20%] right-0 lg:right-auto lg:left-[47%] -bottom-6 lg:-bottom-20 floating-3">
          <AnimateLogo className="-rotate-45" />
        </div>
        <motion.div
          className="absolute bottom-0 right-0 z-20 flex flex-row items-end lg:right-12"
          variants={slideUpVariants}
        >
          <img className="mb-4 h-14" src={chainlink} />
          <img className="h-32 ml-3" src={certik} />
        </motion.div>
      </motion.div>
      <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black z-10"></div>
    </div>
  );
}
