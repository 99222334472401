export const Even = () => {
  return (
    <svg width="15" height="16" viewBox="0 0 15 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.913 0H2.009C.9 0 0 .91 0 2.028v11.003c0 1.119.899 2.027 2.01 2.027h10.903c1.111 0 2.01-.908 2.01-2.027V2.028c0-1.119-.9-2.028-2.01-2.028zM4.667 9.556c-1.078 0-1.953-.895-1.953-1.998S3.59 5.56 4.667 5.56c1.079 0 1.954.895 1.954 1.998s-.875 1.998-1.954 1.998zm5.647 0c-1.078 0-1.953-.895-1.953-1.998s.875-1.998 1.953-1.998c1.079 0 1.954.895 1.954 1.998s-.875 1.998-1.954 1.998z"
      />
    </svg>
  );
};
