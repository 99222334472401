import { Asset } from "../config";
import useMenu from "../hooks/useMenu";
import { Dashboard } from "./Dashboard";
import { GraphPriceInfo } from "../prediction/GraphPriceInfo";
import { LetsPlay } from "./LetsPlay";
import { TabGroupAssets } from "./TabGroupAssets";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { useAssetStore } from "../store/asset-store";
import { PredictionAsset } from "../context/PredictionAssetContext";
import TradingViewGraph from "../common/TradingViewGraph";
import { Graph } from "./Graph";

export type Tab = "BTC" | "ETH" | "MATIC";
export const tabIndexes = ["BTC", "ETH", "MATIC"] as const;
export const indexesTab: Record<Tab, number> = {
  BTC: 0,
  ETH: 1,
  MATIC: 2,
};

export function getEnumKeyByEnumValue<R extends string | number, T extends { [key: string]: R }>(
  myEnum: T,
  enumValue: any
): string {
  if (Number.isInteger(enumValue)) {
    return tabIndexes[Number(enumValue)];
  }
  const keys = Object.keys(myEnum).filter((x) => myEnum[x] === enumValue);
  return keys[0];
}

const assetTab: Record<Tab, Asset> = {
  BTC: "btc",
  ETH: "eth",
  MATIC: "matic",
};

let banners = [
  require("../assets/prediction/banner-btc.png"),
  require("../assets/prediction/banner-eth@2x.png"),
  require("../assets/prediction/banner-matic.png"),
];

export function SelectAsset() {
  const { activeTabIndex, keyTab, setActiveTabIndex } = useMenu();
  const hash = useLocation().hash as PredictionAsset;
  const { asset, setAsset } = useAssetStore();

  useEffect(() => {
    const tab = hash.replace("#", "");
    const index = tabIndexes.indexOf(tab as Tab);
    if (index >= 0) {
      setActiveTabIndex(tabIndexes[index]);
      setAsset(tab as Tab);
    }
  }, [hash, setActiveTabIndex, setAsset]);

  return (
    <>
      <div className="bg-black">
        <div className="container pt-16 mx-auto">
          <p className="text-2xl font-bold text-center uppercase text-gradient gold">
            Select Asset
          </p>
          <TabGroupAssets
            tabs={["BTC"]}
            tabClassName="relative z-10 justify-center mx-auto mt-4"
            className="text-sm md:text-lg"
            buttonClassName="lg:px-12 md:px-10 px-6"
            initialTabIndex={0}
            imageName={["btc", "eth"]}
            comingSoonClassName="py-2 pl-5 lg:pr-8 md:pr-6 pr-5"
            onTabChange={(index: number) => {
              setActiveTabIndex(tabIndexes[index]);
            }}
          />
        </div>
        <div className="container justify-center">
          <img
            className="w-full relative -top-[30px] lg:-top-[60px] z-0"
            src={banners[indexesTab[activeTabIndex]]}
            alt=""
          />
          <Dashboard
            keyTab={keyTab}
            index={indexesTab[activeTabIndex]}
            className="hidden lg:block"
          />
        </div>
      </div>
      <LetsPlay keyTab={keyTab} index={indexesTab[activeTabIndex]} />
      <Graph />
    </>
  );
}
