import { RoomDigit } from "../config";
import { Rank } from "../hooks/useBillionboxRanking";
import { compactAddress } from "../utils/formatter";
import { EmptyWiningPlayer } from "./EmptyWiningPlayer";

type TableWinningRanksProps = {
  ranks: Rank[];
  room: RoomDigit;
};

export function TableWinningRanks({ ranks, room }: TableWinningRanksProps) {
  return (
    <div className="rounded-lg card-border-gradient-blue-to-yellow h-[405px] lg:w-[745px] md:w-[650px] sm:w-[590px] w-full mx-auto lg:mt-8 mt-5 lg:px-4 px-3 pt-0 overflow-hidden">
      {ranks.length > 0 ? (
        <div>
          <div className="grid grid-cols-12 text-[#a7a1a1] font-light lg:text-lg text-xs text-left mt-4 lg:mb-2 mb-0 ">
            <p className="col-span-2 pl-3 pr-2 lg:pl-8 md:pl-5 lg:pr-8 md:pr-5">Place</p>
            <p className="col-span-4 px-1 lg:px-8 md:px-5">Wallet</p>
            <p className="col-span-3 px-1 lg:px-8 md:px-5">Total Round</p>
            <p className="hidden col-span-3 px-1 lg:px-8 md:px-5 lg:block">Winning prize</p>
          </div>
          <div className="overflow-y-auto lg:h-[355px] h-[375px] no-scrollbar">
            {ranks.map((rank, index) => {
              return (
                <div
                  className="grid grid-cols-12 my-2 text-sm font-light text-left bg-blue-gray rounded-xl lg:text-2xl lg:my-3"
                  key={rank.user}
                >
                  <p className="col-span-2 py-3 pl-4 pr-2 font-medium lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 text-gradient gold">
                    #{index + 1}
                  </p>
                  <p className="col-span-4 px-1 py-3 font-medium lg:px-8 md:px-5">
                    {compactAddress(rank.user)}
                  </p>
                  <p className="col-span-3 px-1 py-3 font-normal lg:px-8 md:px-5">
                    {rank.rounds.toLocaleString()}
                  </p>
                  <p className="col-span-3 px-1 py-3 font-bold text-gradient gold lg:px-8 md:px-5">
                    ${rank.total.toLocaleString()}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      ) : (
        <EmptyWiningPlayer linkTo={`/decimal-box/${room}`} />
      )}
    </div>
  );
}
