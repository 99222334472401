import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount } from "wagmi";
import { useClaimReward } from "../hooks/contracts/useClaimReward";
import { Ticket } from "../hooks/contracts/usePortionBoxRead";
import { useWalletGuard } from "../hooks/useWalletGuard";
import { Button } from "./Button";
import { useNotificationContext } from "./NotificationProvider";
import { skeletonWhileLoading } from "./Skeleton";

type ClaimRewardProps = {
  prize?: string;
  winTickets: Ticket[];
  onClaimed?: () => void;
  room?: string;
  jackpot?: boolean
};

export function ClaimReward({ winTickets, prize, onClaimed, room = "", jackpot }: ClaimRewardProps) {
  const { isConnected } = useAccount();
  const { openConnectModal } = useConnectModal();
  const claim = useClaimReward(winTickets);
  const displayClass = room ? " order-5 pt-4 pb-3 lg:py-3 z-10" : "lg:flex-row py-4";
  const classTitle = room ? "lg:text-xl md:text-lg text-base mb-2" : "mb-1";
  const { pushNotification } = useNotificationContext();

  const executeClaim = useWalletGuard(async () => {
    await claim?.();
    onClaimed?.();
    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: "You've claimed all the rewards!",
    });
  });

  return (
    <div
      className={`rounded-2xl flex flex-col justify-around items-center relative overflow-hidden ${displayClass} ${jackpot ? "bg-gradient-gold-max": "bg-gradient-blue"}`}
    >
      <div className="text-center lg:text-left">
        <p className={classTitle}>Your Reward</p>
        <p
          className={`font-bold text-gradient gold mb-4 lg:mb-2 ${
            room ? "text-center text-2xl" : "text-3xl"
          }`}
        >
          {skeletonWhileLoading({
            value: prize,
            skeletonClassName: "w-[120px]",
          })}
        </p>
      </div>
      {isConnected && (
        <Button
          onClick={executeClaim}
          buttonSize={room ? "xs" : "sm"}
          className={`uppercase z-10 px-6`}
          preset= "white"
          disabled={prize === "$0"}
        >
          Claim reward
        </Button>
      )}

      {!isConnected && (
        <Button
          onClick={() => openConnectModal?.()}
          buttonSize={room ? "xs" : "sm"}
          className={`uppercase z-10 px-6`}
          preset="white"
        >
          Connect Wallet
        </Button>
      )}

      <img
        alt=""
        className="absolute bottom-0 left-0 w-36 rounded-bl-2xl"
        src={require("../assets/money-bag.png")}
      />
    </div>
  );
}
