import { memo, useEffect, useMemo, useState } from "react";
import clsx from "clsx";
import { Tab, tabIndexes } from "./SelectAsset";
import { useAssetStore } from "../store/asset-store";
import { useLocation } from "react-router-dom";

interface Stringify {
  toString: () => string;
}

type TabGroupProps = {
  tabs: Stringify[];
  tabClassName?: string;
  className?: string;
  buttonClassName?: string;
  imageName: Stringify[];
  comingSoonClassName?: string;
  initialTabIndex?: number;
  onTabChange?: (index: number) => void;
};

const _TabGroupAssets = ({
  tabs,
  tabClassName,
  className,
  buttonClassName,
  imageName,
  onTabChange,
  initialTabIndex = 0,
  comingSoonClassName,
}: TabGroupProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  const { asset, setAsset } = useAssetStore();
  const location = useLocation();
  const isPro = useMemo(() => location.pathname.includes("pro"), [location]);

  useEffect(() => {
    let tab = asset.replace("#", "");
    setActiveTabIndex(tabIndexes.indexOf(tab as Tab));
  }, [asset]);

  function setAssetTab(asset: Tab) {
    window.location.href = `#${asset}`;
    setAsset(asset);
  }

  return (
    <div className={clsx("bg-[#151515] rounded-full w-max flex items-center", tabClassName)}>
      <div
        className={`tabs bg-blue-gray rounded-full flex justify-center items-center text-center mx-auto ${className}`}
      >
        {tabs.map((tab, index) => (
          <button
            key={index}
            onClick={() => {
              setActiveTabIndex(index);
              onTabChange?.(index);
              setAssetTab(tabIndexes[index]);
            }}
            className={`rounded-full py-3 ${buttonClassName} ${activeTabIndex === index && "blue"}`}
          >
            <div className="flex items-center justify-center">
              <img
                className="w-6 mr-1"
                src={require(`../assets/prediction/${imageName[index]}.png`)}
                alt=""
              />
              <p>{tab.toString()}</p>
            </div>
          </button>
        ))}
      </div>
      <div className={clsx("flex items-center justify-center", comingSoonClassName)}>
        <img className="w-5 lg:w-6" src={require("../assets/icon/bb-icon-s.png")} alt="" />
        <p className="text-[#818181] text-lg ml-2">BB</p>
        <div className="font-medium text-[10px] uppercase text-gradient gold ml-2 leading-tight">
          <div>{isPro ? "PRO" : "CLASSIC"}</div>
          <div>MODE</div>
        </div>
      </div>
    </div>
  );
};

export const TabGroupAssets = memo(_TabGroupAssets);
