import { NavBarMode } from "../common/NavBarMode";
import { NotificationProvider } from "../common/NotificationProvider";
import { SelectMode } from "../common/SelectMode";
import { GameContext, PredictionGame } from "../context/PredictionGameContext";
import { TabProvider } from "../contexts/MenuContext";
import { useBinanceSocketPriceCallback } from "../hooks/useBinanceSocketPriceCallback";
import { Questions } from "../portion-box/Questions";
import { Banner } from "../prediction-pro/Banner";
import { GameRankAndHistory } from "../prediction-pro/GameRankAndHistory";
import { SelectAsset } from "../prediction-pro/SelectAsset";
import { WinningCriteria } from "../prediction/WinningCriteria";
import { useAssetPriceStore } from "../store/asset-price";

export function PredictionPro(props: { game: PredictionGame }) {
  const setPrice = useAssetPriceStore((s) => s.setPrice);

  useBinanceSocketPriceCallback("btc", (price) => {
    setPrice("btc", price);
  });
  
  return (
    <GameContext.Provider value={props.game}>
      <NotificationProvider>
        <TabProvider>
          <NavBarMode />
          <Banner />
          <SelectMode />
          <SelectAsset />
          <GameRankAndHistory tabGroupMode />
        </TabProvider>
        <WinningCriteria />
        <Questions />
      </NotificationProvider>
    </GameContext.Provider>
  );
}
