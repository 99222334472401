import { BigNumberish } from "ethers";
import { useMemo } from "react";
import { roomAddress, RoomDigit } from "./../config";
import { useRequest } from "./useRequest";

type PoolRoundResponse = {
  buyers: {
    id: number;
    contract: string;
    roundId: number;
    user: string;
    number: number[];
    size: number[];
    totalPrice: number;
    transactionHash: string;
    transactionAt: string;
    createdAt: string;
  }[];
};

export const useRoundData = (digit: RoomDigit, round?: BigNumberish) => {
  const pool = roomAddress[digit];
  const url = `/room/${pool}/round/${round}`;

  const request = useRequest<PoolRoundResponse>({
    path: url,
    enabled: Boolean(round),
  });

  const buyers = useMemo(() => {
    let buyers: {
      user: string;
      time: string;
      number: number;
      size: number;
    }[] = [];

    request.data?.buyers.forEach((buyer) => {
      buyer.number.forEach((number, index) => {
        buyers.push({
          number,
          user: buyer.user,
          time: buyer.transactionAt,
          size: buyer.size[index],
        });
      });
    });

    return buyers.sort((a, b) => new Date(b.time).getTime() -  new Date(a.time).getTime());
  }, [request.data?.buyers]);

  return { round: request.data, buyers, ...request };
};
