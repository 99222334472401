import { motion, Variants } from "framer-motion";
import { AnimatedGradient } from "../common/AnimatedGradient";

export function Slogan() {
  const containerVariants: Variants = {
    hidden: { opacity: 0, y: "-20%" },
    visible: { opacity: 1, y: "0%", transition: { duration: 0.5 } },
  };
  return (
    <div className="px-5 py-5 text-center bg-black 2xl:px-44 lg:px-20">
      <motion.div
        initial="hidden"
        whileInView="visible"
        variants={containerVariants}
        viewport={{ once: true, margin: "0px 0px -20% 0px" }}
        className="relative overflow-hidden rounded-3xl p-7 lg:p-10"
      >
        <img
          alt=""
          className="absolute top-0 left-0 z-10 w-52"
          src={require("../assets/cube-trans2.png")}
        />
        <AnimatedGradient className="absolute inset-0" />
        <p className="relative z-10 text-3xl uppercase lg:text-6xl font-audiowide">
          Transparency
        </p>
        <p className="relative z-10 mt-2 text-lg lg:text-xl lg:mt-5">
          All of records are stored on the blockchain, <br />
          users can have a transparent and traceable view of the saved transactions
        </p>
        <img
          alt=""
          className="absolute bottom-0 right-0 z-10 w-52"
          src={require("../assets/cube-trans3.png")}
        />
      </motion.div>
    </div>
  );
}
