import {
  animate,
  HTMLMotionProps,
  motion,
  MotionValue,
  useMotionTemplate,
  useMotionValue
} from "framer-motion";
import { slideUpVariants } from "../animation";

export const GradientBorderDiv = ({
  children,
  className,
  ...rest
}: HTMLMotionProps<"div">) => {
  const finalBorderAngle = 206;
  const border = useMotionValue(finalBorderAngle);

  const getBorderTemplate = (border: MotionValue<number>) => {
    return useMotionTemplate`linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(${border}deg, rgba(27, 96, 250, 1) 0%, rgba(2, 13, 30, 1) 50%, rgba(251, 235, 98, 1) 100%) border-box`;
  };

  const animateBorderIn = (border: MotionValue<number>) => {
    animate(border, 0, { duration: 0.35 });
  };

  const animateBorderOut = (border: MotionValue<number>) => {
    animate(border, finalBorderAngle, { duration: 0.25 });
  };

  return (
    <motion.div
      variants={slideUpVariants}
      className={`col-span-2 lg:col-span-1 lg:row-span-2 card-border-gradient-blue-to-yellow-bg-black-to-blue ${className}`}
      onHoverStart={() => animateBorderIn(border)}
      onHoverEnd={() => animateBorderOut(border)}
      style={{
        background: getBorderTemplate(border),
      }}
      {...rest}
    >
      {children}
    </motion.div>
  );
};
