import { format } from "path";
import { useEffect, useState } from "react";

export const useCountdown = (targetDate?: number, withHours?: boolean) => {
  const countDownDate = new Date(targetDate ?? 0).getTime();

  const [countDown, setCountDown] = useState(countDownDate - new Date().getTime());

  useEffect(() => {
    setCountDown(countDownDate - new Date().getTime());

    const interval = setInterval(() => {
      setCountDown(countDownDate - new Date().getTime());
    }, 1000);

    return () => clearInterval(interval);
  }, [countDownDate]);

  return getReturnValues(countDown, withHours ?? true);
};

const getReturnValues: (
  countDown: number,
  withHours: boolean
) => [number, number, string, number] = (countDown: number, withHours: boolean) => {
  const hours = Math.max(Math.floor(countDown / 1000 / 60 / 60), 0);
  const minutes = Math.max(Math.floor((countDown % (1000 * 60 * 60)) / (1000 * 60)), 0);
  const seconds = Math.max(Math.floor((countDown % (1000 * 60)) / 1000), 0);

  let formatted = `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`;

  if (withHours) {
    formatted = `${hours.toString()}:${formatted}`;
  }

  return [minutes, seconds, formatted, countDown];
};
