import { utils } from "ethers";
import { AnimatePresence, motion } from "framer-motion";
import { useCallback, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import useMenu from "../hooks/useMenu";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { useRequest } from "../hooks/useRequest";
import { EmptyState } from "../portion-box/EmptyState";
import { formattedTime } from "../utils/formatter";
import { CHAIN } from "./ClaimReward";

export type UserHistoryType = {
  roundId: number;
  roundTime: Date;
  earned: number;
  win: boolean;
};

export type HistoryDetailType = {
  roundId: string;
  direction: "up" | "down";
  yourPosition: string;
  earned: string;
  closePrice: string;
  lockPrice: string;
  prizePool: string;
  percentChange: string;
  win: boolean;
  unit: string;
  roundTime: number;
  payoutUp: string;
  payoutDown: string;
};

export const UserHistory = () => {
  const { address = "" } = useAccount();
  const { activeTabIndex } = useMenu();
  const [showHistoryIndex, setShowHistoryIndex] = useState<number>();
  const selectToken = activeTabIndex;
  const rewardCurrency = usePredictionRewardCurrency(selectToken);

  const toggleHistoryDetail = useCallback(
    (index: number) => {
      setShowHistoryIndex(showHistoryIndex === index ? undefined : index);
    },
    [showHistoryIndex]
  );

  const { data } = useRequest<HistoryDetailType[]>({
    baseURL: process.env.REACT_APP_BASE_PREDICTION_API,
    path:
      "/api/history/prediction?" +
      new URLSearchParams({
        chain: CHAIN.MATIC,
        contractName: selectToken,
        address: address,
      }),
    enabled: address !== "",
  });

  const filteredData = useMemo(() => {
    return data
      ?.filter((item) => item.closePrice !== "0")
      .sort((a, b) => Number(b.roundId) - Number(a.roundId));
  }, [data]);

  return (
    <div className="order-first col-span-5 overflow-hidden border lg:order-last lg:col-span-2 rounded-3xl border-primary">
      <div className="py-4 text-center bg-gradient-blue">
        <h3 className="text-xl font-semibold text-white uppercase">History</h3>
      </div>
      <div className="grid grid-cols-12 px-4 py-4 text-xs font-light bg-black gap-x-4 text-light-gray lg:text-base">
        <div className="col-span-4">Round</div>
        <div className="col-span-5">Earned</div>
        <div className="col-span-3">Result</div>
      </div>
      <div className="h-[400px] overflow-y-scroll no-scrollbar bg-black px-4">
        {filteredData?.map((history, index) => (
          <button
            key={index}
            className="w-full text-left"
            onClick={() => toggleHistoryDetail(index)}
          >
            <div className="rounded-2xl mb-2 grid grid-cols-12 items-center px-4 py-4 bg-[#04132c] gap-x-4">
              <div className="col-span-4">
                <div className="text-xs font-semibold lg:text-base">#{history.roundId}</div>
                <div className="text-[9px] font-light">
                  {formattedTime(new Date(history.roundTime * 1000))}
                </div>
              </div>
              <div className="col-span-5 text-xs font-semibold text-gradient gold lg:text-lg">
                {(+utils.formatUnits(history.win ? history.earned ?? 0 : 0, 6)).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                  }
                )}{" "}
                {rewardCurrency}
              </div>
              <div className="flex items-center justify-between col-span-3 text-sm font-bold lg:text-base">
                {history.win && <span className="text-gradient gold">WON</span>}
                {!history.win && <span className="text-[#6f6f6f]">LOSE</span>}
                <img alt="" className="h-2" src={require("../assets/btn-down.png")} />
              </div>
            </div>
            <div className="overflow-hidden">
              <AnimatePresence>
                {showHistoryIndex === index && (
                  <UserHistoryDetail
                    roundId={history.roundId}
                    direction={history.direction}
                    yourPosition={history.yourPosition}
                    earned={history.win ? history.earned : "0"}
                    closePrice={history.closePrice}
                    lockPrice={history.lockPrice}
                    prizePool={history.prizePool}
                    percentChange={history.percentChange}
                    win={history.win}
                    unit={rewardCurrency}
                    roundTime={history.roundTime}
                    payoutUp={history.payoutUp}
                    payoutDown={history.payoutDown}
                  />
                )}
              </AnimatePresence>
            </div>
          </button>
        ))}
        {(!filteredData || filteredData?.length === 0) && (
          <EmptyState className="mt-24">
            You didn't have any history. <br />
          </EmptyState>
        )}
      </div>
    </div>
  );
};

export const UserHistoryDetail = (props: HistoryDetailType) => {
  const {
    direction,
    yourPosition,
    earned,
    closePrice,
    lockPrice,
    prizePool,
    percentChange,
    unit,
    payoutUp,
    payoutDown,
  } = props;
  return (
    <motion.div
      className="px-4 py-2 overflow-hidden"
      initial={{ y: "-40%", opacity: 0 }}
      exit={{ y: "-40%", opacity: 0 }}
      animate={{ y: "0%", opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <div className="overflow-hidden bg-black rounded-xl">
        <div className="px-4 py-2 text-sm font-bold bg-blue-gray">Your History</div>
        <div className="grid items-center grid-cols-2 px-4 py-2 gap-y-2">
          <div className="text-sm font-light">Your direction</div>
          <div className="text-right">
            <PredictDirection
              direction={direction}
              className="px-3 py-1 text-xs font-medium rounded-xl"
            />
          </div>
          <div className="text-sm font-light">Your position</div>
          <div className="text-sm font-medium text-right">
            {(+utils.formatUnits(yourPosition ?? 0, 6)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}{" "}
            {unit}
          </div>
          <div className="text-sm font-light">Your earning</div>
          <div className="text-sm font-medium text-right text-gradient gold">
            {(+utils.formatUnits(earned ?? 0, 6)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}{" "}
            {unit}
          </div>
        </div>
      </div>

      <div className="mt-4 overflow-hidden bg-black rounded-xl">
        <div className="px-4 py-2 text-sm font-bold bg-blue-gray">Round History</div>
        <div className="grid grid-cols-2 px-4 py-2 gap-y-2">
          <div className="text-sm font-light">Closed price</div>
          <div
            className={`text-sm font-medium text-right ${
              parseFloat(percentChange) > 0 ? "text-up" : "text-down"
            }`}
          >
            <div>
              $
              {(+utils.formatUnits(closePrice ?? 0, "8")).toLocaleString(undefined, {
                minimumFractionDigits: 2,
              })}
            </div>
            <div>
              {parseFloat(percentChange).toLocaleString(undefined, { minimumFractionDigits: 2 })}%
            </div>
          </div>
          <div className="text-sm font-light">Locked Price</div>
          <div className="text-sm font-medium text-right">
            $
            {(+utils.formatUnits(lockPrice ?? 0, "8")).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}
          </div>
          <div className="text-sm font-light">Prize Pool</div>
          <div className="text-sm font-medium text-right">
            {(+utils.formatUnits(prizePool ?? 0, 6)).toLocaleString(undefined, {
              minimumFractionDigits: 2,
            })}{" "}
            {unit}
          </div>
          <div className="text-xs font-light">Up</div>
          <div className="text-xs text-right text-light-gray">
            {parseFloat(payoutUp).toLocaleString(undefined, { minimumFractionDigits: 2 })}x Payout
          </div>
          <div className="text-xs font-light">Down</div>
          <div className="text-xs text-right text-light-gray">
            {parseFloat(payoutDown).toLocaleString(undefined, { minimumFractionDigits: 2 })}x Payout
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export const PredictDirection = (props: { className?: string; direction: "up" | "down" }) => {
  const usedProperty = useMemo(() => {
    const directionProperty = {
      up: {
        colorClass: "bg-up",
        icon: require("../assets/icon/arrow-up.png"),
        text: "Going up",
      },
      down: {
        colorClass: "bg-down",
        icon: require("../assets/icon/arrow-down.png"),
        text: "Going down",
      },
    };
    return directionProperty[props.direction];
  }, [props.direction]);

  return (
    <div className={`${usedProperty.colorClass} ${props.className} inline-block`}>
      <img src={usedProperty.icon} alt="" className="inline-block h-3" />
      <span className="ml-2">{usedProperty.text}</span>
    </div>
  );
};
