import clsx from "clsx";
import { utils } from "ethers";
import { useMemo } from "react";
import { useAccount } from "wagmi";
import { FadeSlideAnimatePresence } from "../../common/FadeSlideAnimatePresence";
import { config } from "../../config";
import {
  MultiplierBoxFinishedRound,
  MultiplierBoxPlayInfo,
  usePlayInfo,
} from "../../hooks/contracts/multiplier-box";
import { EmptyState } from "../../portion-box/EmptyState";
import { bigNumberToFormattedTime } from "../../utils/formatter";
import { useCurrentRoundState } from "../play/CurrentRoundProvider";
import { isNumberWinning } from "../utils/gameWinning";

export type Play = {
  number: number;
  play: number;
  round: number;
  time: string;
  prize: number;
  status: string;
};

export const TableMyPlay = () => {
  const { address = "" } = useAccount();
  const { payoutRate, digits = 1, gameAddress = "" } = useCurrentRoundState();
  const { playInfos = [], roundInfos = [] } = usePlayInfo(address, gameAddress);

  const PlayItems = useMemo(() => {
    const items: React.ReactNode[] = [];

    for (let roundIndex = 0; roundIndex < playInfos.length; roundIndex++) {
      const round = roundInfos[roundIndex];
      const roundPayout =
        round?.executedTime.eq(0) && payoutRate?.rate ? payoutRate?.rate : round?.payout;

      for (let playIndex = 0; playIndex < playInfos[roundIndex].length; playIndex++) {
        if (playInfos[roundIndex][playIndex].game !== gameAddress) continue;
        const roundInfo = roundInfos[roundIndex];
        if (roundInfo === undefined) continue;

        const playInfo = playInfos[roundIndex][playIndex];
        const status = roundInfo.executedTime.eq(0)
          ? "waiting"
          : roundInfo && isNumberWinning(playInfo.number, roundInfo.answer, gameAddress)
          ? "win"
          : "lose";

        items.push(
          <PlayItem
            key={`${roundIndex}-${playIndex}`}
            digits={digits}
            play={playInfos[roundIndex][playIndex]}
            status={status}
            round={roundInfos[roundIndex]}
            payout={roundPayout?.toNumber() ?? 0}
          />
        );
      }
    }

    return items;
  }, [playInfos, roundInfos, payoutRate, gameAddress]);

  return (
    <FadeSlideAnimatePresence>
      {playInfos.length > 0 && (
        <>
          <div className="grid grid-cols-12 text-[#a7a1a1] lg:text-base md:text-sm text-xs font-light text-left mt-6 lg:mb-2 mb-0 ">
            <p className="hidden col-span-2 pl-3 pr-2 text-center lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 lg:block">
              Number
            </p>
            <p className="col-span-6 px-1 lg:col-span-2">Round</p>
            <p className="col-span-3 px-1 lg:px-8 md:px-5">Play</p>
            <p className="hidden col-span-3 px-1 lg:block">Estimate Prize</p>
            <p className="col-span-3 px-1 lg:col-span-2">Result</p>
          </div>
          <div className="overflow-y-auto lg:h-[510px] h-[410px] pb-10 no-scrollbar">
            {PlayItems}
          </div>
        </>
      )}
      {playInfos.length === 0 && <EmptyState className="lg:mt-48 mt-36">Let's play!</EmptyState>}
    </FadeSlideAnimatePresence>
  );
};

const PlayItem = ({
  play,
  round,
  payout,
  status,
  digits,
}: {
  play: MultiplierBoxPlayInfo;
  round: MultiplierBoxFinishedRound;
  payout: number;
  status: "win" | "lose" | "waiting";
  digits: number;
}) => {
  const playAmount = useMemo(() => {
    return Number(utils.formatUnits(play.amount, config.stablecoinDecimals)).toLocaleString(
      undefined,
      {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      }
    );
  }, [play]);

  const payoutAmount = useMemo(() => {
    if (status === "lose") return "0.00";

    const amount = play.amount.mul(payout).div(100);
    return Number(utils.formatUnits(amount, config.stablecoinDecimals)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }, [round, play, status]);

  return (
    <div className="grid items-center grid-cols-12 my-2 text-sm text-left bg-blue-gray rounded-xl lg:text-2xl lg:my-3">
      <p className="col-span-2 text-center tracking-[0.5em] py-3 pl-4 pr-2 space-x-4 font-medium lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 text-gradient gold">
        {play.number.toString().padStart(digits, "0")}
      </p>
      <div className="col-span-4 px-1 py-3 lg:col-span-2">
        <p className="text-xs font-normal lg:text-sm">Round {round.roundId.toString()}</p>
        <p className="text-[10px] font-light lg:text-sm">
          {bigNumberToFormattedTime(round.closeTime)}
        </p>
      </div>
      <p className="col-span-3 px-1 py-3 font-bold lg:px-8 md:px-5">${playAmount}</p>
      <p className="hidden col-span-3 px-1 py-3 font-bold lg:block">${payoutAmount}</p>
      <p
        className={clsx(
          "lg:col-span-2 col-span-2 px-1 py-3 font-bold",
          status === "lose" ? "text-brownish-grey" : "text-gradient gold"
        )}
      >
        {status.toUpperCase()}
      </p>
    </div>
  );
};
