import { useCallback, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { TabGroup } from "../common/TabGroup";
import { RoomDigit, digits } from "../config";
import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";
import { useTicketBalance } from "../hooks/contracts/useTicketBalance";
import { useTickets } from "../hooks/contracts/useTickets";
import { Dashboard } from "./Dashboard";
import { useRoomState } from "./RoomDataProvider";

export const DashboardContainer = () => {
  const { latest } = useChainlinkPrice("btc");
  const { address = "" } = useAccount();
  const [digit, setDigit] = useState<RoomDigit>(5);
  const rooms = useRoomState();

  const roomState = useMemo(() => rooms[digit], [rooms, digit]);
  const { formatted: formattedTicketBalance } = useTicketBalance(address);
  const { refetchTickets, prize, winTickets } = useTickets(address);

  const MemoTabGroup = useMemo(
    () => (
      <TabGroup
        tabs={["2 Digits"]}
        className="items-center justify-center mx-auto mb-6 bg-blue-gray lg:w-max rounded-3xl"
        buttonClassName="px-6 py-3 lg:py-3 w-1/3 lg:w-[130px] lg:text-base text-sm lg:font-semibold"
        onTabChange={(tabIndex) => setDigit(digits[tabIndex])}
      />
    ),
    []
  );

  const memoRefetchTickets = useCallback(() => {
    return refetchTickets();
  }, [refetchTickets]);

  return (
    <Dashboard
      digit={digit}
      prize={prize}
      round={roomState?.round}
      ticketBalance={formattedTicketBalance}
      formattedPrice={latest?.formattedAnswer}
      tabGroup={MemoTabGroup}
      winTickets={winTickets}
      onClaimed={memoRefetchTickets}
    />
  );
};
