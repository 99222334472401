import { useConnectModal } from "@rainbow-me/rainbowkit";
import { useAccount, useDisconnect } from "wagmi";
import { compactAddress } from "../utils/formatter";
import { MyReward } from "./MyReward";

export function Dashboard() {
  const { address = "" } = useAccount();
  const { openConnectModal } = useConnectModal();
  const { disconnect } = useDisconnect();

  return (
    <div className="relative md:bg-my-wallet bg-black mt-[60px] bg-no-repeat bg-cover bg-center">
      <div className="absolute inset-x-0 top-0 h-[300px] bg-gradient-to-b from-black z-0"></div>
      <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black z-0"></div>
      <img
        className="absolute top-0 block w-full md:hidden"
        src={require("../assets/bg@3x.png")}
        alt=""
      />
      <div className="container relative z-10 pt-16 pb-10 mx-auto">
        <div className="flex flex-col justify-center lg:flex-row lg:justify-between">
          <div className="flex flex-col justify-center text-center lg:text-left">
            <p className="text-5xl uppercase font-audiowide text-gradient gold">My dashboard</p>
            <p className="hidden mt-2 text-lg font-light lg:block">
              Your reward is all in one place
            </p>
          </div>
          <div className="relative px-8 py-5 mt-10 lg:mt-0 card-border-light-gradient-silver-bg-black-to-blue">
            <img
              className="absolute bottom-0 left-0 w-[84px] rounded-bl-xl"
              src={require("../assets/user.png")}
              alt=""
            />
            <div className="flex justify-between md:space-x-12 lg:space-x-24">
              <div className="flex flex-col">
                <p className="pb-1 text-xl">Your wallet</p>
                {address ? (
                  <p className="text-xl text-gradient gold">{compactAddress(address)}</p>
                ) : (
                  <p className="text-base text-gradient gold">No wallet connected</p>
                )}
              </div>
              {address ? (
                <button
                  className="px-3 my-2 rounded-full btn-border-gradient-blue lg:px-8"
                  onClick={() => disconnect()}
                >
                  <span className="text-sm font-bold uppercase">Disconnect</span>
                </button>
              ) : (
                <button
                  className="px-3 my-2 rounded-full btn-border-gradient-blue lg:px-6"
                  onClick={openConnectModal}
                >
                  <span className="text-sm font-bold uppercase">Connect Wallet</span>
                </button>
              )}
            </div>
          </div>
        </div>

        <MyReward />
      </div>
    </div>
  );
}
