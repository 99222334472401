import clsx from "clsx";
import { useCallback, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../common/Button";
import { useNotificationContext } from "../common/NotificationProvider";
import { useBillionboxNumberRemaining } from "../hooks/useBillionboxNumberRemaining";
import { useNumberSize } from "../hooks/useNumberSize";
import { useRoundData } from "../hooks/useRoundData";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";
import { config } from "../config";

type ConfirmSwipeDigitModalProps = {
  isOpen: string;
  closeModal: any;
  swipeAtIndex: number;
  onSwiped: () => void;
  gold?: boolean
};

export function ConfirmSwipeDigitModal({
  isOpen,
  closeModal,
  swipeAtIndex,
  onSwiped,
  gold
}: ConfirmSwipeDigitModalProps) {
  const { room = "" } = useParams();
  const { addNumbers, currentNumber } = useNumberStore();
  const { pushNotification } = useNotificationContext();
  const roomState = useSpecificRoomState(room);
  const { buyers = [] } = useRoundData(room, roomState?.roundId);
  const numberSize = useNumberSize(buyers);
  const getNumberRemaining = useBillionboxNumberRemaining(numberSize);

  const numberSlices = useMemo(() => {
    const firstHalf = currentNumber?.toString().slice(0, swipeAtIndex);
    const secondHalf = currentNumber?.toString().slice(swipeAtIndex + 1);
    return { firstHalf, secondHalf };
  }, [currentNumber, swipeAtIndex]);

  const numbers = useMemo(() => {
    return [...Array(10)]
      .map((_, index) => {
        const newNumber = Number(
          numberSlices.firstHalf + index.toString() + numberSlices.secondHalf
        );
        return getNumberRemaining(newNumber, 0) > 0 ? newNumber : undefined;
      })
      .filter((num) => num !== undefined) as number[];
  }, [numberSlices, getNumberRemaining]);

  const swipeNumbers = useCallback(() => {
    if (!currentNumber) return;
    addNumbers(numbers, config.maxNumberPerTx(room));

    pushNotification({
      type: "swipe",
      number: `${numberSlices.firstHalf}0${numberSlices.secondHalf} -
        ${numberSlices.firstHalf}9${numberSlices.secondHalf}`,
      key: Math.random().toString(),
    });

    onSwiped();
  }, [currentNumber, numbers, addNumbers, pushNotification, numberSlices, onSwiped]);

  return (
    <div className={`modal ${isOpen}`}>
      <div className="modal-background"></div>
      <div className="modal-card w-[300px]">
        <header className={clsx("justify-between border-b-0 modal-card-head rounded-t-2xl", gold ? "bg-gradient-gold-max" : "bg-gradient-blue")}>
          <div className="flex items-center">
            <img className="h-4 mr-2" src={gold ? require("../assets/union-white.png") : require("../assets/union.png")} alt="" />
            <p className="text-lg font-bold text-white uppercase modal-card-title">Swipe Digit</p>
          </div>
          <button onClick={closeModal}>
            <img
              src={require("../assets/icon/close.png")}
              alt=""
              className="w-5 h-5 lg:w-5 lg:h-5"
            />
          </button>
        </header>
        <section className="text-center modal-card-body bg-dark-blue-gray rounded-b-2xl">
          <p className="my-2 text-lg font-bold uppercase text-gradient gold">
            Add {numbers.length} numbers?
          </p>
          <div className="px-4 font-semibold text-white">
            {numbers.map((number) => number.toString().padStart(Number(room), "0")).join(", ")}
          </div>
          <Button
            className="px-16 mt-8 uppercase"
            preset={gold ? "goldMax" : "gold"}
            buttonSize="sm"
            onClick={() => {
              swipeNumbers();
              closeModal();
            }}
          >
            ADD
          </Button>
        </section>
      </div>
    </div>
  );
}
