import { roomAddress, RoomDigit } from "./../config";
import { useRequest } from "./useRequest";

export type Rank = { total: number; user: string; ticket: number; rounds: number };

export type RankingResponse = {
  ranking: Rank[];
};

export const useBillionboxRanking = (digit: RoomDigit) => {
  const pool = roomAddress[digit];
  const url = `/room/${pool}/ranking`;

  const request = useRequest<RankingResponse>({ path: url, enabled: pool !== undefined });

  return { ranking: request.data?.ranking, ...request };
};
