import { Variants } from "framer-motion";

export const spinUpVariants: (delay?: number) => Variants = (delay = 1) => ({
  hidden: { scale: 0, rotate: -70, opacity: 0 },
  visible: {
    scale: 1,
    rotate: 2,
    opacity: 1,
    transition: {
      type: "spring",
      damping: 20,
      delay,
    },
  },
});
