import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Button } from "../common";
import { NumberCircleCard } from "../common/NumberCircleCard";
import { TabGroup } from "../common/TabGroup";
import { useTransformTicketsElement } from "../hooks";
import { Ticket, useTickets } from "../hooks/contracts";
import { EmptyState } from "../portion-box/EmptyState";
import { bigNumberToFormattedTime } from "../utils/formatter";

const rooms = [2];

export function TableDecimalBox() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const { address = "" } = useAccount();
  const { tickets } = useTickets(address, rooms[activeTabIndex]);

  const sortedNewestTickets = useMemo(() => {
    return tickets.sort((a, b) => b.id.sub(a.id).toNumber());
  }, [tickets]);

  function getTicketLabel(number: number, ticket: Ticket) {
    if (ticket.round.executedTime.eq(0)) {
      return <p className="text-gradient gold">WAITING</p>;
    } else if (ticket.round.answer.number.eq(number)) {
      return <p className="text-gradient gold">WIN</p>;
    } else {
      return <p className="text-brownish-grey">LOSE</p>;
    }
  }

  const TicketItems = useTransformTicketsElement(
    sortedNewestTickets,
    ({ index, number, round, ticket }) => (
      <div
        className="rounded-2xl mb-2 items-center grid grid-cols-12 md:px-4 px-2 py-3 md:py-4 bg-[#04132c] font-semibold lg:text-2xl md:text-xl sm:text-sm text-xs"
        key={index}
      >
        <div className="col-span-5">
          <div className="flex flex-row justify-center">
            {number
              .toString()
              .padStart(rooms[activeTabIndex], "0")
              .split("")
              .map((number, index) => (
                <NumberCircleCard
                  number={number}
                  key={index.toString()}
                  className="text-[10px] w-9 md:w-10 aspect-square lg:text-2xl md:text-xl"
                  borderWidth={1}
                />
              ))}
          </div>
        </div>
        <div className="col-span-4 pl-3 md:col-span-3">
          <p className="md:text-sm text-[10px] lg:text-sm font-medium">
            #{round.roundId.toString()}
          </p>
          <p className="md:text-xs text-[8px] font-light lg:text-sm">
            {bigNumberToFormattedTime(round.startTime)}
          </p>
        </div>
        <div className="col-span-2 pl-0 font-bold md:pl-9 lg:pl-12">
          {getTicketLabel(number, ticket)}
          <p className="text-[8px] lg:text-base md:text-xs text-gradient gold "></p>
        </div>
        <div className="col-span-1 pl-0 md:pl-10">
          {round.executedTime.gt(0) && (
            <a
              href={`/decimal-box/${
                rooms[activeTabIndex]
              }?round=${round.roundId.toString()}#finished-round`}
              className="flex items-center space-x-1 text-xs font-normal md:text-sm text-gradient gold hover:opacity-70"
            >
              <span>VIEW</span>
              <img
                className="w-3 -mt-[1px] md:w-4 lg:w-3"
                src={require("../assets/icon/left-gold2.png")}
                alt=""
              />
            </a>
          )}
        </div>
      </div>
    )
  );

  return (
    <div className="col-span-5 mt-12 overflow-hidden border lg:col-span-3 rounded-3xl border-primary lg:mt-10">
      <div className="py-4 text-center bg-gradient-blue">
        <p className="text-xl text-center uppercase font-audiowide">Decimal Box</p>
      </div>
      <div className="px-2 pt-5 bg-black md:px-9">
        <TabGroup
          tabs={["2 Digits"]}
          className="mx-auto lg:w-max"
          buttonClassName="px-6 py-3 lg:py-3 w-1/4 lg:w-[140px] md:text-base sm:text-xs text-[10px] font-semibold"
          onTabChange={setActiveTabIndex}
        />
        {sortedNewestTickets.length === 0 ? (
          <EmptyState className="h-[450px]">
            You didn't have any number.
            <br />
            Start buy a number.
            <br />
            <Button
              preset="gold"
              buttonSize="sm"
              className="px-6 mt-4"
              onClick={() => (window.location.href = "/decimal-box")}
            >
              Buy Number
            </Button>
          </EmptyState>
        ) : (
          <>
            <div className="grid grid-cols-12 px-2 py-4 text-xs font-light md:px-4 text-light-gray lg:text-base">
              <div className="col-span-5 text-center">Ticket Number</div>
              <div className="col-span-3 pl-3 lg:pl-3">Round</div>
              <div className="hidden col-span-3 pl-12 lg:block">Result</div>
              <div className="col-span-2 md:col-span-1"></div>
            </div>
            <div className="h-[400px] overflow-y-scroll no-scrollbar pb-2">{TicketItems}</div>
          </>
        )}
      </div>
    </div>
  );
}
