import { FadeSlideAnimatePresence } from "../../common/FadeSlideAnimatePresence";
import { Rank } from "../../hooks/contracts/multiplier-box";
import { EmptyState } from "../../portion-box/EmptyState";
import { compactAddress } from "../../utils/formatter";

type Props = {
  ranks: Rank[];
};

export const TableWiningRank = ({ ranks }: Props) => {
  return (
    <FadeSlideAnimatePresence>
      {ranks.length === 0 && <EmptyState className="lg:mt-48 mt-36">Let's play!</EmptyState>}
      {ranks.length > 0 && (
        <>
          <div className="grid grid-cols-9 text-[#a7a1a1] lg:text-base md:text-sm text-xs font-light text-left mt-6 lg:mb-2 mb-0 max-w-[600px] mx-auto">
            <p className="col-span-2 pl-3 pr-2 lg:pl-8 md:pl-5 lg:pr-8 md:pr-5">Place</p>
            <p className="col-span-4 px-1 lg:px-8 md:px-5">Player</p>
            <p className="col-span-3 px-1 lg:px-8 md:px-5">Winning prize</p>
          </div>
          <div className="overflow-y-auto lg:h-[510px] h-[410px] pb-10 no-scrollbar max-w-[600px] mx-auto">
            {ranks.map((rank, index) => {
              return (
                <div
                  className="grid grid-cols-9 my-2 text-left bg-blue-gray rounded-xl lg:text-2xl lg:my-3"
                  key={rank.user}
                >
                  <p className="col-span-2 py-3 pl-4 pr-2 font-medium lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 text-gradient gold">
                    #{index + 1}
                  </p>
                  <p className="col-span-4 px-1 py-3 font-medium lg:px-8 md:px-5">
                    {compactAddress(rank.user)}
                  </p>
                  <p className="col-span-3 px-1 py-3 font-bold text-gradient gold lg:px-8 md:px-5">
                    ${rank.total.toLocaleString()}
                  </p>
                </div>
              );
            })}
          </div>
        </>
      )}
    </FadeSlideAnimatePresence>
  );
};
