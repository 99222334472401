import { utils } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useContractRead } from "wagmi";
import { address, Asset, config } from "../../config";
import EACAggregatorProxy from "../../contracts/EACAggregatorProxy.json";

export const useChainlinkPrice = (
  asset: Asset,
  options?: { formattedDecimalPlaces?: number; refetchInterval?: number; enabled?: boolean }
) => {
  const [refreshIn, setRefreshIn] = useState(
    (options?.refetchInterval ?? config.refetchInterval) / 1_000
  );
  const aggregator = address.AGGREGATOR[asset];

  const contractRead = useContractRead({
    addressOrName: aggregator,
    contractInterface: EACAggregatorProxy,
    functionName: "latestRoundData",
    staleTime: options?.refetchInterval ?? config.refetchInterval,
    cacheTime: options?.refetchInterval ?? config.refetchInterval,
    enabled: options?.enabled ?? true,
  });

  const { data } = contractRead;
  const latest = useMemo(() => {
    if (!data) return;

    const price = utils.formatUnits(data?.answer ?? 0, 8);
    let [answerDigit, answerDecimal]: string[] | number[] = price.split(".");
    const answerDecimalLength = answerDecimal.length;
    answerDigit = Number(answerDigit);
    answerDecimal = answerDecimal.slice(0, 5).padEnd(5, "0");

    return {
      answerDigit,
      answerDecimal,
      answerDecimalLength,
      formattedAnswer: `${answerDigit.toLocaleString()}.${answerDecimal.slice(
        0,
        options?.formattedDecimalPlaces ?? 2
      )}`,
      answer: data!.answer,
      parsedAnswer: Number(price),
      answeredInRound: data!.answeredInRound,
      roundId: data!.roundId,
      startedAt: data!.startedAt,
      updatedAt: data!.updatedAt,
    };
  }, [data, options]);

  useEffect(() => {
    if (options?.enabled === false) return;

    const fetchInterval = setInterval(() => {
      contractRead.refetch();
      setRefreshIn(config.refetchInterval / 1_000 + 1);
    }, config.refetchInterval);

    const updateRefreshTimeInterval = setInterval(() => {
      setRefreshIn((refreshIn) => {
        const newRefreshIn = refreshIn - 1;
        return newRefreshIn < 0 ? 0 : newRefreshIn;
      });
    }, 1_000);

    return () => {
      clearInterval(fetchInterval);
      clearInterval(updateRefreshTimeInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.enabled]);

  return { contractRead, latest, refreshIn };
};
