import create from "zustand";

export type NumberInfo = {
  number: number;
  amount?: number;
};

type MultiplierBoxNumberStore = {
  numbers: NumberInfo[];
  totalAmount: (options?: { exclude: number }) => number;
  findNumberIndex: (number: number) => number;
  addNumber: (number: number, amount?: number) => void;
  addRandomNumber: (maxNumber: number) => number | undefined;
  removeNumber: (number: number) => void;
  setOverallAmount: (amount: number, numberLimits: number[], maxPerNumber: number) => void;
  setNumberAmount: (number: number, amount?: number) => void;
  clearNumbers: () => void;
};

export const useMultiplierBoxNumberStore = create<MultiplierBoxNumberStore>((set, get) => ({
  numbers: [],
  totalAmount(options) {
    const numbers = get().numbers;
    if (options) {
      return numbers.reduce((prev, curr) => {
        if (curr.number !== options.exclude) {
          return prev + (curr.amount ?? 0);
        } else {
          return prev;
        }
      }, 0);
    } else {
      return numbers.reduce((prev, curr) => prev + (curr.amount ?? 0), 0);
    }
  },
  findNumberIndex(number) {
    return get().numbers.findIndex((numberInfo) => numberInfo.number === number);
  },
  addNumber(number, amount) {
    set((state) => {
      const numberIndex = state.findNumberIndex(number);
      if (numberIndex === -1) {
        state.numbers.push({ number, amount });
        return { ...state };
      } else {
        return state;
      }
    });
  },
  addRandomNumber(maxNumber: number) {
    const { numbers, findNumberIndex } = get();
    let number: number | undefined;

    while (numbers.length < maxNumber) {
      number = Math.round(Math.random() * (maxNumber - 1));
      const numberIndex = findNumberIndex(number);

      if (numberIndex === -1) {
        break;
      }
    }

    set((state) => {
      if (number || number === 0) {
        state.numbers.push({ number });
      }
      return { ...state };
    });

    return number;
  },
  removeNumber(number) {
    set((state) => {
      const numberIndex = state.findNumberIndex(number);
      if (numberIndex >= 0) {
        state.numbers.splice(numberIndex, 1);
        return { ...state };
      } else {
        return state;
      }
    });
  },
  setOverallAmount(amount, _numberLimits, maxPerNumber) {
    set((state) => {
      const numberLimits = _numberLimits;

      if (numberLimits.length === 0) return state;

      const { numbers } = state;
      const numberLength = state.numbers.length;
      const amountPerNumber = amount == 0 ? 0 : Math.floor(amount / numberLength);

      let remainder = 0;
      let remainderIndexes: number[] = [];

      numbers.forEach((numberInfo, index) => {
        const limitLeft = maxPerNumber - numberLimits[index];

        if (limitLeft >= amountPerNumber) {
          remainderIndexes.push(index);
          numberInfo.amount = amountPerNumber;
        } else {
          numberInfo.amount = limitLeft;
          remainder += amountPerNumber - limitLeft;
        }
      });

      if (remainder > 0) {
        const remainderLength = remainderIndexes.length;

        remainderIndexes.forEach((remainderIndex, index) => {
          const addedAmount = Math.floor(remainder / (remainderLength - index));
          const numberAmount = numbers[remainderIndex].amount ?? 0;
          const limitLeft = maxPerNumber - (numberLimits[remainderIndex] + numberAmount);

          if (limitLeft >= addedAmount) {
            numbers[remainderIndex].amount = numberAmount + addedAmount;
            remainder -= addedAmount;
          } else {
            numbers[remainderIndex].amount = numberAmount + limitLeft;
            remainder -= limitLeft;
          }
        });
      }

      return { ...state };
    });
  },
  setNumberAmount(number, amount) {
    set((state) => {
      const numberIndex = state.findNumberIndex(number);
      if (numberIndex >= 0) {
        state.numbers[numberIndex].amount = amount;
        return { ...state };
      } else {
        return state;
      }
    });
  },
  clearNumbers() {
    set((state) => {
      state.numbers = [];
      return { ...state };
    });
  },
}));
