import { constants } from "ethers";
import { useEffect, useMemo, useState } from "react";
import { useAccount, useBalance } from "wagmi";
import {
  Button,
  InputPercentSlider,
  Loading,
  Modal,
  TabGroup,
  useNotificationContext
} from "../../../common";
import { address, config } from "../../../config";
import { useAmountPercent, useFormattedBalance } from "../../../hooks";
import {
  useChainlinkPrice,
  useChainlinkPriceCalculate,
  useTokenAllowance,
  useTokenWrite,
} from "../../../hooks/contracts";
import {
  useExecutePlay,
  useNumberLimits,
  useRoundAmounts,
} from "../../../hooks/contracts/multiplier-box";
import { useMultiplierBoxNumberStore } from "../../../store/multiplier-box-number-store";
import { useCurrentRoundState } from "../CurrentRoundProvider";
import { InputAmount } from "./InputAmount";
import { MaticRateInfo } from "./MaticRateInfo";
import { NumberItem } from "./NumberItem";
import { PercentSelector } from "./PercentSelector";
import { PlaySummary } from "./PlaySummary";
import { Token, TokenSelector, tokens } from "./TokenSelector";
import { UserBalance } from "./UserBalance";
import { useTokenTotalAmount } from "./hooks/useTokenTotalAmount";
import { useUpdateAmount } from "./hooks/useUpdateAmount";

type PlayModalProps = {
  active: boolean;
  onDismiss: () => void;
};

const OVERALL_TAB = 0;
const CUSTOM_TAB = 1;

export const PlayModal = ({ active, onDismiss }: PlayModalProps) => {
  const [amount, setAmount] = useState("");
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedToken, setSelectedToken] = useState<Token>(tokens[0]);

  const { numbers, totalAmount, setOverallAmount, removeNumber, clearNumbers } =
    useMultiplierBoxNumberStore();

  const { currentRound, payoutRate, gameAddress = "" } = useCurrentRoundState();
  const { address: accountAddress } = useAccount();
  const { pushNotification } = useNotificationContext();

  const roundAmounts = useRoundAmounts(currentRound?.roundId, gameAddress);
  const maxPerRound = useMemo(
    () => config.multiplierBox.maxPerRound[gameAddress] ?? 0,
    [gameAddress]
  );

  const { numbers: numbersLimit, maxAmount } = useNumberLimits(
    numbers,
    currentRound?.roundId,
    gameAddress
  );

  const { data: balance, refetch: refetchBalance } = useBalance({
    addressOrName: accountAddress,
    token: selectedToken.address,
  });

  const highestAmount =
    [...numbers].sort((a, b) => Number(b.amount) - Number(a.amount))[0]?.amount ?? 0;
  const formattedBalance = useFormattedBalance(balance, selectedToken.gasToken ? 4 : 2);

  const numberBalance = useMemo(() => Number(balance?.formatted ?? ""), [balance]);
  const amountNumber = useMemo(() => (Number.isNaN(Number(amount)) ? 0 : Number(amount)), [amount]);

  const maxPlayAmount = useMemo(
    () => Math.min(numberBalance, maxPerRound - (roundAmounts.parsedAmount ?? 0)),
    [numberBalance, roundAmounts, maxPerRound]
  );

  const amountPercent = useAmountPercent(amountNumber, maxPlayAmount);
  const maticPrice = useChainlinkPrice("matic", { enabled: !!selectedToken.gasToken });

  const tokenAllowance = useTokenAllowance(
    selectedToken.address ?? "",
    accountAddress,
    address.BB_MAX_ADDRESS
  );

  const executePlay = useExecutePlay(
    numbers,
    currentRound?.roundId,
    selectedToken.address,
    balance?.decimals,
    gameAddress,
    selectedToken.gasToken ? maticPrice?.latest?.parsedAnswer : 1
  );

  const { writeAsync: executeApprove } = useTokenWrite(
    selectedToken.address ?? "",
    !!accountAddress,
    "approve",
    [address.BB_MAX_ADDRESS, constants.MaxUint256]
  );

  function changePercent(percent: number) {
    const newAmount = (percent * maxPlayAmount) / 100;
    updateAmount(newAmount.toFixed(config.multiplierBox.maxDecimalPlaces));
  }

  function getEstimatePrize(amount: number) {
    const rate = payoutRate?.rate?.toNumber() ?? 0;
    const feePercent = (100 - config.multiplierBox.feePercent) / 100;
    return Math.floor(rate * amount * feePercent) / 100;
  }

  const updateAmount = useUpdateAmount(maxPlayAmount, maxAmount, setAmount);
  const tokenTotalAmount = useTokenTotalAmount(totalAmount(), !!selectedToken.gasToken);

  const amountMaticToUsd = useChainlinkPriceCalculate(
    "matic",
    amountNumber,
    !!selectedToken.gasToken
  );

  useEffect(() => {
    if (activeTab === OVERALL_TAB) {
      setOverallAmount(
        selectedToken.gasToken ? amountMaticToUsd : amountNumber,
        numbersLimit,
        config.multiplierBox.maxPerNumber[gameAddress] ?? 0
      );
    }
  }, [amountNumber, activeTab, selectedToken, amountMaticToUsd]);

  async function approve() {
    setIsLoading(true);
    try {
      const result = await executeApprove?.();
      await result?.wait();
      tokenAllowance.refetch();
    } finally {
      setIsLoading(false);
    }
  }

  async function play() {
    setIsLoading(true);
    try {
      let result: any;
      if (!selectedToken.address) {
        result = await executePlay.playNativeToken.writeAsync();
      } else {
        result = await executePlay.play.writeAsync();
      }

      pushNotification({
        key: result.hash,
        type: "sendTx",
        txhash: result.hash,
      });

      clearNumbers();
      onDismiss();

      await result?.wait();

      pushNotification({
        key: result.hash,
        type: "success",
        txhash: result.hash,
      });

      refetchBalance?.();
    } catch (error) {
      const err = error as any
      const data = err.error.error.error.data;
      console.log("data", data)
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal active={active} onDismiss={onDismiss}>
      <div className="modal-card border-gold">
        {isLoading && <Loading />}
        <header className="flex justify-between p-4 bg-gradient-gold-max rounded-t-xl">
          <span className="text-xl font-bold font-audiowide">PLAY</span>
          <button className="w-4" onClick={onDismiss}>
            <img src={require("../../../assets/icon/close.png")} />
          </button>
        </header>
        <section className="relative bg-[#080d1c] grid grid-cols-1 lg:grid-cols-2 p-0 modal-card-body">
          <div className="px-3 pb-6 lg:pb-2 lg:max-h-[545px] lg:overflow-auto scrollbar-gray">
            <h3 className="mt-4 text-center uppercase">Selected Numbers</h3>
            {numbers.map(({ number, amount }, index) => (
              <NumberItem
                key={number}
                number={number}
                amount={amount ?? 0}
                estimatePrize={getEstimatePrize(amount ?? 0)}
                userBalance={numberBalance}
                numberLimit={index >= numbersLimit.length ? 0 : numbersLimit[index]}
                amountEditable={activeTab === CUSTOM_TAB}
                onRemove={() => {
                  removeNumber(number);
                  setOverallAmount(
                    amountNumber,
                    numbersLimit,
                    config.multiplierBox.maxPerNumber[gameAddress] ?? 0
                  );
                }}
              />
            ))}
            <div className="h-6"></div>
          </div>

          <div className="px-4 pt-6 pb-4 bg-dark-blue-gray">
            <div className="text-center text-gradient gold">Fill amount you want to play</div>
            <TabGroup
              onTabChange={setActiveTab}
              className="mx-auto mt-4 w-60"
              buttonClassName="w-1/2 text-xs font-semibold"
              tabs={["Over All", "Custom"]}
              color="gold"
            />
            <TokenSelector value={selectedToken} onChange={setSelectedToken} />
            <UserBalance balance={formattedBalance} tokenName={selectedToken.name} />
            <InputAmount
              selectedToken={selectedToken}
              editable={activeTab === OVERALL_TAB}
              amount={activeTab === OVERALL_TAB ? amount : tokenTotalAmount.toString()}
              onAmountChange={updateAmount}
            />
            <MaticRateInfo
              amount={activeTab === OVERALL_TAB ? amountNumber : tokenTotalAmount}
              show={!!selectedToken.gasToken}
            />
            {activeTab === OVERALL_TAB && (
              <>
                <InputPercentSlider
                  value={amountPercent}
                  className="mt-4"
                  onChange={changePercent}
                />
                <PercentSelector percents={[10, 25, 50, 75, 100]} onSelect={changePercent} />
              </>
            )}
            <PlaySummary
              token={selectedToken}
              inputAmount={activeTab === OVERALL_TAB ? amountNumber : tokenTotalAmount}
              estimatePrize={getEstimatePrize(highestAmount)}
            />
          </div>
        </section>
        <div className="h-[1px] bg-dashed opacity-20"></div>
        <section className="grid p-8 lg:grid-cols-2 gap-y-3">
          <div className="text-sm font-semibold text-center">
            <div>Note : I acknowledge that I have read the</div>
            <div className="text-gold">BillionBox Term & Conditions</div>
          </div>
          {amountNumber > (tokenAllowance.allowance ?? 0) ? (
            <Button onClick={approve} buttonSize="md" preset="goldMax" className="glow-gold">
              APPROVE
            </Button>
          ) : (
            <Button
              buttonSize="md"
              preset="goldMax"
              className="glow-gold"
              disabled={totalAmount() === 0}
              onClick={play}
            >
              {roundAmounts.parsedAmount == maxPerRound ? "REACH ROUND LIMIT" : "CONFIRM"}
            </Button>
          )}
        </section>
      </div>
    </Modal>
  );
};
