import { Variants } from "framer-motion";

export const flip3D: (side: "left" | "right") => Variants = (side) => {
  const rotateY = {
    hidden: { left: 30, right: -30 },
    visible: { left: 4, right: -4 },
  };
  return {
    hidden: {
      transformPerspective: 500,
      rotateY: rotateY.hidden[side],
      opacity: 0,
    },
    visible: {
      transformPerspective: 3000,
      rotateY: rotateY.visible[side],
      opacity: 1,
      transition: {
        type: "spring",
        stiffness: 50,
        damping: 25,
      },
    },
  };
};
