import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { ConnectWalletButton } from "./ConnectWalletButton";
import { SmallBTCBinancePrice, SmallBTCBinanceSocketPrice, SmallBTCPrice } from "./";

export function Navbar() {
  const [open, setOpen] = useState(false);
  const { pathname } = useLocation();

  const toggleOpen = () => {
    setOpen((open) => !open);
  };

  const icon = !open ? require("../assets/icon/toggle.png") : require("../assets/icon/close.png");

  const inactiveClass = "text-base uppercase mx-3";
  const activeClass = inactiveClass + " mx-3 text-base text-gradient gold font-bold";

  function classForPath(path: string) {
    return pathname.includes(path) ? activeClass : inactiveClass;
  }

  return (
    <div className="fixed inset-x-0 top-0 z-30 w-full shadow-lg">
      <div className="p-3 px-4 bg-black lg:px-10">
        <div className="justify-between hidden lg:flex">
          <div className="flex items-center">
            <a href="/" className="mr-6">
              <img alt="" className="w-40" src={require("../assets/logo.png")} />
            </a>
            <a href="/decimal-box" className={classForPath("/decimal-box")}>
              Decimal Box
            </a>
            <a href="/prediction-pro/high-low" className={classForPath("/prediction-pro")}>
              Prediction
            </a>
            <a href="/bb-max" className={classForPath("/bb-max")}>
              BB Max (Lotto)
            </a>
            <a href="https://bsc.billionbox.io/jackpot" className={classForPath("/jackpot")}>
              Jackpot
            </a>
          </div>

          <div className="flex items-center">
            {pathname.includes("prediction-pro") || pathname === "/jackpot" ? (
              <SmallBTCBinanceSocketPrice />
            ) : pathname === "/bb-max" ? (
              <SmallBTCBinancePrice />
            ) : (
              <SmallBTCPrice />
            )}
            <a
              href="https://info.billionbox.io/"
              target="_blank"
              rel="noopener noreferrer"
              className="flex items-center mr-5"
            >
              <img alt="" src={require("../assets/icon/info.png")} className="h-5 mr-1"></img>
              <span className="text-base text-gradient gold">DOC</span>
            </a>
            <ConnectWalletButton />
          </div>
        </div>

        <div className="flex justify-between lg:hidden">
          <Link to="/">
            <img alt="" className="w-40" src={require("../assets/logo.png")} />
          </Link>
          <button onClick={toggleOpen}>
            <img alt="" src={icon} className="h-6" />
          </button>
        </div>
      </div>

      <div className={`${open ? "flex" : "hidden"} bg-black h-screen flex-col flex-grow pt-10`}>
        <a
          href="/decimal-box"
          className="py-5 text-2xl text-center uppercase"
          onClick={() => setOpen(false)}
        >
          The Decimal Box
        </a>
        <a
          href="/prediction-pro/high-low"
          className="py-5 text-2xl text-center uppercase"
          onClick={() => setOpen(false)}
        >
          Prediction
        </a>
        <a
          href="/bb-max"
          className="py-5 text-2xl text-center uppercase"
          onClick={() => setOpen(false)}
        >
          BB Max (Lotto Game)
        </a>
        <a
          href="https://bsc.billionbox.io/jackpot"
          className="py-5 text-2xl text-center uppercase"
          onClick={() => setOpen(false)}
        >
          JACKPOT
        </a>
        <a
          href="https://info.billionbox.io/"
          target="_blank"
          rel="noopener noreferrer"
          className="flex items-center justify-center py-5"
        >
          <img alt="" src={require("../assets/icon/info.png")} className="h-6 mr-1"></img>
          <span className="text-2xl text-gradient gold">DOC</span>
        </a>
        <div className="py-5 mx-auto text-xl">
          <ConnectWalletButton />
        </div>
      </div>
    </div>
  );
}
