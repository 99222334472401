import { useParams } from "react-router-dom";
import { useAccount } from "wagmi";
import { NumberCircleCard } from "../common/NumberCircleCard";
import { useTickets } from "../hooks/contracts/useTickets";
import { useCurrentRoundTickets } from "../hooks/useCurrentRoundTickets";
import { useTransformTicketsElement } from "../hooks/useTransformTicketsElement";
import { EmptyState } from "../portion-box/EmptyState";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";

type Props = {
  className?: string;
};

export function YourTickets({ className }: Props) {
  const { address = "" } = useAccount();
  const { room = "" } = useParams();
  const roomState = useSpecificRoomState(room);
  const { tickets = [] } = useTickets(address, room, roomState?.roundId);
  const currentRoundTickets = useCurrentRoundTickets(tickets, roomState?.roundId);
  
  const TicketItems = useTransformTicketsElement(currentRoundTickets, ({ number, size, index }) => {
    return <TicketItem number={number} size={size} key={index} />;
  });

  return (
    <div className={`lg:mt-10 mt-8 ${className}`}>
      <p className="mb-4 text-xl font-semibold text-center text-gradient gold">
        Your Tickets in This Round
      </p>
      <div className="flex flex-col overflow-y-auto lg:max-h-[458px] md:max-h-[742px] sm:max-h-[726px] max-h-[716px] no-scrollbar px-8">
        {TicketItems}

        {tickets.length === 0 && (
          <EmptyState className="my-12">
            You didn't have a number in this round,
            <br />
            Start adding a number.
          </EmptyState>
        )}
        <div className="mb-1"></div>
      </div>
    </div>
  );
}

const TicketItem = ({ number, size }: { number: number; size: number }) => {
  const { room = "0" } = useParams();
  const numberDigits = number.toString().padStart(Number(room), "0").split("");

  return (
    <div className="flex mb-3 items-center justify-center bg-blue-gray py-4 w-full max-w-[600px] mx-auto rounded-2xl">
      {numberDigits.map((num, index) => (
        <NumberCircleCard key={index} number={num} className="w-10 h-10 text-2xl md:w-14 md:h-14" />
      ))}
      <p className="ml-2 text-2xl font-semibold text-gradient gold">x{size}</p>
    </div>
  );
};
