import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import BinanceLogo from "../assets/multiplier-box/binance-logo.svg";
import { TabGroup } from "../common";
import { Switch } from "../common/Switch";
import TradingViewGraph from "../common/TradingViewGraph";
import { useGameContext } from "../context/PredictionGameContext";
import { useBinancePrice, useCurrentEpoch, useRounds } from "../hooks";
import { usePredictionGameUp } from "../hooks/usePredictionGameUp";
import { LineChart } from "./LineChart";
import { PriceDecimal } from "./PriceDecimal";
import { PredictionRound } from "../hooks/contracts/usePredictionV3Read";
import { utils } from "ethers";
import { config } from "../config";

const timeframes = [1, 5, 15, 30, 60];

export function Graph() {
  const game = useGameContext();
  const price = useBinancePrice("btc");
  const [showGraph, setShowGraph] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [hoverRound, setHoverRound] = useState<PredictionRound>();

  const { currentEpoch } = useCurrentEpoch({
    keyTab: "BTC",
    activeTabIndex: 0,
    pro: game,
  });

  const showEpoch = useMemo(() => currentEpoch - 1, [currentEpoch]);

  const currentRound = useRounds({
    activeTabIndex: 0,
    keyTab: "BTC",
    currentEpoch: showEpoch,
    pro: game,
    watch: true,
  });

  const showRound = useMemo(() => {
    if (hoverRound) {
      return {
        epoch: hoverRound.epoch.toString(),
        closeTimestamp: hoverRound.closeOracleId.toString(),
      };
    } else {
      return {
        epoch: currentRound.epoch.toString(),
        closeTimestamp: currentRound.lockTimestamp.toString(),
      };
    }
  }, [hoverRound, currentRound]);

  const showPrice = useMemo(() => {
    let showPrice = price;

    if (hoverRound) {
      showPrice = Number(utils.formatUnits(hoverRound.closePrice, config.stablecoinDecimals)) / 100;
    }

    return showPrice?.toLocaleString("en-US", { minimumFractionDigits: 2 });
  }, [price, hoverRound]);

  const isOdd = usePredictionGameUp("even-odd", showPrice);
  const isHigh = usePredictionGameUp("high-low", showPrice);

  useEffect(() => {
    if (!showGraph) {
      setHoverRound(undefined);
    }
  }, [showGraph]);

  return (
    <div className="relative py-6 bg-black">
      <div className="h-[2px] bg-gold mb-8"></div>
      <div className="flex justify-center -mt-16">
        <div className="relative z-10 px-4 py-5 mx-auto overflow-hidden bg-cover rounded-2xl btn-border-gradient-gold w-[265px] lg:w-[500px]">
          <div className="absolute inset-0 z-0 bg-cover bg-graph-toggle"></div>
          <div className="relative flex flex-row items-center space-x-3">
            <img
              alt=""
              className="object-contain h-4"
              src={require("../assets/chart-icon@2x.webp")}
            />
            <span className="font-bold lg:text-xl">CHART</span>
            <Switch
              className="w-[300px]"
              title={["Line Chart", "Trading View Chart"]}
              checked={showGraph}
              onCheckChanged={setShowGraph}
            />
          </div>
        </div>
      </div>
      <div className="grid grid-cols-3 px-8 mt-6 font-roboto-condensed">
        <div>
          <div>
            <span className="font-bold text-white">
              <strong className="text-btc">ROUND</strong> {showRound?.epoch}
            </span>
            <span className="ml-3 text-white/80">
              {dayjs(Number(showRound?.closeTimestamp) * 1000).format("MMM DD, YYYY, HH:mm")}
            </span>
          </div>
          <div className="flex items-center mt-2 gap-x-3">
            <img className="w-7" src={require("../assets/prediction/btc.png")} alt="" />
            <PriceDecimal price={showPrice} borderColor="#353734" color="#ffffff" />

            <div className="flex px-2 py-1 border border-[#353734] rounded-xl bg-[#07132B]">
              <div className="flex items-center text-sm font-bold gap-x-1">
                {isHigh ? (
                  <>
                    <img
                      className="w-3"
                      src={require("../assets/multiplier-box/high-icon@2x.png")}
                      alt=""
                    />
                    <span className="text-high">HIGH</span>
                  </>
                ) : (
                  <>
                    <img
                      className="w-3"
                      src={require("../assets/multiplier-box/low-icon@2x.png")}
                      alt=""
                    />
                    <span className="text-low">LOW</span>
                  </>
                )}
                <span>{isOdd ? "ODD" : "EVEN"}</span>
              </div>
            </div>
          </div>
        </div>
        <div>
          {!showGraph && (
            <TabGroup
              tabs={["1 min", "5 mins", "15 mins", "30 mins", "60 mins"]}
              className="mt-3"
              buttonClassName="px-3 w-1/5"
              onTabChange={setActiveTab}
            />
          )}
        </div>
        <div className="flex items-center justify-end">
          <a
            href={`https://www.binance.com/en/trade/BTCUSDT`}
            target="_blank"
            rel="noreferrer"
            className="flex flex-row items-center px-4 py-2 space-x-2 bg-black border border-gray-600 rounded-3xl"
          >
            <span className="text-sm">View on</span>
            <img className="h-[12px]" src={BinanceLogo} alt="" />
          </a>
        </div>
      </div>
      <div className="h-[400px] mt-6">
        {!showGraph ? (
          <TradingViewGraph timeframe={timeframes[activeTab]} asset="btc" />
        ) : (
          <LineChart
            asset="btc"
            onHoverRound={(round) => {
              setHoverRound(round);
            }}
            onUnhoverRound={() => setHoverRound(undefined)}
          />
        )}
      </div>
    </div>
  );
}
