import { useChainlinkPrice } from "../hooks/contracts/useChainlinkPrice";

export const SmallBTCPrice = () => {
  const { latest } = useChainlinkPrice("btc", { formattedDecimalPlaces: 2 });

  return (
    <div className="flex items-center mr-4">
      <img alt="" className="w-5 mr-1 lg:mr-2" src={require("../assets/icon/btc.png")} />
      <span className="font-bold">${latest?.formattedAnswer}</span>
    </div>
  );
};
