import { useMemo } from "react";
import { skeletonWhileLoading } from "../common/Skeleton";
import { useRequest } from "../hooks/useRequest";
import clsx from "clsx";

type Props = {
  silver?: boolean;
};

export function TotalWeeklyPrizes({ silver }: Props) {
  const { data } = useRequest<{ prize: number }>({
    path: "/prediction/prize/weekly",
  });

  const formattedPrize = useMemo(() => {
    if (data) {
      return (
        "$" +
        data.prize.toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })
      );
    }
  }, [data]);

  return (
    <div
      className={clsx(
        "relative z-10 flex flex-col items-center justify-center px-2 w-44 lg:px-10 md:w-72 sm:w-60 lg:mt-12",
        silver
          ? "card-border-gradient-silver-bg-black-to-blue"
          : "card-border-gradient-gold-bg-black-to-blue"
      )}
    >
      <p className="pb-2 text-sm font-base lg:text-lg">{ silver ? "Overall Prizes": "Total Weekly Prizes"}</p>
      <p className={clsx("text-2xl font-bold lg:text-2xl", silver ? "text-gradient silver" : "text-gradient gold")}>
        {skeletonWhileLoading({ value: formattedPrize, skeletonClassName: "w-36" })}
      </p>
      <img
        className="absolute z-10 hidden w-12 -right-12 -top-8 lg:block"
        src={require("../assets/portion-box/star-1.png")}
        alt=""
      />
      <img
        className="absolute z-10 hidden w-12 -left-9 bottom-2 lg:block"
        src={require("../assets/portion-box/star-1.png")}
        alt=""
      />
      <img
        className="absolute z-10 hidden -right-10 top-12 w-14 lg:block"
        src={require("../assets/portion-box/star-2.png")}
        alt=""
      />
    </div>
  );
}
