import { animate, motion, MotionValue, useMotionTemplate, useMotionValue } from "framer-motion";
import { useState } from "react";
import { slideUpVariants } from "../animation";
import { CountUpNumber } from "../common/CountUpNumber";
import { Skeleton } from "../common/Skeleton";
import { useRequest } from "../hooks/useRequest";

type StatResponse = {
  totalCurrentPrize: number;
  totalWinners: number;
  biggestWinningPrize: number;
  transactions: number;
  paidPrize: number;
};

export function Dashboard() {
  const [isCountVisible, setIsCountVisible] = useState(false);
  const finalBorderAngle = 206;
  const mainBorder = useMotionValue(finalBorderAngle);
  const border1 = useMotionValue(finalBorderAngle);
  const border2 = useMotionValue(finalBorderAngle);
  const border3 = useMotionValue(finalBorderAngle);
  const border4 = useMotionValue(finalBorderAngle);
  const { data } = useRequest<StatResponse>({ path: "/stats" });

  const getBorderTemplate = (borderValue: MotionValue<number>) => {
    return useMotionTemplate`linear-gradient(#060606, #060606) padding-box, linear-gradient(${borderValue}deg, #151515, #5b5d59) border-box`;
  };

  const getMainBorderTemplate = () => {
    return useMotionTemplate`linear-gradient(#060606, #060606) padding-box,
    linear-gradient(${mainBorder}deg, rgba(27, 96, 250, 1) 0%, rgba(2, 13, 30, 1) 50%, rgba(251, 235, 98, 1) 100%) border-box`;
  };

  const animateBorderIn = (border: MotionValue<number>) => {
    animate(border, 0, { duration: 0.35 });
  };

  const animateBorderOut = (border: MotionValue<number>) => {
    animate(border, finalBorderAngle, { duration: 0.25 });
  };

  return (
    <div className="relative bg-black">
      <img alt="" className="absolute bottom-0 right-0" src={require("../assets/cube-trans.png")} />
      <motion.div
        className="container py-12 mx-auto lg:py-24"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, margin: "0px 0px -25% 0px" }}
        transition={{ staggerChildren: 0.1 }}
        onViewportEnter={() => setIsCountVisible(true)}
      >
        <div className="grid grid-cols-2 gap-4 text-center lg:grid-cols-3">
          <motion.div
            variants={slideUpVariants}
            className="flex flex-col items-center justify-center row-span-2 px-2 card-border-gradient-blue-to-yellow"
            onHoverStart={() => animateBorderIn(mainBorder)}
            onHoverEnd={() => animateBorderOut(mainBorder)}
            style={{
              background: getMainBorderTemplate(),
            }}
          >
            <p className="mb-0 lg:text-2xl text-md lg:mb-3">Total Current Prizes</p>
            {!data?.totalCurrentPrize ? (
              <Skeleton containerClassName="w-40" className="h-[26px] lg:h-[39px]" />
            ) : (
              <CountUpNumber
                className="text-2xl font-bold text-gradient gold lg:text-4xl"
                startCounting={isCountVisible}
                end={data?.totalCurrentPrize ?? 0}
                decimals={0}
                prefix="$"
              />
            )}
          </motion.div>
          <motion.div
            variants={slideUpVariants}
            className="px-2 card-border-gradient-gray-to-white py-7 lg:py-9"
            onHoverStart={() => animateBorderIn(border1)}
            onHoverEnd={() => animateBorderOut(border1)}
            style={{
              background: getBorderTemplate(border1),
            }}
          >
            <p className="mb-0 lg:text-2xl text-md lg:mb-3">Total Winners</p>
            {!data?.totalWinners ? (
              <Skeleton containerClassName="w-40" className="h-[26px] lg:h-[39px]" />
            ) : (
              <CountUpNumber
                className="text-2xl font-bold text-gradient gold lg:text-4xl"
                startCounting={isCountVisible}
                end={data?.totalWinners ?? 0}
                decimals={0}
                prefix=""
              />
            )}
          </motion.div>
          <motion.div
            variants={slideUpVariants}
            className="px-2 card-border-gradient-gray-to-white py-7 lg:py-9"
            onHoverStart={() => animateBorderIn(border2)}
            onHoverEnd={() => animateBorderOut(border2)}
            style={{
              background: getBorderTemplate(border2),
            }}
          >
            <p className="mb-0 lg:text-2xl text-md lg:mb-3">Total Transactions</p>
            {!data?.transactions ? (
              <Skeleton containerClassName="w-40" className="h-[26px] lg:h-[39px]" />
            ) : (
              <CountUpNumber
                className="text-2xl font-bold text-gradient gold lg:text-4xl"
                startCounting={isCountVisible}
                end={data?.transactions ?? 0}
                decimals={0}
                prefix=""
              />
            )}
          </motion.div>
          <motion.div
            variants={slideUpVariants}
            className="px-2 card-border-gradient-gray-to-white py-7 lg:py-9"
            onHoverStart={() => animateBorderIn(border3)}
            onHoverEnd={() => animateBorderOut(border3)}
            style={{
              background: getBorderTemplate(border3),
            }}
          >
            <p className="mb-0 lg:text-2xl text-md lg:mb-3">Biggest Jackpots</p>
            {!data?.biggestWinningPrize ? (
              <Skeleton containerClassName="w-40" className="h-[26px] lg:h-[39px]" />
            ) : (
              <CountUpNumber
                className="text-2xl font-bold text-gradient gold lg:text-4xl"
                startCounting={isCountVisible}
                end={data?.biggestWinningPrize ?? 0}
                decimals={0}
                prefix="$"
              />
            )}
          </motion.div>
          <motion.div
            variants={slideUpVariants}
            className="px-2 card-border-gradient-gray-to-white py-7 lg:py-9"
            onHoverStart={() => animateBorderIn(border4)}
            onHoverEnd={() => animateBorderOut(border4)}
            style={{
              background: getBorderTemplate(border4),
            }}
          >
            <p className="mb-0 lg:text-2xl text-md lg:mb-3">Total Prize Distribution</p>
            {!data?.paidPrize ? (
              <Skeleton containerClassName="w-40" className="h-[26px] lg:h-[39px]" />
            ) : (
              <CountUpNumber
                className="text-2xl font-bold text-gradient gold lg:text-4xl"
                startCounting={isCountVisible}
                end={data?.paidPrize ?? 0}
                decimals={0}
                prefix="$"
              />
            )}
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
}
