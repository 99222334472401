import clsx from "clsx";
import { BigNumberish } from "ethers";
import { AnimatePresence, motion, usePresence } from "framer-motion";
import { ReactNode, useEffect, useMemo, useState } from "react";
import { config } from "../../config";
import { Buyer, usePayoutRate, useRoundInfos } from "../../hooks/contracts/multiplier-box";
import { EmptyState } from "../../portion-box/EmptyState";
import { bigNumberToFormattedTime, compactAddress } from "../../utils/formatter";
import { useCurrentRoundState } from "../play/CurrentRoundProvider";
import { isNumberWinning } from "../utils/gameWinning";

type Props = {
  buyers: Buyer[];
};

const INITIAL_BUYER_COUNT = 50;
const BUYER_SHOW_PER_INTERVAL = 1;

export const TableLive = ({ buyers }: Props) => {
  const roundIds = useMemo(
    () =>
      Array.from(
        buyers.reduce((prev, cur) => {
          prev.add(cur.roundId);
          return prev;
        }, new Set<BigNumberish>())
      ),
    [buyers]
  );

  const { currentRound, digits = 1, gameAddress = "" } = useCurrentRoundState();
  const roundInfos = useRoundInfos(roundIds, gameAddress);
  const currentPayout = usePayoutRate(currentRound?.roundId, gameAddress);
  const [showBuyerCount, setShowBuyerCount] = useState(INITIAL_BUYER_COUNT);

  const buyersWithResult = useMemo(() => {
    const rounds = roundInfos ?? [];

    return buyers.flatMap((buyer) => {
      const roundInfo = rounds.find((info) => info.roundId.eq(buyer.roundId));
      const usedPayout = currentRound?.roundId?.eq(buyer.roundId)
        ? currentPayout.rate
        : roundInfo?.payout;

      const payoutRate = (usedPayout?.toNumber() ?? 0) / 100;

      return buyer.number.map((number, index) => {
        const status = roundInfo?.executedTime.eq(0)
          ? "pending"
          : roundInfo && isNumberWinning(number, roundInfo.answer, buyer.game)
          ? "win"
          : "lose";

        const amount = (buyer.amounts[index] * (100 - config.multiplierBox.feePercent)) / 100;

        return {
          ...buyer,
          amount,
          number,
          status: status as typeof status,
          payoutRate,
          roundInfo,
          payout: status === "lose" ? amount : payoutRate * amount,
        };
      });
    });
  }, [buyers, roundInfos, currentPayout, currentRound]);

  // useEffect(() => {
  //   setShowBuyerCount(INITIAL_BUYER_COUNT);
  // }, [buyers]);

  // useEffect(() => {
  //   if (buyersWithResult.length - showBuyerCount <= 0) {
  //     setShowBuyerCount(buyersWithResult.length);
  //     return;
  //   }

  //   const timeout = setTimeout(() => {
  //     setShowBuyerCount((prev) => prev + BUYER_SHOW_PER_INTERVAL);
  //   }, Math.random() * 4000);

  //   return () => clearTimeout(timeout);
  // }, [showBuyerCount, buyersWithResult]);

  if (buyers.length === 0) {
    return (
      <EmptyState className="lg:mt-48 mt-36">Don't have any number in this round yet.</EmptyState>
    );
  }

  return (
    <div className="overflow-x-scroll">
      <div className="overflow-y-auto lg:h-[510px] h-[410px] pb-10 no-scrollbar min-w-[500px]">
        <div className="grid grid-cols-12 text-[#a7a1a1] md:text-sm text-xs font-light text-left mt-6 lg:mb-2 mb-0 ">
          <p className="col-span-2 pl-3 pr-2 text-center lg:pl-8 md:pl-5 lg:pr-8 md:pr-5">Number</p>
          <p className="col-span-2 px-1 pl-4 lg:px-8 md:px-5 lg:pl-0">Round</p>
          <p className="col-span-2 px-4 lg:px-0 md:px-5">Play</p>
          <p className="col-span-2 px-1 lg:px-8 md:px-5 lg:block">Payout Rate</p>
          <p className="col-span-2 px-1 lg:px-8 md:px-5 lg:block">Payout</p>
          <p className="col-span-2 px-1 lg:px-8 md:px-5">Player</p>
        </div>
        <AnimatePresence>
          {buyersWithResult
            // .slice(buyersWithResult.length - showBuyerCount, buyersWithResult.length)
            .map((buyer, i) => (
              <ListItem
                key={buyer.id.toString() + buyer.number.toString() + buyer.roundId.toString()}
              >
                <div
                  className="grid items-center grid-cols-12 my-2 text-sm text-left bg-blue-gray rounded-xl lg:text-xl lg:my-3"
                  key={i}
                >
                  <p className="col-span-2 tracking-[0.5rem] text-center py-3 pl-4 pr-2 space-x-4 font-bold lg:pl-8 md:pl-5 lg:pr-8 md:pr-5 text-gradient gold">
                    {buyer.number.toString().padStart(digits, "0")}
                  </p>
                  <div className="col-span-2 px-1 py-3 pl-4 lg:pl-0 lg:px-8 md:px-5">
                    <p className="text-xs font-normal">Round {buyer.roundId}</p>
                    <p className="text-[10px] font-light lg:text-xs">
                      {bigNumberToFormattedTime(buyer.roundInfo?.closeTime)}
                    </p>
                  </div>
                  <p className="col-span-2 px-0 py-3 font-bold lg:px-1">
                    $
                    {buyer.amount.toLocaleString("en-US", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </p>

                  <p className="col-span-2 px-1 py-3 font-bold text-center lg:px-8 md:px-5 lg:block">
                    X{buyer.payoutRate}
                  </p>
                  <p className="col-span-2 px-1 py-3 text-base font-bold lg:px-8 md:px-5 lg:block">
                    {buyer.status === "pending" ? (
                      <span className="text-gradient gold">WAITING..</span>
                    ) : (
                      <span
                        className={clsx(
                          buyer.status === "win" && "text-green-500",
                          buyer.status === "lose" && "text-slate-500"
                        )}
                      >
                        {buyer.status === "lose" ? "-" : buyer.status === "win" && "+"}$
                        {buyer.payout.toLocaleString("en-US", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                      </span>
                    )}
                  </p>
                  <p className="col-span-2 px-1 py-3 text-base font-bold lg:px-8 md:px-5">
                    {compactAddress(buyer.user)}
                  </p>
                </div>
              </ListItem>
            ))}
        </AnimatePresence>
      </div>
    </div>
  );
};

const transition = { type: "spring", stiffness: 200, damping: 20, mass: 1 };

function ListItem({ children }: { children: ReactNode }) {
  const [isPresent, safeToRemove] = usePresence();

  return (
    <motion.div
      layout
      initial="out"
      animate={isPresent ? "in" : "out"}
      transition={transition}
      style={{ position: isPresent ? "static" : "absolute" }}
      variants={{
        in: { translateY: 0, opacity: 1 },
        out: { translateY: -30, opacity: 0, zIndex: -1 },
      }}
      onAnimationComplete={() => !isPresent && safeToRemove()}
    >
      {children}
    </motion.div>
  );
}
