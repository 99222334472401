import { TabGroupMode } from "./TabGroupMode";
import { TabGroupAssets } from "../prediction-pro/TabGroupAssets";
import { useGameContext } from "../context/PredictionGameContext";
import { Tab, tabIndexes } from "../prediction-pro/SelectAsset";
import { useEffect } from "react";
import useMenu from "../hooks/useMenu";
import { useAssetStore } from "../store/asset-store";

export function NavBarMode() {
  const { setActiveTabIndex } = useMenu();
  const game = useGameContext()
  const { asset, setAsset } = useAssetStore()
  
  const classicInitialTabIndex = game === "up-down" ? 0 : -1
  const proInitialTabIndex = game === "high-low" ? 0 : game === "even-odd" ? 1 : -1

  useEffect(() => {
    let tab = asset.replace("#", "");    
    const index = tabIndexes.indexOf(tab as Tab);    
    setActiveTabIndex(tabIndexes[index]);
  }, [asset, setActiveTabIndex]);  

  return (
    <div className="fixed top-[65px] inset-x-0 bg-[#151515] z-30">
      <div className="bg-[#151515] py-2">
        <div className="flex flex-col lg:justify-between lg:flex-row">
          <div className="flex flex-row px-2 mx-auto lg:space-x-5 md:justify-center lg:mx-8">
            {/* <div className="flex flex-col items-center space-x-3 lg:flex-row">
              <div className="flex lg:flex-col flex-row text-[#818181] lg:text-sm text-xs text-right space-x-2">
                <div>CLASSIC</div>
                <div>MODE</div>
              </div>

              <div>
                <TabGroupMode
                  tabs={["UP / DOWN"]}
                  className="mt-1 text-xs lg:text-sm lg:mt-0"
                  buttonClassName="lg:py-2 py-1"
                  initialTabIndex={classicInitialTabIndex}
                  imageName={["up-down"]}
                />
              </div>
            </div> */}

            <div className="flex flex-col items-center space-x-3 lg:flex-row">
              <div className="flex lg:flex-col flex-row text-[#818181] lg:text-sm text-xs text-right space-x-2">
                <div>PRO <br/> MODE</div>
              </div>

              <div className="">
                <TabGroupMode
                  tabs={["HIGH / LOW"]}
                  className="mt-1 text-xs lg:text-sm lg:mt-0"
                  buttonClassName="lg:py-2 py-1"
                  initialTabIndex={proInitialTabIndex}
                  imageName={["high-low", "even-odd"]}
                />
              </div>
            </div>
          </div>
          <hr className="w-full py-1 mt-3 border-dashed border-smoke lg:hidden border-1"></hr>
          <div className="flex mx-auto md:justify-center lg:mx-8">
            <TabGroupAssets
              tabs={["BTC"]}
              tabClassName=""
              className="text-xs lg:text-sm"
              buttonClassName="lg:py-2 py-1 text-base lg:px-6 md:px-4 px-3"
              comingSoonClassName="py-2 lg:pl-5 pl-2 lg:pr-8 pr-1"
              initialTabIndex={0}
              imageName={["btc"]}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
