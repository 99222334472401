import { useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { Button, Loading, useNotificationContext } from "../common";
import { useClaimReward, useTickets } from "../hooks/contracts";
import { PrizeItem } from "./PrizeItem";
import { useMultiplierBoxWrite, useUserReward } from "../hooks/contracts/multiplier-box";
import { address as contractAddress } from "../config";

export function MyReward() {
  const [isDecimalLoading, setIsDecimalLoading] = useState(false);
  const [isMaxLoading, setIsMaxLoading] = useState(false);

  const { pushNotification } = useNotificationContext();
  const { address = "" } = useAccount();
  const tickets1 = useTickets(address, 1);
  const tickets2 = useTickets(address, 2);
  const tickets3 = useTickets(address, 3);
  const tickets4 = useTickets(address, 4);

  const claimDecimal1 = useClaimReward(tickets1.winTickets);
  const claimDecimal2 = useClaimReward(tickets2.winTickets);
  const claimDecimal3 = useClaimReward(tickets3.winTickets);
  const claimDecimal4 = useClaimReward(tickets4.winTickets);

  const totalDecimalPrize = useMemo(() => {
    return (
      tickets1.prizeNumber + tickets2.prizeNumber + tickets3.prizeNumber + tickets4.prizeNumber
    );
  }, [tickets1, tickets2, tickets3, tickets4]);

  const oneDigitReward = useUserReward(address, contractAddress.BB_MAX_ONE_DIGIT_ADDRESS);
  const twoDigitReward = useUserReward(address, contractAddress.BB_MAX_LOTTERY_ADDRESS);

  const rewardRoundIds = useMemo(() => {
    const rewardRoundIds = oneDigitReward.reward.rewardRoundIds.concat(
      twoDigitReward.reward.rewardRoundIds
    );
    return Array.from(new Set(rewardRoundIds));
  }, [oneDigitReward.reward, twoDigitReward.reward]);

  const totalMaxReward = useMemo(
    () => oneDigitReward.reward.reward + twoDigitReward.reward.reward,
    [oneDigitReward, twoDigitReward]
  );

  const { writeAsync: claimMaxAsync } = useMultiplierBoxWrite("claim", [rewardRoundIds]);

  async function executeClaim(claim?: () => Promise<void>, refetch?: () => void) {
    setIsDecimalLoading(true);
    try {
      await claim?.();
      pushNotification({
        key: Math.random().toString(),
        type: "success",
        text: "You've claimed the rewards!",
      });
      refetch?.();
    } finally {
      setIsDecimalLoading(false);
    }
  }

  async function executeMaxClaim() {
    setIsMaxLoading(true);
    try {
      const tx = await claimMaxAsync?.();
      await tx.wait(3);
      pushNotification({
        key: Math.random().toString(),
        type: "success",
        text: "You've claimed the rewards!",
      });
      oneDigitReward.refetch();
      twoDigitReward.refetch();
    } finally {
      setIsMaxLoading(false);
    }
  }

  return (
    <div className="mt-20">
      <p className="text-3xl text-center uppercase font-audiowide">MY reward</p>
      <div className="grid items-start grid-cols-1 gap-20 mt-20 lg:gap-5 lg:grid-cols-3">
        <div className="card-border-light-gradient-silver-bg-black-to-blue">
          <div className="flex flex-col items-center px-8">
            <img
              alt=""
              className="relative -mt-10 lg:w-32 w-28"
              src={require("../assets/icon/decimal.png")}
            />
            <p className="mt-5 text-2xl font-bold">Decimal Box</p>
            <p className="mt-3 text-3xl font-bold text-gradient gold">
              ${totalDecimalPrize.toLocaleString(undefined, { maximumFractionDigits: 5 })}
            </p>
          </div>

          <div className="relative overflow-hidden rounded-b-2xl">
            {isDecimalLoading && <Loading />}
            <PrizeItem
              title="2 Digits"
              prize={tickets2.prizeNumber}
              onClaim={() => executeClaim(claimDecimal2, tickets2.refetchTickets)}
            />
          </div>
        </div>

        <div className="pb-8 card-border-light-gradient-silver-bg-black-to-blue h-max">
          <div className="flex flex-col items-center px-8">
            <img
              alt=""
              className="relative -mt-10 lg:w-32 w-28"
              src={require("../assets/icon/prediction2.png")}
            />
            <p className="mt-5 text-2xl font-bold">Prediction Box</p>
            <p className="mt-3 text-2xl font-bold text-gradient gold">Coming Soon</p>
          </div>
        </div>
        <div className="flex flex-col space-y-10 lg:space-y-3">
          <div className="relative pb-8 card-border-light-gradient-silver-bg-black-to-blue h-max">
            {isMaxLoading && (
              <div className="absolute inset-0 m-1">
                <Loading />
              </div>
            )}
            <div className="flex flex-col items-center px-8">
              <img
                alt=""
                className="relative z-10 -mt-10 lg:w-32 w-28"
                src={require("../assets/icon/bb-max2.png")}
              />
              <p className="mt-5 text-2xl font-bold">BB Max</p>
              <p className="mt-3 text-3xl font-bold text-gradient gold">
                ${totalMaxReward.toLocaleString(undefined, { maximumFractionDigits: 5 })}
              </p>
              {totalMaxReward > 0 ? (
                <Button
                  preset="gradient"
                  buttonSize="md"
                  className="w-40 px-2 mt-6 uppercase"
                  onClick={executeMaxClaim}
                >
                  Claim
                </Button>
              ) : (
                <Button preset="opacity" buttonSize="md" className="w-40 px-2 mt-6 uppercase">
                  Claimed
                </Button>
              )}
            </div>
          </div>

          <div className="relative w-full px-4 py-4 mx-auto overflow-hidden card-border-brown-bg-blue">
            <p className="text-xl font-bold">Claim Condition</p>
            <p className="mt-2 text-sm font-light">
              Fee of claim reward condition Fee of claim reward condition
            </p>
            <img
              className="absolute top-0 right-0 rounded-r-lg lg:w-40 w-36"
              src={require("../assets/question.png")}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
