import { BigNumber, constants, utils } from "ethers";
import { useCallback, useMemo, useState } from "react";
import { erc20ABI, useAccount, useBalance, useContractRead } from "wagmi";
import { InputPercentSlider } from "../common";
import { Button } from "../common/Button";
import { Loading } from "../common/Loading";
import { Modal } from "../common/Modal";
import { useNotificationContext } from "../common/NotificationProvider";
import { config, predictionAddr, predictionProAddr, tokenAddr } from "../config";
import { PredictionGame } from "../context/PredictionGameContext";
import { usePredictionV2Write } from "../hooks/contracts/usePredictionV2Write";
import { usePredictionV3Write } from "../hooks/contracts/usePredictionV3Write";
import { useTokenWrite } from "../hooks/contracts/useTokenWrite";
import useMenu from "../hooks/useMenu";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { validateAmountNumber } from "../utils/vadlidator";
import { PlayType } from "./CardPlay";
import { Tab } from "./SelectAsset";

const MAX_DECIMAL_PLACES = 3;

type SetPositionProp = {
  currentEpoch: number;
  keyTab: Tab;
  isOpen: boolean;
  playType: PlayType;
  game?: PredictionGame;
  pro?: boolean;
  maxAmount?: number;
  onCloseModal: () => void;
  onSuccessChanged: (isSuccess: boolean) => void;
};

const positionInfo = {
  "up-down": {
    titleBear: "Going Down",
    titleBull: "Going Up",
    iconBear: require("../assets/icon/arrow-down.png"),
    iconBull: require("../assets/icon/arrow-up.png"),
  },
  "high-low": {
    titleBear: "Low",
    titleBull: "High",
    iconBear: require("../assets/btn-down.png"),
    iconBull: require("../assets/btn-up.png"),
  },
  "even-odd": {
    titleBear: "EVEN",
    titleBull: "ODD",
    iconBear: require("../assets/icon/even2.png"),
    iconBull: require("../assets/icon/odd2.png"),
  },
} as const;

export function SetPositionModal({
  currentEpoch,
  keyTab,
  isOpen,
  playType,
  pro,
  maxAmount,
  game = "up-down",
  onCloseModal,
  onSuccessChanged,
}: SetPositionProp) {
  const { pushNotification } = useNotificationContext();
  const predictionAddress = useMemo(
    () => (pro ? predictionProAddr[game][keyTab] : predictionAddr[keyTab]),
    [pro, keyTab, game]
  );

  const { writeAsync: executeApprove } = useTokenWrite(
    tokenAddr[keyTab],
    utils.isAddress(predictionAddress),
    "approve",
    [predictionAddress, constants.MaxUint256]
  );

  const { address: accountAddress } = useAccount();
  const rewardCurrency = usePredictionRewardCurrency(keyTab.toString());

  const balance = useBalance({
    addressOrName: accountAddress,
    token: tokenAddr[keyTab],
    watch: true,
  });

  const userBalance = useMemo(() => {
    if (maxAmount) {
      return maxAmount.toString();
    }
    return balance.data?.formatted ?? "0";
  }, [balance, maxAmount]);

  const allowance = useContractRead({
    addressOrName: tokenAddr[keyTab],
    contractInterface: erc20ABI,
    functionName: "allowance",
    args: [accountAddress, predictionAddress],
    watch: true,
    enabled: !!accountAddress,
  });

  const { data: userAllowance, refetch: refetchAllowance } = allowance;

  const [amount, setAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const amountNumber = useMemo(() => (Number.isNaN(Number(amount)) ? 0 : Number(amount)), [amount]);

  const amountEther = useMemo(() => {
    if (keyTab === "MATIC") {
      return utils.parseEther(amountNumber.toString());
    } else {
      return utils.parseUnits(amountNumber.toString(), config.stablecoinDecimals);
    }
  }, [amountNumber, keyTab]);

  const { activeTabIndex } = useMenu();

  const { writeAsync: betBullV2 } = usePredictionV2Write("betBull", currentEpoch, amountEther);
  const { writeAsync: betBearV2 } = usePredictionV2Write("betBear", currentEpoch, amountEther);

  const { writeAsync: betBull3 } = usePredictionV3Write(predictionAddress, "betBull", [
    currentEpoch,
    amountEther,
  ]);

  const { writeAsync: betBear3 } = usePredictionV3Write(predictionAddress, "betBear", [
    currentEpoch,
    amountEther,
  ]);

  let btnPersents = [
    { number: 0.1, text: "10%" },
    { number: 0.25, text: "25%" },
    { number: 0.5, text: "50%" },
    { number: 0.75, text: "75%" },
    { number: 1, text: "MAX" },
  ];
  const propertyPlayType = {
    UP: {
      color: "",
      text: "",
      icon: "",
    },
    DOWN: {
      color: "",
      text: "",
      icon: "",
    },
  };

  const usedPropPlayType = propertyPlayType[playType];

  if (playType === "UP") {
    usedPropPlayType.color = "bg-[#46b900]";
    usedPropPlayType.text = positionInfo[game].titleBull;
    usedPropPlayType.icon = positionInfo[game].iconBull;
  } else {
    usedPropPlayType.color = "bg-[#df473b]";
    usedPropPlayType.text = positionInfo[game].titleBear;
    usedPropPlayType.icon = positionInfo[game].iconBear;
  }

  async function confirm() {
    setIsLoading(true);

    const selectToken = activeTabIndex;
    const betBull = selectToken === "MATIC" ? betBullV2 : betBull3;
    const betBear = selectToken === "MATIC" ? betBearV2 : betBear3;

    let txHash = "";
    let waitPromise: Promise<any>;

    try {
      if (playType === "UP") {
        const result = await betBull();
        txHash = result.hash;
        waitPromise = result.wait();
      } else {
        const result = await betBear();
        txHash = result.hash;
        waitPromise = result.wait();
      }

      pushNotification({
        type: "sendTx",
        txhash: txHash,
        key: txHash,
      });
      await waitPromise;

      onSuccessChanged(true);
      onCloseModal();
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  const amountPercent = useMemo(() => {
    const percent = (amountNumber * 100) / parseFloat(userBalance);
    return Number.isNaN(percent) || !Number.isFinite(percent) ? 0 : percent;
  }, [amountNumber, userBalance]);

  function changePercent(el: number) {
    setAmount(el.toFixed(MAX_DECIMAL_PLACES));
  }

  const approve = useCallback(async () => {
    setIsLoading(true);
    try {
      const result = await executeApprove?.();
      await result?.wait();
      await refetchAllowance();
    } finally {
      setIsLoading(false);
    }
  }, [executeApprove]);

  const updateAmount = useCallback(
    (value: string) => {
      if (validateAmountNumber(value, { maxDecimalPlaces: MAX_DECIMAL_PLACES })) {
        const userBalanceNumber = Number(userBalance);
        const amountNumber = Number(value);
        if (amountNumber > userBalanceNumber) {
          setAmount(userBalance);
        } else {
          setAmount(value);
        }
      }
    },
    [userBalance]
  );

  return (
    <Modal active={isOpen} onDismiss={onCloseModal}>
      <div className="modal-card max-w-[420px]">
        {isLoading && <Loading />}
        <header className="flex justify-between px-4 py-3 border-b-0 rounded-t-lg bg-gradient-blue">
          <p className="text-xl text-white uppercase modal-card-title font-audiowide">
            SET POSITION
          </p>
          <button onClick={onCloseModal}>
            <img src={require("../assets/icon/close.png")} alt="" className="w-4" />
          </button>
        </header>
        <section className="p-0 divide-y modal-card-body bg-dark-blue-gray rounded-b-2xl divide-dashed">
          <div className="px-4 pt-3 md:px-7 pb-7">
            <div
              className={`flex justify-center items-center rounded-xl p-2 px-4 mt-2 ${usedPropPlayType.color}`}
            >
              <img src={usedPropPlayType.icon} alt="" className="w-4 mr-2" />
              <p className="text-sm font-bold text-white">{usedPropPlayType.text}</p>
            </div>
            <div className="flex items-center justify-between mt-4">
              <p className="text-xs font-medium text-gradient gold">Commit:</p>
              <p className="text-xs font-normal text-brownish-grey">
                Balance {balance.data?.formatted} {rewardCurrency}
              </p>
            </div>

            <div className="relative mt-2">
              <input
                className="bg-[#080d1c] text-white text-2xl font-bold text-right w-full pr-16 py-3 rounded-xl"
                placeholder="0.00"
                onChange={({ target }) => {
                  updateAmount(target.value);
                }}
                value={amount}
              />
              <div className="absolute flex flex-col justify-center top-2 lg:top-3 right-4">
                <img
                  src={require(`../assets/prediction/${rewardCurrency.toLowerCase()}.png`)}
                  alt=""
                  className="w-5 ml-1"
                />
                <p className="text-xs font-semibold text-white">{rewardCurrency}</p>
              </div>
              {maxAmount !== undefined && maxAmount > 0 && (
                <div className="mt-3 text-xs text-right">
                  <span className="text-white/40">Limit </span>
                  <span className="text-white">
                    (${config.predictionMaxAmount - maxAmount} / ${config.predictionMaxAmount})
                  </span>
                </div>
              )}
              {maxAmount !== undefined && maxAmount === 0 && (
                <div className="mt-3 text-xs text-right text-white/40">
                  <span className="text-white/40">Limit </span>
                  <span className="text-red-600/80">
                    (${config.predictionMaxAmount - maxAmount} / ${config.predictionMaxAmount})
                  </span>
                </div>
              )}
            </div>

            <InputPercentSlider
              value={amountPercent}
              size="md"
              className="mt-8"
              onChange={(percent) => {
                changePercent((percent * parseFloat(userBalance)) / 100);
              }}
            />

            <div className="grid w-full grid-cols-5 gap-2 mt-9">
              {btnPersents.map((btnPersent, index) => {
                return (
                  <button
                    key={index}
                    className="py-2 text-center card-border-brown-bg-blue"
                    onClick={() => changePercent(btnPersent.number * parseFloat(userBalance))}
                  >
                    <p className="text-sm font-bold text-white">{btnPersent.text}</p>
                  </button>
                );
              })}
            </div>
            <div className="my-5 text-center">
              <span className="text-xs">Note: I acknowledge that I have read the </span>
              <span className="text-xs font-bold text-gold">BillionBox Term & Conditions</span>
              <div className="text-xs text-center">3% House edge</div>
            </div>

            {userAllowance && BigNumber.from(userAllowance).lte(0) && activeTabIndex !== "MATIC" ? (
              <Button
                className="w-full uppercase"
                preset="gold"
                buttonSize="sm"
                disabled={amountPercent === 0}
                onClick={() => {
                  approve?.();
                }}
              >
                APPROVE
              </Button>
            ) : amountNumber > 0 ? (
              <Button className="w-full uppercase" preset="gold" buttonSize="sm" onClick={confirm}>
                CONFIRM
              </Button>
            ) : (
              <Button className="w-full uppercase" preset="disabled" buttonSize="sm">
                Enter an amount
              </Button>
            )}
            <div className="flex items-start justify-center mt-4 md:items-center">
              <img src={require("../assets/icon/question.png")} alt="" className="w-4 mr-2" />
              <p className="text-xs">
                Once you enter your position, you cannot remove or change it.
              </p>
            </div>
          </div>
        </section>
      </div>
    </Modal>
  );
}
