import clsx from "clsx";
import { CurrentPrice } from "./CurrentPrice";
import { HistortAndChart } from "./HistortAndChart";
import { IndexTab } from "./LetsPlay";
import { Time } from "./Time";

type Props = {
  className?: string
}

export function Dashboard({ index, keyTab, className }: IndexTab & Props) {
  return (
    <div className={clsx("bg-black lg:px-12", className)}>
      <div className="relative z-10 grid grid-cols-2 gap-2 mx-auto -mt-14 lg:-mt-28 lg:grid-cols-10 md:gap-4">
        <CurrentPrice index={index} keyTab={keyTab} />
        <Time index={index} keyTab={keyTab} />
        <HistortAndChart />
      </div>
    </div>
  );
}
