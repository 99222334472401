import { BigNumberish, utils } from "ethers";
import { useEffect, useMemo } from "react";
import { config } from "../../../config";
import { isNumberWinning } from "../../../multiplier-box/utils/gameWinning";
import { useMultiplierBoxStartRoundEvent } from "./useMultiplierBoxStartRoundEvent";
import { usePlayInfo } from "./usePlayInfo";

export const useUserReward = (account: string, game: string) => {
  const { roundInfos, playInfos, refetch } = usePlayInfo(account, game);

  const userReward = useMemo(() => {
    let reward = 0;
    let rewardRoundIds: BigNumberish[] = [];

    if (roundInfos && playInfos) {
      for (let index = 0; index < playInfos.length; index++) {
        const round = roundInfos[index];
        const roundPlayInfos = playInfos[index];

        for (const playInfo of roundPlayInfos) {
          if (playInfo.game !== game) continue;
          if (!playInfo.claimed && isNumberWinning(playInfo.number, round.answer, game)) {
            reward += Number(
              utils.formatUnits(
                playInfo.amount.mul(round.payout).div(100),
                config.stablecoinDecimals
              )
            );
            rewardRoundIds.push(roundInfos[index].roundId);
          }
        }
      }
    }
    return { reward, rewardRoundIds };
  }, [roundInfos, playInfos]);

  const newRoundId = useMultiplierBoxStartRoundEvent();

  useEffect(() => {
    if (newRoundId) {
      refetch();
    }
  }, [newRoundId, refetch]);

  return {
    refetch,
    reward: userReward,
  };
};
