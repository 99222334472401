import { useEffect, useMemo } from "react";
import { useAccount } from "wagmi";
import { NotificationProvider } from "../common";
import { useMultiplierBoxStartRoundEvent, useUserReward } from "../hooks/contracts/multiplier-box";
import { ClaimButton } from "./ClaimButton";
import { config, address as contractAddress } from "../config";

type RewardProps = { className?: string };

export const Reward = ({ className }: RewardProps) => {
  const { address = "" } = useAccount();
  const oneDigitReward = useUserReward(address, contractAddress.BB_MAX_ONE_DIGIT_ADDRESS);
  const twoDigitReward = useUserReward(address, contractAddress.BB_MAX_LOTTERY_ADDRESS);

  const rewardRoundIds = useMemo(() => {
    const rewardRoundIds = oneDigitReward.reward.rewardRoundIds.concat(
      twoDigitReward.reward.rewardRoundIds
    );
    return Array.from(new Set(rewardRoundIds));
  }, [oneDigitReward.reward, twoDigitReward.reward]);

  const totalReward = useMemo(
    () => oneDigitReward.reward.reward + twoDigitReward.reward.reward,
    [oneDigitReward, twoDigitReward]
  );

  const newRoundId = useMultiplierBoxStartRoundEvent();

  useEffect(() => {
    if (newRoundId) {
      refetch();
    }
  }, [newRoundId, oneDigitReward, twoDigitReward]);

  function refetch() {
    oneDigitReward.refetch();
    twoDigitReward.refetch();
  }

  return (
    <div
      className={`relative items-center justify-between px-10 py-5 overflow-hidden border-gold ${className}`}
    >
      <div>
        <div>Your Reward</div>
        <div className="text-2xl font-bold text-gradient gold">
          $
          {totalReward.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}
        </div>
      </div>
      <div className="flex flex-col items-center mt-3 lg:mt-0 lg:items-start gap-y-3">
        <NotificationProvider gold>
          <ClaimButton
            disabled={totalReward === 0}
            rewardRoundIds={rewardRoundIds}
            onClaimed={refetch}
          />
        </NotificationProvider>
      </div>
      <img
        alt=""
        className="absolute bottom-0 left-0 h-full opacity-80"
        src={require("../assets/money-bag.png")}
      />
    </div>
  );
};
