import { TabGroup } from "../common/TabGroup";
import { TableDecimalBox } from "./TableDecimalBox";
import { TablePredictionBox } from "./TablePredictionBox";
import { TableBBMax } from "./TableBBMax";
import { useState } from "react";

export function MyHistory() {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  return (
    <div className="relative bg-gradient-black-to-blue-r">
       <img
        alt=""
        className="absolute top-0 left-0 md:w-96 w-72"
        src={require("../assets/cube-trans4.png")}
      />
        <img
        alt=""
        className="absolute bottom-0 right-0 md:w-96 w-72"
        src={require("../assets/cube-trans5.png")}
      />
      <div className="absolute inset-x-0 top-0 h-[200px] bg-gradient-to-b from-black z-0"></div>
      <div className="absolute inset-x-0 bottom-0 h-[150px] bg-gradient-to-t from-black z-0"></div>
      <div className="container relative z-10 pb-20 mx-auto ">
        <p className="pt-16 text-3xl text-center uppercase font-audiowide">MY HISTORY</p>

        {/* <TabGroup
          tabs={["Decimal Box", "Prediction Box", "BB Max"]}
          className="mx-auto mt-8 lg:w-max"
          buttonClassName="px-6 py-3 lg:py-3 w-1/3 lg:w-[170px] md:text-base text-[10px] font-semibold" 
          onTabChange={setActiveTabIndex}
        /> */}
        {activeTabIndex === 0 && <TableDecimalBox />}
        {activeTabIndex === 1 && <TablePredictionBox />}
        {activeTabIndex === 2 && <TableBBMax />}
      </div>
    </div>
  );
}
