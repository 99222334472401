import { Variants } from "framer-motion";

export const gradientVariants: Variants = {
  hidden: {
    gradientTransform: "rotate(60)",
  },
  visible: {
    gradientTransform: "rotate(-30)",
    transition: {
      type: "spring",
      duration: 4,
    },
  },
};
