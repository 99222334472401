import { useCallback, useEffect, useMemo } from "react";
import { useMultiplierBoxPlayEvent } from "./useMultiplierBoxPlayEvent";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";
import { useMultiplierBoxStartRoundEvent } from "./useMultiplierBoxStartRoundEvent";
import { useRoundInfos } from "./useRoundInfos";

export const usePlayInfo = (account: string, game: string) => {
  const { data: positions, contractRead: getPositionRead } = useMultiplierBoxRead({
    functionName: "getPositions",
    args: account,
    enabled: !!account,
  });

  const roundIds = useMemo(() => {
    const roundIdMapped: Record<number, number> = {};
    return (
      positions?.reduce((acc, position) => {
        if (position.game !== game) {
          return acc;
        } else if (roundIdMapped[position.roundId.toNumber()]) {
          return acc;
        } else {
          roundIdMapped[position.roundId.toNumber()] = 1;
          return [...acc, position.roundId.toNumber()];
        }
      }, [] as number[]) ?? []
    ).sort((a, b) => b - a);
  }, [positions]);

  const roundInfos = useRoundInfos(roundIds, game);

  const { data: playInfos, contractRead: playInfoRead } = useMultiplierBoxRead({
    functionName: "getPlayInfosByRoundIds",
    args: [account, roundIds, 0, 500],
    enabled: !!account,
  });

  useMultiplierBoxPlayEvent({
    account,
    listener() {
      playInfoRead.refetch();
    },
  });

  const newRoundId = useMultiplierBoxStartRoundEvent();

  const refetch = useCallback(() => {
    getPositionRead.refetch();
    playInfoRead.refetch();
  }, [getPositionRead, playInfoRead]);

  useEffect(() => {
    if (newRoundId) {
      refetch();
    }
  }, [newRoundId, refetch]);

  return { playInfos, roundInfos, refetch };
};
