import { CountUpNumber } from "../common/CountUpNumber";
import { useBinancePrice } from "../hooks/useBinancePrice";

type PriceProps = { className?: string };

export const Price = ({ className }: PriceProps) => {
  const price = useBinancePrice("btc");
  return (
    <div
      className={`flex flex-col items-center flex-1 py-6 card-border-gradient-gold-bg-black border-sm ${className}`}
    >
      <div className="flex flex-row items-center space-x-2">
        <img className="w-6" src={require("../assets/icon/btc.png")} alt="" />
        <span className="text-gradient gold">Current Price</span>
      </div>
      <div className="mt-1 text-xl font-bold lg:text-3xl">
        <CountUpNumber startCounting duration={1} end={price ?? 0} decimals={2} prefix="$" />
      </div>
    </div>
  );
};
