import { useEffect, useState } from "react";

export const useCompareCurrentTime = (targetTimestamp?: number) => {
  const [passed, setPassed] = useState(false);

  useEffect(() => {
    if (!targetTimestamp) return;
    setPassed(false);
    
    const interval = setInterval(() => {
      const now = new Date().getTime();
      if (now >= targetTimestamp * 1000) {
        setPassed(true);
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [targetTimestamp]);

  return passed;
};
