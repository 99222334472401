import "@rainbow-me/rainbowkit/styles.css";
import React from "react";
import ReactDOM from "react-dom/client";
import "react-loading-skeleton/dist/skeleton.css";
import "./assets/css/animations.css";
import "./buffer-patch";
import "./index.scss";

import { getDefaultWallets, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { configureChains, createClient, WagmiConfig } from "wagmi";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { publicProvider } from "wagmi/providers/public";
import { EthereumAvatar } from "./common/ethereum-avatar/EthereumAvatar";
import { config } from "./config";
import reportWebVitals from "./reportWebVitals";
import { AppRoutes } from "./routes/AppRoutes";

const supportChains = [config.chain];

const { chains, provider } = configureChains(supportChains, [
  publicProvider({ priority: 3 }),
  jsonRpcProvider({
    priority: 1,
    rpc(chain) {
      return {
        http: "https://polygon-rpc.com",
      };
    },
  }),
  jsonRpcProvider({
    priority: 2,
    rpc(chain) {
      return {
        http: "https://rpc.ankr.com/polygon",
      };
    },
  }),
]);

const { connectors } = getDefaultWallets({ appName: "Billionbox", chains });
const wagmiClient = createClient({
  autoConnect: true,
  connectors,
  provider,
});

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <WagmiConfig client={wagmiClient}>
      <RainbowKitProvider
        chains={supportChains}
        modalSize="compact"
        avatar={EthereumAvatar}
        initialChain={config.chain}
      >
        <AppRoutes />
      </RainbowKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
