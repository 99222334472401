import {
  GameInfo,
  Header,
  LivePlay,
  PlayRound,
  Price,
  Prize,
  Questions,
  Reward,
  WinningCriteria,
} from "../multiplier-box";
import { CurrentRoundProvider } from "../multiplier-box/play/CurrentRoundProvider";

export function MultiplierBox() {
  return (
    <div className="bg-black">
      <Header />
      <div className="container lg:w-[600px] mx-auto">
        <div className="grid grid-cols-2 gap-4 -mt-12">
          <Price />
          <Prize />
        </div>
      </div>
      <div className="container grid grid-cols-1 gap-4 mt-8 lg:grid-cols-2">
        <CurrentRoundProvider>
          <div className="space-y-4">
            <GameInfo />
            <Reward className="flex" />
          </div>
          <PlayRound />
        </CurrentRoundProvider>
      </div>
      <CurrentRoundProvider>
        <LivePlay />
      </CurrentRoundProvider>
      <WinningCriteria />
      <Questions />
    </div>
  );
}
