import { useRequest } from "../hooks/useRequest";
import { compactAddress } from "../utils/formatter";
import { UserHistory } from "./UserHistory";
import { TabGroupMode } from "../common/TabGroupMode";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

type Props = {
  tabGroupMode?: boolean;
};

export const GameRankAndHistory = ({ tabGroupMode }: Props) => {
  const location = useLocation();
  const initialTab = useMemo(() => {
    if (location.pathname.includes("high-low")) {
      return 1;
    } else if (location.pathname.includes("even-odd")) {
      return 2;
    } else {
      return 0;
    }
  }, [location]);

  return (
    <div className="pt-16 bg-black bg-our-game" id="history">
      {/* {tabGroupMode && (
        <TabGroupMode
          tabs={["UP / DOWN", "HIGH / LOW", "EVEN / ODD"]}
          className="mx-auto mb-10 text-sm md:text-lg w-max"
          buttonClassName="py-3 lg:px-8 md:px-6 px-3"
          imageName={["up-down", "high-low", "even-odd"]}
          initialTabIndex={initialTab}
        />
      )} */}

      <div className="container grid grid-cols-5 lg:space-x-4">
        <WinningRanks />
        <UserHistory />
      </div>
    </div>
  );
};

type Rank = {
  prizeAmount: number;
  buyAmount: number;
  user: string;
};

export const WinningRanks = () => {
  const { data } = useRequest<{ ranking: Rank[] }>({ path: "/prediction/ranking" });

  return (
    <div className="col-span-5 mt-12 overflow-hidden border lg:col-span-3 rounded-3xl border-primary lg:mt-0">
      <div className="py-4 text-center bg-gradient-blue">
        <h3 className="text-xl font-semibold text-white uppercase">Winning Ranks</h3>
      </div>
      <div className="grid grid-cols-12 px-4 py-4 text-xs font-light bg-black gap-x-4 text-light-gray lg:text-base">
        <div className="col-span-2">Place</div>
        <div className="col-span-5">Wallet</div>
        <div className="col-span-4">Winning Prize</div>
      </div>
      <div className="h-[400px] overflow-y-scroll no-scrollbar bg-black px-4">
        {data?.ranking.map((rank, index) => (
          <div
            key={rank.user}
            className="rounded-2xl mb-2 grid grid-cols-12 px-4 py-4 bg-[#04132c] gap-x-4 font-semibold lg:text-base text-xs"
          >
            <div className="col-span-2 font-semibold text-gradient gold">#{index + 1}</div>
            <div className="col-span-5">{compactAddress(rank.user)}</div>
            <div className="col-span-4 text-gradient gold">
              ${rank.prizeAmount.toLocaleString()}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
