import { animate, motion, MotionValue, useMotionTemplate, useMotionValue } from "framer-motion";
import { slideUpVariants } from "../animation";
import { RoomStateType } from "../portion-box/RoomDataProvider";
import { TopRoundCountdownDisplay } from "./round-countdown/TopRoundCountdownDisplay";
import { RoomDigit } from "../config";

type DrawInProps = {
  room?: RoomStateType;
  digit: RoomDigit;
  jackpot?: boolean;
};

export function DrawIn({ room, digit, jackpot }: DrawInProps) {
  const finalBorderAngle = 206;
  const border = useMotionValue(finalBorderAngle);
  const bgClass = room
    ? "card-border-gradient-blue-to-yellow-bg-black-to-blue flex flex-col order-2 items-center justify-center py-6"
    : jackpot
    ? "card-border-gradient-gold-bg-black border-sm flex flex-col h-full py-5"
    : "card-border-gradient-gray-to-white py-5";

  const getBorderTemplate = () => {
    return useMotionTemplate`linear-gradient(#060606, #060606) padding-box, linear-gradient(${border}deg, #151515, #5b5d59) border-box`;
  };

  const getMainBorderTemplate = () => {
    return useMotionTemplate`linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(${border}deg, rgba(27, 96, 250, 1) 0%, rgba(2, 13, 30, 1) 50%, rgba(251, 235, 98, 1) 100%) border-box`;
  };

  const getJackpotBorderTemplate = () => {
    return useMotionTemplate`linear-gradient(119deg, #000 34%, #0a172b 56%) padding-box,
    linear-gradient(${border}deg, #fccf61 0%,  #905613 28%,  #965d17 37%, #a87325 51%, #c4973b 68%, #ecc859 88%, #fad964 94%) border-box`;
  };

  const background = room
    ? getMainBorderTemplate()
    : jackpot
    ? getJackpotBorderTemplate()
    : getBorderTemplate();

  const animateBorderIn = (border: MotionValue<number>) => {
    animate(border, 0, { duration: 0.35 });
  };

  const animateBorderOut = (border: MotionValue<number>) => {
    animate(border, finalBorderAngle, { duration: 0.25 });
  };

  return (
    <motion.div
      variants={slideUpVariants}
      className={`${bgClass} px-2 text-center`}
      onHoverStart={() => animateBorderIn(border)}
      onHoverEnd={() => animateBorderOut(border)}
      style={{
        background: background,
      }}
    >
      <TopRoundCountdownDisplay digit={digit} round={room?.round} />
    </motion.div>
  );
}
