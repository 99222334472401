import { useState } from "react";
import { TabGroup } from "../common/TabGroup";
import { WinningRanks } from "../common/WinningRanks";
import { digits } from "../config";
import { useBillionboxRanking } from "../hooks/useBillionboxRanking";

export const AllWinningRanks = () => {
  const [activeTab, setActiveTab] = useState(0);
  const { ranking = [] } = useBillionboxRanking(digits[activeTab]);

  return (
    <WinningRanks
      room={activeTab === 0 ? digits[0] : digits[activeTab]}
      ranks={ranking}
      tabComponent={
        <TabGroup
          tabs={["5 Digits"]}
          className="items-center justify-center mx-auto mt-8 bg-blue-gray lg:w-max rounded-3xl"
          buttonClassName="px-6 py-3 lg:py-3 w-1/3 lg:w-[130px] lg:text-base text-sm lg:font-semibold"
          onTabChange={setActiveTab}
        />
      }
    />
  );
};
