import { memo, useEffect, useState } from "react";
import { useAssetContext } from "../context/PredictionAssetContext";
import { Tab, tabIndexes } from "./SelectAsset";

interface Stringify {
  toString: () => string;
}

type TabGroupProps = {
  tabs: Stringify[];
  className?: string;
  buttonClassName?: string;
  imageName: Stringify[];
  initialTabIndex?: number;
  onTabChange?: (index: number) => void;
};

const _TabGroupEth = ({
  tabs,
  className,
  buttonClassName,
  imageName,
  onTabChange,
  initialTabIndex = 0,
}: TabGroupProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  const asset = useAssetContext();

  useEffect(() => {
    let tab = asset.replace("#", "");
    setActiveTabIndex(tabIndexes.indexOf(tab as Tab));
  }, [asset]);

  function setAssetTab(asset: Tab) {
    window.location.href = `#${asset}`;
  }

  return (
    <div
      className={`tabs bg-blue-gray rounded-full md:text-lg text-sm flex justify-center items-center text-center w-max mx-auto ${className}`}
    >
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveTabIndex(index);
            onTabChange?.(index);
            setAssetTab(tabIndexes[index]);
          }}
          className={`rounded-full py-3 lg:px-12 md:px-10 px-6 ${buttonClassName} ${
            activeTabIndex === index && "blue"
          }`}
        >
          <div className="flex items-center justify-center">
            <img
              className="w-6 mr-1"
              src={require(`../assets/prediction/${imageName[index]}.png`)}
              alt=""
            />
            <p>{tab.toString()}</p>
          </div>
        </button>
      ))}
    </div>
  );
};

export const TabGroupEth = memo(_TabGroupEth);
