import { useContext } from "react";
//
import { TabContext } from "../contexts/MenuContext";

// ----------------------------------------------------------------------

const useMenu = () => {
  const context = useContext(TabContext);

  if (!context) throw new Error("Auth context must be use inside AuthProvider");

  return context;
};

export default useMenu;
