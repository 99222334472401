import { BigNumber } from "ethers";
import { useEffect, useState } from "react";

export const now = (): number => {
  return Math.floor(Date.now() / 1000);
};

export const Time = (totalSeconds: number): string => {
  if (totalSeconds <= 0) return "00:00";
  // 👇️ get number of full minutes
  const minutes = Math.floor(totalSeconds / 60);

  // 👇️ get remainder of seconds
  const seconds = totalSeconds % 60;

  return `${padTo2Digits(minutes)}:${padTo2Digits(seconds)}`;
};

export function padTo2Digits(num: number) {
  return num.toString().padStart(2, "0");
}

export const useCountdown = (lockTimestamp: string) => {
  const [countDown, setCountDown] = useState("00:00");

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(
        !lockTimestamp ? "00:00" : Time(BigNumber.from(lockTimestamp ?? 0).toNumber() - now())
      );
    }, 500);
    
    return () => clearInterval(interval);
  }, [lockTimestamp]);

  return { countDown };
};
