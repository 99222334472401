import { motion } from "framer-motion";
import { slideUpVariants } from "../animation";
import { AnimatedText } from "../common/AnimatedText";

export function Security() {
  const divide = {
    height: "1.5px",
    backgroundColor: "#505250",
  };
  return (
    <div className="bg-gradient-black">
      <div className="container py-16 mx-auto">
        <div className="grid grid-cols-1 gap-10 lg:grid-cols-2">
          <div className="relative px-5 mx-auto overflow-hidden text-center lg:pb-0 pb-9 card-border-gradient-gray-to-white lg:w-9/12">
            <motion.div
              className="blur-3xl bg-white absolute -top-[50%] -bottom-[50%] w-1/2"
              initial={{ x: "-400%", rotate: -45 }}
              animate={{ x: "400%", rotate: -45 }}
              transition={{
                duration: 1,
                repeat: Infinity,
                repeatDelay: 1,
                repeatType: "reverse",
              }}
            />
            <p className="py-5 text-2xl uppercase text-gradient blue lg:text-3xl font-audiowide">
              Security
            </p>
            <p style={divide} className="mb-9" />
            <img alt="" className="mx-auto max-w-[80%]" src={require("../assets/security-score.png")} />

            <p className="text-2xl font-bold uppercase py-7">AuDIT COMPLETE</p>
            <a
              href="https://www.certik.com/projects/billion-box"
              target="_blank"
              rel="noreferrer"
              className="rounded-2xl text-sm lg:text-lg px-7 p-2 lg:mb-4 mb-10 bg-gradient-to-tr from-[#1921ae] to-[#1a60f9] font-bold uppercase"
            >
              View report
            </a>
          </div>

          <motion.div
            className="text-center lg:text-left"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, margin: "0px 0px -20% 0px" }}
            transition={{ staggerChildren: 0.03 }}
          >
            <AnimatedText text="P" />
            <AnimatedText text="R" />
            <AnimatedText text="O" />
            <AnimatedText text="T" />
            <AnimatedText text="O" />
            <AnimatedText text="C" />
            <AnimatedText text="O" />
            <AnimatedText text="L" />
            <br />
            <AnimatedText text="S" gold />
            <AnimatedText text="E" gold />
            <AnimatedText text="C" gold />
            <AnimatedText text="U" gold />
            <AnimatedText text="R" gold />
            <AnimatedText text="I" gold />
            <AnimatedText text="T" gold />
            <AnimatedText text="Y" gold />
            <p className="mt-5 font-light lg:text-2xl">
              Our code smart contract is audited by Top Blockchain Security Audit, with
              aline-by-line inspection of a contract's source code. We use the Smart contract audit
              provider by top exchanges like Binance, OKEx, and Huobi.
            </p>
            <motion.img
              alt=""
              className="pl-12 mx-auto -mt-10 lg:mx-0 lg:pl-0"
              src={require("../assets/secure-by.png")}
              variants={slideUpVariants}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}
