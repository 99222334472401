import { utils } from "ethers";
import { motion } from "framer-motion";
import { useEffect, useMemo, useState } from "react";

import { CountUpNumber } from "../common/CountUpNumber";
import { GradientBorderDiv } from "../common/GradientBorderDiv";
import { Skeleton } from "../common/Skeleton";
import { TabGroup } from "../common/TabGroup";
import { digits } from "../config";
import { useRequest } from "../hooks/useRequest";
import { useRoomState } from "./RoomDataProvider";

export function Banner() {
  const [isCountVisible, setIsCountVisible] = useState(false);

  const rooms = useRoomState();

  const totalPrize = useMemo(() => {
    let total = 0;
    let someRoomUndefined = false;

    for (const digit of digits) {
      const room = rooms[digit];
      if (room?.prizeReserved !== undefined && room?.round?.totalPrize !== undefined) {
        const prizeReserved = Number(utils.formatUnits(room.prizeReserved, 6));
        const totalPrize = Number(utils.formatUnits(room.round.totalPrize, 6));
        total += prizeReserved + totalPrize;
      } else {
        someRoomUndefined = true;
        break;
      }
    }

    return { prize: total, loading: someRoomUndefined };
  }, [rooms]);

  const { data: soldData } = useRequest<{ sold: number }>({
    path: "/ticket/sold/today",
    config: {
      refreshInterval: 30_000,
    },
  });

  const [activeTab, setActiveTab] = useState<number>();
  const MemoTabGroup = useMemo(
    () => (
      <TabGroup
        tabs={["Info", "Play", "Rank", "How to"]}
        className="mx-auto lg:w-max"
        buttonClassName="px-6 py-3 lg:py-3 w-1/4 lg:text-lg md:text-base text-xs"
        onTabChange={setActiveTab}
      />
    ),
    []
  );

  useEffect(() => {
    if (activeTab) {
      document.getElementById(`${activeTab}`)?.scrollIntoView({
        behavior: "smooth",
        block: "start",
        inline: "nearest",
      });
    }
  }, [activeTab]);

  return (
    <>
      <div className="mt-[60px] md:mt-[60px]"></div>
      <div className="block p-5 bg-black lg:hidden">{MemoTabGroup}</div>
      <div className="relative bg-no-repeat bg-cover bg-portion-box">
        <motion.div
          className="px-6 pt-10 pb-12 lg:px-20 lg:pb-20"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -25% 0px" }}
          transition={{ staggerChildren: 0.1 }}
          onViewportEnter={() => setIsCountVisible(true)}
        >
          <div className="flex flex-col justify-center gap-10 lg:flex-row">
            <div>
              <img
                className="lg:w-[820px] lg:-ml-16"
                src={require("../assets/decimal-box/title-logo@3x.png")}
                alt=""
              />
              <p className="text-lg font-light 3xl:w-[930px] lg:w-[610px] 3xl:-mt-14 lg:-mt-10 lg:text-left text-center">
                Matching five numbers allows you to win a larger prize pool. After the numbers have
                been drawn and the matching tickets identified, the prizes are distributed. You will
                be awarded prizes based on how many other tickets won in the same prize bracket as
                yours.
              </p>
              <div className="flex flex-col justify-center mt-6 xl:flex-row lg:justify-start">
                <div className="flex justify-center lg:justify-start">
                  <img
                    className="h-12 3xl:h-16"
                    src={require("../assets/secure-by-gold@2x.png")}
                    alt=""
                  ></img>
                  <img
                    className="h-12 mx-5 3xl:h-16"
                    src={require("../assets/chainlink@2x.png")}
                    alt=""
                  ></img>
                </div>
                <div className="flex justify-center mt-6 lg:justify-start xl:mt-0">
                  <a
                    href="https://info.billionbox.io/products/the-decimal-box"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center mr-10"
                  >
                    <img className="h-5 mr-2" src={require("../assets/icon/question.png")} alt="" />
                    <p className="text-lg uppercase text-gradient gold">How to play</p>
                  </a>
                  <a
                    href="https://info.billionbox.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex items-center"
                  >
                    <img className="h-5 mr-2" src={require("../assets/icon/doc.png")} alt="" />
                    <p className="text-lg uppercase text-gradient gold">doc</p>
                  </a>
                </div>
              </div>
            </div>
            <GradientBorderDiv id="0" className="flex-none px-5 py-4 text-center lg:mt-10">
              <div className="mt-8">
                <p className="mb-2 text-xl 3xl:text-2xl lg:mb-3">THE DECIMAL BOX</p>
                {totalPrize.loading ? (
                  <Skeleton containerClassName="w-40" className="h-[30px]" />
                ) : (
                  <CountUpNumber
                    className="text-3xl font-bold text-gradient gold"
                    startCounting={isCountVisible}
                    end={totalPrize.prize}
                    decimals={0}
                    prefix="$"
                  />
                )}
                <p className="mt-2 text-xl 3xl:text-2xl lg:mt-3">In Prizes</p>
              </div>
              <p className="px-3 mt-10 mb-6 text-lg">
                Don't miss your chance!
                <br />
                <span className="px-1 font-bold text-gold">
                  {Boolean(soldData) ? (
                    <>{soldData?.sold}</>
                  ) : (
                    <Skeleton
                      containerClassName="w-12 inline-block px-1"
                      className="inline-block"
                    />
                  )}
                </span>
                numbers have been sold today!
              </p>

              <img
                className="absolute top-0 right-0 z-10 w-10 lg:-right-12"
                src={require("../assets/portion-box/star-1.png")}
                alt=""
              />
              <img
                className="absolute z-10 right-2 lg:-right-10 top-12 w-14"
                src={require("../assets/portion-box/star-2.png")}
                alt=""
              />
            </GradientBorderDiv>
          </div>
        </motion.div>
        <div className="absolute inset-x-0 bottom-0 lg:h-[100px] h-[50px] bg-gradient-to-t from-black z-10"></div>
      </div>
    </>
  );
}
