import { BigNumber, ContractFactory } from "ethers";
import { useState } from "react";
import { useContractWrite } from "wagmi";
import Prediction from "../../contracts/PredictionV3.json";

type PredictionWriteFns = "betBear" | "betBull" | "claim";

// prettier-ignore
type PredictionWriteArgs<Fn> = 
  Fn extends "betBear" ? [string | BigNumber | number, string | BigNumber | number] :
  Fn extends "betBull" ? [string | BigNumber | number, string | BigNumber | number] :
  Fn extends "claim" ? [number[] | string[]] :
  never;

type PredictionWriteError = "";

export function usePredictionV3Write<WriteFn extends PredictionWriteFns>(
  address: string,
  functionName: WriteFn,
  args?: PredictionWriteArgs<WriteFn>
) {
  const [writeError, setWriteError] = useState<PredictionWriteError>();

  return {
    writeError,
    ...useContractWrite({
      addressOrName: address,
      contractInterface: Prediction,
      functionName,
      mode: "recklesslyUnprepared",
      args,
      onError(error: any) {
        setWriteError(
          ContractFactory.getInterface(Prediction).parseError(error.error.data.data)
            .name as PredictionWriteError
        );
      },
    }),
  };
}
