import { BigNumber, BigNumberish, ContractFactory } from "ethers";
import { useState } from "react";
import { useContractWrite } from "wagmi";
import PortionBox from "../../contracts/PortionBox.json";
import { roomAddress, RoomDigit } from "./../../config";

type PortionBoxWriteFns = "executeRound" | "buyWithToken" | "buyWithETH" | "buy" | "claim";

// prettier-ignore
type PortionBoxWriteArgs<Fn> = 
  Fn extends "executeRound" ? undefined :
  Fn extends "buyWithToken" ? [string, string | BigNumber | number, BigNumberish[], BigNumberish[]] :
  Fn extends "buyWithETH" ? [BigNumberish, BigNumberish[], BigNumberish[]] :
  Fn extends "buy" ? [BigNumberish[], BigNumberish[]] :
  Fn extends "claim" ? [BigNumberish[]] :
  never;

type PortionBoxWriteError =
  | "CallOnlyFirstRound"
  | "ExceedMaximumNumberPerTx"
  | "ExceedMaximumSizePerRound"
  | "ExecuteBeforeDelayPeriod"
  | "InitRoundNotStart"
  | "InvalidFeeBPS"
  | "InvalidNumber"
  | "InvalidNumberAndSizeLength"
  | "NotTicketOwner"
  | "RoundClosed";

export function usePortionBoxWrite<WriteFn extends PortionBoxWriteFns>(
  room: RoomDigit,
  functionName: WriteFn,
  args?: PortionBoxWriteArgs<WriteFn>
) {
  const [writeError, setWriteError] = useState<PortionBoxWriteError>();
  const overrides: any = {
    value: functionName === "buyWithETH" ? args?.[0] : "0",
  };

  return {
    writeError,
    ...useContractWrite({
      addressOrName: roomAddress[room],
      contractInterface: PortionBox,
      functionName,
      mode: "recklesslyUnprepared",
      args,
      overrides,
      onError(error: any) {
        setWriteError(
          ContractFactory.getInterface(PortionBox).parseError(error.error.data.data)
            .name as PortionBoxWriteError
        );
      },
    }),
  };
}
