import { memo, useState } from "react";
import clsx from "clsx";
import { useAssetContext } from "../context/PredictionAssetContext";

interface Stringify {
  toString: () => string;
}

type TabGroupProps = {
  tabs: Stringify[];
  className?: string;
  buttonClassName?: string;
  imageName: Stringify[];
  initialTabIndex?: number;
  onTabChange?: (index: number) => void;
  disabled?: boolean;
  page?: string;
};

const _TabGroupMode = ({
  tabs,
  className,
  buttonClassName,
  imageName,
  onTabChange,
  initialTabIndex = 0,
  disabled,
}: TabGroupProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState(initialTabIndex);
  const asset = useAssetContext();

  function changeGame(tab: Stringify) {
    if (tab.toString() === "UP / DOWN") {
      window.location.href = `/prediction${asset}`;
    } else if (tab.toString() === "HIGH / LOW") {
      window.location.href = `/prediction-pro/high-low${asset}`;
    } else if (tab.toString() === "EVEN / ODD") {
      window.location.href = `/prediction-pro/even-odd${asset}`;
    }
  }
  return (
    <div
      className={clsx(
        "tabs rounded-full flex items-center text-center bg-blue-gray",
        className,
        disabled ? "opacity-30" : ""
      )}
    >
      {tabs.map((tab, index) => (
        <button
          key={index}
          onClick={() => {
            setActiveTabIndex(index);
            onTabChange?.(index);
            changeGame(tab);
          }}
          disabled={disabled}
          className={clsx(
            "rounded-full py-3 px-4",
            buttonClassName,
            activeTabIndex === index && "blue"
          )}
        >
          <div className="flex items-center justify-center">
            <img
              className="w-4 mr-1"
              src={require(`../assets/prediction/${imageName[index]}.png`)}
              alt=""
            />
            <p>{tab.toString()}</p>
          </div>
        </button>
      ))}
    </div>
  );
};

export const TabGroupMode = memo(_TabGroupMode);
