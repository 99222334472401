import { useMemo } from "react";
import { config } from "../../config";

export function useMaxAmount(bearAmount: string, bullAmount: string) {
  return useMemo(() => {
    const bearNumber = Number(bearAmount);
    const bullNumber = Number(bullAmount);
    return {
      bear: config.predictionMaxAmount - bearNumber,
      bull: config.predictionMaxAmount - bullNumber,
    };
  }, [bearAmount, bullAmount]);
}