import { NotificationProvider } from "../common/NotificationProvider";
import { Dashboard } from "../my-dashboard/Dashboard";
import { MyHistory } from "../my-dashboard/MyHistory";

export function MyDashboard() {
  return (
    <NotificationProvider>
      <Dashboard />
      <MyHistory />
    </NotificationProvider>
  )
}