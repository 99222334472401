import { useEffect, useState } from "react";
import { Round } from "./usePortionBoxRead";

export enum RoundStatus {
  open,
  closed,
  executing,
}

export const useRoundStatus = (round?: Round, watch?: boolean) => {
  const [currentTime, setCurrentTime] = useState(Math.floor(Date.now() / 1000));

  useEffect(() => {
    let interval: NodeJS.Timer | undefined;
    if (watch) {
      interval = setInterval(() => setCurrentTime(Math.floor(Date.now() / 1000)), 1000);
    }

    return () => clearInterval(interval);
  }, [watch]);

  if (!round) {
    return RoundStatus.open;
  }

  const status = round.executeAfterTime.lte(currentTime)
    ? RoundStatus.executing
    : round.closeTime.lte(currentTime)
    ? RoundStatus.closed
    : RoundStatus.open;

  return status;
};
