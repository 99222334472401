import clsx from "clsx";
import { useChainlinkPriceCalculate } from "../../../hooks/contracts";

type MaticRateInfoProps = {
  amount: number;
  show: boolean;
};

export const MaticRateInfo = ({ amount, show }: MaticRateInfoProps) => {
  const amountMaticToUsd = useChainlinkPriceCalculate("matic", amount, show);

  return (
    <div
      className={clsx(
        "flex justify-center mt-3 text-xs gap-x-1 text-white/30",
        !show && "invisible"
      )}
    >
      <span className="text-gradient gold">Rate</span> <span>{amount} MATIC</span>
      <img className="h-4" src={require("../../../assets/icon/swap@2x.png")} />
      <span>~{amountMaticToUsd.toLocaleString()} USD</span>
    </div>
  );
};
