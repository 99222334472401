import { BigNumber } from "ethers";
import { useContractEvent } from "wagmi";
import { address } from "../../config";
import PortionBox from "../../contracts/PortionBox.json";

export const usePortionBoxStartRoundEvent = (listener: (roundId: BigNumber) => void) => {
  useContractEvent({
    addressOrName: address.PORTION_BOX2,
    contractInterface: PortionBox,
    eventName: "StartRound",
    listener(roundId) {
      listener(roundId);
    },
  });
};
