import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";
import Step1Icon from "../assets/step-1.svg"
import Step2Icon from "../assets/step-2.svg"
import Step3Icon from "../assets/step-3.svg"

export function HowToPlay() {
  return (
    <div id="3" className="relative overflow-hidden bg-gradient-black-to-blue">
      <div className="absolute inset-x-0 top-0 h-[150px] bg-gradient-to-b from-black"></div>
      <img
        alt=""
        className="absolute top-0 left-0 z-0 hidden w-96 lg:block"
        src={require("../assets/cube-trans4.png")}
      />
      <div className="container py-10 mx-auto text-center lg:py-8">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.5, staggerChildren: 0.03 }}
        >
          <AnimatedText size="md" text="H" />
          <AnimatedText size="md" text="O" />
          <AnimatedText size="md" text="W" />
          <AnimatedText size="md" className="ml-4" text="T" />
          <AnimatedText size="md" text="O" />
          <AnimatedText size="md" className="ml-4" text="P" gold />
          <AnimatedText size="md" text="L" gold />
          <AnimatedText size="md" text="A" gold />
          <AnimatedText size="md" text="Y" gold />
        </motion.div>
        <div className="text-lg font-light max-w-[800px] mx-auto">
          If the digits on your tickets match the winning numbers in the correct order, you win a
          portion of the prize pool. Congratulations!
        </div>

        <div className="grid grid-cols-1 gap-5 mx-6 mt-8 lg:grid-cols-3 lg:-mx-4">
          <div className="z-10 flex flex-col items-center px-6 card-border-gradient-gray-to-white lg:px-8">
            <img alt="" className="mt-10 w-36 lg:w-52" src={Step1Icon} />
            <p className="mt-6 text-2xl font-bold">Buy ticket</p>
            <p className="pb-10 mt-2 text-lg font-light">
              Choose the ticket numbers to match with Bitcoin decimal places to win the prize
            </p>
          </div>

          <div className="z-10 flex flex-col items-center px-8 card-border-gradient-gray-to-white">
            <img alt="" className="mt-10 w-36 lg:w-52" src={Step2Icon} />
            <p className="mt-6 text-2xl font-bold">Wait for the draw</p>
            <p className="pb-10 mt-2 text-lg font-light">
              The waiting period at the end of each round is when the draw occurs
            </p>
          </div>

          <div className="flex flex-col items-center px-8 card-border-gradient-gray-to-white">
            <img alt="" className="mt-10 w-36 lg:w-52" src={Step3Icon} />
            <p className="mt-6 text-2xl font-bold">Check for prizes</p>
            <p className="pb-10 mt-2 text-lg font-light">Check your prize after the draw is over</p>
          </div>
        </div>
      </div>
    </div>
  );
}
