import React, { useEffect } from "react";
import { ClaimReward } from "../common/ClaimReward";
import { NotificationProvider } from "../common/NotificationProvider";
import { Ticket } from "../common/Ticket";
import { RoomDigit } from "../config";
import { Round, Ticket as TicketModel } from "../hooks/contracts/usePortionBoxRead";
import { GraphPriceInfo } from "../portion-box-room/GraphPriceInfo";
import { useRoundStore } from "../store/round-store";

type DashboardProps = {
  digit: RoomDigit;
  formattedPrice?: string;
  round?: Round;
  prize?: string;
  ticketBalance?: string;
  tabGroup: React.ReactNode;
  winTickets: TicketModel[];
  onClaimed: () => void;
};

export function Dashboard(props: DashboardProps) {
  const { setEndRound } = useRoundStore.getState();

  useEffect(() => {
    setEndRound(undefined);
  }, [props.digit, setEndRound]);

  return (
    <div className="relative bg-gradient-black">
      <img
        alt="decorate cube"
        className="absolute top-0 left-0 z-0 opacity-60"
        src={require("../assets/cube-trans4.png")}
      />
      <div className="container relative z-10 py-2 mx-auto">
        {/* {props.tabGroup} */}

        <GraphPriceInfo status digit={props.digit} />

        <div className="grid grid-cols-1 gap-8 mt-6 lg:grid-cols-2">
          <Ticket ticketBalance={props.ticketBalance} />
          <NotificationProvider>
            <ClaimReward
              winTickets={props.winTickets}
              prize={props.prize}
              onClaimed={props.onClaimed}
            />
          </NotificationProvider>
        </div>
      </div>
    </div>
  );
}
