import { BigNumberish } from "ethers";
import { isAddress } from "ethers/lib/utils";
import { useMemo } from "react";
import { RoomDigit } from "./../../config";
import { usePortionBoxRead } from "./usePortionBoxRead";
import { usePortionBoxTicketRead } from "./usePortionBoxTicketRead";

export const useSpecificRoomTicketBalance = (
  room: RoomDigit,
  owner: string,
  roundId?: BigNumberish
) => {
  const {
    data: tickets = [],
    contractRead: { refetch },
  } = usePortionBoxRead({
    room,
    functionName: "ticketsOf",
    args: owner,
    enabled: isAddress(owner),
  });

  const ticketBalance = useMemo(() => {
    if (roundId) {
      return tickets.filter((ticket) => ticket.round.roundId.eq(roundId)).length;
    } else {
      return tickets.length;
    }
  }, [tickets, roundId]);

  const formattedTicketBalance = useFormattedTicketBalance(owner, ticketBalance);
  return { tickets, balance: ticketBalance, formatted: formattedTicketBalance, refetch };
};

export const useTicketBalance = (owner: string) => {
  const { data: tickets2 = [], contractRead: contractRead2 } = usePortionBoxRead({
    room: 2,
    functionName: "ticketsOf",
    args: owner,
  });

  const { data: tickets3 = [], contractRead: contractRead3 } = usePortionBoxRead({
    room: 3,
    functionName: "ticketsOf",
    args: owner,
  });

  const { data: tickets4 = [], contractRead: contractRead4 } = usePortionBoxRead({
    room: 4,
    functionName: "ticketsOf",
    args: owner,
  });

  const ticketBalance = useMemo(() => {
    return [...tickets2, ...tickets3, ...tickets4].reduce(
      (prev, cur) => prev + cur.numbers.length,
      0
    );
  }, [tickets2, tickets3, tickets4]);

  const refetch = useMemo(() => {
    return () =>
      [contractRead2, contractRead3, contractRead4].forEach((contractRead) =>
        contractRead.refetch()
      );
  }, [contractRead2, contractRead3, contractRead4]);

  const formattedTicketBalance = useFormattedTicketBalance(owner, ticketBalance);

  return { balance: ticketBalance, formatted: formattedTicketBalance, refetch };
};

export const useFormattedTicketBalance = (address: string, balance?: number) => {
  return useMemo(() => {
    if (!isAddress(address)) {
      return "0";
    }

    return balance?.toLocaleString();
  }, [balance, address]);
};
