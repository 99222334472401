import { useMemo } from "react";
import Chart from "react-apexcharts";
import useSWR from "swr";
import { Asset, assetPairs, config } from "../config";
import { useGameContext } from "../context/PredictionGameContext";
import { fetcher, useCurrentEpoch } from "../hooks";
import { PredictionRound } from "../hooks/contracts/usePredictionV3Read";
import { useMultipleRounds } from "./hook/useMultipleRounds";

type BinanceKlines = [
  openTime: number,
  open: string,
  high: string,
  low: string,
  close: string,
  volume: string,
  closeTime: number,
  quoteAssetVolume: string,
  numberOfTrades: number,
  takerBuyBaseAssetVolume: string,
  takerBuyQuoteAssetVolume: string,
  ignore: string
];

export function LineChart({
  asset,
  onHoverRound,
  onUnhoverRound,
}: {
  asset: Asset;
  onHoverRound?: (round: PredictionRound) => void;
  onUnhoverRound?: () => void;
}) {
  const game = useGameContext();
  const { currentEpoch } = useCurrentEpoch({
    keyTab: "BTC",
    activeTabIndex: 0,
    pro: game,
  });

  const symbol = useMemo(() => assetPairs[asset], [asset]);
  const { data } = useSWR<BinanceKlines[], Error>(
    `${config.binanceKlinesUrl}?symbol=${symbol}&interval=1m&limit=100`,
    fetcher,
    {
      refreshInterval: 1000 * 30,
    }
  );

  const chartRoundIds = useMemo(() => {
    const roundIds: number[] = [];

    for (let i = 1; i <= 10; i++) {
      roundIds.push(currentEpoch - i);
      if (currentEpoch - i === 0) {
        break;
      }
    }

    return roundIds;
  }, [currentEpoch]);

  const { data: rounds = [] } = useMultipleRounds(chartRoundIds, game);

  const series = useMemo(() => {
    return (
      data?.map((kline, index) => {
        const round = rounds?.find(
          (round) => round.closeOracleId.toNumber() === Math.floor(kline[6] / 1000)
        );

        return {
          round,
          x: kline[0],
          y: kline[4],
          closeTime: kline[6],
        };
      }) ?? []
    );
  }, [data, rounds]);

  const annotations = useMemo(() => {
    return series
      .map((serie) => {
        if (!serie.round) return null;
        return {
          x: serie.x,
          y: serie.y,
          marker: {
            size: 0,
          },
          image: {
            path: "/bb-icon1.png",
            width: 35,
            height: 35,
          },
        };
      })
      .filter((a) => a !== null);
  }, [series]);

  const options = useMemo(
    () =>
      ({
        grid: { borderColor: "rgba(255, 255, 255, 0.2)" },
        chart: {
          toolbar: {
            show: false,
            autoSelected: "pan",
          },
          background: "#04132C",
        },
        stroke: {
          width: 2,
        },
        annotations: {
          points: annotations,
        },
        xaxis: {
          type: "datetime",
          crosshairs: {
            position: "back",
            stroke: {
              width: 2,
              dashArray: 4,
              color: "#E2D27F",
            },
          },
          labels: {
            format: "HH:mm",
            datetimeUTC: false,
            style: { colors: "#ffffff80" },
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          forceNiceScale: true,
          labels: {
            offsetX: -10,
            style: { colors: "#ffffff80" },
          },
          tooltip: {
            enabled: false,
          },
          opposite: true,
        },
        tooltip: {
          followCursor: true,
          enabled: true,
          custom: (tooltip: any) => {
            const timeframeData = series?.[tooltip.dataPointIndex];
            if (!timeframeData) {
              return "";
            }
            const price = timeframeData.y;
            const [digits, decimals] = price.toString().split(".");
            const decimalNumbers = decimals?.slice(0, 2) ?? "00";

            if (timeframeData.round) {
              onHoverRound?.(timeframeData.round);
            }

            return `
          <div class="px-1">
            <div>$${digits}.${decimalNumbers}</div>
          </div>
        `;
          },
        },
      } as any),
    [series, annotations, rounds]
  );

  return <Chart height={400} series={[{ data: series }]} options={options} />;
}
