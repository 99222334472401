import clsx from "clsx";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { useAccount } from "wagmi";
import { ProgressBar } from "../common/ProgressBar";
import { config } from "../config";
import { useAssetColor } from "../hooks/useAssetColor";
import { usePrediction, useRounds } from "../hooks/usePrediction";
import { usePredictionRewardCurrency } from "../hooks/usePredictionRewardCurrency";
import { useCountdown } from "../hooks/useTime";
import { CardCalculating } from "./CardCalculating";
import { PlayGameButton } from "./PlayGameButton";
import { Tab } from "./SelectAsset";

type CardLiveType = {
  activeTabIndex: number;
  currentEpoch: number;
  keyTab: Tab;
};

export const CardLive = forwardRef<HTMLDivElement, CardLiveType>(
  ({ currentEpoch, activeTabIndex, keyTab }, ref) => {
    const { address } = useAccount();
    const [progress, setProgress] = useState(0);

    // end round is n-1
    const epoch = currentEpoch - 1 > 0 ? currentEpoch - 1 : 1;
    const { lockPrice, prizePool, latestPrice, priceChange, status, payoutUp, payoutDown } =
      usePrediction({ activeTabIndex, currentEpoch: epoch, keyTab, address });
    const { closeTimestamp } = useRounds({ currentEpoch: epoch, keyTab, activeTabIndex });
    const { countDown } = useCountdown(closeTimestamp);
    const rewardCurrency = usePredictionRewardCurrency(keyTab.toString());
    const color = useAssetColor(keyTab);
    const priceChangeUp = useMemo(() => parseFloat(priceChange) > 0, [priceChange]);

    useEffect(() => {
      const interval = setInterval(() => {
        const now = Math.floor(new Date().getTime() / 1000);
        const diff = Number(closeTimestamp) - now;
        const progress = diff > 0 ? 1 - diff / config.predictionRoundTime[keyTab] : 1;
        setProgress(progress * 100);
      }, 1000);

      return () => clearInterval(interval);
    }, [closeTimestamp, keyTab]);

    if (countDown === "00:00") {
      return (
        <div ref={ref}>
          <CardCalculating currentEpoch={epoch} />
        </div>
      );
    }

    return (
      <div
        ref={ref}
        id="card-live"
        className={clsx("w-card lg:mr-8 mr-4 mb-4 relative border rounded-2xl", color.border)}
      >
        <div className="flex items-center justify-center py-2 space-x-1 text-sm bg-blue-gray rounded-t-2xl">
          <img className="w-4" src={require("../assets/bb-icon1.png")} alt="" />
          <span className="font-semibold">LIVE</span>
          <span className="font-semibold text-[#9a9a9a]">#{epoch}</span>
        </div>

        <ProgressBar size="sm" rounded={false} progress={progress} progressClassName={color.bg} />

        <div className="pt-3 bg-gradient-black-to-blue-r rounded-2xl">
          <div className="flex justify-between px-4 text-sm font-bold">
            <p>Prize pool</p>
            <p>
              {prizePool} {rewardCurrency}
            </p>
          </div>
          <div className="flex justify-between px-4 pb-4 text-sm">
            <p>Last price</p>
            <p>${lockPrice}</p>
          </div>
          <div
            className={`${
              priceChangeUp ? "card-gradient-green" : "card-gradient-red"
            } mx-4 py-2 flex flex-col items-center justify-center`}
          >
            <div className="flex items-center">
              <img className="w-3 mr-1" src={require("../assets/icon/shield.png")} alt="" />
              <p className="py-1 text-sm text-gradient gold">Current price</p>
            </div>
            <p
              className={`text-xl font-bold ${priceChangeUp ? "text-[#46b900]" : "text-[#ff1010]"}`}
            >
              ${latestPrice}
            </p>
            <div
              className={`flex items-center space-x-1 font-bold text-sm ${
                priceChangeUp ? "text-[#46b900]" : "text-[#ff1010]"
              }`}
            >
              <img
                alt=""
                className="w-2"
                src={
                  priceChangeUp
                    ? require("../assets/up-icon-green.png")
                    : require("../assets/down-icon-red.png")
                }
              />
              <span>${priceChange}</span>
            </div>
          </div>
          <div className="flex items-center justify-center mt-4 space-x-1">
            <img className="w-3" src={require("../assets/icon/clock.png")} alt="" />
            <p>Close in</p>
            <p className="text-lg font-bold text-gradient gold">{countDown}</p>
          </div>

          <div className="relative grid grid-cols-2 top-5">
            <div
              className={clsx(
                "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                color.border
              )}
            >
              Payout {payoutDown}x
            </div>
            <div
              className={clsx(
                "px-2 py-1 mx-6 text-xs font-semibold text-center border-2 rounded-xl bg-black",
                color.border
              )}
            >
              Payout {payoutUp}x
            </div>
          </div>
          <div className={clsx("h-[2px]", color.bg)}></div>
          <div className="grid grid-cols-2">
            <PlayGameButton highlight={!priceChangeUp} type="down" className="rounded-bl-2xl" />
            <PlayGameButton highlight={priceChangeUp} type="up" className="rounded-br-2xl" />
          </div>
        </div>
        <div className="absolute inset-x-0">
          <div className="relative flex justify-around -top-3">
            {status === "UP" ? (
              <>
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : status === "DOWN" ? (
              <>
                <img
                  className={`w-20 mx-6 visible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
                <img
                  className={`w-20 mx-6 invisible`}
                  src={require("../assets/entered.png")}
                  alt=""
                />
              </>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
);
