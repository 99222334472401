import { RoomDigit, roomAddress } from "./../config";
import { useRequest } from "./useRequest";

export type PopularNumberResponse = {
  popularNumbers: {
    number: number;
    size: number;
  }[];
};

export const usePopularNumbers = (room: RoomDigit, roundId?: string | number) => {
  const address = roomAddress[room];
  const { data, mutate } = useRequest<PopularNumberResponse>({
    path: `/room/${address}/round/${roundId}/popular-number`,
    enabled: roundId !== undefined,
  });

  return { popularNumbers: data?.popularNumbers, refetch: mutate };
};
