import { useMemo } from "react";

export const ToggleGraphSwitch = (props: {
  checked: boolean;
  jackpot?: boolean;
  onCheckChanged: (checked: boolean) => void;
}) => {
  const leftPercent = useMemo(() => (props.checked ? "0%" : "50%"), [props.checked]);
  return (
    <div className="right-4 absolute flex py-2 w-[140px] justify-around overflow-hidden border rounded-3xl bg-[#04132c]  border-white/5">
      <div
        style={{ left: leftPercent }}
        className="absolute inset-y-0 z-0 w-1/2 transition-all rounded-3xl bg-gradient-blue"
      ></div>
      <button
        onClick={() => props.onCheckChanged(true)}
        className="relative w-full text-sm font-medium md:text-base"
      >
        Show
      </button>
      <button
        onClick={() => props.onCheckChanged(false)}
        className="relative w-full text-sm font-medium md:text-base"
      >
        Hide
      </button>
    </div>
  );
};
