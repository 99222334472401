import { BigNumber } from "ethers";
import { useContractEvent } from "wagmi";
import { address } from "../../../config";
import MultiplierBox from "../../../contracts/MultiplierBox";

type Args = {
  account?: string;
  listener: (event: NodeEvent) => void;
};

type NodeEvent = {
  game: string;
  sender: string;
  roundId: BigNumber;
  numbers: BigNumber[];
  amounts: BigNumber[];
  totalAmount: BigNumber;
  timestamp: BigNumber;
};

export const useMultiplierBoxPlayEvent = ({ account, listener }: Args) => {
  useContractEvent({
    addressOrName: address.BB_MAX_ADDRESS,
    contractInterface: MultiplierBox,
    eventName: "Play",
    listener(node) {
      const [, sender] = node;
      if (account && sender !== account) return;
      listener({
        game: node[0],
        sender: node[1],
        roundId: node[2],
        numbers: node[3],
        amounts: node[4],
        totalAmount: node[5],
        timestamp: node[6],
      });
    },
  });
};
