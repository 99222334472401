import { motion } from "framer-motion";
import { useMemo } from "react";
import { config } from "../config";

export type NotificationType = "add" | "random" | "swipe" | "failed" | "sendTx" | "success";

export type TransactionNotificationProps = {
  type: NotificationType;
  number?: string;
  text?: string;
  txhash?: string;
  gold?: boolean;
};

export function TransactionNotification({
  type,
  number,
  txhash,
  text,
  gold = false,
}: TransactionNotificationProps) {
  let icon;
  let body;

  switch (type) {
    case "add":
      icon = gold
        ? require("../assets/icon/add-gold@2x.png")
        : require("../assets/icon/add@2x.png");
      body = (
        <div className="text-base font-bold lg:text-2xl">
          <div className="text-base font-bold text-gradient gold lg:text-2xl">{number} ADDED</div>
          <div className="text-sm font-medium">Check on section below, to buy this number</div>
        </div>
      );
      break;

    case "random":
      icon = gold
        ? require("../assets/icon/add-gold@2x.png")
        : require("../assets/icon/add@2x.png");
      body = (
        <div>
          <div className="text-base font-bold text-gradient gold lg:text-2xl">RANDOM ADDED</div>
          <div className="text-sm font-medium">
            Your random number is <span className="tracking-widest">{number}</span>{" "}
          </div>
        </div>
      );
      break;

    case "swipe":
      body = (
        <div className="w-full px-6 pb-3 font-bold text-center">
          <img
            src={
              gold
                ? require("../assets/icon/add-gold@2x.png")
                : require("../assets/icon/add@2x.png")
            }
            className="inline-block w-16 -mt-20"
            alt="icon"
          />
          <div className="tracking-[4px] uppercase lg:text-3xl text-gradient gold">{number}</div>
          <div className="mt-2 text-base font-medium">
            Added 10 number by Swipe Digit. Check on “Selected Number” section below, to buy these
            numbers
          </div>
        </div>
      );
      break;

    case "failed":
      icon = require("../assets/icon/fail.png");
      body = (
        <div>
          <p className="text-base font-bold text-gradient gold lg:text-2xl">Failed Transaction</p>
          <p className="text-sm font-medium">The transaction cannot be succeed due to error</p>
        </div>
      );
      break;

    case "sendTx":
      icon = gold
        ? require("../assets/icon/submit-gold@2x.png")
        : require("../assets/icon/submit.png");
      body = (
        <div>
          <p className="text-base font-bold text-gradient gold lg:text-2xl">
            Transaction Submitted!
          </p>
          <p className="font-medium text-gold">
            View on Explorer:{" "}
            <a target="_blank" href={config.explorerUrl + "/tx/" + txhash} rel="noreferrer">
              {txhash?.slice(0, 10)}...
            </a>{" "}
          </p>
        </div>
      );
      break;

    case "success":
      icon = gold
        ? require("../assets/icon/submit-gold@2x.png")
        : require("../assets/icon/submit.png");
      body = (
        <div>
          <p className="text-base font-bold text-gradient gold lg:text-2xl">Success!</p>
          <p className="font-medium text-gold">{text}</p>
        </div>
      );
      break;
  }

  const rightBottomNotification = useMemo(
    () => ["success", "sendTx", "failed", "add", "random"].includes(type),
    [type]
  );

  return (
    <>
      {rightBottomNotification && (
        <motion.div className={`fixed z-40 bottom-6 right-6`}>
          <motion.div
            className="card-border-gradient-blue-to-yellow-bg-black-to-blue p-5 my-3 flex items-start w-[250px] lg:w-[365px]"
            style={{ boxShadow: "0 0 15px 0 rgba(229, 213, 131, 0.4)" }}
            initial={{ opacity: 0, y: "200%" }}
            animate={{ opacity: 1, y: "0%" }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.25 }}
          >
            <img src={icon} className="w-6 mt-1 mr-3 lg:w-8" alt="icon" />
            {body}
          </motion.div>
        </motion.div>
      )}
      {!rightBottomNotification && (
        <motion.div
          className={`fixed inset-0 z-40 flex items-center justify-center`}
          initial={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
          animate={{ backgroundColor: "rgba(0, 0, 0, 0.7)" }}
          exit={{ backgroundColor: "rgba(0, 0, 0, 0)" }}
        >
          <motion.div
            className="card-border-gradient-blue-to-yellow-bg-black-to-blue p-5 my-3 flex items-start w-[250px] lg:w-[365px]"
            style={{ boxShadow: "0 0 15px 0 rgba(229, 213, 131, 0.4)" }}
            initial={{ opacity: 0, y: "200%" }}
            animate={{ opacity: 1, y: "0%" }}
            exit={{ opacity: 0, y: "200%" }}
            transition={{ duration: 0.15 }}
          >
            {body}
          </motion.div>
        </motion.div>
      )}
    </>
  );
}
