import Popup from "reactjs-popup";
import { Button } from "../common/Button";
import InfoIcon from "../assets/info-black-icon.svg";
import clsx from "clsx";

type SwipeDigitProps = {
  enableSwipeButton: boolean;
  toggleIsSwipe: () => void;
  gold?: boolean;
};

export function SwipeDigit({ enableSwipeButton, toggleIsSwipe, gold }: SwipeDigitProps) {
  return (
    <div
      className={clsx(
        " flex flex-col items-center text-center lg:pt-5 pt-12 pb-2 px-2 relative",
        gold
          ? "card-border-gradient-gold-bg-black border-sm"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <Popup
        trigger={
          <img
            className="absolute w-6 top-1.5 right-1.5"
            src={require("../assets/icon/info1.png")}
            alt=""
          />
        }
        on="hover"
        position="bottom right"
        mouseEnterDelay={0}
        contentStyle={{
          border: 0,
          backgroundColor: "#faf6a6",
          backgroundImage: "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
        }}
        arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
      >
        <div className="px-[4px] py-1">
          <div className="flex items-start space-x-2">
            <img alt="delay info" className="w-4 mt-[1px]" src={InfoIcon} />
            <div className="text-black">
              <div className="text-sm font-bold">Swipe Digit</div>
              <div className="text-[11px] leading-tight">
                Add 0-9 numbers to your selected digit. ex. If you want 10 number of 8’X’ (x=
                1,2,3,4,5,6,7,8,9,0)
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <img className="h-[30px]" src={require("../assets/union.png")} alt="" />
      <p className="pt-6 text-sm font-bold">SWIPE DIGIT</p>
      <p className="pb-4 text-xs font-light">Add 0-9 numbers to your selected digit</p>
      <Button
        className="lg:px-20 px-12 py-[7px]"
        preset="gold"
        buttonSize="sm"
        disabled={!enableSwipeButton}
        onClick={() => toggleIsSwipe()}
      >
        Swipe
      </Button>
      <p
        className={`text-[10px] font-normal text-center text-[#a7a1a1] mt-1 ${
          enableSwipeButton && "invisible"
        }`}
      >
        Complete the number before using this option
      </p>
    </div>
  );
}
