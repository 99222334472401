import { BigNumber, ContractFactory } from "ethers";
import { useState } from "react";
import { useContractWrite } from "wagmi";
import { predictionAddr } from "../../config";
import Prediction from "../../contracts/PredictionV2.json";

type PredictionWriteFns = "betBear" | "betBull" | "claim";

// prettier-ignore
type PredictionWriteArgs<Fn> = 
  Fn extends "betBear" ? string | BigNumber | number :
  Fn extends "betBull" ? string | BigNumber | number:
  Fn extends "claim" ? [number[] | string[]] :
  never;

type PredictionWriteError = "";

export function usePredictionV2Write<WriteFn extends PredictionWriteFns>(
  functionName: WriteFn,
  args?: PredictionWriteArgs<WriteFn>,
  amount?: string | BigNumber | number
) {
  const [writeError, setWriteError] = useState<PredictionWriteError>();
  const overrides: any = {
    value: functionName === "betBear" || functionName === "betBull" ? amount : "0",
  };

  return {
    writeError,
    ...useContractWrite({
      addressOrName: predictionAddr.MATIC,
      contractInterface: Prediction,
      functionName,
      mode: "recklesslyUnprepared",
      args,
      overrides,
      onError(error: any) {
        console.log(error);
        setWriteError(
          ContractFactory.getInterface(Prediction).parseError(error.error.data.data)
            .name as PredictionWriteError
        );
      },
    }),
  };
}
