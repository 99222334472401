import { HTMLProps } from "react";
import Popup from "reactjs-popup";
import InfoIcon from "../assets/icon/info-icon.svg";

type NumberCircleCardProp = HTMLProps<HTMLDivElement> & {
  number?: number | string;
  borderWidth?: number;
  tooltip?: JSX.Element;
  gold?: boolean
};

export function NumberCircleCard({
  number,
  className,
  borderWidth,
  children,
  tooltip,
  gold,
  ...rest
}: NumberCircleCardProp) {
  return (
    <div
      className={`mx-1 relative ${className} ${gold ? "ellipse-border-gradient-gold" : "ellipse-border-gradient-blue-to-yellow-bg-black-to-blue"}`}
      style={{ borderWidth }}
      {...rest}
    >
      {tooltip && (
        <div className="absolute top-0 hidden right-1 lg:block">
          <Popup
            trigger={
              <img alt="info trigger icon" className="w-5" src={InfoIcon} />
            }
            on="hover"
            position="bottom center"
            mouseEnterDelay={0}
            contentStyle={{
              border: 0,
              backgroundColor: "#faf6a6",
              backgroundImage:
                "linear-gradient(180deg, #faf6a6 0%, #c5a44e 100%)",
            }}
            arrowStyle={{ color: "#faf6a6", strokeWidth: 0 }}
          >
            <div className="px-[4px] py-1">{tooltip}</div>
          </Popup>
        </div>
      )}
      <div className="absolute font-bold -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2">
        {children ?? number}
      </div>
    </div>
  );
}
