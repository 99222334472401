import { LinkButton } from "../common/Button";

export function EmptyWiningPlayer({ linkTo }: { linkTo?: string }) {
  return (
    <div className="m-auto flex flex-col items-center justify-center w-max h-[405px]">
      <img className="lg:w-16 w-14 my-3" src={require("../assets/bb-icon1.png")} alt="" />
      <div className="font-normal lg:text-lg text-base text-[#a5a5a5] text-center">
        <p>Didn't have a record.</p>
        <p>Be the first. Let's play.</p>
      </div>

      <LinkButton
        to={linkTo ?? ""}
        className="lg:px-14 px-12 uppercase text-black my-3"
        preset="primary"
        buttonSize="sm"
      >
        Play
      </LinkButton>
    </div>
  );
}
