import { useChainlinkPrice } from "../../../../hooks/contracts";

export const useTokenTotalAmount = (totalAmount: number, convertToMatic?: boolean) => {
  const { latest } = useChainlinkPrice("matic", { enabled: !!convertToMatic });

  if (!convertToMatic) {
    return totalAmount;
  } else {
    return Math.ceil(totalAmount / (latest?.parsedAnswer ?? 0) * 100) / 100;
  }
};
