import { motion } from "framer-motion";
import { AnimatedText } from "../common/AnimatedText";

export const WinningCriteria = () => {
  return (
    <div className="relative bg-gradient-black-to-blue">
      <div className="container relative z-10 pt-20 mx-auto">
        <motion.div
          className="text-center"
          initial="hidden"
          whileInView="visible"
          viewport={{ once: true, margin: "0px 0px -20% 0px" }}
          transition={{ delayChildren: 0.2, staggerChildren: 0.04 }}
        >
          <AnimatedText size="md" text="W" goldMax />
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="I" goldMax />
          <AnimatedText size="md" text="N" goldMax />
          <AnimatedText size="md" text="G" goldMax />
          <br className="lg:hidden" />
          <AnimatedText size="md" text="C" className="lg:ml-4" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="T" />
          <AnimatedText size="md" text="E" />
          <AnimatedText size="md" text="R" />
          <AnimatedText size="md" text="I" />
          <AnimatedText size="md" text="A" />
        </motion.div>

        <p className="mt-3 text-lg font-light text-center">
          Decentralized Prediction Game - Correct Up & Down trend to Win the Prize!
        </p>

        <div className="grid grid-cols-1 lg:grid-cols-2">
          <img src={require("../assets/group-3@2x.png")} alt="" />
          <div className="lg:pt-20">
            <div className="flex items-center">
              <img
                src={require("../assets/multiplier-box/multi-dream-icon.png")}
                alt=""
                className="w-10"
              />
              <p className="ml-3 text-2xl font-bold">The BB Max</p>
            </div>
            <p className="mt-6 text-lg font-light">
              Choose two BTC decimals and unlock the chance to win big with up to 95x your original
              bet in the Double Lotto. Prefer a lower risk? Try the Single Lotto with a 8x payout.
            </p>
            <p className="mt-5 text-lg font-light">
              BB Max combines excitement and rewards, allowing you to put your prediction skills to
              the test. Join Billion Box now and dive into the world of BB Max!
            </p>
          </div>
        </div>
      </div>
      <img
        alt=""
        className="absolute bottom-0 right-0 hidden w-96 -z-0 lg:block"
        src={require("../assets/cube-trans6.png")}
      />
      <img
        alt=""
        className="absolute top-0 left-0 hidden w-96 -z-0 lg:block"
        src={require("../assets/cube-trans2.png")}
      />
      <div className="absolute inset-x-0 top-0 lg:h-[120px] h-[100px] bg-gradient-to-b from-black -z-0"></div>
      <div className="absolute inset-x-0 bottom-0 lg:h-[120px] h-[100px] bg-gradient-to-t from-black -z-0"></div>
    </div>
  );
};
