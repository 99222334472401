type PercentSelectorProps = {
  percents: number[];
  onSelect: (percent: number) => void;
};

export const PercentSelector = ({ percents, onSelect }: PercentSelectorProps) => {
  return (
    <div className="grid w-full grid-cols-5 gap-[6px] mt-8">
      {percents.map((percent, index) => {
        return (
          <button
            key={index}
            className="py-2 text-center card-border-brown-bg-blue"
            onClick={() => onSelect(percent)}
          >
            <p className="text-sm font-bold text-white">{percent === 100 ? "MAX" : `${percent}%`}</p>
          </button>
        );
      })}
    </div>
  );
};
