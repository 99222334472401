import { useMemo } from "react";
import SuccessIcon from "../../../assets/success.svg";
import { address } from "../../../config";
import { useCurrentRoundState } from "../CurrentRoundProvider";
import clsx from "clsx";

export const tokens = [
  {
    icon: require("../../../assets/icon/usdt.png"),
    name: "usdt",
    address: address.USDT_ADDRESS,
    decimals: 6,
  },
  {
    icon: require("../../../assets/icon/usdc.png"),
    name: "usdc",
    address: address.USDC_ADDRESS,
    gasToken: false,
    decimals: 6,
  },
  // {
  //   icon: require("../../../assets/icon/matic.png"),
  //   name: "matic",
  //   gasToken: true,
  //   decimals: 18,
  // },
  // {
  //   icon: require("../../../assets/icon/dbusd.png"),
  //   name: "dbusd",
  //   address: address.DBUSD_ADDRESS,
  //   decimals: 6,
  // },
];

export type Token = (typeof tokens)[number];

type TokenSelectorProps = {
  value: Token;
  onChange: (token: Token) => void;
};

export const TokenSelector = ({ value, onChange }: TokenSelectorProps) => {
  const { digits = 1 } = useCurrentRoundState();

  return (
    <div className="grid grid-cols-2 gap-2 mt-4">
      {tokens.map((token) => {
        return (
          <button
            key={token.name}
            className="relative flex flex-col items-center py-2 overflow-hidden card-border-brown-bg-blue"
            onClick={() => onChange(token)}
          >
            {value.name === token.name && (
              <div className="absolute inset-0 flex items-center justify-center">
                <div className="absolute inset-0 z-0 bg-gold opacity-90"></div>
                <img src={SuccessIcon} className="z-10 w-6" alt="Selected icon" />
              </div>
            )}
            <img className="w-7" src={token.icon} alt="" />
            <p className="text-xs font-semibold">{token.name.toUpperCase()}</p>
          </button>
        );
      })}
    </div>
  );
};
