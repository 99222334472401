import { motion } from "framer-motion";
import { AnimateLogo } from "../home/AnimateLogo";

export const Loading = () => {
  return (
    <div className="absolute inset-0 z-10 flex flex-col items-center justify-center bg-black/80">
      <motion.div
        initial={{ rotate: -24, y: -10 }}
        animate={{ rotate: -24, y: 0 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 1.25 }}
      >
        <AnimateLogo className="w-[80px]" />
      </motion.div>
      <motion.div
        className="mt-2 text-xs font-audiowide"
        initial={{ opacity: 1 }}
        animate={{ opacity: 0.2 }}
        transition={{ repeat: Infinity, repeatType: "reverse", duration: 2 }}
      >
        Loading...
      </motion.div>
    </div>
  );
};
