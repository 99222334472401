import { BigNumberish, utils } from "ethers";
import { useMemo } from "react";
import { config } from "../../../config";
import { NumberInfo } from "./../../../store/multiplier-box-number-store";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";

export const useNumberLimits = (numbers: NumberInfo[], roundId?: BigNumberish, game?: string) => {
  const maxPerNumber = config.multiplierBox.maxPerNumber[game ?? ""] ?? 0;
  const { data = [] } = useMultiplierBoxRead({
    functionName: "getRoundNumbers",
    args: [roundId, numbers.map((n) => n.number), numbers.map(() => game ?? "")],
    enabled: !!roundId && numbers.length > 0,
    watch: true,
  });

  const dataInNumbers = useMemo(
    () => data.map((limit) => Number(utils.formatUnits(limit, config.stablecoinDecimals))),
    [data]
  );

  const maxAmount = useMemo(() => {
    return dataInNumbers.reduce((prev, cur) => prev + maxPerNumber - cur, 0);
  }, [dataInNumbers]);

  return { data, numbers: dataInNumbers, maxAmount };
};
