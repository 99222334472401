import { motion, Variants } from "framer-motion";
import clsx from "clsx";

type AnimateTextProps = {
  text: string;
  size?: "lg" | "md";
  gold?: boolean;
  goldMax?: boolean;
  className?: string;
};

export const AnimatedText = ({ text, size = "lg", gold, goldMax, className }: AnimateTextProps) => {
  const item: Variants = {
    hidden: {
      y: "150%",
    },
    visible: {
      y: 0,
      transition: {
        type: "spring",
        damping: 30,
        stiffness: 150,
      },
    },
  };

  const textStyle = size === "lg" ? "text-4xl lg:text-7xl" : "text-4xl lg:text-5xl";

  return (
    <span className="inline-block overflow-hidden">
      <motion.span
        className={clsx(
          "inline-block overflow-hidden font-audiowide uppercase text-center",
          gold && " text-gradient gold",
          goldMax && " text-gradient gold-max",
          className,
          textStyle
        )}
        variants={item}
      >
        {text}
      </motion.span>
    </span>
  );
};
