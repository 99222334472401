
export const Odd = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.72 0H2.136C.955 0 0 .966 0 2.155v11.69C0 15.036.955 16 2.135 16h11.586a2.145 2.145 0 0 0 2.136-2.154V2.156A2.147 2.147 0 0 0 13.721 0zM3.96 6.153c-1.147 0-2.076-.95-2.076-2.123 0-1.172.93-2.123 2.075-2.123 1.146 0 2.076.951 2.076 2.123 0 1.172-.93 2.123-2.076 2.123zm8 8c-1.146 0-2.075-.95-2.075-2.122s.929-2.123 2.075-2.123c1.146 0 2.076.95 2.076 2.123 0 1.172-.93 2.123-2.076 2.123zm-4-4c-1.147 0-2.076-.95-2.076-2.123 0-1.172.93-2.123 2.075-2.123 1.147 0 2.077.951 2.077 2.123 0 1.173-.93 2.124-2.076 2.124z"
      />
    </svg>
  );
};
