import { useEffect } from "react";
import { useMultiplierBoxRead } from "./useMultiplierBoxRead";
import { useMultiplierBoxStartRoundEvent } from "./useMultiplierBoxStartRoundEvent";

export const useCurrentRound = () => {
  const { data: currentRoundId, contractRead } = useMultiplierBoxRead({
    functionName: "currentRoundId",
  });

  const { data: round } = useMultiplierBoxRead({
    functionName: "rounds",
    args: currentRoundId,
    enabled: !!currentRoundId,
  });

  const newRoundId = useMultiplierBoxStartRoundEvent();

  useEffect(() => {
    if (newRoundId) {
      contractRead.refetch();
    }
  }, [newRoundId]);

  return round;
};
