import { useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { RoundCountdownDisplay } from "../common/round-countdown/RoundCountdownDisplay";
import { TabGroup } from "../common/TabGroup";
import { usePopularNumbers } from "../hooks/usePopularNumbers";
import { useSoldNumber } from "../hooks/useSoldNumber";
import { EmptyState } from "../portion-box/EmptyState";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";

export function TicketsInRound() {
  const { room = "" } = useParams();
  const roomDigit = Number(room);
  const roomState = useSpecificRoomState(room);
  const { popularNumbers } = usePopularNumbers(room, roomState?.roundId);
  const soldNumbers = useSoldNumber(roomDigit, roomState?.roundId);

  const [activeTabIndex, setActiveTabIndex] = useState(roomDigit === 1 ? 1 : 0);
  const numberPerTab = useMemo(() => {
    if (roomDigit === 1) return 10;
    return 10 ** (roomDigit - 1);
  }, [roomDigit]);

  const startNumber = useMemo(
    () => (activeTabIndex - 1) * numberPerTab,
    [activeTabIndex, numberPerTab]
  );

  const tabs = useMemo(() => {
    const numberTabs = [...Array(10)].map((_, i) => {
      const startNumber = (numberPerTab * i).toString().padStart(roomDigit, "0");
      const lastNumber = (numberPerTab * (i + 1) - 1).toString().padStart(roomDigit, "0");
      return `${startNumber}-${lastNumber}`;
    });

    return ["Popular", ...numberTabs];
  }, [numberPerTab, roomDigit]);

  const popularNumberElements = useMemo(() => {
    return popularNumbers?.map((number) => (
      <TicketInfo
        key={number.number.toString()}
        number={number.number}
        digit={roomDigit}
        sold={number.size}
      />
    ));
  }, [popularNumbers, roomDigit]);

  const numberElements = useMemo(() => {
    return [...Array(numberPerTab)].map((_, index) => {
      const number = startNumber + index;
      return (
        <TicketInfo
          key={number.toString()}
          number={number}
          digit={roomDigit}
          sold={soldNumbers.get(number) ?? 0}
        />
      );
    });
  }, [numberPerTab, roomDigit, startNumber, soldNumbers]);

  return (
    <div className="pt-8 my-6 overflow-hidden text-center card-border-gradient-blue-to-yellow-bg-black-to-blue">
      <div className="flex flex-col items-center justify-center px-12 font-bold lg:flex-row lg:justify-between">
        <p className="text-lg text-center md:text-xl">
          <span className="text-gradient gold">
            {roomState?.round?.totalSize.toNumber().toLocaleString()} Tickets{" "}
          </span>
          <span>in this Round!</span>
        </p>
        <div className="flex items-center mt-3 text-lg text-center md:text-xl lg:mt-0">
          <RoundCountdownDisplay
            drawComponent={() => <></>}
            executingComponent={(countdown) => (
              <>
                <img className="w-6 pr-2" src={require("../assets/icon/time1.png")} alt="" />
                <p>Draw in</p>
                <p className="pl-1 text-gradient gold">{countdown}</p>
              </>
            )}
            calculatingComponent={
              <>
                <img className="w-6 pr-2" src={require("../assets/icon/time1.png")} alt="" />
                <p>Draw in</p>
                <p className="pl-1 text-gradient gold">Calculating..</p>
              </>
            }
          />
        </div>
      </div>

      <div className="relative z-10 pl-4 my-6 overflow-x-auto no-scrollbar lg:pl-12">
        {roomDigit > 1 && (
          <TabGroup
            tabs={tabs}
            onTabChange={setActiveTabIndex}
            className="flex items-center justify-center mx-auto text-center rounded-full bg-blue-gray text-md w-max"
            buttonClassName="rounded-full py-2 px-6 font-normal"
          />
        )}
      </div>
      <div className="overflow-scroll no-scrollbar h-[500px] pb-12">
        <div className="grid justify-center grid-cols-7 gap-5 px-4 mx-auto w-max lg:px-12">
          {activeTabIndex === 0 ? popularNumberElements : numberElements}
        </div>
        {activeTabIndex === 0 && popularNumbers?.length === 0 && (
          <EmptyState className="h-full">
            This round didn't have any purchased number <br />
            Waiting for the next round.
          </EmptyState>
        )}
      </div>
    </div>
  );
}

function TicketInfo({ number, digit, sold }: { number: number; digit: number; sold: number }) {
  return (
    <div className="py-3 w-28 card-border-brown-bg-blue">
      <p
        className={`text-xl font-bold tracking-[0.12em] ${
          sold === 0 ? "text-[#6f6f6f]" : "text-gradient gold"
        }`}
      >
        {number.toString().padStart(digit, "0")}
      </p>
      <p className="text-sm font-light">{sold.toLocaleString()} Tickets</p>
    </div>
  );
}
