import { Outlet } from "react-router-dom";
import { Navbar } from "./common/Navbar";
import { Footer } from "./home/Footer";

function App() {
  return (
    <>
      <Navbar />
      <Outlet />
      <Footer />
      <div id="modal-container"></div>
    </>
  );
}

export default App;
