import { address } from "../../../config";
import { useRequest } from "../../useRequest";

export type MultiplierBoxRankingResponse = {
  ranking: Rank[];
};

export type Rank = {
  total: number;
  user: string;
  totalBuy: number;
};

export const useMultiplierBoxRanking = (game: string) => {
  const request = useRequest<MultiplierBoxRankingResponse>({
    path: `/max/${address.BB_MAX_ADDRESS}/game/${game}/ranking`,
    enabled: !!game
  });

  return request;
};
