import clsx from "clsx";
import { useMemo } from "react";
import { config } from "../config";
import { useGameContext } from "../context/PredictionGameContext";
import { useCurrentEpoch, useRounds } from "../hooks/usePrediction";
import { useCountdown } from "../hooks/useTime";
import { SilverProp } from "./CurrentPrice";
import { IndexTab } from "./LetsPlay";

export function Time({ index, keyTab, silver, pro }: IndexTab & SilverProp & { pro?: boolean }) {
  const game = useGameContext();
  const { currentEpoch } = useCurrentEpoch({
    keyTab,
    activeTabIndex: index,
    pro: pro ? game : undefined,
  });

  const epoch = currentEpoch === 0 ? 1 : currentEpoch;
  const { lockTimestamp } = useRounds({
    currentEpoch: epoch,
    keyTab,
    activeTabIndex: index,
    watch: false,
    pro: pro ? game : undefined,
  });

  const { countDown } = useCountdown(lockTimestamp);
  const roundTime = useMemo(() => config.predictionRoundTime.pro[keyTab] / 60 / 60, [keyTab]);

  return (
    <div
      className={clsx(
        "flex flex-col items-center justify-center col-span-1 py-3 lg:col-span-3 lg:py-4 font-roboto-condensed",
        silver
          ? "card-border-gradient-blue-to-purple-bg-black-to-blue"
          : "card-border-gradient-blue-to-yellow-bg-black-to-blue"
      )}
    >
      <div className="flex items-center mb-1">
        <img
          className="w-4 mr-1 lg:mr-2"
          src={require(silver ? "../assets/icon/clock@2x.png" : "../assets/icon/clock.png")}
          alt=""
        />
        <p className="text-sm">Time</p>
      </div>
      {countDown === "00:00" && (
        <p
          className={clsx(
            "mb-1 text-xl font-bold lg:text-2xl",
            silver ? "text-gradient silver" : "text-gradient gold"
          )}
        >
          Calculating...
        </p>
      )}
      {countDown !== "00:00" && (
        <p
          className={clsx(
            "mb-1 text-xl font-bold lg:text-2xl",
            silver ? "text-gradient silver" : "text-gradient gold"
          )}
        >
          {countDown}
        </p>
      )}
      <p className="text-sm font-normal">({roundTime} hour)</p>
    </div>
  );
}
