import { BigNumberish } from "ethers";
import { Button, useNotificationContext } from "../common";
import { useMultiplierBoxWrite } from "../hooks/contracts/multiplier-box";

type ClaimButtonProps = {
  disabled: boolean;
  rewardRoundIds: BigNumberish[];
  onClaimed?: () => void;
};

export const ClaimButton = ({ disabled, rewardRoundIds, onClaimed }: ClaimButtonProps) => {
  const { writeAsync } = useMultiplierBoxWrite("claim", [rewardRoundIds]);
  const { pushNotification } = useNotificationContext();

  async function claim() {
    const result = await writeAsync();

    pushNotification({
      key: result.hash,
      type: "sendTx",
      txhash: result.hash,
    });

    await result.wait(3);

    pushNotification({
      key: Math.random().toString(),
      type: "success",
      text: "You've claimed the rewards!",
    });

    onClaimed?.();
  }

  return (
    <Button className="py-2 text-sm w-36" preset="goldMax" onClick={claim} disabled={disabled}>
      CLAIM
    </Button>
  );
};
