import { memo } from "react";
import { RoomDigit } from "../config";
import { NumberCircleCard } from "./NumberCircleCard";

const _NumberCircleGroup = ({
  number,
  room,
  className,
}: {
  number: number;
  room: RoomDigit;
  className: string;
}) => {
  return (
    <>
      {number
        .toString()
        .padStart(Number(room), "0")
        .split("")
        .map((number, index) => (
          <NumberCircleCard number={number} key={index.toString()} className={className} />
        ))}
    </>
  );
};

export const NumberCircleGroup = memo(_NumberCircleGroup);
