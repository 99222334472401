import { motion } from "framer-motion";
import { memo, useEffect, useRef, useState } from "react";
import { useCountUp } from "react-countup";
import { fadeInVariants } from "../animation";

type CountUpNumberProps = {
  startCounting: boolean;
  end: number;
  decimals: number;
  prefix: string;
  duration?: number;
  className?: string;
};

function _CountUpNumber({
  startCounting,
  end,
  decimals,
  prefix,
  duration = 2.75,
  className,
}: CountUpNumberProps) {
  const ref = useRef(null);
  const [animate, setAnimate] = useState(false);
  const { start: startFn, update } = useCountUp({
    ref,
    start: 0,
    end,
    prefix,
    decimals,
    separator: ",",
    duration,
    startOnMount: false,
  });

  useEffect(() => {
    if (startCounting) {
      setAnimate(true);
      startFn();
    }
  }, [startCounting, startFn]);

  useEffect(() => {
    if (startCounting) update(end);
  }, [end, update, startCounting]);

  return (
    <motion.div
      className={`inline-block ${className}`}
      animate={animate ? "visible" : "hidden"}
      variants={fadeInVariants}
    >
      <span ref={ref}></span>
    </motion.div>
  );
}

export const CountUpNumber = memo(_CountUpNumber);
