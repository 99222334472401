import { useEffect, useId, useRef } from "react";

let tvScriptLoadingPromise;

const assetPairs = {
  btc: "BINANCE:BTCUSDT",
  eth: "BINANCE:ETHUSDT",
  bnb: "BINANCE:BNBUSDT",
};

/**
 *
 * @param {{ timeframe: Number, asset: "btc" | "eth" | "bnb"}} props
 */
export default function TradingViewGraph(props) {
  const onLoadScriptRef = useRef();
  const id = useId();

  useEffect(() => {
    onLoadScriptRef.current = createWidget;

    if (!tvScriptLoadingPromise) {
      tvScriptLoadingPromise = new Promise((resolve) => {
        const script = document.createElement("script");
        script.id = "tradingview-widget-loading-script";
        script.src = "https://s3.tradingview.com/tv.js";
        script.type = "text/javascript";
        script.onload = resolve;

        document.head.appendChild(script);
      });
    }

    tvScriptLoadingPromise.then(() => onLoadScriptRef.current && onLoadScriptRef.current());

    return () => (onLoadScriptRef.current = null);

    function createWidget() {
      if (document.getElementById(id) && "TradingView" in window) {
        new window.TradingView.widget({
          autosize: true,
          symbol: assetPairs[props.asset],
          interval: props.timeframe.toString(),
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          theme: "dark",
          style: "1",
          locale: "en",
          toolbar_bg: "#f1f3f6",
          enable_publishing: false,
          allow_symbol_change: false,
          hide_top_toolbar: true,
          hide_legend: true,
          save_image: false,
          container_id: id,
        });
      }
    }
  }, [props.timeframe, props.asset, id]);

  return (
    <div className="h-full tradingview-widget-container">
      <div className="h-full" id={id} />
    </div>
  );
}
