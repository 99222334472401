import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button } from "../common/Button";
import { useNotificationContext } from "../common/NotificationProvider";
import { useBillionboxNumberRemaining } from "../hooks/useBillionboxNumberRemaining";
import { useNumberReachMaxPerTx } from "../hooks/useNumberReachMaxPerTx";
import { useNumberSize } from "../hooks/useNumberSize";
import { useRoundData } from "../hooks/useRoundData";
import { useWalletGuard } from "../hooks/useWalletGuard";
import { useSpecificRoomState } from "../portion-box/RoomDataProvider";
import { useNumberStore } from "../store/number-store";
import { config } from "../config";

export function Random() {
  const { addNumber, numbers } = useNumberStore();
  const { pushNotification } = useNotificationContext();

  const { room = "1" } = useParams();
  const roomState = useSpecificRoomState(room);
  const { buyers } = useRoundData(room, roomState?.roundId);
  const numberSize = useNumberSize(buyers);
  const getNumberRemaining = useBillionboxNumberRemaining(numberSize);
  const maxNumberPerTx = config.maxNumberPerTx(room);
  const reachMaxPerTx = useNumberReachMaxPerTx(maxNumberPerTx);

  const maxNumberInRoom = useMemo(() => 10 ** Number(room), [room]);
  const addRandom = useWalletGuard(() => {
    let randomNumber = Math.floor(Math.random() * maxNumberInRoom);

    while (
      getNumberRemaining(randomNumber, 1) <= 0 ||
      numbers.findIndex((n) => n.number === randomNumber) !== -1
    ) {
      randomNumber = Math.floor(Math.random() * maxNumberInRoom);
    }

    addNumber(randomNumber, 1, maxNumberPerTx);
    pushNotification({
      type: "random",
      number: randomNumber.toString().padStart(Number(room), "0"),
      key: Math.random().toString(),
    });
  });

  return (
    <>
      <div className="relative flex flex-col items-center px-2 py-10 text-center card-border-gradient-blue-to-yellow-bg-black-to-blue lg:py-6">
        <img className="h-[35px]" src={require("../assets/random.png")} alt="" />
        <p className="pt-4 text-sm font-bold">RANDOM NUMBER</p>
        <p className="pb-4 text-xs font-light">No idea? try random number</p>
        <Button
          className="lg:px-14 px-6 py-[7px]"
          preset="primary"
          buttonSize="sm"
          onClick={addRandom}
          disabled={reachMaxPerTx}
        >
          Add Random
        </Button>
      </div>
    </>
  );
}
