import { BigNumberish } from "ethers";
import { useMemo } from "react";
import { useBuyersInRound } from "./useBuyersInRound";

export const useRoundNumberStats = (roundId?: BigNumberish, game?: string, digits?: number) => {
  const buyers = useBuyersInRound(roundId, game);
  const allNumbers = useMemo(
    () => Array.from({ length: 10 ** (digits ?? 0) }, (_, i) => i),
    [digits]
  );

  const numberStats = useMemo(() => {
    const numberStats = allNumbers.map((number) => ({ number, amount: 0 }));

    buyers.forEach((buyer) => {
      buyer.number.forEach((number, index) => {
        numberStats[number].amount += buyer.amounts[index];
      });
    });

    return numberStats.sort((a, b) => b.amount - a.amount);
  }, [buyers]);

  return numberStats;
};
