import { BigNumberish } from "ethers";
import { address } from "../../config";

export const gameWinningLogic = {
  [address.BB_MAX_LOTTERY_ADDRESS.toLowerCase()]: (number: BigNumberish, answer: BigNumberish) => {
    return number.toString() === answer.toString();
  },
  [address.BB_MAX_ONE_DIGIT_ADDRESS.toLowerCase()]: (
    number: BigNumberish,
    answer: BigNumberish
  ) => {
    return number.toString() === answer.toString().padStart(2, "0")[0];
  },
};

export function isNumberWinning(number: BigNumberish, answer: BigNumberish, game: string) {
  return gameWinningLogic[game.toLowerCase()]?.(number, answer) ?? false;
}
